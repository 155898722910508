import { CButton } from "@coreui/react";
import styled from "styled-components";

export const StyledAccountSettingsButton = styled(CButton)`
  position: relative;
  width: 34px;
  height: 34px;
  padding: 5px;
  vertical-align: middle;
  text-align: center;
  font-size: 17px;
  font-weight: 700;
  color: #ffffff;

  :hover {
    color: #ffffff;

    ::after {
      content: "";
      width: 34px;
      position: absolute;
      top: -5px;
      right: 0;
      left: 0;
      border-top: 3px solid var(--secondary) !important;
    }
  }
`;

export const AlertCircle = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: 0;
  left: 0;
  border-radius: 100%;
  background-color: var(--danger);
  z-index: 999;
`;
