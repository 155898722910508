import React, { useState } from "react";
import { RangeWrapper, StyledInput } from "./RangeInput.style";

const RangeInput = ({
  name,
  defaultValue,
  onChange,
  labelLeft = "0%",
  labelRight = "100%",
  step = "10",
  color,
  max = "100",
  margin = "4",
}) => {
  const [rangeValue, setRangeValue] = useState(defaultValue || 0);

  return (
    <RangeWrapper className={`my-${margin}`}>
      <span className="text-muted">{labelLeft}</span>
      <StyledInput
        id={name + "range-input"}
        name={name}
        value={rangeValue}
        onChange={(e) => {
          onChange(e);
          setRangeValue(e.target.value);
        }}
        type="range"
        min="0"
        max={max}
        step={step}
        color={color}
      />
      <p className="range-value">{rangeValue}%</p>
      <span className="text-muted">{labelRight}</span>
    </RangeWrapper>
  );
};

export default RangeInput;
