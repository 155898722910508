import { cloneDeep, kebabCase, set } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SECTOR_KEYS } from "src/constants/sectorKeys";
import {
  selectFormatCategories,
  selectInvestmentProviders,
  selectPensionProviders,
  selectSites,
  selectSuppliers,
  selectTasks,
} from "src/store/projectSelectors";
import { convertFormatString } from "src/utils/ad-data-utils";
import useSectors from "../../useSectors/useSectors";

const useTasksState = ({ setTouched }) => {
  const tasks = useSelector(selectTasks);

  const [localTasks, setLocalTasks] = useState(tasks || {});

  useEffect(() => {
    if (!tasks) return;
    setLocalTasks(tasks);
  }, [tasks]);

  const adFormats = useSelector(selectFormatCategories) || [];

  const { SECTORS } = useSectors();

  const sites = useSelector(selectSites);

  const pensionProviders =
    useSelector(selectPensionProviders)?.map(
      (provider) => provider["provider-name"]
    ) ?? [];

  const investmentProviders =
    useSelector(selectInvestmentProviders)?.map(
      (provider) => provider["provider-name"]
    ) ?? [];

  const suppliers = useSelector(selectSuppliers);

  const onTasksChange = (e) => {
    setTouched();
    const { name, value } = e.target;

    setLocalTasks((prevTasks) => {
      const newTasks = cloneDeep(prevTasks);
      if (name.includes("entire-section")) {
        setEntireSector(newTasks, name, value);
      } else {
        resetEntireSector(newTasks, name);
      }
      return set(newTasks, name, value);
    });
  };

  const resetEntireSector = (newTasks, name) => {
    const lastSegmentInName = name.match(/[^\.]*$/)?.[0];

    const nameToReset = name.replace(lastSegmentInName, "entire-section");
    set(newTasks, nameToReset, "none");
  };

  const setEntireSector = (newTasks, name, value) => {
    if (name.includes(`${SECTOR_KEYS.siteData}.entire-section`)) {
      sites.forEach((site) => {
        setEntireSector(
          newTasks,
          `${SECTOR_KEYS.siteData}.${site.id}.entire-section`,
          value,
          site["is-cinema"]
        );
        set(
          newTasks,
          `${SECTOR_KEYS.siteData}.${site.id}.entire-section`,
          value
        );
      });
    } else {
      let fieldsToAssign = [];
      const sectorName = name.replace(".entire-section", "");
      if (name.includes(SECTOR_KEYS.siteData)) {
        const [, siteId] = name.split(".");
        const isCinema = !!sites?.find((site) => site.id === siteId)?.[
          "is-cinema"
        ];
        fieldsToAssign = getFieldsToAssign(sectorName, isCinema);
      } else {
        fieldsToAssign = getFieldsToAssign(sectorName);
      }

      const sectorObj = {};
      fieldsToAssign.forEach((field) => {
        sectorObj[field] = value;
      });
      set(newTasks, sectorName, sectorObj);
    }
  };

  const getFieldsToAssign = (sectorName, isCinema = false) => {
    if (sectorName.includes(SECTOR_KEYS.investmentData)) {
      const type = sectorName.split(".")[1];
      return type === "pensions" ? pensionProviders : investmentProviders;
    }
    if (sectorName.includes(SECTOR_KEYS.suppliersData)) {
      return suppliers.map((item) => kebabCase(item.name));
    }
    if (sectorName.includes(SECTOR_KEYS.adData)) {
      return [
        ...adFormats.map((field) => convertFormatString(field)),
        "ad-formats-category",
      ];
    }
    if (sectorName.includes(SECTOR_KEYS.siteData)) {
      return SECTORS.find(
        (sector) => sector.name === SECTOR_KEYS.siteData
      ).fieldsToAssign.filter((field) => isCinema || field !== "cinema");
    }
    return SECTORS.find((sector) => sector.name === sectorName).fieldsToAssign;
  };

  return {
    localTasks,
    onTasksChange,
  };
};

export default useTasksState;
