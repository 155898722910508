import { useEffect } from "react";
import { DB_PATHS } from "src/constants";
import { generateUUID } from "src/utils/common-utils/helpers";
import { useUpdateDatabase } from "..";
import { useCollection } from "../databaseHooks";
import { useDatabasePaths } from "src/hooks";

const useDesktopNotification = () => {
  const { getPath } = useDatabasePaths();
  const { updateDocument } = useUpdateDatabase();

  const { data: adminUsers } = useCollection(getPath(DB_PATHS.adminUsers));

  const addNotificationsForUser = async (user, notificationsArray) => {
    const existingNotifications = user.notification || [];

    const notificationsToAdd = notificationsArray.filter(
      (item) =>
        !existingNotifications.find(
          (existingItem) => existingItem.key === item.key
        )
    );

    if (!notificationsToAdd.length) return;

    await updateDocument([...DB_PATHS.adminUsers, user.id], {
      notification: [...existingNotifications, ...notificationsToAdd],
    });
  };

  const setNotification = async (contentArray, receiver = []) => {
    if (!contentArray.length) return;

    const notificationsArray = contentArray.map(({ title, body, key }) => {
      return { title, body, notifyID: generateUUID(), key };
    });

    if (!receiver?.length) {
      // send to all organisation users
      if (!adminUsers?.length) return;

      adminUsers.forEach((user) =>
        addNotificationsForUser(user, notificationsArray)
      );
    } else {
      receiver.forEach((user) =>
        addNotificationsForUser(user, notificationsArray)
      );
    }
  };

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);
  return {
    setNotification,
  };
};

export default useDesktopNotification;
