import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectOrgId } from "src/store/projectSelectors";
import { resetProject, setOrg } from "src/store/projectSlice";
import { selectPrimaryOrg, selectSecondaryOrgs } from "src/store/userSlice";

// routes config
import CIcon from "@coreui/icons-react";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { useDatabasePaths, useUpdateDatabase } from "src/hooks";
import { DB_PATHS } from "src/constants";

const SwitchOrgDropdown = () => {
  const secondary = useSelector(selectSecondaryOrgs);
  const primaryOrg = useSelector(selectPrimaryOrg);
  const currentOrg = useSelector(selectOrgId);

  const history = useHistory();
  const dispatch = useDispatch();

  const [orgList, setOrgList] = useState([]);

  const { getPath } = useDatabasePaths();

  const { getRef } = useUpdateDatabase();

  useEffect(() => {
    const orgs = [primaryOrg, ...(secondary || [])];

    const convertOrgs = async () => {
      let result = [];
      for (let org of orgs) {
        const ref = getRef(getPath(DB_PATHS.organisation, org));
        const orgDoc = await ref.get();
        const orgData = await orgDoc.data();
        const name = orgData?.name || "";
        result.push({ id: org, name });
      }
      setOrgList(result);
    };

    convertOrgs();
  }, [primaryOrg, secondary]);

  const switchOrg = (org) => {
    if (org !== currentOrg) {
      dispatch(resetProject());
      dispatch(setOrg(org));
      history.replace("/");
    }
  };

  return (
    <CDropdown className="mt-2">
      <CDropdownToggle className="py-0 mb-2" variant="ghost" caret={false}>
        <HiOutlineSwitchHorizontal size={20} style={{ color: "gray" }} />
      </CDropdownToggle>{" "}
      <CDropdownMenu>
        <CDropdownItem header>Switch organisation</CDropdownItem>
        {!!secondary?.length &&
          orgList.map((org) => {
            const isCurrent = org.id === currentOrg;
            const style = isCurrent ? { backgroundColor: "gainsboro" } : {};
            return (
              <CDropdownItem
                key={org.id}
                onClick={() => switchOrg(org.id)}
                disabled={isCurrent}
                style={style}
              >
                <CIcon name="cilBuilding" className="mr-2" /> {org.name}
              </CDropdownItem>
            );
          })}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default SwitchOrgDropdown;
