import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSteps } from "react-step-builder";
import {
  DemoWarning,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  VerticalRadioButtons,
} from "src/components";
import { convertStrToBool } from "src/utils/common-utils";
import { Subtitle } from "../components";
import OptionalQuestion from "../components/OptionalQuestion";
import OptionalQuestionNumber from "../components/OptionalQuestionNumber";

const EmployeeSurveyOffset = ({
  data: values = {},
  onChange,
  info,
  isDemo,
  workDays,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { jump, prev } = useSteps();

  const onPrev = async () => {
    if (values["is-commuting"] === false) {
      jump(4);
    } else {
      prev();
    }
  };

  const { t } = useTranslation();

  return (
    <FormStepWrapper
      onPrev={onPrev}
      aboveCardSlot={
        <>
          {isDemo && <DemoWarning />}
          <Subtitle content={t("employeeSurvey.offset")} />
        </>
      }
    >
      <OptionalQuestion name="q17">
        <QuestionWrapper>
          <h3>17. {t("employeeSurvey.q_travelOffset")}</h3>
          <VerticalRadioButtons
            name="offset-travel"
            data={[
              { value: true, label: t("shared.yes") },
              { value: false, label: t("shared.no") },
            ]}
            onChange={(e) =>
              onChange({
                target: {
                  name: e.target.name,
                  value: convertStrToBool(e.target.value),
                },
              })
            }
            defaultValue={values["offset-travel"]}
          />

          <ErrorText value={errors["offset-travel"]} />
        </QuestionWrapper>
      </OptionalQuestion>
      <OptionalQuestion name="q18">
        <QuestionWrapper>
          <h3>
            <OptionalQuestionNumber questionNumber={18} />.{" "}
            {t("employeeSurvey.q_totalOffset")}
          </h3>
          <VerticalRadioButtons
            name="offset-total"
            data={[
              { value: true, label: t("shared.yes") },
              { value: false, label: t("shared.no") },
            ]}
            onChange={(e) =>
              onChange({
                target: {
                  name: e.target.name,
                  value: convertStrToBool(e.target.value),
                },
              })
            }
            defaultValue={values["offset-total"]}
          />

          <ErrorText value={errors["offset-total"]} />
        </QuestionWrapper>
      </OptionalQuestion>
    </FormStepWrapper>
  );
};

export default EmployeeSurveyOffset;
