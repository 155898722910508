export const onCallHelp = ({ phoneNumber = "+1-123-456-7890" }) => {
  //Need to modify to the ZeroBees number
  window.open(`tel:${phoneNumber}`, "_self");
};

export const timestampToString = (timestamp) => {
  if (!timestamp) return "";
  const date = new Date(timestamp);
  const years = date.getFullYear();
  const months = date.getMonth() + 1;
  const days = date.getDate();

  const addZero = (value) => (value < 10 ? "0" + value : value);

  return `${addZero(days)}.${addZero(months)}.${years}`;
};

export const firstLetterToUpperCase = (string) => {
  if (!string) return "";
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const shuffleArray = (arr) => {
  return arr?.sort(() => Math.random() - 0.5) || [];
};

export const convertStringToDate = (date) => {
  if (!date) return;
  return new Date(date);
};

export const thousandsSeparator = (num) => {
  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
};

export const alphabetSort = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

export const hasLocalStorageFlagExpired = (flagName) => {
  const EXPIRED_DATE = localStorage.getItem(flagName);
  const CURRENT_TIME = new Date() / 1000;

  if (!EXPIRED_DATE) return true;

  if (EXPIRED_DATE > CURRENT_TIME) return false;

  if (EXPIRED_DATE <= CURRENT_TIME) {
    localStorage.removeItem(flagName);
    return true;
  }
};

export const generateUUID = () => {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};
