import React from "react";
import { useState } from "react";
import DataTable from "../DataTable/DataTable";
import ControlledInput from "../ControlledInput/ControlledInput";
import {
  EMPTY_ITEM,
  MATERIALS,
  MATERIALS_TABLE_COLUMNS,
  MATERIAL_SUB_CATEGORIES,
} from "./constants";
import BasicSelect from "../BasicSelect/BasicSelect";
import { BasicNumberInput } from "..";
import { isRequired, isRequiredBetween } from "src/utils/validationOptions";

const ConstructionMaterialsTable = ({
  tableName = "construction-materials",
  formMethods,
  values,
}) => {
  const { getValues, control, trigger } = formMethods;

  const [initValues, setInitValues] = useState(
    values[tableName] || [EMPTY_ITEM]
  );

  const refresh = () => {
    const newVal = getValues()[tableName];
    setInitValues(newVal);
  };

  return (
    <DataTable
      fields={MATERIALS_TABLE_COLUMNS}
      emptyItem={EMPTY_ITEM}
      tableName={tableName}
      {...{ initValues, formMethods }}
      scopedSlots={{
        material: (item, index) => {
          return (
            <td>
              <ControlledInput
                {...{ control, trigger, getValues }}
                Component={BasicSelect}
                name={`${tableName}.${index}.material`}
                options={MATERIALS}
                showError={false}
                rules={isRequired}
                refresh={refresh}
                customDefault=""
              />
            </td>
          );
        },
        subcategory: (item, index) => {
          const isSingleOption =
            MATERIAL_SUB_CATEGORIES[item.material]?.length === 1;

          return (
            <td style={{ minWidth: "120px" }}>
              {item?.material &&
                (isSingleOption ? (
                  <span className="font-semibold">General</span>
                ) : (
                  <ControlledInput
                    {...{ control, trigger, getValues }}
                    Component={BasicSelect}
                    name={`${tableName}.${index}.subcategory`}
                    options={MATERIAL_SUB_CATEGORIES[item.material] || []}
                    showError={false}
                    rules={isRequired}
                    refresh={refresh}
                    customDefault=""
                  />
                ))}
            </td>
          );
        },
        quantity: (item, index) => {
          const { material, subcategory } = item || {};

          const differentUnit = MATERIAL_SUB_CATEGORIES?.[material]?.find(
            (item) => item.value === subcategory
          )?.unit;

          return (
            <td>
              <div className="d-flex flex-row align-items-center ">
                <ControlledInput
                  {...{ control, trigger, getValues }}
                  Component={BasicNumberInput}
                  name={`${tableName}.${index}.quantity`}
                  showError={false}
                  rules={isRequiredBetween(0, 1e9)}
                  customDefault={0}
                  decimalScale={2}
                  allowNegative={false}
                />
                <span
                  style={{ width: "50px" }}
                  className="d-block ml-2 font-bold"
                >
                  {differentUnit || "kg"}
                </span>
              </div>
            </td>
          );
        },
      }}
    />
  );
};

export default ConstructionMaterialsTable;
