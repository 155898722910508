import React from "react";
import CheckboxItem from "./CheckboxItem";

const VerticalCheckboxes = ({
  data,
  name,
  onChange = () => {},
  className,
  defaultValues = [],
  twoColumns = false,
  ...rest
}) => {
  const middleIndex = Math.ceil(data.length / 2);

  return (
    <>
      {!twoColumns ? (
        <div
          className={`d-flex flex-column align-self-start ${className}`}
          {...rest}
        >
          {data.map((item, index) => {
            return (
              <CheckboxItem
                key={index}
                {...{ item, index, name, onChange, defaultValues }}
              />
            );
          })}
        </div>
      ) : (
        <div className="d-flex flex-column flex-md-row ">
          <div style={{ flex: "none" }} className="pl-0 mr-4 col-12 col-md-6">
            {data.slice(0, middleIndex).map((item, index) => (
              <CheckboxItem
                key={index}
                {...{ item, index, name, onChange, defaultValues }}
              />
            ))}
          </div>
          <div style={{ flex: "none" }} className="pl-0 col-12 col-md-6">
            {data.slice(middleIndex).map((item, index) => (
              <CheckboxItem
                key={index}
                index={middleIndex + index}
                {...{ item, name, onChange, defaultValues }}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default VerticalCheckboxes;
