import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { adapator_createDatabaseRef } from "src/adapters"
import { selectOrgId, selectPeriod } from "src/store/projectSelectors"

const useLatestLog = () => {
    const [log, setLog] = useState({})
    const [status, setStatus] = useState("idle")
    const orgId = useSelector(selectOrgId)
    const periodId = useSelector(selectPeriod)

    useEffect(() => {
        if (!orgId || !periodId) return
        async function fetchLog() {
            setStatus("loading")
            const logSnapshot = await adapator_createDatabaseRef(["organisations", orgId, "periods", periodId, "result-logs"]).orderBy("created", "desc").limit(1).get()
            if (logSnapshot.empty) return
            const log = logSnapshot.docs[0].data()
            setLog(log)
            setStatus("success")
        }

        fetchLog()
    }, [orgId, periodId])

    return { log, status }
}

export default useLatestLog