import { ButtonGhost } from "src/components";

import { useTranslation } from "react-i18next";

const HelpButton = ({ onCallHelp }) => {
  const { t } = useTranslation();

  return (
    <ButtonGhost
      className="align-self-start d-flex  align-items-center "
      style={{ paddingRight: "30px" }}
      onClick={onCallHelp}
    >
      {t("shared.needHelp", "Need help?")}{" "}
      <strong className="ml-1">{t("shared.callUs", "Call us")}</strong>
    </ButtonGhost>
  );
};

export default HelpButton;
