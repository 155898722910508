import { CFormGroup, CInputCheckbox, CLabel } from "@coreui/react";
import React from "react";

const Checkbox = ({
  name,
  label,
  onChange,
  defaultValue,
  value,
  className,
  disabled,
  style,
}) => {
  return (
    <CFormGroup
      className={className}
      variant="custom-checkbox"
      style={{ fontSize: "14px" }}
    >
      <CInputCheckbox
        custom
        id={`${name}`}
        onChange={onChange}
        defaultChecked={defaultValue}
        checked={value}
        disabled={disabled}
      />
      <CLabel         style={style}
 variant="custom-checkbox" htmlFor={`${name}`}>
        {label}
      </CLabel>
    </CFormGroup>
  );
};

export default Checkbox;
