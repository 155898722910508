

export const PURCHASE_CATEGORIES =[
  {
    value: "construction-and-manufacture-and-rennovations",
    label: "Construction, manufacture, rennovations",
  },
  {
    value: "software-hosting-or-computer-programming-and-related-activities",
    label: "Software, hosting, computer programming, related activities",
  },
  {
    value: "insurance",
    label: "Insurance",
  },
  {
    value: "banking-fees-and-charges",
    label: "Banking fees and charges",
  },
  {
    value: "consultants-and-lawyers-and-accountants",
    label: "Consultants, lawyers, accountants",
  },
  {
    value: "other-tools-and-equipment-and-machinery",
    label: "Other tools and equipment, machinery",
  },
  {
    value: "purchased-vehicles",
    label: "Purchased vehicles",
  },
  {
    value: "phones-and-tv-and-comms-equipment",
    label: "Phones, TVs, Comms equipments",
  },
  {
    value: "computers-and-office-machinery",
    label: "Computers, office machinery",
  },
  {
    value: "rail-freight",
    label: "Rail freight",
  },
  {
    value: "warehousing",
    label: "Warehousing",
  },
  {
    value: "road-freight",
    label: "Road freight",
  },
  {
    value: "air-freight",
    label: "Air freight",
  },
  {
    value: "sea-freight",
    label: "Sea freight",
  },
  {
    value: "wood-products",
    label: "Wood products",
  },
  {
    value: "furniture",
    label: "Furniture",
  },
  {
    value: "other-general-products",
    label: "Other general products",
  },
  {
    value: "chemicals-and-pharmaceuticals",
    label: "Chemicals and pharmaceuticals",
  },
  {
    value: "textiles",
    label: "Textiles",
  },
  {
    value: "plastic-products",
    label: "Plastic products",
  },
  {
    value: "metal-products",
    label: "Metal products",
  },
  {
    value: "paper-and-packaging",
    label: "Paper and Packaging",
  },
  {
    value: "books-and-movies-and-related-services",
    label: "Books, movies, related services (publishing, printing, reproduction of recorded materials)",
  },
  {
    value: "car",
    label: "Car",
  },
  {
    value: "train",
    label: "Train",
  },
  {
    value: "bus",
    label: "Bus",
  },
  {
    value: "flights",
    label: "Flights",
  },
  {
    value: "wine",
    label: "Wine",
  },
  {
    value: "food-and-drink",
    label: "Food and Drink",
  },
  {
    value: "cost-of-films",
    label: "Cost of films",
  },
  {
    value: "advertising",
    label: "Advertising",
  },
  {
    value: "labs",
    label: "Labs",
  },
  {
    value: "general-dental-products",
    label: "General dental products",
  },
]
