import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { convertStrToBool } from "src/utils/common-utils";

const SupplierSurveyController9 = ({ onChange, values }) => {
  const {
    formState: { errors },
    getValues,
    setValue,
    register,
  } = useFormContext();

  const emptyComponent = {
    name: "",
    weight: 0,
    country: "",
  };

  const emptyProduct = {
    name: "",
    components: Array(1).fill(emptyComponent),
  };

  const [producesGoods, setProducesGoods] = useState(values["produces-goods"]);

  const [products, setProducts] = useState(values["products"] || []);

  const [isTouched, setIsTouched] = useState(false);

  const isProducerHandler = (e) => {
    const { value } = e.target;
    const isProducer = convertStrToBool(value);
    setProducesGoods(isProducer);
    if (!isProducer) {
      setValue("products", [], { shouldTouch: true });
    }
    if (isProducer && !products.length) {
      addProductsHandler();
    }
    onChange(e);
  };

  const productNameHandler = (e, index) => {
    setProducts((prev) => {
      const newArr = [...prev];
      newArr[index].name = e.target.value;
      return newArr;
    });
  };

  const addProductsHandler = () => {
    setIsTouched(true);
    setProducts((prev) => [...prev, emptyProduct]);
  };

  const addComponentsHandler = (prodIndex) => {
    setIsTouched(true);
    setProducts((prev) => {
      const newArr = [...prev];
      newArr[prodIndex].components = [
        ...newArr[prodIndex].components,
        emptyComponent,
      ];
      return newArr;
    });
  };

  const deleteProductsHandler = (index) => {
    setIsTouched(true);
    setProducts((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  const deleteComponentsHandler = (prodIndex, compIndex) => {
    setIsTouched(true);
    setProducts((prev) => {
      const newArr = [...prev];
      const components = newArr[prodIndex].components;
      const newComponents = [
        ...components.slice(0, compIndex),
        ...components.slice(compIndex + 1),
      ];
      newArr[prodIndex].components = newComponents;
      return newArr;
    });
  };

  useEffect(() => {
    if (!products.length) return;
    if (!isTouched) return;
    setValue("products", products, { shouldTouch: true });
  }, [products, setValue, isTouched]);

  return {
    producesGoods,
    products,
    isProducerHandler,
    productNameHandler,
    addComponentsHandler,
    register,
    getValues,
    setValue,
    errors,
    setIsTouched,
    setProducts,
    addProductsHandler,
    deleteComponentsHandler,
    deleteProductsHandler
  };
};

export default SupplierSurveyController9;
