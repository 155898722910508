import React from "react";
import { useTranslation } from "react-i18next";
import RadioButtons from "../RadioButtons/RadioButtons";

const YesOrNoQuestion = (props) => {
  const { t } = useTranslation();

  return (
    <RadioButtons
      data={[
        { value: true, label: t("shared.yes") },
        { value: false, label: t("shared.no") },
      ]}
      {...props}
    />
  );
};

export default YesOrNoQuestion;
