import { RESULT_KEYS } from "src/constants/resultsSections";

const { roundNumber, sumValuesInArray } = require("src/utils/common-utils");
const { calculateReductionPercentage } = require("./general");

export const calculateSwitchCommuteFuel = (
  results,
  recommendation,
  year,
  log,
  emissionFactors,
  scaleUpFactor,
  baseYear
) => {
  if (!results) return 0;
  const {
    specifics: { amount: reduceBy, oldFuel, newFuel },
    shareImplemented,
    yearImplemented,
  } = recommendation;

  const vehiclesLog = log?.commuteVehiclesLog;
  if (!vehiclesLog) return 0;
  const vehicleEF = emissionFactors?.["passenger-vehicles"];
  if (!vehicleEF) return 0;

  const currentPrivateCommute = results[RESULT_KEYS.commutePrivate];

  const totalEmissionsInLog = sumValuesInArray(vehiclesLog, "emissions");

  const recordsToSwitch = vehiclesLog.filter(
    (record) => record.fuel === oldFuel
  );
  if (!recordsToSwitch?.length) return 0;

  const emissionsToSwitch = sumValuesInArray(recordsToSwitch, "emissions");

  const newEmissionsArray = recordsToSwitch.map((record) => {
    const { SCOPE1_CO2E_PER_KM, WTT_CO2E_PER_KM } = vehicleEF;
    const { size, distance, wtt } = record;
    let emissionFactor;
    if (!wtt) {
      emissionFactor = SCOPE1_CO2E_PER_KM?.car?.[size]?.[newFuel]|| 0;
    } else {
      emissionFactor = WTT_CO2E_PER_KM?.car?.[size]?.[newFuel] || 0;
    }

    return {
      emissions: distance * emissionFactor,
    };
  });

  const newEmissions = sumValuesInArray(newEmissionsArray, "emissions");

  const reductionPercentage = calculateReductionPercentage(
    reduceBy,
    shareImplemented,
    year,
    yearImplemented,
    baseYear
  );

  const currentAdjustment = roundNumber(
    currentPrivateCommute / (totalEmissionsInLog * scaleUpFactor),
    4
  );

  const reduction =
    (emissionsToSwitch - newEmissions) *
    reductionPercentage *
    scaleUpFactor *
    currentAdjustment;

  return roundNumber(reduction, 2);
};

export const calculateSwitchPrivateTransportToPublic = (
  results,
  recommendation,
  year,
  log,
  baseYear
) => {
  if (!results) return;

  const {
    specifics: { amount: reduceBy },
    shareImplemented,
    yearImplemented,
  } = recommendation;

  const publicTransportRecords = log?.log?.filter(
    (record) =>
      record.category === "Employee commute" &&
      ["direct", "WTT"].includes(record.field)
  );
  if (!publicTransportRecords) return;

  const vehiclesLog = log?.commuteVehiclesLog;
  if (!vehiclesLog) return;

  const totalDistancePublic =
    sumValuesInArray(publicTransportRecords, "value") / 2;

  const totalEmissionsPublic = results[RESULT_KEYS.commutePublic];

  const averageEmFactorPublic = totalEmissionsPublic / totalDistancePublic;

  const totalDistancePrivate = sumValuesInArray(vehiclesLog, "distance") / 2;

  const reductionPercentage = calculateReductionPercentage(
    reduceBy,
    shareImplemented,
    year,
    yearImplemented,
    baseYear
  );

  const distanceToSwitch = totalDistancePrivate * reductionPercentage;

  const decreasePrivate =
    results[RESULT_KEYS.commutePrivate] * reductionPercentage;
  const increasePublic = distanceToSwitch * averageEmFactorPublic;
  const totalReduction = roundNumber(decreasePrivate - increasePublic, 2);

  return { total: totalReduction, increasePublic, decreasePrivate };
};

export const applySwitchPrivateTransportToPublic = (
  result,
  recommendation,
  year,
  log,
  baseYear
) => {
  const reduction = calculateSwitchPrivateTransportToPublic(
    result,
    recommendation,
    year,
    log,
    baseYear
  );
  if (reduction) {
    const { increasePublic, decreasePrivate } = reduction;

    result[RESULT_KEYS.commutePrivate] -= decreasePrivate;
    result[RESULT_KEYS.commutePublic] += increasePublic;
  }
};
