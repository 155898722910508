import CIcon from "@coreui/icons-react";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAssignModal } from "src/store/modalSlice";
import { selectCompletedTasks } from "src/store/projectSelectors";
import StyledSelect from "../BasicSelect/BasicSelect.style";

const CollaboratorSelect = ({
  name,
  defaultValue,
  onTasksChange,
  collaborators,
  className,
  shouldDisabled = false,
  shouldHaveAssignNew = false,
  shouldHaveCompleted = false,
  value,
}) => {
  const dispatch = useDispatch();

  const completedTasks = useSelector(selectCompletedTasks);
  const taskIsCompleted = get(completedTasks, name);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    if (value === "assign-new") {
      dispatch(setAssignModal({ show: true, taskToAssign: name }));
    } else {
      onTasksChange(e);
    }
  };

  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center ml-auto">
      <StyledSelect
        className={className}
        type="text"
        name={name}
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        disabled={shouldDisabled}
        value={value}
      >
        <option value="none" className="text-info">
          {t("shared.none", "none")}
        </option>
        {collaborators.map((email, i) => (
          <option value={email} key={i}>
            {email}
          </option>
        ))}
        {shouldHaveCompleted && (
          <option value="completed" className="text-success">
            Task completed
          </option>
        )}
        {shouldHaveAssignNew && (
          <option value="assign-new" className="text-info">
            {t("tasks.assignNewContact", "Assign a new contact")}
          </option>
        )}
      </StyledSelect>
      {taskIsCompleted ? (
        <CIcon
          className="ml-1"
          style={{
            color: "green",
            width: "1.3rem",
            height: "1.3rem",
          }}
          name="cil-check-circle"
        />
      ) : (
        <div
          className="ml-1"
          style={{ width: "1.3rem", height: "1.3rem" }}
        ></div>
      )}
    </div>
  );
};

export default CollaboratorSelect;
