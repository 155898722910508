import React from "react";

const DemoWarning = ({ className }) => {
  return (
    <span
      style={{ fontSize: "30px", fontWeight: "bold" }}
      className={`text-center  text-danger ${className}`}
    >
      DEMO
    </span>
  );
};

export default DemoWarning;
