import { CFormGroup, CLabel } from "@coreui/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSteps } from "react-step-builder";
import {
  BasicNumberInput,
  DemoWarning,
  DistanceUnitSelect,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
} from "src/components";
import { useSharedConstants } from "src/hooks";
import { isBetween } from "src/utils/validationOptions";
import { InfoTooltip, Subtitle } from "../components";

const INFO = {
  tram: "a railway or tram system operating small, frequent trains and trams in an urban area",
  coach: "a long-distance bus",
  bus: "a local or city bus",
};

const EmployeeSurvey7 = ({
  data: values = {},
  onChange,
  info,
  workDays,
  isDemo,
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const { publicTransport } = useSharedConstants();

  publicTransport.forEach((mode) => {
    register(`public-transport.${mode.name}`, isBetween(0, 1e3));
  });

  const { jump, prev } = useSteps();

  const onPrev = async () => {
    if (values["uses-vehicles"] === false) {
      jump(4);
    } else if (
      values["uses-vehicles"] &&
      values["has-second-vehicle"] === false
    ) {
      jump(5);
    } else {
      prev();
    }
  };

  const nightsAwayDefault =
    values["nights-away-year"] ?? values["nights-away"] * 47 ?? undefined;

  return (
    <FormStepWrapper
      onPrev={onPrev}
      aboveCardSlot={
        <>
          {isDemo && <DemoWarning />}
          <Subtitle
            content={t(
              "employeeSurvey.publicTransport",
              "About the other modes of transport you use for your commute"
            )}
          />
        </>
      }
    >
      <QuestionWrapper>
        <h3>
          15.{" "}
          {t(
            "employeeSurvey.q_publicTransport",
            "Thinking about a typical week of commuting in 2021, how far did you travel by each mode in total over the course of a week - total for the week, both to and from work?"
          )}
          <InfoTooltip
            content={t(
              "employeeSurvey.publicTransportTooltip",
              "This is travel to and from work that you pay for out of your own expenses. Please give a total of miles for that mode for each week."
            )}
          />
        </h3>
        <p className="text-info mb-4">
          {t(
            "employeeSurvey.publicTransportInfo",
            "If a mode not used, please leave blank. If you only work from home, please skip this question."
          )}
        </p>
        <div className="w-100">
          <div className="d-flex align-items-baseline justify-content-end ml-auto mb-3">
            <CLabel>{t("shared.selectUnit", "Select unit")}</CLabel>
            <DistanceUnitSelect
              onChange={onChange}
              name="commute.unit"
              defaultValue={values?.["commute"]?.unit || "mi"}
              className="font-weight-bold ml-3 col-4"
            />
          </div>
          {publicTransport.map((item, index) => {
            const { label, name } = item;
            return (
              <div key={index}>
                <h5>
                  {label} {INFO[name] && <InfoTooltip content={INFO[name]} />}
                </h5>
                <CFormGroup className="rounded bg-light p-4 mb-4">
                  <CLabel>{t("shared.distance", "Distance")} </CLabel>
                  <BasicNumberInput
                    allowNegative={false}
                    min={0}
                    decimalScale={2}
                    name={`public-transport.${name}`}
                    max={1000}
                    placeholder={t("shared.enterDistance", "enter distance...")}
                    onChange={(e) => {
                      onChange({
                        target: {
                          name: `public-transport.${name}`,
                          value: parseFloat(e.target.value),
                        },
                      });
                    }}
                    defaultValue={values["public-transport"]?.[name]}
                  />
                  <ErrorText value={errors["public-transport"]?.[name]} />
                </CFormGroup>
              </div>
            );
          })}
        </div>
      </QuestionWrapper>

      <QuestionWrapper>
        <h3>
          16.{" "}
          Across the whole year, approximately how many nights did you spend <u><b>at your own expense</b></u> (and not reimbursed by {info.orgName}) in a hotel or airbnb for the purpose of working?
        </h3>
        <BasicNumberInput
          allowNegative={false}
          min={0}
          max={365}
          name="nights-away-year"
          onChange={onChange}
          defaultValue={nightsAwayDefault}
          placeholder={t("shared.pleaseSpecify", "Please specify")}
        />
        <ErrorText value={errors["nights-away-year"]} />
      </QuestionWrapper>
    </FormStepWrapper>
  );
};

export default EmployeeSurvey7;
