import React from "react";
import { CTooltip } from "@coreui/react";
import { BsInfoCircle } from "react-icons/bs";

const InfoTooltip = ({ content, size = 16 }) => {
  return (
    <CTooltip content={content} placement="top">
      <BsInfoCircle className="ml-2 mb-2 text-info" size={size} />
    </CTooltip>
  );
};

export default InfoTooltip;
