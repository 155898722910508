import { CCol, CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { BasicNumberInput, ErrorText, QuestionWrapper } from "src/components";
import VanQuestions from "./VanQuestions";
import PostQuestion from "./PostQuestion";
import EnergyQuestions from "./EnergyQuestions";

const LabQuestions = ({
  info,
  values,
  onChange,
  errors,
  control,
  register,
  getValues,
}) => {
  const { t } = useTranslation();

  register("lab-inbound", {
    validate: {
      sumsTo100: (value) => {
        if (!value) return true;
        if ((value.post || value.digital || value.van) && !value.total)
          return "Please specify the total number of inbound cases";
        if (+value.digital + +value.van + +value.post !== 100) {
          return "The sum of the percentages must be 100";
        }
        return true;
      },
    },
  });

  register("lab-outbound", {
    validate: {
      sumsTo100: (value) => {
        if (!value) return true;
        if ((value.post || value.van) && !value.total)
          return "Please specify the total number of outbound cases";
        if (+value.van + +value.post !== 100) {
          return "The sum of the percentages must be 100";
        }
        return true;
      },
    },
  });

  return (
    <>
      <QuestionWrapper>
        <h3 className="mb-3">
          11a. How many inbound cases do you receive from {info.orgName} on
          average per week?
        </h3>
        <BasicNumberInput
          allowNegative={false}
          min={0}
          max={1e5}
          name={"lab-inbound.total"}
          defaultValue={values["lab-inbound"]?.total}
          onChange={onChange}
          placeholder={t("shared.pleaseSpecify")}
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <h3 className="mb-3">
          11b. What percentage of your inbound cases from {info.orgName} come
          in...?
        </h3>
        <CRow style={{ marginLeft: "-15px" }}>
          <CCol className="col-12 col-md-4 mb-3 ">
            <p className="mb-1">Digitally</p>
            <BasicNumberInput
              allowNegative={false}
              min={0}
              max={100}
              name={"lab-inbound.digital"}
              defaultValue={values["lab-inbound"]?.digital}
              onChange={onChange}
              placeholder={t("shared.pleaseSpecify")}
            />
          </CCol>
          <CCol className="col-12 col-md-4 mb-3 ">
            <p className="mb-1">By owned/operated van</p>
            <BasicNumberInput
              allowNegative={false}
              min={0}
              max={100}
              name={"lab-inbound.van"}
              defaultValue={values["lab-inbound"]?.van}
              onChange={onChange}
              placeholder={t("shared.pleaseSpecify")}
            />
          </CCol>
          <CCol className="col-12 col-md-4 ">
            <p className="mb-1">By courier/post</p>
            <BasicNumberInput
              allowNegative={false}
              min={0}
              max={100}
              name={"lab-inbound.post"}
              defaultValue={values["lab-inbound"]?.post}
              onChange={onChange}
              placeholder={t("shared.pleaseSpecify")}
            />
          </CCol>
        </CRow>
        <ErrorText value={errors["lab-inbound"]} />
      </QuestionWrapper>
      <QuestionWrapper>
        <h3 className="mb-3">
          11c. How many outbound cases do you send to {info.orgName} on average
          per week?
        </h3>
        <BasicNumberInput
          allowNegative={false}
          min={0}
          max={1e5}
          name={"lab-outbound.total"}
          defaultValue={values["lab-outbound"]?.total}
          onChange={onChange}
          placeholder={t("shared.pleaseSpecify")}
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <h3 className="mb-3">
          11d. What percentage of your outbound cases to {info.orgName} are
          delivered...?
        </h3>
        <CRow style={{ marginLeft: "-15px" }}>
          <CCol className="col-12 col-md-6 mb-3 ">
            <p className="mb-1">By owned/operated van</p>
            <BasicNumberInput
              allowNegative={false}
              min={0}
              max={100}
              name={"lab-outbound.van"}
              defaultValue={values["lab-outbound"]?.van}
              onChange={onChange}
              placeholder={t("shared.pleaseSpecify")}
            />
          </CCol>
          <CCol className="col-12 col-md-6 ">
            <p className="mb-1">By courier/post</p>
            <BasicNumberInput
              allowNegative={false}
              min={0}
              max={100}
              name={"lab-outbound.post"}
              defaultValue={values["lab-outbound"]?.post}
              onChange={onChange}
              placeholder={t("shared.pleaseSpecify")}
            />
          </CCol>
        </CRow>
        <ErrorText value={errors["lab-outbound"]} />
      </QuestionWrapper>
      <VanQuestions
        {...{ info, onChange, values, errors, control, register, getValues }}
      />
      <PostQuestion
        {...{ values, onChange, errors, control, register, getValues }}
      />
      <EnergyQuestions {...{ info, onChange, values, errors }} />
    </>
  );
};

export default LabQuestions;
