import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ENDPOINTS } from "src/constants/endpoints";
import { useHttp } from "src/hooks";
import { selectOrgId, selectPeriod } from "src/store/projectSelectors";

/**
* A custom hook that provides a function to calculate results for the current period.

* @returns {{calculateResults:Function,isCalculatingResults:boolean,calculationsError:string}} An object that contains:
* - `calculateResults` - function which sends a request to the backend to calculate results for the current organisation and period,
* - `isCalculatingResults` - flag to indicate if the results are being calculated,
* - `calculationsError` -  string to indicate if an error occurred.
*/

const useCalculateResults = () => {
  const [isCalculatingResults, setCalculatingResults] = useState(false);
  const [calculationsError, setCalculationsError] = useState("");
  const orgId = useSelector(selectOrgId);
  const periodId = useSelector(selectPeriod);

  const { sendRequest } = useHttp();

  const { t } = useTranslation();

  const calculateResults = async () => {
    setCalculatingResults(true);

    try {
      await sendRequest(ENDPOINTS.calculateResults, {
        body: JSON.stringify({ orgId, periodId }),
        successMsg: t("requests.resultsSuccess"),
        errorMsg: t("requests.resultsError"),
      });
    } catch (err) {
      setCalculationsError(err.message);
    } finally {
      setCalculatingResults(false);
    }
  };

  return { calculateResults, isCalculatingResults, calculationsError };
};

export default useCalculateResults;
