import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import { useTranslation } from "react-i18next";

const UploadResultsModal = ({
  excelLog,
  showModal,
  toggle,
  onActiveTabChangeHandler,
}) => {
  const { t } = useTranslation();

  return (
    <CModal show={showModal} onClose={toggle} centered>
      <CModalHeader className="d-flex flex-column bg-light">
        <h2 className="w-100">
          {t("shared.savedTemplate", "Saved template!")}
        </h2>
        <h5 className="mb-2">
          Excel template was saved with following errors:
        </h5>
      </CModalHeader>
      <CModalBody style={{ maxHeight: "75vh", overflow: "auto" }}>
        {excelLog
          .sort((a, b) => a.index - b.index)
          .map((entry, index) => (
            <p
              key={index}
              style={{ fontSize: "16px" }}
              className={`text-${entry.type}`}
            >
              {entry.msg}
            </p>
          ))}
      </CModalBody>
      <CModalFooter className="bg-secondary">
        <CButton
          color="success"
          onClick={() => {
            onActiveTabChangeHandler?.("results");
            toggle();
          }}
        >
          {!!onActiveTabChangeHandler ? "See uploaded ledger" : "Ok"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default UploadResultsModal;
