import { RESULT_KEYS } from "src/constants/resultsSections";
import { roundNumber, sumValuesInArray } from "src/utils/common-utils";
import { calculateReductionPercentage } from "./general";

const calculateSwitchToFossilFreeCategory = (
  result,
  recommendation,
  year,
  log,
  emissionFactors,
  baseYear
) => {
  const {
    specifics: { amount: reduceBy },
    shareImplemented,
    yearImplemented,
    fieldToReduce,
  } = recommendation;
  if (!result) return 0;

  const generalLog = log?.log;

  const investmentEF = emissionFactors?.["investments"];

  const reductionPercentage = calculateReductionPercentage(
    reduceBy,
    shareImplemented,
    year,
    yearImplemented,
    baseYear
  );

  if (!fieldToReduce || !reductionPercentage || !investmentEF) return 0;
  if (!generalLog) return 0;

  const filteredRecords = generalLog.filter(
    (record) =>
      record.category === "Investments" && record.subcategory === fieldToReduce
  );

  if (!investmentEF) return 0;

  const {
    CO2E_PER_USD_FOSSIL_FUEL_FREE: { current: FOSSIL_FREE_EF },
  } = investmentEF;

  const totalEmissions = sumValuesInArray(filteredRecords, "emissions");
  const totalAmount = sumValuesInArray(filteredRecords, "value");

  const fffRecords = filteredRecords.filter(
    (record) => record.ef === FOSSIL_FREE_EF
  );

  const fffAmount = sumValuesInArray(fffRecords, "value");
  const notFFFAmount = totalAmount - fffAmount;
  const amountToSwitch = notFFFAmount * reductionPercentage;

  const notFFFemissions = totalEmissions - FOSSIL_FREE_EF * fffAmount;

  const overrideAdjustment = result[fieldToReduce] / totalEmissions;

  const reduction =
    (notFFFemissions * reductionPercentage - amountToSwitch * FOSSIL_FREE_EF) *
    overrideAdjustment;
  return roundNumber(reduction || 0, 2);
};

export const calculateSwitchToFossilFree = (
  result,
  recommendation,
  year,
  log,
  emissionFactors,
  baseYear
) => {
  const {
    specifics: { category },
  } = recommendation;
  if (category === "total-funds") {
    let sum = 0;
    [RESULT_KEYS.pensions, RESULT_KEYS.investments].forEach((field) => {
      const reduction = calculateSwitchToFossilFreeCategory(
        result,
        {
          ...recommendation,
          fieldToReduce: field,
        },
        year,
        log,
        emissionFactors,
        baseYear
      );
      sum += reduction;
    });
    return roundNumber(sum, 2);
  } else {
    const reduction = calculateSwitchToFossilFreeCategory(
      result,
      recommendation,
      year,
      log,
      emissionFactors,
      baseYear
    );

    return roundNumber(reduction, 2);
  }
};

const applySwitchToFFFCategory = (
  result,
  recommendation,
  year,
  log,
  emissionFactors,
  baseYear
) => {
  const { fieldToReduce } = recommendation;

  const reduction = calculateSwitchToFossilFreeCategory(
    result,
    recommendation,
    year,
    log,
    emissionFactors,
    baseYear
  );

  result[fieldToReduce] -= reduction;
};

export const applySwitchToFFF = (
  result,
  recommendation,
  year,
  log,
  emissionFactors,
  baseYear
) => {
  const {
    specifics: { category },
  } = recommendation;

  if (category === "total-funds") {
    [RESULT_KEYS.pensions, RESULT_KEYS.investments].forEach((field) => {
      applySwitchToFFFCategory(
        result,
        { ...recommendation, fieldToReduce: field },
        year,
        log,
        emissionFactors,
        baseYear
      );
    });
  } else {
    applySwitchToFFFCategory(
      result,
      recommendation,
      year,
      log,
      emissionFactors,
      baseYear
    );
  }
};
