import { createSlice } from "@reduxjs/toolkit";

const initialAlertState = { alerts: [] };

const alertSlice = createSlice({
  name: "alert",
  initialState: initialAlertState,
  reducers: {
    addAlert(state, action) {
      state.alerts = [...state.alerts, action.payload];
    },
    cancelAlert(state, action) {
      state.alerts = state.alerts.filter(
        (alert) => alert.text !== action.payload.text
      );
    },
  },
});

export const { addAlert, cancelAlert } = alertSlice.actions;

export default alertSlice.reducer;
