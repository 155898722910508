import React from "react";
import { CPopover } from "@coreui/react";
import { BsInfoCircle } from "react-icons/bs";

const InfoPopover = ({ header, content, size = 13 }) => {
  return (
    <CPopover header={header} content={content}>
      <BsInfoCircle className="ml-1 mb-3 text-info" size={size} />
    </CPopover>
  );
};

export default InfoPopover;
