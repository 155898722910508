import React from 'react'
import { useSelector } from 'react-redux';
import { selectOrgId, selectPeriod } from 'src/store/projectSelectors';
import firebase from "firebase/compat/app";



const useCheckPurchases = () => {
    const [hasPurchases, setHasPurchases] = React.useState(false)

    const orgId = useSelector(selectOrgId);
    const periodId = useSelector(selectPeriod);


    React.useEffect(() => {
        if (!orgId || !periodId) return;

        async function fetchPurchaseCount() {
            const db = firebase.firestore();


            const ref = db
                .collection("organisations")
                .doc(orgId)
                .collection("periods")
                .doc(periodId)
                .collection("details")
                .doc("purchase-data")
                .collection("purchases")

            const snapshot = await ref.limit(1).get()

            const hasPurchases = !snapshot.empty

            setHasPurchases(hasPurchases)

        }

        fetchPurchaseCount()


    }, [orgId, periodId])



    return { hasPurchases }
}

export default useCheckPurchases