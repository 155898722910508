import { cloneDeep, merge } from "lodash";

export const getEmissionFactors = (emissionFactors, periodStart, periodEnd) => {
  if (!emissionFactors) return;

  const startYear = new Date(periodStart).getFullYear();
  const endYear = new Date(periodEnd).getFullYear();

  const staticEmissionFactors = cloneDeep(
    emissionFactors.find((o) => o.year == "static")
  );

  const yearEmissionFactors = emissionFactors.filter(
    (o) => o.year !== "static"
  );
  const completeEmissionFactors = merge(
    staticEmissionFactors,
    ...yearEmissionFactors
  );

  const startYearEmissionFactors = cloneDeep(
    emissionFactors.find((o) => o.year == startYear)
  );

  let finalEmissionFactors = merge(
    completeEmissionFactors,
    startYearEmissionFactors || {}
  );

  // if (startYear !== endYear) {
  //   const daysInStartYear = Math.ceil(
  //     (new Date(startYear + 1, 0, 1) - new Date(periodStart)) /
  //       (1000 * 60 * 60 * 24)
  //   );

  //   const daysInEndYear = Math.ceil(
  //     (new Date(periodEnd) - new Date(endYear, 0, 1)) / (1000 * 60 * 60 * 24)
  //   );

  //   finalEmissionFactors = findWeightedAverageOfObjects(
  //     finalEmissionFactors,
  //     emissionFactors.find((o) => o.year == endYear),
  //     daysInStartYear,
  //     daysInEndYear
  //   );
  // }

  const pensionFactors = Object.values(
    finalEmissionFactors.pensions?.CO2E_PER_GBP || {}
  ).reduce((acc, provider) => acc.concat(Object.values(provider)), []);

  const calculateAverageOfArrayValues = (arr) => {
    return arr.reduce((acc, val) => acc + val, 0) / arr.length;
  };

  const bankFactors = Object.values(
    finalEmissionFactors.banks?.CO2E_PER_GBP || {}
  );

  const averageBankFactor = calculateAverageOfArrayValues(bankFactors);
  const averagePensionFactor = calculateAverageOfArrayValues(pensionFactors);

  if (averageBankFactor) {
    finalEmissionFactors.banks.CO2E_PER_GBP.average = averageBankFactor;
  }
  if (averagePensionFactor) {
    finalEmissionFactors.pensions.CO2E_PER_GBP.average = averagePensionFactor;
  }

  return finalEmissionFactors;
};
