import firebaseInstance from "src/adapters/common-set-up/firebase";
import "firebase/compat/auth";

/**
 *
 * @category Adaptors
 *
 *
 * @module auth-adaptors
 *
 */

/**
 *
 * Retuns the UUID of the currently signed in user
 *
 * @returns {String}
 */
export const adaptor_getUserUID = () => {
  return firebaseInstance?.auth()?.currentUser?.uid || null;
};

export const adaptor_getUserData = () => {
  let uid;
  let email;
  const user = firebaseInstance?.auth()?.currentUser || null;
  if (user) {
    uid = user.uid;
    email = user.email;
  }
  return { uid, email };
};

/**
 *
 * Returns a promise as to the wether the current use is an Admin user.
 * - admin users are distinguished from investors.
 * - admin users can access this side of the platform only.
 *
 * @returns {Pomise}
 */
export const adaptor_isAdmin = () => {
  return new Promise((resolve, reject) => {
    firebaseInstance
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        // Confirm the user is an Admin.
        if (!!idTokenResult.claims.admin) {
          // Show admin UI.
          resolve(true);
        } else {
          // Show regular user UI.
          resolve(false);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
