import { CInputGroup } from "@coreui/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BasicInput,
  BasicNumberInput,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  VerticalRadioButtons,
} from "src/components";
import { useSurveyConstants } from "src/hooks";
import AboveCard from "../components/AboveCard";

const SupplierSurvey3 = ({ values, onChange, info, isDemo }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();

  const { commutePatterns } = useSurveyConstants();

  return (
    <FormStepWrapper aboveCardSlot={<AboveCard {...{ info, isDemo }} />}>
      <QuestionWrapper>
        <h3>
          {t(
            "supplierSurvey.q_fteStart",
            "3. How many employees (FTE) did you have at the end of {{period}}?",
            { period: info.reportingPeriod }
          )}
        </h3>
        <CInputGroup>
          <BasicNumberInput
            name="employees-number"
            min={0}
            allowNegative={false}
            defaultValue={values["employees-number"]}
            onChange={onChange}
            placeholder={t("shared.pleaseSpecify", "Please specify")}
          />
        </CInputGroup>
        <ErrorText value={errors["employees-number"]} />
      </QuestionWrapper>

      <QuestionWrapper>
        <h3 className="mb-4">
          {t(
            "supplierSurvey.q_daysFromHome",
            "4. Thinking about your employees in {{period}}, what was the average days per week per employee of working from home?",
            { period: info.reportingPeriod }
          )}
        </h3>
        <CInputGroup>
          <BasicNumberInput
            name="days-from-home"
            min={0}
            max={6}
            allowNegative={false}
            defaultValue={values["days-from-home"]}
            onChange={onChange}
            placeholder={t("shared.pleaseSpecify", "Please specify")}
          />
        </CInputGroup>
        <ErrorText value={errors["days-from-home"]} />
      </QuestionWrapper>

      <QuestionWrapper>
        <h3 className="mb-4">
          {t(
            "supplierSurvey.q_commutePatterns",
            "5. Which of the following would roughly describe your employees' commuting patterns over {{period}}?",
            { period: info.reportingPeriod }
          )}
        </h3>
        <CInputGroup>
          <VerticalRadioButtons
            name="commuting-pattern"
            onChange={onChange}
            data={commutePatterns}
            defaultValue={values["commuting-pattern"]}
          />
        </CInputGroup>
        <ErrorText value={errors["commuting-pattern"]} />
      </QuestionWrapper>
    </FormStepWrapper>
  );
};

export default SupplierSurvey3;
