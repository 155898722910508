import { CCard } from "@coreui/react";
import React from "react";
import { useSelector } from "react-redux";
import { selectOrgName } from "src/store/projectSelectors";
import styled from "styled-components";
import CIcon from "@coreui/icons-react";

const OrgCard = () => {
  const orgName = useSelector(selectOrgName);

  return (
    <StyledCard className="bg-dark d-flex flex-row align-items-center mb-4">
      <CIcon  name="cil-building"  /> <span className="ml-2">{orgName}</span>
    </StyledCard>
  );
};

const StyledCard = styled(CCard)`
  position: fixed;
  bottom: 0rem;
  padding: 0.5rem;
  font-size: 16px;
  font-weight: 500;
  color: white;
  /* transform: translateX(50%); */
  right: 5%;

  @media screen and (max-width: 576px) {
    font-size: 12px;
    right: 1%;

  }
`;

export default OrgCard;
