import { t } from "i18next";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { BasicSelect, ErrorText, PassengerVehiclesSize } from "src/components";
import { useSharedConstants } from "src/hooks";

const VehicleQuestion = ({ values, onChange, name, title, errors }) => {
  const [vehicleType, setVehicleType] = useState(values[name]?.type);
  useEffect(() => {
    if (vehicleType !== "Motorbike") return;
    if (values[name]?.fuel === "Unknown") return;
    onChange({ target: { name: `${name}.fuel`, value: "Unknown" } });
  }, [vehicleType]);

  const { passengerSizes, passengerTypes, vehicleFuelTypes } =
    useSharedConstants();

  return (
    <>
      <h3 className="mb-4">{title}</h3>
      <div className="mb-4">
        <h5>
          <Trans i18nKey="employeeSurvey.q_vehicleType">
            <strong>A.</strong> What <strong>type</strong> of vehicle is it?
          </Trans>
        </h5>
        <BasicSelect
          options={passengerTypes}
          onChange={(e) => {
            setVehicleType(e.target.value);
            onChange(e);
          }}
          placeholder={t("shared.pleaseSelect", "Please select...")}
          name={`${name}.type`}
          defaultValue={values[name]?.type}
        />
        <ErrorText value={errors?.[name]?.type} />
      </div>
      <div className="mb-4">
        <div className="d-flex">
          <h5>
            <Trans i18nKey="employeeSurvey.q_vehicleSize">
              <strong>B.</strong> What <strong>size</strong> is it?
            </Trans>
          </h5>
          <PassengerVehiclesSize />
        </div>
        <BasicSelect
          options={passengerSizes}
          onChange={onChange}
          placeholder={t("shared.pleaseSelect", "Please select...")}
          name={`${name}.size`}
          defaultValue={values[name]?.size}
        />
        <ErrorText value={errors?.[name]?.size} />
      </div>
      {vehicleType !== "Motorbike" && (
        <div className="mb-4">
          <h5>
            <Trans i18nKey="employeeSurvey.q_vehicleFuel">
              <strong>C.</strong> What kind of <strong>fuel</strong> does it use
              (cars only)?
            </Trans>
          </h5>

          <BasicSelect
            options={vehicleFuelTypes}
            onChange={onChange}
            placeholder={t("shared.pleaseSelect", "Please select...")}
            name={`${name}.fuel`}
            defaultValue={values[name]?.fuel}
          />
          <ErrorText value={errors?.[name]?.fuel} />
        </div>
      )}
    </>
  );
};

export default VehicleQuestion;
