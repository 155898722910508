import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAuth } from "reactfire";

import { setAlertWithTimeout } from "../../store/operations";

const SEND_DATA_TIMEOUT = 300000;
const URL = process.env.REACT_APP_BACKEND;

/**
 *
 * A custom hook that provides a function to send HTTP requests with timeout and authorization headers,
 * and dispatches success and error notifications with the response data or error message.
 * @returns {Object} An object that contains a function sendRequest to send HTTP requests.
 */

const useHttp = () => {
  const dispatch = useDispatch();

  const auth = useAuth();

  const { t } = useTranslation();

  // A function that wraps the fetch API and adds a timeout to the request
  const fetchWithTimeout = useCallback(
    async (resource, options = {}, timeout = SEND_DATA_TIMEOUT) => {
      const abortController = new AbortController();
      const timeoutId = setTimeout(() => abortController.abort(), timeout);
      const response = await fetch(resource, {
        ...options,
        signal: abortController.signal,
      });
      clearTimeout(timeoutId);
      return response;
    },
    []
  );

  const sendRequest = useCallback(
    async (
      endpoint,
      {
        method = "POST",
        body = null,
        headers = {
          "Content-Type": "application/json",
        },
        successMsg = t(
          "notifications.sendSuccess",
          "Successfully sent request!"
        ),
        errorMsg = t("notifications.sendError", "Failed request!"),
      },
      dontShowMessages = false,
      url = URL
    ) => {
      try {
        // Get the current user's auth token
        const authToken = (await auth.currentUser?.getIdToken()) || "";

        const response = await fetchWithTimeout(url + endpoint, {
          method: method,
          body: body,
          //add the auth token to the request headers
          headers: { ...headers, Authorization: "Bearer " + authToken },
        });

        const responseData = await response.json();
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        const { msg } = responseData;

        if (!dontShowMessages) {
          dispatch(
            setAlertWithTimeout({
              type: "success",
              text: successMsg || msg,
            })
          );
        }
        return responseData;
      } catch (err) {
        dispatch(
          setAlertWithTimeout({
            type: "error",
            text: err.message || errorMsg,
          })
        );
        throw err;
      }
    },
    [dispatch, fetchWithTimeout, auth.currentUser]
  );

  return {
    sendRequest,
  };
};

export default useHttp;
