import { CSidebarNavDropdown, CSidebarNavItem } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSummaryTabs } from "src/hooks";
import { setSummaryTab } from "src/store/navSlice";
import {
  selectIsFreemium,
  selectOrgId
} from "src/store/projectSelectors";

const ResultsDropdown = ({ show, toggle }) => {
  const { t } = useTranslation();
  const organisation = useSelector(selectOrgId);
  const dispatch = useDispatch();
  const isFreemium = useSelector(selectIsFreemium);

  const isDisabled = (name) =>
    isFreemium && ["reductions", "survey"].includes(name);

  const { tabs } = useSummaryTabs();

  return (
    <CSidebarNavDropdown
      name={
        <div className="text-warning font-weight-bold">{t("logs.results")}</div>
      }
      id="resultsDropdown"
      className={`${show ? "c-show" : ""} "text-info"`}
      show={show}
      onClick={toggle}
    >
      {tabs.map((tab) => (
        <CSidebarNavItem
          key={tab.name}
          name={t(`summary.titles.${tab.name}`)}
          icon={tab.icon}
          onClick={() => {
            if (isDisabled(tab.name)) return;
            dispatch(setSummaryTab(tab.name));
          }}
          to={
            isDisabled(tab.name)
              ? ""
              : `/project/${organisation}/summaryDetails`
          }
          style={isDisabled(tab.name) ? { color: "gray" } : {}}
        />
      ))}
    </CSidebarNavDropdown>
  );
};

export default ResultsDropdown;
