import { useSteps } from "react-step-builder";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setStep } from "src/store/navSlice";

const useStepNavigation = () => {
  const stepToJump = useSelector((state) => state.nav.step);
  const dispatch = useDispatch();
  const { current } = useSteps();
  
  useEffect(() => {
    if (!stepToJump) return;
    if (current === stepToJump) {
      dispatch(setStep(0));
    }
  }, [current, stepToJump]);

  
  const { jump, } = useSteps();

  useEffect(() => {
    if (!stepToJump) return;
    jump(stepToJump);
  }, [stepToJump, dispatch, jump]);

};

export default useStepNavigation;
