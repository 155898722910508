import CIcon from "@coreui/icons-react";
import {
  CButton,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CPopover,
  CToggler,
} from "@coreui/react";
import React from "react";
import { useState } from "react";
import Select from "react-select";
import { ErrorText, LoadingSpinner } from "src/components";
import SendNotificationController from "./SendNotificationController";

const SendNotification = () => {
  const [show, setShow] = useState(false);

  const {
    loading,
    onSubmit,
    handleSubmit,
    orgOptions,
    onChange,
    errors,
    selectedOrg,
    orgSelectChange,
    closeHandler,
  } = SendNotificationController();

  return (
    <>
      <CPopover content="Send notification">
        <CToggler inHeader onClick={() => setShow(true)}>
          <CIcon name="cil-bullhorn" alt={"send notification"} />
        </CToggler>
      </CPopover>
      {show && (
        <CModal show={show} closeOnBackdrop={false} centered>
          <CModalHeader>Send Notification</CModalHeader>
          <CModalBody>
            <CLabel>
              <span className="text-info">To</span>
            </CLabel>
            <Select
              isMulti
              value={selectedOrg}
              options={orgOptions}
              onChange={orgSelectChange}
              className="text-primary mb-2"
            />
            <ErrorText value={errors?.organisation} />

            <CFormGroup>
              <CLabel>
                <span className="text-info">Title</span>
              </CLabel>
              <CInput
                name="title"
                placeholder="e.g. New feature updated"
                onChange={onChange}
                invalid={!!errors?.title}
              />
              <ErrorText value={errors?.title} />
            </CFormGroup>

            <CFormGroup>
              <CLabel>
                <span className="text-info">Body</span>
              </CLabel>
              <CInput
                name="body"
                onChange={onChange}
                placeholder="e.g. Try it out, our new feature"
                invalid={!!errors?.body}
              />
              <ErrorText value={errors?.body} />
            </CFormGroup>
          </CModalBody>
          <CModalFooter>
            <CButton color="success" onClick={handleSubmit(onSubmit)}>
              Send
            </CButton>
            <CButton
              color="dark"
              onClick={() => {
                closeHandler();
                setShow(false);
              }}
            >
              Cancel
            </CButton>
          </CModalFooter>
          {loading && <LoadingSpinner asOverlay />}
        </CModal>
      )}
    </>
  );
};

export default SendNotification;
