import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BasicInput,
  BasicNumberInput,
  BasicSelect,
  CountrySelect,
  SquareButtonOutline,
} from "src/components";
import ControlledInput from "src/components/ControlledInput/ControlledInput";
import DataTable from "src/components/DataTable/DataTable";
import { MATERIAL_SUB_CATEGORIES } from "src/constants";
import { isRequired, isRequiredBetween } from "src/utils/validationOptions";
import MaterialsCreatable from "./components/MaterialsCreatable";
import SupplierSelect from "./components/SupplierSelect";

const EMPTY_MATERIAL = {
  category: "",
  name: "",
  subcategory: "",
  description: "",
  quantity: 0,
  unit: "",
  country: null,
  supplier: "",
  transport: "",
  packaging: "",
};

const MaterialsTable = ({
  formMethods,
  tableName,
  transportOptions,
  packagingOptions,
  materialsOptions,
  categoryOptions,
  onClickTransport,
  onClickPackaging,
}) => {
  const { control, trigger, getValues, setValue, reset } = formMethods;

  const [initValues, setInitValues] = useState(
    getValues(tableName) || [EMPTY_MATERIAL]
  );

  const refresh = () => {
    const newVal = getValues?.(tableName);
    setInitValues(newVal);
  };

  const { t } = useTranslation();

  const MATERIALS_COLUMNS = [
    {
      label: "Category",
      key: "category",
    },
    {
      label: "Material",
      key: "name",
    },
    {
      label: "Subcategory",
      key: "subcategory",
    },
    {
      label: "Weight/Volume",
      key: "quantity",
    },
    {
      label: t("shared.unit"),
      key: "unit",
    },

    {
      label: t("shared.country", "Country"),
      key: "country",
    },
  ];

  if (transportOptions) {
    MATERIALS_COLUMNS.push({
      label: t("shared.transport"),
      key: "transport",
    });
  }

  if (packagingOptions) {
    MATERIALS_COLUMNS.push({
      label: "Packaging",
      key: "packaging",
    });
  }

  const unitOptions = ["g", "kg", "tonne"].map((unit) => ({
    value: unit,
    label: t(`materialsData.units.${unit}`),
  }));

  return (
    <DataTable
      emptyItem={EMPTY_MATERIAL}
      tableName={tableName}
      addMoreLabel={"Add more materials"}
      pagination
      itemsPerPage={10}
      fields={MATERIALS_COLUMNS}
      formMethods={{ getValues, setValue, reset, trigger, control }}
      initValues={initValues}
      scopedSlots={{
        category: (item, index) => {
          return (
            <td style={{ minWidth: "200px" }}>
              <ControlledInput
                {...{ control, trigger, getValues }}
                Component={BasicSelect}
                name={`${tableName}.${index}.category`}
                options={categoryOptions || []}
                showError={false}
                refresh={refresh}
                customDefault=""
              />
            </td>
          );
        },
        name: (item, index) => {
          return (
            <td style={{ minWidth: "250px",textAlign:"start" }}>
              <ControlledInput
                Component={MaterialsCreatable}
                options={materialsOptions || []}
                category={item?.category}
                name={`${tableName}.${index}.name`}
                rules={isRequired}
                {...{ control, trigger, getValues }}
                placeholder={t("shared.pleaseSpecify")}
                customDefault={null}
                refresh={refresh}
              />
            </td>
          );
        },
        subcategory: (item, index) => {
          return (
            <td style={{ minWidth: "200px" }}>
              {item?.name && MATERIAL_SUB_CATEGORIES[item.name]?.length > 1 && (
                <ControlledInput
                  {...{ control, trigger, getValues }}
                  Component={BasicSelect}
                  name={`${tableName}.${index}.subcategory`}
                  options={MATERIAL_SUB_CATEGORIES[item.name] || []}
                  showError={false}
                  refresh={refresh}
                  customDefault=""
                />
              )}
            </td>
          );
        },
        quantity: (item, index) => {
          return (
            <td style={{ minWidth: "130px" }}>
              <ControlledInput
                Component={BasicNumberInput}
                decimalScale={3}
                min={0}
                name={`${tableName}.${index}.quantity`}
                {...{ control, trigger, getValues }}
                rules={isRequiredBetween(0, 1e6)}
                allowNegative={false}
                customDefault={0}
              />
            </td>
          );
        },
        unit: (item, index) => {
          return (
            <td style={{ minWidth: "130px" }}>
              <ControlledInput
                Component={BasicSelect}
                rules={isRequired}
                name={`${tableName}.${index}.unit`}
                {...{ control, trigger, getValues }}
                options={
                  item?.name !== "concrete-blocks" ? unitOptions : ["single"]
                }
              />
            </td>
          );
        },

        country: (item, index) => {
          return (
            <td style={{ minWidth: "200px" }}>
              <ControlledInput
                Component={CountrySelect}
                name={`${tableName}.${index}.country`}
                // rules={isRequired}
                {...{ control, trigger, getValues }}
                customDefault={null}
              />
            </td>
          );
        },

        supplier: (item, index) => {
          return (
            <td style={{ minWidth: "220px" }}>
              <ControlledInput
                Component={SupplierSelect}
                name={`${tableName}.${index}.supplier`}
                // rules={isRequired}
                {...{ control, trigger, getValues }}
                customDefault={null}
              />
            </td>
          );
        },
        description: (item, index) => {
          return (
            <td style={{ minWidth: "200px" }}>
              <ControlledInput
                Component={BasicInput}
                name={`${tableName}.${index}.description`}
                {...{ control, trigger, getValues }}
                customDefault={""}
              />
            </td>
          );
        },
        transport: (item, index) => {
          return (
            <td style={{ minWidth: "150px" }}>
              {transportOptions?.length > 0 ? (
                <ControlledInput
                  Component={BasicSelect}
                  name={`${tableName}.${index}.transport`}
                  {...{ control, trigger, getValues }}
                  options={transportOptions}
                  customDefault={""}
                />
              ) : (
                <SquareButtonOutline
                  className="w-100 mr-3"
                  onClick={onClickTransport}
                >
                  Add option
                </SquareButtonOutline>
              )}
            </td>
          );
        },
        packaging: (item, index) => {
          return (
            <td style={{ minWidth: "150px" }}>
              {packagingOptions?.length > 0 ? (
                <ControlledInput
                  Component={BasicSelect}
                  name={`${tableName}.${index}.packaging`}
                  {...{ control, trigger, getValues }}
                  options={packagingOptions}
                  customDefault={""}
                />
              ) : (
                <SquareButtonOutline
                  className="w-100 mr-3"
                  onClick={onClickPackaging}
                >
                  Add option
                </SquareButtonOutline>
              )}
            </td>
          );
        },
      }}
    />
  );
};

export default MaterialsTable;
