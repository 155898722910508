import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BasicNumberInput,
  DemoWarning,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  RadioWithOther,
} from "src/components";
import { useSurveyConstants } from "src/hooks";
import { Subtitle } from "../components";

const EmployeeSurvey2 = ({
  data: values = {},
  onChange,
  info,
  workDays,
  isDemo,
}) => {
  const {
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { homeEnergy } = useSurveyConstants();

  const { t } = useTranslation();


  return (
    <FormStepWrapper
      aboveCardSlot={
        <>
          {isDemo && <DemoWarning />}
          <Subtitle
            content={t(
              "employeeSurvey.workingPatterns",
              "About your working patterns in reporting period"
            )}
          />
        </>
      }
    >
      <QuestionWrapper>
        <h3 className="mb-1">
          2.{" "}
          {t(
            "employeeSurvey.q_daysWorking1",
            "How many days a week did you work for {{name}} in {{period}}?",
            { name: info.orgName, period: info.reportingPeriod }
          )}
        </h3>
        <p className="mb-3 text-info">
          {t(
            "employeeSurvey.q_daysWorking2",
            " If you are not currently contracted, how many days a week did you work for {{name}} on average?",
            { name: info.orgName }
          )}
        </p>
        <BasicNumberInput
          decimalScale={2}
          allowNegative={false}
          min={0}
          max={7}
          placeholder={t("employeeSurvey.enterDays", "Enter number of days")}
          name="days-per-week"
          defaultValue={values["days-per-week"]}
          onChange={(e) => {
            setValue(
              "days-in-office",
              e.target.value - (getValues("days-from-home") || 0)
            );
            onChange(e);
          }}
        />
        <ErrorText value={errors["days-per-week"]} />
      </QuestionWrapper>

      <QuestionWrapper>
        <h3>
          3.{" "}
          {t(
            "employeeSurvey.q_daysFromHome",
            "How many days per week on average did you work from home during {{period}}?",
            { period: info.reportingPeriod }
          )}
        </h3>
        <BasicNumberInput
          decimalScale={2}
          allowNegative={false}
          min={0}
          max={7}
          placeholder={t("employeeSurvey.enterDays", "Enter number of days")}
          name="days-from-home"
          defaultValue={values["days-from-home"]}
          onChange={(e) => {
            setValue(
              "days-in-office",
              (getValues("days-per-week") || 7) - e.target.value
            );
            onChange(e);
          }}
        />
        <ErrorText value={errors["days-from-home"]} />
      </QuestionWrapper>

      <QuestionWrapper>
        <h3>
          4.{" "}
          Thinking about when you were working from home in {info.reportingPeriod}, what best describes your energy supply?
        </h3>
        <RadioWithOther
          name="home-energy"
          values={values}
          onChange={onChange}
          answers={homeEnergy}
        />
        <ErrorText value={errors["home-energy"]} />
      </QuestionWrapper>
    </FormStepWrapper>
  );
};

export default EmployeeSurvey2;
