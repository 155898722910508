import { useSelector } from "react-redux";
import {
  selectEmployeeCountEnd,
  selectEmployeeCountStart,
} from "src/store/projectSelectors";

const useEmployeeNumber = () => {
  const employeesNumberStart = useSelector(selectEmployeeCountStart);
  const employeesNumberEnd = useSelector(selectEmployeeCountEnd);

  const fteStart = useSelector(
    (state) => state.project.details?.coreData?.fteStart
  );
  const fteEnd = useSelector(
    (state) => state.project.details?.coreData?.fteEnd
  );

  const calculateAverage = (startNum, endNum) => {
    if (startNum !== undefined && endNum !== undefined) {
      return Math.round(((startNum + endNum) * 10) / 2) / 10;
    } else if (startNum !== undefined) {
      return startNum;
    } else if (endNum !== undefined) {
      return endNum;
    } else {
      return 0;
    }
  };

  const isUsingFte = !!(fteStart && fteEnd);

  const numberToUse = !isUsingFte
    ? calculateAverage(employeesNumberStart, employeesNumberEnd)
    : calculateAverage(fteStart, fteEnd);

  return { employeeNumber: numberToUse, isUsingFte };
};

export default useEmployeeNumber;
