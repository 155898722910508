import CIcon from "@coreui/icons-react";
import {
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CPopover,
  CToggler
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import zeroBeesLogo from "../assets/images/ZeroBees_logo_new.svg";

import { useTranslation } from "react-i18next";
import { set } from "src/store/coreuiSlice";
import { SignOutButton } from "src/components";

const TheSurveyHeader = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.coreui.darkMode);

  const { t } = useTranslation();

  return (
    <CHeader>
      <CHeaderBrand className="mr-auto ml-3 d-lg-none" to="/">
        <img src={zeroBeesLogo} alt="ZeroBees Logo" />
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none ml-3 mr-auto">
        <CHeaderNavItem>
          <CHeaderNavLink to="/dashboard">
            <img src={zeroBeesLogo} alt="ZeroBees Logo" />
          </CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>

      <CHeaderNav className="px-3">
        {/* <LanguageButton /> */}
        <CToggler
          inHeader
          className="ml-3  c-d-legacy-none"
          onClick={() => dispatch(set({ darkMode: !darkMode }))}
        >
          <CPopover content={t("header.darkMode")}>
            <CIcon
              name="cil-moon"
              className="c-d-dark-none"
              alt="CoreUI Icons Moon"
            />
          </CPopover>
          <CPopover content={t("header.lightMode")}>
            <CIcon
              name="cil-sun"
              className="c-d-default-none"
              alt="CoreUI Icons Sun"
            />
          </CPopover>
        </CToggler>
        <SignOutButton size="sm" />
      </CHeaderNav>
    </CHeader>
  );
};

export default TheSurveyHeader;
