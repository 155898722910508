import { kebabCase } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSectionOpen, useSectors, useSectionSettings } from "src/hooks";

import { selectOrgId } from "src/store/projectSelectors";

const _nav = () => {
  const organisation = useSelector(selectOrgId);

  const { t } = useTranslation();

  const { sectionSettings } = useSectionSettings();

  const betaBadge = { color: "warning", text: "BETA" };

  const { checkIsOpen, checkIsHidden } = useSectionOpen();

  const isBeta = (name) => (!!sectionSettings?.[name]?.beta ? betaBadge : {});
  const isClosed = (name) => !checkIsOpen(name).isOpen;

  const createNavItem = ({ path, icon }) => {
    return {
      _tag: "CSidebarNavItem",
      name: t(`${path}.title`),
      to: !isClosed(kebabCase(path)) ? `/project/${organisation}/${path}` : "",
      icon,
      badge: isBeta(kebabCase(path)),
      style: isClosed(kebabCase(path)) ? { opacity: 0.5 } : {},
    };
  };

  const { SECTORS } = useSectors();

  const _DataCollectionNav = SECTORS.filter(
    (sector) => !checkIsHidden(sector.name)
  ).map((item) => createNavItem(item));

  return { _DataCollectionNav, organisation };
};

export default _nav;
