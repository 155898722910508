import { cloneDeep, merge } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useDoc } from "src/hooks/databaseHooks";
import { selectSectionSettings } from "src/store/projectSelectors";

const useSectionSettings = () => {
  const { data } = useDoc(["global", "sections"]);
  const defaultSections = data?.sections;

  const projectSections = useSelector(selectSectionSettings);

  const sectionSettings = useMemo(
    () => merge(cloneDeep(defaultSections), projectSections),
    [defaultSections, projectSections]
  );

  return { defaultSections, sectionSettings };
};

export default useSectionSettings;
