import CIcon from "@coreui/icons-react";
import { CToast, CToastBody, CToaster, CToastHeader } from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Toast = () => {
  const position = "top-center";

  const { alerts } = useSelector((state) => state.alert);

  const { t } = useTranslation();

  return (
    <CToaster position={position}>
      {alerts.map((alert, i) => {
        const { text, type } = alert;
        return (
          <CToast key={i} show={!!text} autohide={4000} fade>
            <CToastHeader closeButton>
              {type === "success" && (
                <>
                  <CIcon
                    name="cil-check-circle"
                    size="lg"
                    style={{ color: "green" }}
                  />
                  <span className="ml-2 text-success">
                    {t("shared.succes", "Success")}
                  </span>
                </>
              )}{" "}
              {type === "error" && (
                <>
                  <CIcon
                    name="cil-warning"
                    size="lg"
                    style={{ color: "red" }}
                  />
                  <span className="ml-2 text-danger">
                    {t("shared.error", "Error")}
                  </span>
                </>
              )}
            </CToastHeader>
            <CToastBody className="text-info">{text}</CToastBody>
          </CToast>
        );
      })}
    </CToaster>
  );
};

export default Toast;
