import { CAlert, CButton, CPopover } from "@coreui/react";
import { cloneDeep, get, set } from "lodash";
import { useTranslation } from "react-i18next";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useWriteTasks } from "src/hooks";
import { selectCompletedTasks, selectTasks } from "src/store/projectSelectors";
import { selectUserData } from "src/store/userSlice";
import styled from "styled-components";

const TasksAlert = ({ taskName }) => {
  const { email } = useSelector(selectUserData);
  const tasks = useSelector(selectTasks);
  const completedTasks = useSelector(selectCompletedTasks);

  const defaultValue = get(tasks, taskName);
  const isAssignedToMe = defaultValue === email;
  const { t } = useTranslation();

  const taskIsCompleted = get(completedTasks, taskName);

  const { writeCompletedTasks } = useWriteTasks();

  const onClickCompleted = async () => {
    const tasksToSave = set(
      cloneDeep(completedTasks),
      taskName,
      !taskIsCompleted
    );

    try {
      await writeCompletedTasks(tasksToSave);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    isAssignedToMe && (
      <>
        <StyledAlert color="success">
          {t("assign.myTask", "This task is assigned to me!")}
          <CPopover
            content={
              taskIsCompleted ? t("tasks.clickIfNot") : t("tasks.clickIfCompl")
            }
          >
            <CButton variant="outline" color="info" onClick={onClickCompleted}>
              {taskIsCompleted ? <BsXLg /> : <BsCheckLg />}
            </CButton>
          </CPopover>
        </StyledAlert>
      </>
    )
  );
};

const StyledAlert = styled(CAlert)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .c-dark-theme & {
    background-color: #023118;
    color: #c2ffe3;
    border-color: #055c33;
  }
`;

export default TasksAlert;
