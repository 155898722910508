import { useSelector } from "react-redux";
import { DB_PATHS } from "src/constants";
import { NOTIFICATION_KEYS } from "src/constants/notificationKeys";
import { useOwnerNotifications, useUpdateDatabase } from "..";

const usePurchaseFlags = () => {
  const arePurchasesChanged = useSelector(
    (state) => state.project?.data?.["categories-changed"]
  );
  const areSuppliersChanged = useSelector(
    (state) => state.project?.data?.["suppliers-changed"]
  );
  const { removeScheduledEmailFromReminders } = useOwnerNotifications();


  const { updateDocument } = useUpdateDatabase();

  const markPurchasesUnchanged = async () => {
    if (!arePurchasesChanged) return;

    await updateDocument(DB_PATHS.organisation, {
      "categories-changed": false,
    });
    await removeScheduledEmailFromReminders(NOTIFICATION_KEYS.purchases);
  };

  const markSuppliersUnchanged = async () => {
    if (!areSuppliersChanged) return;
    await updateDocument(DB_PATHS.organisation, {
      "suppliers-changed": false,
    });
  };

  const updateAllFlags = async (flagsToWrite) => {
    await updateDocument(DB_PATHS.organisation, flagsToWrite);
  };

  return { markPurchasesUnchanged, markSuppliersUnchanged, updateAllFlags };
};

export default usePurchaseFlags;
