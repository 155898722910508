import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFirestoreDocDataOnce } from "reactfire";
import { DB_PATHS } from "src/constants";
import { useDatabasePaths, useUpdateDatabase } from "src/hooks";
import { useCollection, useDoc } from "src/hooks/databaseHooks";
import { setGlobalConstants } from "src/store/globalSlice";
import {
  resetPeriodSettings,
  setEmissionFactors,
  setSettings,
  updateData
} from "src/store/projectSlice";

const useSettings = () => {
  const dispatch = useDispatch();

  const { getPath } = useDatabasePaths();
  const { getRef, setOrUpdateDocument } = useUpdateDatabase();

  const { data: periodSettings } = useCollection(
    getPath(DB_PATHS.periodSettingsCollection)
  );

  useEffect(() => {
    if (!periodSettings) return;
    dispatch(resetPeriodSettings(periodSettings[0]));
  }, [periodSettings, dispatch]);

  /***************GET SETTINGS**************/

  const { data: settingsData } = useCollection(getPath(DB_PATHS.settings));

  const { tasks, completedTasks } =
    settingsData?.find((doc) => doc["doc-id"] === "tasks") || {};

  useEffect(() => {
    if (!tasks) return;
    dispatch(updateData({ tasks }));
  }, [tasks, dispatch]);

  useEffect(() => {
    if (!completedTasks) return;
    dispatch(updateData({ completedTasks }));
  }, [completedTasks, dispatch]);

  /***************DISPATCH PROJECT CONSTANTS**************/

  const projectSettings = settingsData?.find(
    (doc) => doc["doc-id"] === "project"
  );

  useEffect(() => {
    if (!projectSettings) return;
    dispatch(setSettings(projectSettings));
  }, [projectSettings, dispatch]);

  const { data: globalSettings } = useFirestoreDocDataOnce(
    getRef(["global", "sections"])
  );

  useEffect(() => {
    if (!projectSettings || !globalSettings) return;
    if (projectSettings.sections) return;
    const loadDefaultSettings = async () => {
      await setOrUpdateDocument(DB_PATHS.project, {
        sections: globalSettings.sections,
      });
    };
    loadDefaultSettings();
  }, [projectSettings, globalSettings]);

  /***************DISPATCH EMISSION FACTORS**************/

  const emissionFactors = settingsData?.find(
    (doc) => doc["doc-id"] === "emission-factors"
  );

  const purchaseCategoriesFactors = emissionFactors?.["purchase-categories"];

  // dispatching only ef for purchase categories - they are needed in multiple places
  useEffect(() => {
    if (!purchaseCategoriesFactors) return;
    dispatch(setEmissionFactors(purchaseCategoriesFactors));
  }, [purchaseCategoriesFactors, dispatch]);

  /***************DISPATCH GLOBAL CONSTANTS**************/

  const { data: globalConstantsDoc, status: globalStatus } = useDoc([
    "global",
    "constants",
  ]);

  const globalConstants = globalConstantsDoc?.constants;

  useEffect(() => {
    if (globalStatus !== "success" || !globalConstants) return;
    dispatch(setGlobalConstants(globalConstants));
  }, [globalConstants, globalStatus]);

  return;
};

export default useSettings;
