import { CCard, CCardBody, CProgress } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { InfoTooltip } from "src/components";
import FreemiumText from "src/components/FreemiumText/FreemiumText";
import { useEmployeeNumber } from "src/hooks";
import { setWelcomeModal } from "src/store/modalSlice";
import { selectEmployees, selectIsFreemium } from "src/store/projectSelectors";

const EmployeesCard = () => {
  const employeeSurvey = useSelector(selectEmployees);

  const isFreemium = useSelector(selectIsFreemium);

  const { employeeNumber, isUsingFte } = useEmployeeNumber();

  const avgEmployeeNum = employeeNumber;

  const employeeRecordsNum = employeeSurvey?.length || 0;
  const employeeRespondentsNum = employeeSurvey?.filter(
    (record) => record.isFromSurvey
  ).length;

  const employeeRecordsRate = avgEmployeeNum
    ? ((employeeRecordsNum / avgEmployeeNum) * 100).toFixed()
    : 100;

  const employeeResponseRate = avgEmployeeNum
    ? ((employeeRespondentsNum / avgEmployeeNum) * 100).toFixed()
    : 100;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <CCard className="px-3 ">
      <CCardBody>
        <div className="d-flex flex-column flex-xl-row justify-content-between mb-xl-3 ">
          <h2 className="mb-2">{t("welcomeModal.emplSurvey")}</h2>
          <span style={{ fontSize: "16px" }} className="text-muted mb-2">
            {t("welcomeModal.employees")} <b>{avgEmployeeNum}</b>
            <InfoTooltip
              content={
                isUsingFte
                  ? t("welcomeModal.avgFte")
                  : t("welcomeModal.avgEmployees")
              }
              size={14}
            />
          </span>
        </div>
        {!isFreemium ? (
          <>
            <h5 className="mb-0">{t("welcomeModal.recordsEntered")} </h5>
            <p style={{ fontSize: "16px" }} className="text-muted mb-0">
              <b style={{ fontSize: "24px" }} className="text-warning mr-2">
                {employeeRecordsNum}
              </b>
              {t("welcomeModal.ofTotalFTE", { rate: employeeRecordsRate })}
            </p>
            <CProgress
              className="progress-xs"
              precision={1}
              color="warning"
              value={employeeRecordsRate}
            />
            <h5 className="mt-3 mb-0">{t("welcomeModal.surveysCompl")}</h5>
            <p style={{ fontSize: "16px" }} className="text-muted mb-0">
              <b style={{ fontSize: "24px" }} className="text-warning mr-2">
                {employeeRespondentsNum}
              </b>
              {t("welcomeModal.ofTotalFTE", { rate: employeeResponseRate })}
            </p>
            <CProgress
              className="progress-xs"
              precision={1}
              color="warning"
              value={employeeResponseRate}
            />
          </>
        ) : (
          <FreemiumText
            onClick={() => dispatch(setWelcomeModal({ show: false }))}
            text={t("freemium.sectionNotAvailable")}
          />
        )}
      </CCardBody>
    </CCard>
  );
};

export default EmployeesCard;
