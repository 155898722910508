import { CFormGroup, CLabel } from "@coreui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BasicInput,
  BasicNumberInput,
  CurrencySelect,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  VerticalRadioButtons,
} from "src/components";
import { convertStrToBool } from "src/utils/common-utils";
import AboveCard from "../components/AboveCard";

const SupplierSurvey6 = ({ values, onChange, info, isDemo }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const [providesTurnover, setProvidesTurnover] = useState(
    values["provides-turnover"]
  );

  const { t } = useTranslation();

  return (
    <FormStepWrapper aboveCardSlot={<AboveCard {...{ info, isDemo }} />}>
      <QuestionWrapper>
        <h3 className="mb-4">
          {t(
            "supplierSurvey.q_turnover1",
            "9. Are you willing to provide us securely with your turnover for {{period}}?",
            { period: info.reportingPeriod }
          )}
        </h3>
        <p className="mb-4 text-info">
          {t(
            "supplierSurvey.q_turnover2",
            `This will not be passed onto {{name}}, and only used for the
              carbon calculations within this platform. The data will not be
              shared in any form outside of this platform.`,
            { name: info.orgName }
          )}
        </p>
        <CFormGroup className="w-100">
          <VerticalRadioButtons
            name="provides-turnover"
            onChange={(e) => {
              setProvidesTurnover(convertStrToBool(e.target.value));
              onChange(e);
            }}
            data={[
              { label: t("shared.yes"), value: true },
              { label: t("shared.no"), value: false },
            ]}
            defaultValue={values["provides-turnover"]}
          />
          <ErrorText value={errors["provides-turnover"]} />
        </CFormGroup>
      </QuestionWrapper>

      {providesTurnover && (
        <QuestionWrapper>
          <h3>
            {t(
              "supplierSurvey.q_turnover3",
              "10. Thank you. What was your turnover for {{period}}?",
              { period: info.reportingPeriod }
            )}
          </h3>

          <CFormGroup className="mb-3">
            <CLabel>{t("tasks.turnover", "Turnover")}</CLabel>
            <BasicNumberInput
              min={0}
              allowNegative={false}
              max={1e12}
              name="turnover"
              defaultValue={values["turnover"]}
              onChange={onChange}
              placeholder={t("shared.pleaseSpecify")}
            />
            <ErrorText value={errors["turnover"]} />
          </CFormGroup>

          <CFormGroup>
            <CLabel>{t("shared.currency")}</CLabel>
            <CurrencySelect
              onChange={onChange}
              name="currency"
              defaultValue={values["currency"]}
            />
            <ErrorText value={errors["currency"]} />
          </CFormGroup>
        </QuestionWrapper>
      )}
      {providesTurnover === false && (
        <QuestionWrapper>
          <h3>
            {t(
              "supplierSurvey.q_shareFromClient",
              "11. Approximately what proportion of your overall business came from {{name}} in {{period}} (%) ?",
              { name: info.orgName, period: info.reportingPeriod }
            )}
          </h3>

          <BasicNumberInput
            max={100}
            step={0.1}
            min={0}
            allowNegative={false}
            decimalScale="4"
            name="share-from-client"
            defaultValue={values["share-from-client"] || 0}
            onChange={onChange}
          />
          <ErrorText value={errors["share-from-client"]} />
        </QuestionWrapper>
      )}
    </FormStepWrapper>
  );
};

export default SupplierSurvey6;
