import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { analytics } from "src/adapters/common-set-up/firebase";
import { DB_PATHS } from "src/constants";
import { USER_NOTIFICATION_KEYS } from "src/constants/notificationKeys";
import {
  useBasicForm,
  useDatabasePaths,
  useDesktopNotification,
  useUpdateDatabase,
  useWriteTasks,
} from "src/hooks";
import { useCollection } from "src/hooks/databaseHooks";
import { selectUserData } from "src/store/userSlice";
import { checkIsFormTouched } from "src/utils/common-utils";
import useCollaborators from "./hooks/useCollaborators";
import useTaskEmail from "./hooks/useTaskEmail";
import useTasksState from "./hooks/useTasksState";

const useTasks = () => {
  const { firstName, email } = useSelector(selectUserData);
  const { collaborators } = useCollaborators();
  const { sendTaskEmails } = useTaskEmail();
  const [changedAssignee, setChangedAssignee] = useState([]);
  const { setNotification } = useDesktopNotification();

  const { getPath } = useDatabasePaths();

  const { data: usersData } = useCollection(getPath(DB_PATHS.adminUsers));

  const { writeTasks } = useWriteTasks();

  const { updateDocument } = useUpdateDatabase();

  const writeToDB = async (values) => {
    await writeTasks(values);

    if (changedAssignee?.length) {
      const receivers = usersData.filter((user) =>
        changedAssignee.some((data) => data.email === user.email)
      );

      await setNotification(
        [
          {
            title: "Task assigned",
            body: `${
              firstName || email
            } assigned the Task to you. Check your tasks`,
            key: USER_NOTIFICATION_KEYS.newTask,
          },
        ],
        receivers
      );

      setChangedAssignee([]);
    }
  };

  const { t } = useTranslation();

  const {
    formMethods: {
      formState: { touchedFields },
      reset,
      setValue,
      getValues,
    },
    onFormSubmit,
  } = useBasicForm({
    writeToDB,
    messages: {
      success: t("tasks.updated", "Tasks updated"),
      error: t("tasks.updateFail", "Failed to update tasks"),
    },
  });

  const onSubmit = async (e, sendEmails, updateDb = false) => {
    const values = getValues();
    await onFormSubmit(e);
    analytics.logEvent("assign_tasks");
    if (process.env.REACT_APP_SEND_EMAILS === "true" && sendEmails) {
      sendTaskEmails(values);
    }
    if (updateDb) {
      await updateDocument(DB_PATHS.organisation, {
        "task-emails": sendEmails,
      });
    }
  };

  const setTouched = () => {
    touchedFields.touched = true;
  };

  const { onTasksChange, localTasks } = useTasksState({ setTouched });

  const onChange = (e) => {
    if (e.target.value !== "none") {
      const removeDuplicate = changedAssignee?.filter(
        ({ task, email }) => task !== e.target.name
      );
      setChangedAssignee((prev) => [
        ...removeDuplicate,
        { task: e.target.name, email: e.target.value },
      ]);
    }

    onTasksChange(e);
  };

  useEffect(() => {
    reset(localTasks, { keepTouched: true });
  }, [localTasks, reset]);

  const areFieldsTouched = checkIsFormTouched(touchedFields);

  return {
    onTasksChange: onChange,
    onSubmit,
    areFieldsTouched,
    collaborators,
    values: localTasks,
    setValue,
    getValues,
    touchedFields,
  };
};

export default useTasks;
