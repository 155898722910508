import { CDataTable, CModalBody, CModalHeader } from "@coreui/react";
import formatDistance from "date-fns/formatDistance";
import { useTranslation } from "react-i18next";
import { convertKebabToDisplay } from "src/utils/common-utils";
import { LoadingSpinner } from "..";
import StyledModal from "../BasicModal/BasicModal.style";
import controller from "./controller";

const GlobalLogModal = ({ show, toggle }) => {
  const { t } = useTranslation();

  let tableColumns = [
    { label: t("log.project", "Project"), key: "project" },
    { label: t("shared.user", "User"), key: "email" },
    { label: t("log.change"), key: "step" },
    { label: t("log.changedSector", "Changed sector"), key: "sector" },
    { label: t("log.timeOfChange", "Time of change"), key: "timestamp" },
  ];

  const convertTimestamp = (seconds) => {
    return formatDistance(new Date(seconds * 1000), new Date(), {
      addSuffix: true,
    });
  };

  const { status, onPageChange, onPagesChange, logs } = controller();

  return (
    <StyledModal size="lg" show={show} onClose={toggle} centered>
      <>
        <CModalHeader
          closeButton
          className="pb-0 pt-4 flex-column align-items-center text-center"
        >
          <h3 style={{ fontWeight: 400 }} className="mb-3">
            {t("log.historyOfChanges", "History of changes")}
          </h3>
        </CModalHeader>
        {status !== "error" ? (
          <CModalBody>
            <CDataTable
              pagination
              itemsPerPage={7}
              fields={tableColumns}
              items={logs}
              onPagesChange={onPagesChange}
              onPageChange={onPageChange}
              noItemsViewSlot={
                status === "loading" ? (
                  <LoadingSpinner className="py-5" />
                ) : (
                  <p>No logs yet</p>
                )
              }
              scopedSlots={{
                step: (item, index) => {
                  return (
                    <td style={{ color: "black" }}>
                      <p>
                        {item.step
                          ? t("shared.step") + " " + item.step
                          : item.change || "N/A"}
                      </p>
                      {!!item.site && (
                        <span>
                          {" "}
                          {t("shared.in", "in")} <b>{item.site}</b>
                        </span>
                      )}
                      {!!item.provider && (
                        <span>
                          {" "}
                          {t("shared.in", "in")} <b>{item.provider}</b>
                        </span>
                      )}
                    </td>
                  );
                },
                sector: (item, index) => {
                  return (
                    <td style={{ color: "#5768FF" }}>
                      {convertKebabToDisplay(item.sector)}
                    </td>
                  );
                },
                timestamp: (item, index) => {
                  return (
                    <td style={{ color: "black" }}>
                      <p>{convertTimestamp(item.timestamp)}</p>
                    </td>
                  );
                },
              }}
            />
          </CModalBody>
        ) : (
          <p>Error fetching logs</p>
        )}
      </>
    </StyledModal>
  );
};

export default GlobalLogModal;
