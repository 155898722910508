import { useCallback } from "react";
import { RESULT_KEYS } from "src/constants/resultsSections";
import { roundNumber } from "src/utils/common-utils";
import { useCollection } from "../databaseHooks";
import usePeriodLength from "../usePeriodLength/usePeriodLength";

import { useSelector } from "react-redux";
import { selectEndDate, selectLog, selectStartDate } from "src/store/projectSelectors";
import { getEmissionFactors } from "src/utils/ef-utils";
import { calculateSwitchDomesticAirToRail } from "./functions/air";
import {
  calculateSwitchCommuteFuel,
  calculateSwitchPrivateTransportToPublic,
} from "./functions/commute";
import {
  calculateSwitchDeliveryFuel,
  calculateSwitchPassengerFuel,
} from "./functions/fleet";
import { calculateBasicReduction } from "./functions/general";
import { calculateHomeEnergySwitchToGreen } from "./functions/home";
import { calculateSwitchToFossilFree } from "./functions/investment";
import { calculateBasicPaperSwitch } from "./functions/locations";

/**
 * Hook that calculates reduction amount, for a single reduction on the assumpton it's fully implemented.
 *
 * @returns {{calculateGains: Function}}
 * - `calculateGains` - Function that calculates the reduction.
 */

const useBasicReductions = () => {

  // const pathArrEmissFact = getPath(DB_PATHS.emissionFactors);
  // const { data: emissionFactors } = useDoc(pathArrEmissFact);

  const { data: dbEmissionFactors } = useCollection(["emission-factors"]);
  const reportingPeriodStart = useSelector(selectStartDate);
  const reportingPeriodEnd = useSelector(selectEndDate);

  const emissionFactors = getEmissionFactors(
    dbEmissionFactors,
    reportingPeriodStart,
    reportingPeriodEnd
  );

  const mainLog = useSelector(selectLog);

  const { numberFTE, numberRespondents } = mainLog;

  const scaleUpFactor =
    !!numberFTE && !!numberRespondents ? numberFTE / numberRespondents : 1;

  const { reportDateFrom } = usePeriodLength();
  const baseYear = new Date(reportDateFrom).getFullYear() || 0;

  /**
   * Function that calculates the reduction.
   *
   * @param {Object} emissionResults - Calculated emissions.
   * @param {Object} reductionObject - The reduction to calculate amount for.
   *
   * @returns {number} - The reduction amount.
   */
  const calculateGains = useCallback(
    (reductionObject, emissionResults) => {
      const specifics = reductionObject?.specifics;
      if (!specifics) return 0;
      const { category, amount, type } = specifics;
      if (!type || !amount) return 0;

      if (type === "reduce") {
        return roundNumber(
          calculateBasicReduction(emissionResults, reductionObject),
          2
        );
      } else if (type === "increase") {
        return -1 * roundNumber(
          calculateBasicReduction(emissionResults, reductionObject),
          2
        );

      } else if (type === "switch") {
        if (category === "switch-paper") {
          return calculateBasicPaperSwitch(
            emissionResults,
            reductionObject,
            emissionFactors,
            mainLog
          );
        }
        if (category === "switch-energy-location") {
          return calculateBasicReduction(emissionResults, reductionObject);
        }
        if (category === "switch-energy-home") {
          return calculateHomeEnergySwitchToGreen(
            emissionResults,
            reductionObject,
            undefined,
            emissionFactors,
            baseYear
          );
        }

        if (category === "switch-air") {
          return calculateSwitchDomesticAirToRail(
            emissionResults,
            reductionObject,
            undefined,
            mainLog,
            emissionFactors,
            baseYear
          );


        }
        if (
          category.includes(RESULT_KEYS.investments) ||
          category.includes(RESULT_KEYS.pensions) ||
          category === "total-funds"
        ) {
          return calculateSwitchToFossilFree(
            emissionResults,
            reductionObject,
            undefined,
            mainLog,
            emissionFactors,
            baseYear
          );
        }
      } else if (type === "switch-commute") {
        return calculateSwitchCommuteFuel(
          emissionResults,
          reductionObject,
          undefined,
          mainLog,
          emissionFactors,
          scaleUpFactor,
          baseYear
        );
      } else if (type === "switch-public") {
        const reduction = calculateSwitchPrivateTransportToPublic(
          emissionResults,
          reductionObject,
          undefined,
          mainLog,
          baseYear
        );
        return reduction?.total || 0;
      } else if (type === "switch-passenger") {
        const reduction = calculateSwitchPassengerFuel(
          emissionResults,
          reductionObject,
          undefined,
          mainLog,
          emissionFactors,
          baseYear
        );

        return roundNumber(reduction?.total || 0, 2);
      } else if (type === "switch-delivery") {
        const reduction = calculateSwitchDeliveryFuel(
          emissionResults,
          reductionObject,
          undefined,
          mainLog,
          emissionFactors,
          baseYear
        );
        return roundNumber(reduction?.total || 0, 2);
      }
    },
    [mainLog, baseYear, emissionFactors, scaleUpFactor]
  );

  return { calculateGains };
};

export default useBasicReductions;
