export const materialsDataSteps = [
    {
      key: "welcome",
      target: ".intro-welcome",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      spotlightClicks: false,
      placement: "center",
    },
    {
      key: "materials_table",
      target: ".intro-materials-table",
      placement: "auto",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      key: "transport-options",
      target: ".intro-transport-options",
      placement: "auto",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      key: "packaging-options",
      target: ".intro-packaging-options",
      placement: "auto",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
        key: "select-options",
        target: ".intro-select-options",
        placement: "auto",
        disableBeacon: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
      },
      {
        key: "supplier",
        target: ".intro-supplier",
        placement: "auto",
        disableBeacon: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
      },
  ];
  