import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectOrgId } from "src/store/projectSelectors";
import { useHttp } from "src/hooks";

const useEmail = () => {
  const { sendRequest } = useHttp();
  const orgId = useSelector(selectOrgId);

  const { t } = useTranslation();

  const sendNewUserEmail = (body) => {
    const { email } = body;
    sendRequest("/app/email/invite", {
      method: "POST",
      body: JSON.stringify(body),
      successMsg: t("requests.inviteSuccess", { email }),
      errorMsg: t("requests.inviteError", { email }),
    });
  };

  const sendNewTasksEmail = ({ email, tasks, adminName }) => {
    sendRequest("/app/email/tasks", {
      method: "POST",
      body: JSON.stringify({ email, tasks, orgId, adminName }),
      successMsg: t("requests.taskSuccess", { email }),
      errorMsg: t("requests.taskError", { email }),
    });
  };

  const sendSupplierInvite = async ({ email, subject, text }) => {
    await sendRequest("/app/email/supplier", {
      method: "POST",
      body: JSON.stringify({ email, subject, text }),
      successMsg: t("requests.supplierInvite", { email }),
      errorMsg: t("requests.supplierError", { email }),
    });
  };

  const sendEmployeeInvite = async ({ email, subject, text }) => {
    await sendRequest("/app/email/employee", {
      method: "POST",
      body: JSON.stringify({ email, subject, text }),
      successMsg: t("requests.employeeInvite", { email }),
      errorMsg: t("requests.employeeError", { email }),
    });
  };

  const sendRemindEmail = async (body) => {
    const { email } = body;

    await sendRequest("/app/email/remind", {
      method: "POST",
      body: JSON.stringify(body),
      successMsg: t("requests.remindInvite", { email }),
      errorMsg: t("requests.remindError", { email }),
    });
  };

  const sendBasicEmail = async (body) => {
    const { email } = body;

    await sendRequest("/app/email/send", {
      method: "POST",
      body: JSON.stringify(body),
      successMsg: t("requests.basicInvite", { email }),
      errorMsg: t("requests.basicError", { email }),
    });
  };

  return {
    sendNewUserEmail,
    sendNewTasksEmail,
    sendSupplierInvite,
    sendEmployeeInvite,
    sendRemindEmail,
    sendBasicEmail,
  };
};

export default useEmail;
