import CIcon from "@coreui/icons-react";
import { CButton, CSpinner } from "@coreui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBasic, WarningModal } from "src/components";
import { useAutoCategorisation, useClassificationTask } from "src/hooks";

const Categorisation = ({ purchases }) => {
  const categorisedNum = purchases?.filter(
    (item) => !!item.classified?.length
  ).length;

  const categorisedShare = purchases?.length
    ? Math.round((categorisedNum / purchases.length) * 100)
    : 0;

  // const estimatedMinutesCategorisation = Math.round(
  //   ((1 - categorisedShare / 100) * (purchases?.length || 0) * 45) / 60
  // );

  // const convertMinutesToDisplay = (minutes) => {
  //   if (minutes < 60) return t("purchaseData.catMinutes", { minutes });

  //   return t("purchaseData.catDuration2", {
  //     count: Math.floor(minutes / 60),
  //     minutes: minutes % 60,
  //   });
  // };

  const { deleteClassifyTaskIfExists } = useClassificationTask();

  const {
    launchCategorisation,
    queueIsRunning,
    stopCategorisation,
    launchRecategorisation,
    isLaunching,
  } = useAutoCategorisation();

  const allCategorised = categorisedNum === purchases?.length;

  const [isStopping, setIsStopping] = useState(false);

  const onStop = async () => {
    setIsStopping(true);
    await stopCategorisation();
    setIsStopping(false);
  };

  const { t } = useTranslation();

  const [showWarningModal, setShowWarningModal] = useState(false);

  return (
    <>
      <div
        style={{ color: "black", maxWidth: "600px" }}
        className="bg-light rounded p-4 my-4 mx-auto d-flex flex-column align-items-center"
      >
        <h2 className=" text-center">{t("purchaseData.categorisation")}</h2>
        <p style={{ fontSize: "18px" }} className="text-center">
          {t("purchaseData.shareCategorised")}{" "}
          <b className="text-info">{categorisedShare}%</b>
        </p>

        {queueIsRunning ? (
          <>
            <span
              style={{ fontSize: "18px" }}
              className="mb-3 text-info text-center"
            >
              <CSpinner /> {t("purchaseData.catProgress")}
            </span>
            <CButton
              variant="outline"
              color="danger"
              onClick={onStop}
              disabled={isStopping}
            >
              {isStopping ? (
                <span>
                  {" "}
                  <CSpinner
                    className="mb-1"
                    style={{ width: "1rem", height: "1rem", fontSize: "10px" }}
                  />{" "}
                  Stopping...
                </span>
              ) : (
                <>
                  <CIcon name="cil-x" /> {t("purchaseData.stop")}
                </>
              )}
            </CButton>
          </>
        ) : (
          <div className="d-flex flex-column">
            <ButtonBasic
              onClick={() => {
                launchCategorisation();
                deleteClassifyTaskIfExists();
              }}
              disabled={allCategorised || isLaunching}
            >
              {isLaunching ? (
                <span>
                  <CSpinner
                    className="mb-1"
                    style={{ width: "1rem", height: "1rem", fontSize: "10px" }}
                  />{" "}
                  {t("purchaseData.launching")}
                </span>
              ) : (
                t("purchaseData.launch")
              )}
            </ButtonBasic>
            <CButton
              color="danger"
              variant="outline"
              className="mt-2"
              onClick={() => {
                setShowWarningModal(true);
              }}
            >
              Re-categorise all
            </CButton>
          </div>
        )}
        {allCategorised ? (
          <>
            <p className="text-center mt-3 mb-2">
              {t("purchaseData.allCategorised")}
            </p>
            {/* <p className="text-info text-center mb-0">
              {t("purchaseData.clearExisting")}
            </p> */}
          </>
        ) : (
          <>
            {/* <p className="text-center mt-3 mb-2">
              <Trans
                i18nKey="purchaseData.catDuration"
                components={{ 1: <b className="text-info "></b> }}
                values={{
                  time: convertMinutesToDisplay(estimatedMinutesCategorisation),
                }}
              />
            </p> */}
            <p className="text-center mt-3 mb-2">
              {t("purchaseData.alreadyCat")}
            </p>

            <p className="text-info text-center mb-0">
              {t("purchaseData.closeSite")}
            </p>
          </>
        )}
      </div>
      <WarningModal
        customMsg="Are you sure you want to re-categorise all purchases? This action will OVERWRITE all existing categories!"
        show={showWarningModal}
        toggle={() => setShowWarningModal((prev) => !prev)}
        confirmMsg="Launch"
        onConfirm={() => {
          launchRecategorisation();
          deleteClassifyTaskIfExists();
        }}
      />
    </>
  );
};

export default Categorisation;
