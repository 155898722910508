import React from "react";

import {
  VerticalRadioButtons,
  BasicInput,
  CountrySelect,
} from "src/components";
import { useState, useEffect } from "react";
import { CFormGroup, CLabel } from "@coreui/react";

import { t } from "i18next";

const SitesQuestion = ({
  answers,
  onChange,
  otherText,
  defaultValue,
  defaultCountry,
  setValue,
}) => {
  const other = {
    label: otherText || t("shared.otherOption", "Other (please specify)"),
    value: "Other",
  };

  const sharedPublic = {
    label: "Shared public work space",
    value: "shared",
  };
  const convertedAnswers = [...answers, sharedPublic, other];

  const [localState, setLocalState] = useState(defaultValue);

  const [isOther, setIsOther] = useState(false);

  useEffect(() => {
    if (
      defaultValue &&
      answers?.length &&
      !answers.map((item) => item.value).includes(defaultValue)
    ) {
      setIsOther(true);
    } else {
      setIsOther(false);
    }
  }, [defaultValue, answers]);

  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (!isTouched) return;
    onChange({ target: { name: "commute.destination", value: localState } });
  }, [localState, onChange, isTouched]);

  const localStateHandler = (e) => {
    setIsTouched(true);
    const { value } = e.target;
    if (value !== "Other") {
      setLocalState(value);
      setIsOther(false);
    } else {
      setLocalState("");
      setIsOther(true);
    }
  };

  return (
    <>
      <VerticalRadioButtons
        name="commute.destination"
        onChange={localStateHandler}
        data={convertedAnswers}
        defaultValue={!isOther ? localState : "Other"}
        otherIsChecked={isOther}
      />

      {isOther && (
        <div className="d-flex">
          <CFormGroup className="col-6 pl-0 mr-1">
            <CLabel className="text-muted">
              {t("shared.location", "Location")}
            </CLabel>
            <BasicInput
              type="text"
              name="commute.destination"
              onChange={(e) => {
                setIsTouched(true);
                setLocalState(e.target.value);
              }}
              defaultValue={localState}
              placeholder={t("shared.pleaseSpecify")}
              className="mr-4"
            />
          </CFormGroup>
          <CFormGroup className="col-6 pl-0 mr-1">
            <CLabel className="text-muted">
              {t("shared.country", "Country")}
            </CLabel>
            <CountrySelect
              onChange={(e) =>
                setValue("commute.country", e?.value, { shouldTouch: true })
              }
              defaultValue={defaultCountry}
            />
          </CFormGroup>
        </div>
      )}
    </>
  );
};

export default SitesQuestion;
