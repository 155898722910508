import React from "react";

const ErrorText = ({ value, customMessage = "", className = "" }) => {
  return (
    <div className={`${className} w-100`}>
      {!!value && (
        <small className="text-danger mt-2 w-100">
          {customMessage || value.message}
        </small>
      )}
    </div>
  );
};

export default ErrorText;
