import { CButton } from "@coreui/react";
import styled from "styled-components";

const StyledButton = styled(CButton)`
  background-color: transparent;
  color: ${({ color }) => color || "#006bd7"};
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  border-radius: ${({ borderRadius }) => borderRadius || "12px"};
  border-color: ${({ color }) => color || "#006bd7"};
  border-width: 1px !important;
  border-style: solid !important;
  box-shadow: none !important;

  padding: ${({ padding }) => padding || "8px 20px"};
  letter-spacing: 1px;

  :hover {
    color: white;
    background-color: ${({ hovercolor }) => hovercolor || "#006bd7"};
    /* border-style: hidden!important; */
  }

  :disabled {
    cursor: not-allowed;
    color: white !important;
    border: 1px solid #999999 !important;
    background-color: #cccccc !important;
  }

  .c-dark-theme & {
    :disabled {
      background-color: var(--clr-table-header-dark) !important;
    }
  }
`;

export default StyledButton;
