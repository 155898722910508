import styled from "styled-components";
import { CInput } from "@coreui/react";
import placeholderStyle from "../Placeholder/Placeholder.style";

const BasicInput = styled(CInput)`
  &::placeholder {
    ${placeholderStyle}
  }

  &,
  &:focus {
    outline: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: var(--border);
    width: 100%;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "1rem")}!important;
    font-weight: 500;
    color: var(--primary);
  }

  &:disabled {
    border: none;
    cursor: not-allowed;
  }

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

  .c-dark-theme & {
    &::-webkit-calendar-picker-indicator {
      color-scheme: dark;
    }
  }
`;

export default BasicInput;
