import { useTranslation } from "react-i18next";
import {
  FormStepWrapper
} from "src/components";
import AboveCard from "../components/AboveCard";
import StyledDiv from "./SupplierSurvey9.style";
import DefaultQuestions from "./components/DefaultQuestions";
import ConstructionQuestions from "./components/ConstructionQuestions";
import Checkbox from "src/components/Checkbox/Checkbox";
import { useState } from "react";

const SupplierSurvey9 = ({ values, onChange, info, isDemo }) => {


  const { t } = useTranslation();

  const isConstruction = values?.services?.includes("Construction services");
  const [showConstructionQuestions, setShowConstructionQuestions] = useState(false);

  return (
    <FormStepWrapper maxWidth="900px" aboveCardSlot={<AboveCard {...{ info, isDemo }} />}>

      {isDemo && (
        <div className="mb-3 align-self-start ">
          <div className="d-flex flex-direction-row items-center  ">
            <div className="mr-2">
              <span className="text-danger">DEMO:</span> Show construction
              questions
            </div>
            <Checkbox
              value={showConstructionQuestions}
              onChange={(e) => {
                setShowConstructionQuestions(e.target.checked);
              }}
            />
          </div>
          <p className="text-info mb-0" style={{ fontSize: "14px" }}>
            In the survey those questions will appear for suppliers who selected
            "Construction services" in question 2.
          </p>
        </div>
      )}
      {isConstruction || showConstructionQuestions ? <ConstructionQuestions {...{ values, info, onChange }} /> :
        <StyledDiv>
          <DefaultQuestions {...{ values, onChange, info }} />
        </StyledDiv>
      }
    </FormStepWrapper>
  );
};

export default SupplierSurvey9;
