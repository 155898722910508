import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "src/hooks";
import {
  selectFreemiumModal,
  selectWelcomeModal,
  setWelcomeModal,
  setFreemiumModal,
  selectWelcomeSectionModalState,
} from "src/store/modalSlice";
import { selectIsFreemium } from "src/store/projectSelectors";
import { hasLocalStorageFlagExpired } from "src/utils/common-utils/helpers";

const useWelcomeModals = () => {
  const dispatch = useDispatch();
  const isFreemium = useSelector(selectIsFreemium);
  const { isSuperAdmin } = usePermissions();
  const freemiumModal = useSelector(selectFreemiumModal);
  const welcomeDashboardModal = useSelector(selectWelcomeModal);
  const welcomeModal = useSelector(selectWelcomeSectionModalState);

  const showInDashboard = (userAcceptedTerms) => {
    if (
      isFreemium &&
      !isSuperAdmin &&
      !freemiumModal.hasShownInDashboard &&
      userAcceptedTerms
    ) {
      dispatch(setFreemiumModal({ show: true, showFrom: "dashboard" }));
    } else if (!welcomeDashboardModal.hasShown && userAcceptedTerms) {
      hasLocalStorageFlagExpired("welcomeModalCookie") &&
        dispatch(setWelcomeModal({ show: true }));
    }
  };
  const showInResults = () => {
    if (
      isFreemium &&
      !isSuperAdmin &&
      !freemiumModal.hasShownInResults &&
      !welcomeModal
    ) {
      dispatch(setFreemiumModal({ show: true, showFrom: "results" }));
    }
  };

  const closeFreemiumModal = () => {
    const { showFrom } = freemiumModal;

    switch (showFrom) {
      case "results":
        dispatch(
          setFreemiumModal({
            show: false,
            hasShownInResults: true,
            showFrom: null,
          })
        );
        break;

      case "dashboard":
        dispatch(
          setFreemiumModal({
            show: false,
            hasShownInDashboard: true,
            showFrom: null,
          })
        );

        dispatch(setWelcomeModal({ show: true }));
        break;

      default:
        // shown from the Freemium text icon
        dispatch(setFreemiumModal({ show: false }));
        break;
    }
  };

  return {
    setFreemiumModal: freemiumModal.show,
    closeFreemiumModal,
    showInResults,
    showInDashboard,
  };
};

export default useWelcomeModals;
