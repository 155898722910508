import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { DB_PATHS } from "src/constants";
import {
  selectDayUpdates,
  selectOrgName,
  selectOwner,
  selectOwnerId,
  selectReminders,
} from "src/store/projectSelectors";
import { generateUUID } from "src/utils/common-utils/helpers";
import { useUpdateDatabase } from "..";
import logo from "../../assets/images/ZeroBees_logo_new.svg";
import { useEmail } from "src/hooks";

const useOwnerNotifications = () => {
  const owner = useSelector(selectOwner);
  const ownerId = useSelector(selectOwnerId) || "none";

  const { addToArray, updateDocument } = useUpdateDatabase();

  const dayUpdates = useSelector(selectDayUpdates) || [];
  const reminders = useSelector(selectReminders) || [];
  const { sendBasicEmail } = useEmail();
  const orgName = useSelector(selectOrgName);
  const checkArrayHasItemWithKey = (array, key) =>
    !!array?.find((item) => item.key === key);

  const setOwnerDesktopNotification = async ({ title, body }) => {
    if (!owner) return;
    const notifyID = generateUUID();

    const newNotification = { title, body, notifyID };

    await addToArray(
      ["super-users", ownerId],
      "notifications",
      newNotification
    );
  };

  const addToDailyUpdateEmail = async ({ text, key }) => {
    if (!owner) return;
    if (checkArrayHasItemWithKey(dayUpdates, key)) return;
    let newUpdates = cloneDeep(dayUpdates);
    newUpdates.push({ text, key });
    try {
      await setOwnerDesktopNotification({
        title: `${orgName} update`,
        body: text,
      });
      await updateDocument(DB_PATHS.organisation, { dayUpdates: newUpdates });
    } catch (err) {
      console.log(err);
    }
  };

  const sendAndScheduleReminderEmail = async ({ subject, text, key }) => {
    if (!owner) return;

    if (checkArrayHasItemWithKey(reminders, key)) return;

    try {
      await setOwnerDesktopNotification({ title: subject, body: text });
      await sendBasicEmail({ subject, text, email: owner });
    } catch (err) {
      console.log(err);
    }

    let newReminders = cloneDeep(reminders);
    newReminders.push({ subject, text, key });
    try {
      await updateDocument(DB_PATHS.organisation, { reminders: newReminders });
    } catch (err) {
      console.log(err);
    }
  };

  const removeScheduledEmailFromReminders = async (keyToDelete) => {
    if (!reminders) return;
    let newReminders = cloneDeep(reminders).filter(
      (reminder) => reminder.key !== keyToDelete
    );
    try {
      await updateDocument(DB_PATHS.organisation, { reminders: newReminders });
    } catch (err) {
      console.log(err);
    }
  };

  const showOwnerNotifications = async (notifications, ownerId) => {
    for (let notification of notifications) {
      const { body, title } = notification;
      const options = {
        body,
        icon: logo,
        requireInteraction: true,
      };
      new Notification(title, options);
    }

    await updateDocument(["super-users", ownerId], {
      notifications: [],
    });
  };

  return {
    sendAndScheduleReminderEmail,
    addToDailyUpdateEmail,
    removeScheduledEmailFromReminders,
    showOwnerNotifications,
  };
};

export default useOwnerNotifications;
