export const employeeDataIntroSteps = [
  {
    key: "welcome",
    target: ".employeeData-welcome",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    spotlightClicks: false,
    placement: "center",
  },
  {
    key: "survey_explain",
    target: ".employee-intro-survey",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "review_survey_button",
    target: ".employee-intro-survey-review",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "send_survey_button",
    target: ".employee-intro-survey-email",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "click_upload_tab",
    target: ".employee-intro-form-tab",
    placement: "auto",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
    styles: {
      buttonNext: {
        display: "none",
      },
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "upload_employee_explain",
    target: ".employee-intro-upload-form",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "download_upload_button",
    target: ".employee-intro-upload-excel-btn",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "clear_records_button",
    target: ".employee-intro-upload-clear-btn",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "click_results_tab",
    target: ".employee-intro-results-tab",
    placement: "auto",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
    styles: {
      buttonNext: {
        display: "none",
      },
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "view_results_explain",
    target: ".employee-intro-results-table",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "add_employee_button",
    target: ".employee-intro-results-add-btn",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "save_button",
    target: ".employee-intro-results-save-btn",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];
