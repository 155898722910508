import { useTranslation } from "react-i18next";
import { SECTOR_KEYS } from "src/constants/sectorKeys";

const useSectors = () => {
  const { t } = useTranslation();

  const SECTORS = [
    {
      name: SECTOR_KEYS.coreData,
      title: t("coreData.title"),
      dashboardText: t("coreData.text"),
      path: "coreData",
      fieldsToAssign: [
        "full-name",
        "email",
        "website",
        "report-date",
        "turnover",
        "industry-sector",
        "locations",
        "employees-count",
      ],
      fieldsToCalculate: [
        "fullName",
        "email",
        "website",
        "reportDateFrom",
        "turnoverAmount",
        "employeesCountEnd",
      ],
      time: "10 min",
      icon: "cil-puzzle",
    },
    {
      name: SECTOR_KEYS.siteData,
      title: t("siteData.title"),
      dashboardText: t("siteData.text"),
      path: "siteData",
      fieldsToAssign: [
        "name",
        "energy-used",
        "renewable-tariff",
        "gas-used",
        "fuels",
        "water-used",
        "water-produced",
        "waste-collected",
        "renewables-used",
        "air-conditioning",
        "paper",
        "building",
        "customer-travel",
        "cinema",
      ],
      fieldsToCalculate: [
        "energy-used",
        "is-on-tariff",
        "gas-used",
        ["air-cond", "uses-ac"],
        "water-produced",
        "water-used",
        "has-renewables",
        "fuel-used",
        "printer-paper",
        "customer-num",
      ],
      fieldsToCalculateFree: [
        "energy-used",
        "is-on-tariff",
        "gas-used",
        ["air-cond", "uses-ac"],
        "has-renewables",
        "fuel-used",
      ],
      time: "20 min/site",
      icon: "cil-description",
    },

    {
      name: SECTOR_KEYS.employeeData,
      title: t("employeeData.title"),
      dashboardText: t("employeeData.text"),
      path: "employeeData",
      fieldsToAssign: ["send-survey", "upload-form", "edit-results"],
      time: "10 min",
      icon: "cil-people",
    },
    {
      name: SECTOR_KEYS.volunteerData,
      title: t("volunteerData.title"),
      dashboardText: t("volunteerData.text"),
      path: "volunteerData",
      fieldsToAssign: ["shifts", "commute"],
      fieldsToCalculate: ["shifts", "commute"],
      time: "10 min",
      icon: "cil-people",
    },
    {
      name: SECTOR_KEYS.forecastingData,
      title: t("forecastingData.title"),
      dashboardText: t("forecastingData.text"),
      path: "forecastingData",
      fieldsToAssign: ["forecasts"],
      fieldsToCalculate: ["forecasts"],
      time: "5 min",
      icon: "cil-calendar",
    },
    {
      name: SECTOR_KEYS.fleetData,
      title: t("fleetData.title"),
      dashboardText: t("fleetData.text"),
      path: "fleetData",
      fieldsToAssign: ["passenger-vehicles", "delivery-vehicles"],
      fieldsToCalculate: ["passengerVehicles", "deliveryVehicles"],
      time: "10 min",
      icon: "cil-car-alt",
    },
    {
      name: SECTOR_KEYS.purchaseData,
      title: t("purchaseData.title"),
      dashboardText: t("purchaseData.text"),
      path: "purchaseData",
      fieldsToAssign: ["upload-purchases", "edit-results"],
      time: "5 min",
      icon: "cil-pencil",
    },
    {
      name: SECTOR_KEYS.suppliersData,
      title: t("supplierData.title"),
      dashboardText: t("supplierData.text"),
      path: "supplierData",
      fieldsToAssign: ["supplier-invitation"],
      time: "10 min",
      icon: "cil-building",
    },
    {
      name: SECTOR_KEYS.travelData,
      title: t("travelData.title"),
      dashboardText: t("travelData.text"),
      path: "travelData",
      fieldsToAssign: [
        "travel-intro",
        "road-travel",
        "overnight-stays",
        "air-travel",
      ],
      fieldsToCalculate: ["road", "overnight", "flights"],
      time: "10 min",
      icon: "cil-airplane-mode",
    },
    {
      name: SECTOR_KEYS.adData,
      title: t("adData.title"),
      dashboardText: t("adData.text"),
      path: "adData",
      fieldsToAssign: ["format-details"],
      time: "10 min",
      icon: "cil-tv",
    },
    {
      name: SECTOR_KEYS.materialsData,
      title: t("materialsData.title"),
      dashboardText: t("materialsData.text"),
      path: "materialsData",
      time: "5 min",
      icon: "cil-square",
      fieldsToAssign: ["materials"],
      fieldsToCalculate: ["materials"],
    },
    {
      name: SECTOR_KEYS.packagingData,
      title: t("packagingData.title"),
      dashboardText: t("packagingData.text"),
      path: "packagingData",
      time: "5 min",
      icon: "cil-circle",
      fieldsToAssign: ["materials"],
      fieldsToCalculate: ["materials"],
    },
    {
      name: SECTOR_KEYS.distributionData,
      title: t("distributionData.title"),
      dashboardText: t("distributionData.text"),
      path: "distributionData",
      time: "5 min",
      icon: "cil-truck",
      fieldsToAssign: ["channels"],
      fieldsToCalculate: ["transport"],
    },
    {
      name: SECTOR_KEYS.investmentData,
      title: t("investmentData.title"),
      dashboardText: t("investmentData.text"),
      path: "investmentData",
      fieldsToAssign: ["provider-name"],
      fieldsToCalculate: [],
      time: "5 min/provider",
      icon: "cil-money",
    },
    {
      name: SECTOR_KEYS.webData,
      title: t("webData.title"),
      dashboardText: t("webData.text"),
      path: "webData",
      fieldsToAssign: ["website"],
      fieldsToCalculate: ["website", "views", "viewsPeriod", "knowsCountry", "country", "knowsDevice", "device"],
      time: "5 min",
      icon: "cil-browser",
    },
  ];

  return { SECTORS };
};

export default useSectors;
