import { createSlice } from "@reduxjs/toolkit";
import camelcaseKeys from "camelcase-keys";

const initialState = {
  data: {
    name: null,
    organisation: null,
    tasks: null,
    "suppliers-changed": false,
    "categories-changed": false,
    "purchases-loaded": false,
    owner: "",
    completedTasks: null,
    period: "none",
  },
  details: {
    adData: {},
    coreData: {},
    employeeData: {},
    purchaseData: {},
    sectorData: {},
    siteData: {},
    investmentData: {},
    webData: {}
  },
  settings: {
    constants: {},
    sections: {},
  },
  periodSettings: {
    categories: [],
    overrides: {},
    commentary: {},
    "categories-factors": {},
    filters: {},
    "supplier-overrides": {},
    "macro_overrides": {},
  },
  sites: [],
  suppliers: [],
  hasPurchases: false,
  purchases: [],
  employees: [],
  pensionProviders: [],
  investmentProviders: [],
  "emission-factors": {},
  log: {},
  assessmentSurvey: [],
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjectInit: (state, action) => {
      const { orgData, organisation, detailsData } = action.payload;

      const editDetails = () => {
        if (detailsData !== undefined) {
          let obj = {};
          camelcaseKeys(detailsData).forEach((item) => {
            obj = {
              ...obj,
              [item.docId || item.noIdField]: item,
            };
          });
          return camelcaseKeys(obj);
        }
      };
      const updatedDetails = editDetails();

      return {
        ...state,
        data: { ...state.data, ...orgData, organisation },
        details: { ...updatedDetails },
      };
    },

    updateData: (state, action) => {
      return { ...state, data: { ...state.data, ...action.payload } };
    },

    updateProject: (state, action) => {
      const { details, data, organisation } = action.payload;
      return {
        ...state,
        data: { ...state.data, ...data, organisation },
        details: { ...details },
      };
    },

    resetProject: (state, action) => {
      return initialState;
    },

    setSites: (state, action) => {
      state.sites = action.payload;
    },

    setSuppliers: (state, action) => {
      state.suppliers = action.payload;
    },
    setPurchases: (state, action) => {
      state.purchases = action.payload;
    },
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    setOrg: (state, action) => {
      state.data.organisation = action.payload;
    },
    setPensionProviders: (state, action) => {
      state.pensionProviders = action.payload;
    },
    setInvestmentProviders: (state, action) => {
      state.investmentProviders = action.payload;
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
      // state.settings.constants = action.payload.constants;
      // state.settings.sections = action.payload.sections;
    },
    resetPeriodSettings: (state, action) => {
      state.periodSettings = action.payload;
    },
    setEmissionFactors: (state, action) => {
      state["emission-factors"] = action.payload;
    },
    setLog: (state, action) => {
      state.log = action.payload;
    },
    setAssessmentSurvey: (state, action) => {
      state.assessmentSurvey = action.payload;
    },
    setHasPurchases: (state, action) => {
      state.hasPurchases = action.payload;
    }
  },
});

export const {
  setProjectInit,
  updateProject,
  resetProject,
  setSites,
  setSuppliers,
  setPurchases,
  setEmployees,
  setOrg,
  setPensionProviders,
  setInvestmentProviders,
  setSettings,
  setEmissionFactors,
  setGlobalConstants,
  updateData,
  resetPeriodSettings,
  setLog,
  setAssessmentSurvey,
  setHasPurchases
} = projectSlice.actions;

export default projectSlice.reducer;
