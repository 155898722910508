import React, { Suspense } from "react";
import { TheSurveyHeader } from "./index";
import { useSelector } from "react-redux";
import classNames from "classnames";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheSurveyLayout = ({ children, ...rest }) => {
  const darkMode = useSelector((state) => state.coreui.darkMode);

  const classes = classNames(
    "c-app c-default-layout",
    darkMode && "c-dark-theme"
  );

  return (
    <div className={classes} {...rest}>
      <div className="c-wrapper">
        <TheSurveyHeader />
        <div className="c-body">
          <main className="c-main position-relative">
            <Suspense fallback={loading}>{children}</Suspense>
          </main>
        </div>
      </div>
    </div>
  );
};

export default TheSurveyLayout;
