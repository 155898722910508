import {
  CButton,
  CFormGroup,
  CInputCheckbox,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader
} from "@coreui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { DB_PATHS } from "src/constants";
import { usePermissions, useUpdateDatabase } from "src/hooks";
import { selectOrgId } from "src/store/projectSelectors";
import { selectUserId } from "src/store/userSlice";

const TermsModal = () => {
  const { isSuperAdmin } = usePermissions();

  const orgId = useSelector(selectOrgId);
  const uid = useSelector(selectUserId) || "none";

  const [isAccepted, setIsAccepted] = useState(null);

  const { updateDocument, getRef } = useUpdateDatabase();

  const userRef = getRef(DB_PATHS.user);
  userRef.onSnapshot((doc) => {
    const data = doc.data();
    setIsAccepted(!!data?.acceptedTerms);
  });

  const shouldShow = !isSuperAdmin && isAccepted === false;

  const onChange = (e) => {
    const { checked } = e.target;
    setChecked(checked);
  };

  const [checked, setChecked] = useState(false);

  const onAccept = () => {
    updateDocument(DB_PATHS.user, { acceptedTerms: checked });
  };

  return (
    <CModal closeOnBackdrop={false} centered show={shouldShow}>
      <CModalHeader className="bg-light">
        <h4 className=" mb-0" style={{ color: "black", lineHeight: 1.5 }}>
          Please read and accept the terms and conditions
        </h4>
      </CModalHeader>
      <CModalBody>
        <a
          style={{ fontSize: "20px" }}
          className="text-info mx-auto"
          href="https://www.zerobees.com/legal"
          target="_blank"
          rel="noreferrer"
        >
          Read terms and conditions
        </a>
        <CFormGroup className="my-3" variant="custom-checkbox">
          <CInputCheckbox
            custom
            id="terms-checkbox"
            onChange={onChange}
            defaultChecked={false}
            style={{ borderRadius: 0 }}
          />
          <CLabel variant="custom-checkbox" htmlFor="terms-checkbox">
            I accept the terms and conditions
          </CLabel>
        </CFormGroup>
        <CModalFooter>
          <CButton
            color="success"
            size="lg"
            onClick={onAccept}
            disabled={!checked}
          >
            Continue
          </CButton>
        </CModalFooter>
      </CModalBody>
    </CModal>
  );
};

export default TermsModal;
