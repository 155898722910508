import { CAlert, CLink, CPopover } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions, useReportingPeriod } from "src/hooks";
import { setStep } from "src/store/navSlice";
import { selectOrgId, selectOrgName } from "src/store/projectSelectors";
import styled from "styled-components";
import AssignTask from "./AssignTask";

const PeriodAlert = ({
  taskName,
  orgName,
  siteName,
  reportingPeriod: periodFromContext,
  isSurvey,
}) => {
  const orgNameFromRedux = useSelector(selectOrgName);

  const { reportingPeriod, periodName } = useReportingPeriod();

  const { hasExtendedPermissions } = usePermissions();

  const canBeAssigned = hasExtendedPermissions && !!taskName;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const orgId = useSelector(selectOrgId);

  const periodText =
    periodFromContext || reportingPeriod || t("shared.periodNotSet");

  return (
    <>
      <StyledAlert
        className="m-0 d-flex align-items-center w-100 mb-2"
        color="info"
      >
        <span className="mr-auto">
          <b>{orgName || orgNameFromRedux}</b> |{" "}
          {!isSurvey ? (
            <CPopover content={t("shared.setPeriod")}>
              <CLink
                className="text-info"
                onClick={() => {
                  dispatch(setStep(4));
                }}
                to={`/project/${orgId}/coreData`}
              >
                {periodText}
              </CLink>
            </CPopover>
          ) : (
            <span>{periodText}</span>
          )}
          {(periodFromContext || reportingPeriod) && periodName ? (
            <CPopover
              header={t("shared.periodDescription")}
              content={periodFromContext || reportingPeriod}
            >
              {periodName && <strong className="ml-1">({periodName})</strong>}
            </CPopover>
          ) : null}
        </span>
        {canBeAssigned && <AssignTask name={taskName} siteName={siteName} />}
      </StyledAlert>
    </>
  );
};

const StyledAlert = styled(CAlert)`
  .c-dark-theme & {
    background-color: var(--clr-table-dark-2);
    border-color: #4638c2;
    color: white;

    button {
      color: white;
      border-color: white;
    }
  }
`;

export default PeriodAlert;
