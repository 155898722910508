import {
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import { LoadingSpinner, NavPills } from "src/components";
import OrgsCard from "./components/OrgsCard";
import OrgsPanel from "./components/OrgsPanel";
import UsersCard from "./components/UsersCard";
import controller from "./controller";

const SuperAdminPage = () => {
  const { t } = useTranslation();

  const {
    archiveOrg,
    unarchiveOrg,
    tableItems,
    setShowArchive,
    showArchive,
    allOrgs,
    selectOrg,
    assignOwner,
    unassignOwner,
    setFreemium,
    status
  } = controller();



  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center mx-auto w-100"
        style={{ maxWidth: "1200px" }}
      >
        <CTabs activeTab="orgs">
          <NavPills
            className=" d-flex justify-content-center w-100 mt-3"
            variant="pills"
          >
            <CNavItem>
              <CNavLink data-tab="orgs">
                {t("superAdmin.organisations")}
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink data-tab="users"> {t("superAdmin.users")}</CNavLink>
            </CNavItem>
          </NavPills>
          <CTabContent className="w-100">
            <CTabPane data-tab="orgs">
              <h1 className="text-center my-4" style={{ fontWeight: "600" }}>
                {t("superAdmin.orgPage", "Organisation Management Page")}
              </h1>
              {status === "loading" ?
                <LoadingSpinner /> :
                status === "error" ?
                  <h2 className="text-danger">There was an error loading organisations</h2> :
                  <><OrgsPanel {...{ allOrgs }} />
                    <OrgsCard
                      {...{
                        archiveOrg,
                        unarchiveOrg,
                        tableItems,
                        setShowArchive,
                        showArchive,
                        allOrgs,
                        selectOrg,
                        assignOwner,
                        unassignOwner,
                        setFreemium,
                      }}
                    /></>}
            </CTabPane>
            <CTabPane data-tab="users">
              <h1
                className="text-center mt-5 mb-4"
                style={{ fontWeight: "600" }}
              >
                {t("superAdmin.usersPage")}
              </h1>
              <UsersCard {...{ allOrgs }} />
            </CTabPane>
          </CTabContent>
        </CTabs>
      </div>
    </>
  );
};

export default SuperAdminPage;
