import styled from "styled-components";

const StyledDiv = styled.div`

width: 100%;
  h4 {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 300;
  }

  h5 {
    font-size: 16px;
    margin-bottom: 0;
    padding-block: 0.5rem;
  }

  thead {
    background-color:  #F9FAFA;
;
  }

  tbody {
    tr:nth-child(2n) {
      background-color:  #F9FAFA;
;
    }
    tr:nth-child(2n + 1) {
      background-color: #dadada;
    }
  }

  th,
  td {
    font-size: 14px;
    padding: 0.5rem;
    text-align: center;
    vertical-align: center;

  }

`;

export default StyledDiv;
