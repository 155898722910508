import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/storage";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// a function to block or allow analytics cookies
window.dataLayer = window.dataLayer || [];
export function gtag() {
  window.dataLayer.push(arguments);
}

// analytics cookies blocked by default
gtag("consent", "default", {
  analytics_storage: "denied",
});

firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics();

export const storage = firebase.storage();

export default firebase;
