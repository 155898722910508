import { CButton, CTooltip } from "@coreui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskModal } from "src/components";
import { convertKebabToDisplay } from "src/utils/common-utils";

const AssignTask = ({ name, siteName }) => {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const toggle = () => {
    setShowTaskModal((prev) => !prev);
  };

  const { t } = useTranslation();

  let label;

  if (siteName) {
    const [_, id, step] = name.split(".");
    label = `${t("siteData.title")}: ${siteName} - ${convertKebabToDisplay(
      step
    )}`;
  }
  return (
    <>
      <CTooltip content={t("tasks.assignToCollaborator")}>
        <CButton
          className="ml-auto "
          variant="outline"
          size="sm"
          color="info"
          onClick={toggle}
        >
          + Assign
        </CButton>
      </CTooltip>
      <TaskModal
        show={showTaskModal}
        {...{
          name,
          toggle,
          label,
        }}
      />
    </>
  );
};

export default AssignTask;
