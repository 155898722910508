import { useTranslation } from "react-i18next";
import { CURRENCIES } from "src/constants";
import { useExcelConstants } from "src/hooks";

const usePurchaseConstants = () => {
  const { t } = useTranslation();

  const { numberRule, selectRule, HEADER_STYLES, dateRule } =
    useExcelConstants();

  const XLSX_PURCHASE_DATA = [
    {
      id: "Autogenerated",
      name: "Example",
      category: "IT Services, Software ",
      description: "Supporting SMEs measure",
      spending: 30000,
      currency: "GBP",
    },
  ];

  const PURCHASE_EXCEL_COLUMNS = [
    // { header: "Id", key: "id", hidden: true },
    {
      header: "Supplier",
      key: "name",
      width: 25,
    },
    {
      header: "Account",
      key: "category",
      width: 30,
    },
    {
      header: t("shared.description", "Description"),
      key: "description",
      width: 15,
    },
    {
      header: t("excel.totalSpending", "Total spending"),
      key: "spending",
      width: 15,
    },
    { header: t("shared.currency", "Currency"), key: "currency", width: 15 },
    { header: t("shared.date", "Date"), key: "date", width: 15 },
  ];

  const PURCHASE_HEADERS = PURCHASE_EXCEL_COLUMNS.map((item) => item.key);

  const PURCHASE_EXCEL_PROPS = (worksheet) => {
    const applyValidationToColumn = (letter, rule) => {
      // const letter = String.fromCharCode(64 + worksheet.getColumn(key)._number);
      worksheet.dataValidations.model[`${letter}2:${letter}9999`] = rule;
    };

    const requiredRule = {
      type: "textLength",
      operator: "greaterThan",
      allowBlank: true,
      formulae: [0],
      promptTitle: t("excel.required", "Required field"),
      prompt: t("validation.enterValue", "Please enter value"),
      showInputMessage: true,
    };

    applyValidationToColumn("A", requiredRule);
    applyValidationToColumn("B", {
      ...requiredRule,
      prompt: t(
        "excel.enterCategories",
        "Please enter category or a comma-separated list of categories"
      ),
    });
    applyValidationToColumn("D", numberRule(1, 1e9));
    applyValidationToColumn("E", {
      ...selectRule,
      formulae: ["currencies!$B$2:$B$120"],
      promptTitle: t("shared.currency"),
    });
    applyValidationToColumn("F", dateRule);
  };

  const CURRENCY_WORKSHEET = (workbook) => {
    let worksheet = workbook.addWorksheet("currencies");
    worksheet.getColumn(1).values = [
      t("shared.name", "Name"),
      ...Object.values(CURRENCIES).map((item) => item.name),
    ];

    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).values = [
      t("excel.currencyCode", "Code"),
      ...Object.keys(CURRENCIES),
    ];

    worksheet.getRow(1).eachCell((cell) => {
      Object.entries(HEADER_STYLES).forEach((entry) => {
        const [key, values] = entry;
        cell[key] = values;
      });
    });
  };

  const PURCHASE_TABLE_COLUMNS = [
    {
      key: "name",
      label: t("shared.name"),
      _style: { width: "200px" },
    },
    {
      key: "category",
      label: "Account",
      // sorter: false,
      // filter: false,
      _style: { width: "23%" },
    },
    {
      key: "description",
      label: t("shared.description"),
    },
    {
      key: "classified",
      // sorter: false,
      // filter: false,
      label: "Category",
      // _style: { width: "100px" },
    },
    {
      key: "confidence",
      label: t("purchaseData.confidence"),
      _style: { width: "100px" },
    },

    {
      key: "spending",
      label: t("shared.spending"),
      _style: { minWidth: "150px", width: "10%" },
    },
    {
      key: "currency",
      label: t("shared.currency"),
      _style: { width: "160px" },
    },
    {
      key: "date",
      label: t("shared.date"),
      _style: { width: "100px" },
    },
    { key: "records", label: t("purchaseData.purchases") },
  ];

  return {
    PURCHASE_EXCEL_COLUMNS,
    PURCHASE_EXCEL_PROPS,
    PURCHASE_HEADERS,
    CURRENCY_WORKSHEET,
    XLSX_PURCHASE_DATA,
    PURCHASE_TABLE_COLUMNS,
  };
};

export default usePurchaseConstants;
