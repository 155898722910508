import React from "react";
import { useTranslation } from "react-i18next";
import BasicSelect from "../BasicSelect/BasicSelect";

const DistanceUnitSelect = (props) => {
  const { t } = useTranslation();

  return (
    <BasicSelect
      placeholder={t("shared.selectUnit")}
      {...props}
      options={["mi", "km"]}
    />
  );
};

export default DistanceUnitSelect;
