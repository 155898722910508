import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { upperFirst } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  convertArrayOfObjIntoArrays,
  convertDataIntoExcel,
  convertIntoExcelDate,
} from "./utils";

const useExcel = () => {
  const { t } = useTranslation();

  /***************EXCEL LOG**************/

  const [excelLog, setExcelLog] = useState([]);

  const clearExcelLog = () => {
    setExcelLog([]);
  };

  const saveToExcelLog = (index, message, type) => {
    setExcelLog((prev) => [
      ...prev,
      { msg: `Row ${index}: ${message}`, type, index },
    ]);
  };

  /***************CONVERTING AND VALIDATING DATA FROM EXCEL**************/

  const convertFromExcelDate = (excelDate, index, key) => {
    if (!excelDate) return null;
    let date;
    if (typeof excelDate === "string") {
      if (!/^\d{1,2}.\d{1,2}.\d{4}$/.test(excelDate)) {
        saveToExcelLog(
          index,
          t("excel.parseError", "Can't parse {{property}}", {
            property: key.replace("-", " "),
          }),
          "warning"
        );
        return null;
      }

      const [days, months] = excelDate.match(/\d{1,2}/g);
      const years = excelDate.match(/\d{4}/)[0];

      date = new Date(years, months - 1, days);
    } else if (typeof excelDate === "number") {
      const daysAfter1970 = excelDate - 25569;

      date = new Date(daysAfter1970 * 24 * 60 * 60 * 1000);
    }

    const years = date.getFullYear();
    const months = date.getMonth() + 1;
    const days = date.getDate();

    if (isNaN(years) || isNaN(months) || isNaN(days)) {
      saveToExcelLog(
        index,
        t("excel.parseError", "Can't parse {{property}}", {
          property: key.replace("-", " "),
        }),
        "warning"
      );
      return null;
    }

    const addZero = (value) => (value < 10 ? "0" + value : value);

    return `${years}-${addZero(months)}-${addZero(days)}`;
  };

  const validateNumber = (num, min, max, index, name) => {
    if (num === undefined || isNaN(num)) {
      saveToExcelLog(
        index,
        t("excel.naN", "{{name}} is not a number.", {
          name: upperFirst(name),
        }),
        "warning"
      );
      return 0;
    }
    if (num < min || num > max) {
      saveToExcelLog(
        index,
        t("excel.outOfRange", "{{name}} is outside of permitted range.", {
          name: upperFirst(name),
        }),
        "warning"
      );
      return 0;
    }
    return +num;
  };

  const validateSelect = (
    inputStr,
    index,
    name,
    options,
    shouldCapitalise = true,
    valuesArray,
    notifyIsMissing = false
  ) => {
    if (!inputStr || typeof inputStr !== "string") {
      if (notifyIsMissing) {
        saveToExcelLog(
          index,
          t("excel.missing", "{{name}} is missing.", {
            name: upperFirst(name),
          }),
          "warning"
        );
      }
      return;
    }
    if (!options.includes(inputStr.toLowerCase())) {
      saveToExcelLog(
        index,
        t("excel.notPermitted", "{{name}} is not a permitted value.", {
          name: upperFirst(name),
        }),
        "warning"
      );
      return;
    }
    let value = inputStr;
    if (shouldCapitalise && inputStr[0] === inputStr[0]?.toLowerCase()) {
      value = upperFirst(value);
    }
    if (valuesArray) {
      value = valuesArray.find((item) => item.label === value).value;
    }

    return value;
  };

  const createAndSaveExcelSheet = async ({
    dataAoA,
    sheetName,
    fileName,
    columns,
    headerStyles,
    applyWorksheetProperties,
    addWorksheet,
    headersRow = 1,
    headers,
  }) => {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetName);

    if (columns) {
      worksheet.columns = columns;
    } else if (headers) {
      worksheet.getRow(headersRow).values = headers;
    }

    worksheet.getRow(headersRow).eachCell((cell) => {
      Object.entries(headerStyles).forEach((entry) => {
        const [key, values] = entry;
        cell[key] = values;
      });
    });

    dataAoA.forEach((row) => {
      worksheet.addRow(row);
    });

    await applyWorksheetProperties?.(worksheet);

    addWorksheet?.(workbook);

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, `${fileName}.xlsx`);
    });
  };

  return {
    createAndSaveExcelSheet,
    convertArrayOfObjIntoArrays,
    excelLog,
    setExcelLog,
    clearExcelLog,
    saveToExcelLog,
    validateNumber,
    validateSelect,
    convertFromExcelDate,
    convertIntoExcelDate,
    convertDataIntoExcel,
  };
};

export default useExcel;
