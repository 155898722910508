import { CCard, CCardBody } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useCompletedRate } from "src/hooks";
import { selectIsFreemium } from "src/store/projectSelectors";
import CompletionBar from "./CompletionBar";

const DataCollectionCard = () => {
  const { calculateTotalCompletion, calculateFreemiumCompletion } =
    useCompletedRate();

  const isFreemium = useSelector(selectIsFreemium);

  const dataCollectionRate = Math.round(calculateTotalCompletion());

  const freemiumCompletionRate = Math.round(calculateFreemiumCompletion());

  const { t } = useTranslation();
  return (
    <CCard className="px-3 ">
      <CCardBody>
        <h2 className="mb-3">{t("welcomeModal.dataCollection")}</h2>

        {!isFreemium ? (
          <CompletionBar completionRate={dataCollectionRate} />
        ) : (
          <>
            {" "}
            <CompletionBar
              completionRate={freemiumCompletionRate}
              scale={0.75}
              label="freemium completed"
            />
            <CompletionBar
              completionRate={dataCollectionRate}
              color="secondary"
              scale={0.5}
              label="total completed"
            />
          </>
        )}
      </CCardBody>
    </CCard>
  );
};

export default DataCollectionCard;
