import React from "react";

const SectionSubtitle = ({
  title,
  margin = "mb-4",
  className = "",
  ...rest
}) => {
  return (
    <h5 className={`w-100 text-center ${className} ${margin}`} {...rest}>
      {title}
    </h5>
  );
};

export default SectionSubtitle;
