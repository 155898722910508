

export const dashboardIntroSteps = [
  {
    key: "welcome",
    target: ".dashboard-welcome",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    spotlightClicks: false,
    placement: "center",
  },
  {
    key: "card",
    target: ".dashboard-intro-card",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    spotlightClicks: true,
  },
  {
    key: "progress_bar",
    target: ".dashboard-intro-progressbar",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "project_status",
    target: ".dashboard-intro-project-status",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "sidebar_data_collection",
    target: ".sidebar-intro-data-collection",
    placement: "right",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "sidebar_results",
    target: ".sidebar-intro-summary",
    placement: "right",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "sidebar_collaboration",
    target: ".sidebar-intro-collaboration-tools",
    placement: "right",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "notification_box",
    target: ".notification-intro",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "period_switch",
    target: ".period-intro",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "dark_mode",
    target: ".darkmode-intro",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "help_center",
    target: ".help-center-intro",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "account_settings",
    target: ".account-settings",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];
