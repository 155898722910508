import { CButton, CModalBody, CModalFooter } from "@coreui/react";
import { useTranslation, Trans } from "react-i18next";

import { BasicModal } from "src/components";

const WarningModal = ({
  type,
  name,
  onConfirm,
  show,
  toggle,
  customMsg = "",
  confirmMsg = "",
}) => {
  const { t } = useTranslation();

  return (
    <BasicModal show={show} onClose={toggle} centered>
      <CModalBody>
        <h5
          style={{ lineHeight: "1.5" }}
          className="my-4 mx-auto w-75 text-center"
        >
          {customMsg || (
            <Trans i18nKey="shared.deleteWarning">
              {{ type }} <b className="text-info">{{ name }}</b> will be deleted
              and all associated data lost!
            </Trans>
          )}
        </h5>

        <p className="w-100 text-center text-danger">
          {t("shared.warningPrompt", "Are you sure you want to continue?")}
        </p>
      </CModalBody>
      <CModalFooter className="bg-light">
        <CButton
          color="danger"
          variant="outline"
          onClick={() => {
            onConfirm();
            toggle();
          }}
        >
          {confirmMsg || t("shared.delete")}
        </CButton>
        <CButton color="success" variant="outline" onClick={toggle}>
          {t("shared.cancel", "Cancel")}
        </CButton>
      </CModalFooter>
    </BasicModal>
  );
};

export default WarningModal;
