import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { convertStrToBool } from "src/utils/common-utils";

const SurveyController4 = ({ values, onChange, siteData }) => {
  const {
    formState: { errors },
    setValue,
  } = useFormContext();

  const [sites, setSites] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const sites = siteData?.length ? siteData.map(({ name }) => name) : [];
    setSites(() => [
      ...sites.map((site) => {
        return { label: site, value: site };
      }),
      {
        label: t("employeeSurvey.noCommute", "I only work from home (no commute)"),
        value: "noCommute",
      },
    ]);
  }, [siteData]);

  const [doesCommute, setDoesCommute] = useState(
    values.commute?.destination !== "noCommute"
  );

  const [usesVehicle, setUsesVehicle] = useState(values["uses-vehicles"]);

  const commuteDestHandler = (e) => {
    if (e.target.value === "noCommute") {
      setDoesCommute(false);
      setValue("is-commuting", false);
    } else {
      setDoesCommute(true);
      setValue("is-commuting", true);

      const siteCountry = siteData.find(
        (site) => site.name === e.target.value
      )?.country;

      if (siteCountry) {
        setValue("commute.country", siteCountry);
      }
    }
    onChange(e);
  };
  const usesVehicleHandler = (e) => {
    const value = convertStrToBool(e.target.value);
    setUsesVehicle(value);
    onChange({ target: { name: "uses-vehicles", value } });
  };

  return {
    errors,
    usesVehicleHandler,
    usesVehicle,
    doesCommute,
    commuteDestHandler,
    sites,
    setValue,
  };
};

export default SurveyController4;
