import { CButton, CPopover } from "@coreui/react";
import { FaQuestion } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { usePermissions } from "src/hooks";
import { showWelcomeSectionModal } from "src/store/modalSlice";

const HelpIntroButton = ({ className }) => {
  const dispatch = useDispatch();
  const { isSuperAdmin } = usePermissions();

  return (
    !isSuperAdmin && (
      <CPopover content="Introduction">
        <CButton
          className={`${className} py-1 px-2`}
          onClick={() => dispatch(showWelcomeSectionModal(true))}
          size="lg"
          variant="outline"
          color="info"
        >
          <FaQuestion />
        </CButton>
      </CPopover>
    )
  );
};

export default HelpIntroButton;
