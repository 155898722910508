import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBasic } from "src/components";

const ErrorFallback = () => {
  function refreshPage() {
    window.location.reload(false);
  }
  useEffect(() => {
    // just in case, if user try to go back
    return () => {
      refreshPage();
    };
  }, []);

  const { t } = useTranslation();

  return (
    <div className="text-center">
      <h2 className="mb-4">{t("errors.fallback", "Something went wrong")}</h2>
      <ButtonBasic onClick={refreshPage}>
        {t("errors.reload", "Click to reload")}
      </ButtonBasic>
    </div>
  );
};

export default ErrorFallback;
