import { RESULT_KEYS } from "src/constants/resultsSections";
import { roundNumber } from "src/utils/common-utils";
import { calculateReductionPercentage } from "./general";

const calculatePaperSwitchForLocation = (
  result,
  location,
  reductionPercentage,
  emissionFactors,
  log
) => {
  if (!location) return 0;
  if (!result.locations[location]) return 0;

  const defaultPaperRecEf =
    emissionFactors?.location?.paper?.PAPER_RECYCLING_CO2E_PER_KG

  const sitesLog = log?.sitesLog;
  if (!sitesLog) return 0;

  const paperRecyclingRecord = sitesLog.find(
    (record) =>
      record.name === location && record.category === "paper-recycling"
  );

  const paperNotRecyclingRecord = sitesLog.find(
    (record) =>
      record.name === location && record.category === "paper-not-recycling"
  );

  const notRecAmount = paperNotRecyclingRecord?.amount || 0;
  const recAmount = paperRecyclingRecord?.amount || 0;

  const recEmissions =
    result.locations?.[location][RESULT_KEYS.locations.paperRec] || 0;

  const paperRecEf = recAmount ? recEmissions / recAmount : defaultPaperRecEf;

  const amountToSwitch = notRecAmount * reductionPercentage;

  const decreaseNotRec =
    result.locations[location][RESULT_KEYS.locations.paperNotRec] *
    reductionPercentage;

  const increaseRec = amountToSwitch * paperRecEf;
  const reduction = decreaseNotRec - increaseRec;

  return { reduction, increaseRec, decreaseNotRec };
};

export const calculateBasicPaperSwitch = (
  results,
  recommendation,
  emissionFactors,
  log
) => {
  const {
    specifics: { amount: reduceBy, location },
  } = recommendation;
  if (!results) return 0;

  const reductionPercentage = reduceBy / 100;

  if (!results.locations || !Object.values(results.locations).length) {
    return 0;
  }
  if (location === "All locations") {
    let sumForLocations = 0;
    Object.keys(results.locations).forEach((location) => {
      const { reduction } = calculatePaperSwitchForLocation(
        results,
        location,
        reductionPercentage,
        emissionFactors,
        log
      );
      sumForLocations += reduction;
    });
    return roundNumber(sumForLocations, 2);
  } else {
    const { reduction } = calculatePaperSwitchForLocation(
      results,
      location,
      reductionPercentage,
      emissionFactors,
      log
    );

    return roundNumber(reduction, 2);
  }
};

const switchPaperForLocation = (
  location,
  result,
  recommendation,
  year,
  emissionFactors,
  log,
  baseYear
) => {
  const {
    specifics: { amount: reduceBy },
    shareImplemented,
    yearImplemented,
  } = recommendation;
  if (!shareImplemented) return 0;

  if (!result.locations[location]) return;

  const reductionPercentage = calculateReductionPercentage(
    reduceBy,
    shareImplemented,
    year,
    yearImplemented,
    baseYear
  );

  const { increaseRec, decreaseNotRec } = calculatePaperSwitchForLocation(
    result,
    location,
    reductionPercentage,
    emissionFactors,
    log
  );

  result.locations[location][RESULT_KEYS.locations.paperNotRec] -=
    decreaseNotRec;

  result.locations[location][RESULT_KEYS.locations.paperRec] += increaseRec;
};

export const appySwitchPaper = (
  result,
  recommendation,
  year,
  emissionFactors,
  log,
  baseYear
) => {
  if (!result.locations || !Object.values(result.locations).length) {
    return;
  }

  const {
    specifics: { location },
  } = recommendation;

  if (location === "All locations") {
    Object.keys(result.locations).forEach((location) => {
      switchPaperForLocation(
        location,
        result,
        recommendation,
        year,
        emissionFactors,
        log,
        baseYear
      );
    });
  } else {
    switchPaperForLocation(
      location,
      result,
      recommendation,
      year,
      emissionFactors,
      log,
      baseYear
    );
  }
};
