import { useFormContext } from "react-hook-form";
import { checkIsFormTouched } from "src/utils/common-utils";
import { StepComplete, StepWrapper } from "..";

const FormStepWrapper = ({
  onNext,
  onPrev,
  hasUnsavedChanges,
  children,
  siteName,
  onSubmit,
  hideComplete,
  ...rest
}) => {
  const {
    nextClickHandler,
    prevClickHandler,
    formState: { touchedFields },
    backButtonLabel,
    onBackButtonClick,
    showBackButton,
    orgName,
    hideStepComplete,
    isSurvey,
    reportingPeriod,
    navigationSteps,
  } = useFormContext();

  const areFieldsTouched = checkIsFormTouched(touchedFields);

  return (
    <StepWrapper
      nextClickHandler={onNext || nextClickHandler}
      prevClickHandler={onPrev || prevClickHandler}
      {...{
        backButtonLabel,
        onBackButtonClick,
        showBackButton,
        orgName,
        isSurvey,
        siteName,
        reportingPeriod,
        navigationSteps
      }}
      hasUnsavedChanges={hasUnsavedChanges || areFieldsTouched}
      {...rest}
    >
      {children}
      {!hideStepComplete && !hideComplete && (
        <StepComplete
          buttonType={onNext ? "button" : "submit"}
          onComplete={onNext}
          onSaveAndLeave={onSubmit}
        />
      )}
    </StepWrapper>
  );
};

export default FormStepWrapper;
