import React from "react";
import { Progress, ProgressBar } from "src/components";
import { useSteps } from "react-step-builder";

const SectorProgress = () => {
  const { current, total } = useSteps();

  return (
    <Progress className="position-absolute">
      <ProgressBar value={(current / total) * 100} />
    </Progress>
  );
};

export default SectorProgress;
