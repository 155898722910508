import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ButtonBasic, FormStepWrapper } from "src/components";
import { DB_PATHS } from "src/constants";
import { SUPPLIER_STATUS } from "src/constants/supplierStatus";
import { useDatabasePaths, useUpdateDatabase } from "src/hooks";
import AboveCard from "../components/AboveCard";

const SupplierSurvey1 = ({ info, onNextClick, values, isDemo }) => {
  const { t } = useTranslation();

  const { updateDocument } = useUpdateDatabase();
  const { getPath } = useDatabasePaths();

  useEffect(() => {
    if (isDemo) return;
    if (values.status === "complete") return;

    updateDocument(
      getPath(
        [...DB_PATHS.suppliers, values["data-id"]],
        info.orgId,
        info.periodId
      ),
      {
        status: SUPPLIER_STATUS.started,
      }
    );
  }, []);

  return (
    <FormStepWrapper aboveCardSlot={<AboveCard {...{ info, isDemo }} />}>
      <Trans i18nKey="supplierSurvey.intro">
        <p className="text-start">
          Thank you for supporting us with this data collection.
        </p>

        <p>
          We have a strategic goal - and an imperative as a business - to assess
          our full value chain carbon footprint and then reduce it towards Net
          Zero as soon as we can.
        </p>

        <p className="text-start">
          As an important supplier to us, that means you have a role to play as
          well - and we appreciate you coming with us on this journey.
        </p>

        <p className="text-start">
          This survey collects data about your services or what you produce for
          us specifically.
        </p>

        <p className="text-start">
          This survey is about your organisation to understand your overall
          impact at a high level, and then apportion it to{" "}
          {{ name: info.orgName }} purchases from you. If you are a large
          company with multiple discrete units (e.g. {{ name: info.orgName }}{" "}
          uses your marketing agency, but not your wider organisation), please
          report for the subset of the organisation as far as possible. Where
          ever possible, please provide data for delivery of services or
          production of our {{ name: info.orgName }} materials and goods
          specifically.
        </p>

        <p className="text-start">
          Please could you answer this survey as far as possible in the next 2
          weeks?
        </p>

        <p className="text-start">Thank you in advance.</p>
      </Trans>
      <ButtonBasic onClick={onNextClick} className="px-5 py-2">
        {t("supplierSurvey.getStarted", "Get started")}
      </ButtonBasic>
    </FormStepWrapper>
  );
};

export default SupplierSurvey1;
