import {
  CButton,
  CCol,
  CForm,
  CFormGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CSwitch,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import { FaCookieBite } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectUserEmail } from "src/store/userSlice";
import { BasicInput, ButtonBasic } from "..";
import ErrorText from "../ErrorText/ErrorText";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import controller from "./controller/AccountSettingsModal";

import { useState } from "react";
import { useCookieConsent } from "src/hooks";
import Set2fa from "./Set2FA";

const AccountSettingsModal = () => {
  const {
    show,
    toggle,
    enterKeyPrevent,
    onChange,
    onSubmit,
    handleSubmit,
    errors,
    showChangePassword,
    setShowChangePassword,
    showChangeName,
    setShowChangeName,
    userData,
    loading,
    hasValueToSave,
  } = controller();

  const { t } = useTranslation();

  const { showCookieBanner } = useCookieConsent();

  const userEmail = useSelector(selectUserEmail);


  const [show2fa, setShow2fa] = useState(false);


  return (
    <CCol>
      <CModal size="lg" show={show} closeOnBackdrop={false} onClose={toggle} centered>
        <CForm onSubmit={handleSubmit(onSubmit)}>
          <CModalHeader>
            <CCol>
              <h3 className="mb-1">
                {t("settings.accountSettings", "Account settings")}
              </h3>
              <p
                style={{ fontSize: "18px", fontWeight: "300" }}
                className="mb-0 text-info"
              >
                {userEmail}
              </p>
              {!userData?.firstName && (
                <h5>
                  {t(
                    "settings.changeNamePassword",
                    "Change the name and password for the security"
                  )}
                </h5>
              )}
            </CCol>
          </CModalHeader>
          <CModalBody>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <CCol>
                <div className="d-flex flex-row justify-content-between align-items-center my-2">
                  <CLabel className="text-info">
                    {t("settings.changeName", "Change Name")}
                  </CLabel>
                  <CSwitch
                    onChange={() => setShowChangeName((prev) => !prev)}
                    color="success"
                    value="success"
                    shape="pill"
                    labelOn="ON"
                    labelOff="OFF"
                    checked={showChangeName}
                  />
                </div>
                {showChangeName && (
                  <>
                    <CFormGroup>
                      <CLabel>{t("settings.firstName", "First name")}</CLabel>
                      <BasicInput
                        type="text"
                        name="firstName"
                        placeholder={t(
                          "settings.firstNamePlaceholder",
                          "Please enter your first name..."
                        )}
                        onKeyPress={enterKeyPrevent}
                        onChange={onChange}
                        disabled={loading}
                        defaultValue={userData?.firstName}
                      />
                      <ErrorText value={errors.firstName} />
                    </CFormGroup>
                    <CFormGroup>
                      <CLabel>{t("settings.lastName", "Last name")}</CLabel>
                      <BasicInput
                        type="text"
                        name="lastName"
                        placeholder={t(
                          "settings.lastNamePlaceholder",
                          "Please enter your last name..."
                        )}
                        onKeyPress={enterKeyPrevent}
                        onChange={onChange}
                        disabled={loading}
                        defaultValue={userData?.lastName}
                      />
                      <ErrorText value={errors.lastName} />
                    </CFormGroup>
                  </>
                )}


                <div className="d-flex flex-row justify-content-between align-items-center my-2">
                  <CLabel className="text-info">
                    {t("settings.changePassword", "Change Password")}
                  </CLabel>
                  <CSwitch
                    onChange={() => setShowChangePassword((prev) => !prev)}
                    color="success"
                    value="success"
                    shape="pill"
                    labelOn="ON"
                    labelOff="OFF"
                    checked={showChangePassword}
                  />
                </div>
                {showChangePassword && (
                  <>
                    <CFormGroup>
                      <label>{t("settings.password", "Password")}</label>
                      <BasicInput
                        type="password"
                        autoComplete="new-password"
                        name="password"
                        placeholder={t(
                          "settings.passwordPlaceholder",
                          "Please enter your new password..."
                        )}
                        onKeyPress={enterKeyPrevent}
                        onChange={onChange}
                        disabled={loading}
                      />
                    </CFormGroup>
                    <ErrorText value={errors.password} />

                    <label>
                      {t("settings.confirmPassword", "Confirm Password")}
                    </label>
                    <BasicInput
                      type="password"
                      autoComplete="new-password"
                      name="confirm-password"
                      placeholder={t(
                        "settings.confirmPasswordPlaceholder",
                        "Please repeat your new password..."
                      )}
                      onKeyPress={enterKeyPrevent}
                      onChange={onChange}
                      disabled={loading}
                    />
                  </>
                )}

                <div className="d-flex flex-row justify-content-between align-items-center my-2">
                  <CLabel className="text-info">
                    Set up Two Factor Authentication (SMS)
                  </CLabel>
                  <CSwitch
                    onChange={() => setShow2fa((prev) => !prev)}
                    color="success"
                    value="success"
                    shape="pill"
                    labelOn="ON"
                    labelOff="OFF"
                    checked={show2fa}
                  />
                </div>

                {
                  show2fa && <Set2fa
                    onClose={() => setShow2fa(false)}
                  />
                }

                <div style={{
                  gap: "1rem"
                }} className=" d-flex justify-content-end mt-3 mb-1">
                  <CButton
                    as="a"
                    color="primary"
                    variant="outline"
                    href="https://www.zerobees.com/legal"
                  >
                    {t("header.terms")}
                  </CButton>
                  <CButton
                    color="info"
                    variant="outline"
                    href="https://www.zerobees.com/legal"
                  >
                    {t("header.privacyPolicy")}
                  </CButton>
                  <CButton
                    color="success"
                    variant="outline"
                    onClick={showCookieBanner}
                  >
                    <FaCookieBite
                      style={{ fontSize: "18px" }}
                      className=" mr-1"
                    />
                    {t("header.manageCookies")}
                  </CButton>
                </div>
              </CCol>
            )}
          </CModalBody>
          <CModalFooter>
            <ButtonBasic type="submit" disabled={loading || !hasValueToSave}>
              {t("settings.save", "SAVE")}
            </ButtonBasic>
            <ButtonBasic onClick={toggle} bgcolor="var(--secondary)">
              {t("settings.close", "CLOSE")}
            </ButtonBasic>
          </CModalFooter>
        </CForm>
      </CModal>
    </CCol>
  );
};

export default AccountSettingsModal;
