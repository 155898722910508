import styled from "styled-components";

const QuestionWrapper = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > p {
    font-size: 18px;
    /* font-weight: 300; */
  }

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 1rem;
    text-align: start;
  }

  .row {
    margin-left: 0;
  }
`;

export default QuestionWrapper;
