import { CButton } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { StyledAddMoreButton } from "./AddMoreButton.styled";
import { IoAddCircleOutline } from "react-icons/io5";

const AddMoreButton = ({ onClick, className, label, style }) => {
  const { t } = useTranslation();

  return (
    <StyledAddMoreButton className="pl-1 pb-1">
      <CButton
        color="primary"
        variant="outline"
        style={style}
        onClick={onClick}
        className={`d-flex align-self-start align-items-center ${className}`}
      >
        <IoAddCircleOutline className="mr-1" size="1.7rem" />
        <span>
          {label || t("shared.addMore", "Add more if needed")}
        </span>
      </CButton>
    </StyledAddMoreButton>
  );
};

export default AddMoreButton;
