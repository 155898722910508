import React from "react";
import { useFormContext } from "react-hook-form";

const OptionalQuestionNumber = ({ questionNumber }) => {
  const { isDemo, optionalQuestions } = useFormContext();

  const numberHiddenPrecedingQuestions = Object.entries(
    optionalQuestions
  ).filter((entry) => {
    const [name, isShown] = entry;

    const number = Number(name.replace("q", ""));
    return number < questionNumber && isShown === false;
  }).length;

  return (
    <span style={{fontSize:"22px"}}>
      {isDemo
        ? questionNumber
        : questionNumber - numberHiddenPrecedingQuestions}
    </span>
  );
};

export default OptionalQuestionNumber;
