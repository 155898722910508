import { kebabCase } from "lodash";
import { Trans, useTranslation } from "react-i18next";

const useEmissionFactorTabs = ({ data }) => {
  const { t } = useTranslation();

  const EMISSION_FACTORS_TABS = [
    {
      name: "Purchase categories",
      label: t("arrays.emissionFactors.1"),
      addedColumns: [
        {
          label: t("shared.group", "Group"),
          key: "path2",
          _style: { width: "15%" },
        },
        {
          label: t("shared.category"),
          key: "path1",
          _style: { width: "25%" },
        },
      ],
      canAddFactors: true,
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.ef"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Location",
      label: t("arrays.emissionFactors.0"),
      addedColumns: [
        {
          label: t("shared.group", "Group"),
          key: "path2",
          _style: { width: "15%" },
        },
        {
          label: t("shared.name"),
          key: "path1",
          _style: { width: "40%" },
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efKg"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Waste",
      label: t("arrays.emissionFactors.2"),
      addedColumns: [
        { label: t("shared.constant"), key: "path2", _style: { width: "25%" } },
        {
          label: t("shared.category"),
          key: "path1",
          _style: { width: "25%" },
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efKg"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Waste bins",
      label: t("arrays.emissionFactors.3"),
      addedColumns: [
        { label: t("shared.constant"), key: "path3", _style: { width: "25%" } },
        { label: t("shared.category"), key: "path2", _style: { width: "15%" } },
        {
          label: t("shared.size"),
          key: "path1",
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efWaste"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Fuels",
      label: "Fuels",
      addedColumns: [
        { label: t("shared.constant"), key: "path3", _style: { width: "30%" } },
        {
          label: "Fuel",
          key: "path2",
          _style: { width: "15%" },
        },
        {
          label: "Unit",
          key: "path1",
          _style: { width: "15%" },
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.ef"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Passenger vehicles",
      label: t("arrays.emissionFactors.4"),
      addedColumns: [
        { label: t("shared.scope"), key: "path4", _style: { width: "30%" } },
        { label: t("shared.type"), key: "path3", _style: { width: "15%" } },
        { label: t("shared.size"), key: "path2", _style: { width: "15%" } },
        { label: t("shared.fuel"), key: "path1", _style: { width: "15%" } },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efKg"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Delivery vehicles",
      label: t("arrays.emissionFactors.5"),
      addedColumns: [
        { label: t("shared.scope"), key: "path4", _style: { width: "30%" } },
        { label: t("shared.type"), key: "path3", _style: { width: "15%" } },
        { label: t("shared.size"), key: "path2", _style: { width: "15%" } },
        { label: t("shared.fuel"), key: "path1", _style: { width: "15%" } },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efKg"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Hotels",
      label: t("arrays.emissionFactors.6"),
      addedColumns: [
        { label: t("shared.country"), key: "path1", _style: { width: "35%" } },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.hotelsEf"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Website",
      label: "Website",
      addedColumns: [
        { label: "Factor", key: "path2", _style: { width: "35%" } },
        { label: "Subtype", key: "path1", _style: { width: "35%" } },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.hotelsEf"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Land travel",
      label: t("arrays.emissionFactors.7"),
      addedColumns: [
        {
          label: t("shared.modeOfTravel"),
          key: "path2",
          _style: { width: "15%" },
        },
        {
          label: t("shared.constant"),
          key: "path1",
          _style: { width: "30%" },
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efKg"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Air travel",
      label: t("arrays.emissionFactors.8"),
      addedColumns: [
        { label: t("shared.constant"), key: "path3", _style: { width: "30%" } },
        {
          label: t("travelData.flightType"),
          key: "path2",
          _style: { width: "15%" },
        },
        {
          label: t("travelData.flightClass"),
          key: "path1",
          _style: { width: "15%" },
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efKg"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Refrigerants",
      label: t("arrays.emissionFactors.9"),
      addedColumns: [
        {
          label: t("shared.substance"),
          key: "path1",
          _style: { width: "50%" },
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.refrigerantsEf"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Materials",
      label: "Materials",
      addedColumns: [
        {
          label: "Category",
          key: "path2",
          _style: { width: "15%" },
        },
        {
          label: "Material",
          key: "path1",
          _style: { width: "50%" },
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efKg"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Investments",
      label: t("arrays.emissionFactors.10"),
      addedColumns: [
        {
          label: t("shared.constant"),
          key: "path1",
          _style: { width: "50%" },
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efKg"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Pensions",
      label: t("arrays.emissionFactors.11"),
      addedColumns: [
        { label: t("shared.constant"), key: "path3", _style: { width: "15%" } },
        { label: "Provider", key: "path2", _style: { width: "15%" } },

        {
          label: "Fund",
          key: "path1",
          _style: { width: "30%" },
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efKg"
          components={{
            1: <strong className="ml-1 text-center text-info"></strong>,
          }}
        />
      ),
    },
    {
      name: "Banks",
      label: t("arrays.emissionFactors.12"),
      addedColumns: [
        {
          label: t("shared.constant"),
          key: "path2",
          _style: { width: "50%" },
        },
        {
          label: "Bank",
          key: "path1",
          _style: { width: "50%" },
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efKg"
          components={{ 1: <strong className="ml-1  text-info"></strong> }}
        />
      ),
    },
    {
      name: "Dentistry",
      label: "Dentistry",
      addedColumns: [
        {
          label: "Material",
          key: "path2",
          _style: { width: "50%" },
        },
        {
          label: "Factor",
          key: "path1",
          _style: { width: "50%" },
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efKg"
          components={{ 1: <strong className="ml-1  text-info"></strong> }}
        />
      ),
    },
    {
      name: "Construction",
      label: "Construction",
      addedColumns: [
        {
          label: "Material",
          key: "path2",
          _style: { width: "50%" },
        },
        {
          label: "Subcategory",
          key: "path1",
          _style: { width: "50%" },
        },
      ],
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.efKg"
          components={{ 1: <strong className="ml-1  text-info"></strong> }}
        />
      ),
    },
    {
      name: "Sectors",
      label: "Sectors",
      addedColumns: [
        {
          label: "Sector",
          key: "path1",
          _style: { width: "50%" },
        },
      ],
    },
    {
      name: "Averages",
      label: "Averages",
      addedColumns: [
        {
          label: "Sector",
          key: "path1",
          _style: { width: "50%" },
        },
      ]
    },
    {
      name: "Products",
      label: "Products",
      addedColumns: [
        {
          label: "Product",
          key: "path1",
          _style: { width: "50%" },
        },
      ]
    },
    {
      name: "Regions",
      label: "Regions",
      addedColumns: [
        {
          label: "Region",
          key: "path2",
          _style: { width: "20%" },
        },
        {
          label: "Product",
          key: "path1",
          _style: { width: "50%" },
        }
      ]
    },
    {
      name: "Benchmarks",
      aboveTableSlot: (
        <Trans
          i18nKey="adminCategories.ef"
          components={{ 1: <strong className="ml-1  text-info"></strong> }}
        />
      ),
      addedColumns: [
        {
          label: "Sector",
          key: "path2",
          _style: { width: "50%" },
        },
        {
          label: "Company",
          key: "path1",
          _style: { width: "50%" },
        }]
    }
  ];

  const emissionFactorTabs = EMISSION_FACTORS_TABS.map((tab) => {
    return {
      ...tab,
      dbName: kebabCase(tab.name),
      data: data?.[kebabCase(tab.name)] || {},
    };
  });

  return { emissionFactorTabs };
};

export default useEmissionFactorTabs;
