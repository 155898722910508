import { useDispatch, useSelector } from "react-redux";
import { setAlertWithTimeout } from "src/store/operations";

import { useTranslation } from "react-i18next";
import { DB_PATHS } from "src/constants";
import { selectSites } from "src/store/projectSelectors";
import { removeUndefinedFromObjectValues } from "src/utils/common-utils";
import { useUpdateDatabase, useWriteTasks } from "src/hooks";

const useSites = () => {
  const sites = useSelector(selectSites);

  const dispatch = useDispatch();

  const {
    addDocumentToCollection,
    updateDocument,
    deleteDocument,
    deleteField,
  } = useUpdateDatabase();

  const tryAndNotify = async (operation, msgSuccess, msgFailure) => {
    try {
      await operation();
      dispatch(
        setAlertWithTimeout({
          type: "success",
          text: msgSuccess,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        setAlertWithTimeout({
          type: "error",
          text: msgFailure,
        })
      );
    }
  };

  const { t } = useTranslation();

  const addSiteToDB = async (name) => {
    tryAndNotify(
      async () => {
        await addDocumentToCollection(DB_PATHS.sites, {
          name,
        });
      },
      t("siteData.siteAdded", "Location {{name}} added to database!", { name }),
      t(
        "siteData.siteAddFail",
        "Failed to add location {{name}} to database!",
        {
          name,
        }
      )
    );
  };

  const updateSiteNameInDB = async (siteId, newSite) => {
    const updated = removeUndefinedFromObjectValues(newSite);
    tryAndNotify(
      async () => await updateDocument([...DB_PATHS.sites, siteId], updated),
      t("siteData.siteUpdated", "Site {{name}} updated!", {
        name: newSite.name,
      }),
      t("siteData.siteUpdateFail", "Failed to update site {{name}}!", {
        name: newSite.name,
      })
    );
  };

  const { deleteTask } = useWriteTasks();

  const deleteSiteFromTasks = async (siteId) => {
    await deleteTask(`site-data.${siteId}`);
  };
  const deleteSiteFromOverrides = async (siteId) => {
    await deleteField(
      DB_PATHS.periodSettings,
      "overrides",
      `locations.${siteId}`
    );
  };

  const deleteSiteFromDB = async (siteId, siteName) => {
    await deleteSiteFromTasks(siteId);
    await deleteSiteFromOverrides(siteId);
    tryAndNotify(
      async () => await deleteDocument([...DB_PATHS.sites, siteId]),
      t("siteData.siteDeleted", "Site {{name}} deleted!", { name: siteName }),
      t("siteData.siteDeleteFail", "Failed to delete site {{name}}!", {
        name: siteName,
      })
    );
  };

  return {
    sites,
    addSiteToDB,
    updateSiteNameInDB,
    deleteSiteFromDB,
    deleteSiteFromTasks,
    deleteSiteFromOverrides,
  };
};

export default useSites;
