import { CButton, CPopover } from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const StartTutorialButton = ({ tutorialClickHandler, ...rest }) => {
  const { t } = useTranslation();
  return (
    <CPopover content={t("welcomeModal.watchTutorial")}>
      <CButton
        // className="mr-3"
        color="info"
        onClick={tutorialClickHandler}
        {...rest}
      >
        {t("welcomeModal.showTutorial")}
      </CButton>
    </CPopover>
  );
};

export default StartTutorialButton;
