import { useSelector } from "react-redux";
import { DB_PATHS } from "src/constants";
import { selectUserEmail, selectUserId } from "src/store/userSlice";
import { useUpdateDatabase } from "src/hooks";

const useSaveToLog = () => {
  const userId = useSelector(selectUserId);
  const userEmail = useSelector(selectUserEmail);

  const { setOrUpdateDocument } = useUpdateDatabase();

  const saveToLog = async ({ change, sector }) => {
    const timestamp = Math.round(Date.now() / 1e3);

    const logEntry = {
      uid: userId,
      email: userEmail,
      change,
      timestamp,
      sector,
    };

    await setOrUpdateDocument([...DB_PATHS.logs, timestamp], logEntry);
  };

  return { saveToLog };
};

export default useSaveToLog;
