import React from "react";
import ButtonGhost from "src/components/ButtonGhost/ButtonGhost";
import CIcon from "@coreui/icons-react";
import { t } from "i18next";

const BackButton = ({
  history,
  onClick = null,
  label = t("shared.summaryPage"),
}) => {
  return (
    <ButtonGhost
      className="align-self-start d-flex align-items-center summary-btn coreData-intro-back-button"
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          history.push("/dashboard");
        }
      }}
    >
      <CIcon name="cil-arrow-left" />
      <span className="ml-2" style={{ fontSize: "14px" }}>
        {label}
      </span>
    </ButtonGhost>
  );
};

export default BackButton;
