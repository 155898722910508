import { CSpinner } from "@coreui/react";
import React from "react";
import StyledDiv from "./LoadingSpinner.style";

const LoadingSpinner = ({ size = "4rem", asOverlay, label, className, style =
  {} }) => {
  return (
    <StyledDiv
      className={`${asOverlay && "overlay"
        } ${className} d-flex flex-column justify-content-center align-items-center w-100 h-100`}
    >
      {asOverlay ? <h1 className="text-center">{label}</h1> : <h3 className="text-primary">{label}</h3>}
      <CSpinner style={{ width: size, height: size, ...style }} />
    </StyledDiv>
  );
};

export default LoadingSpinner;
