import { CModal } from "@coreui/react";
import styled from "styled-components";

export const StyledModal = styled(CModal)`
  .modal-dialog {
    min-width: 800px;
  }
`;

export const StyledTable = styled.table`
  p,
  span {
    margin-bottom: 0.7rem;
  }
`;
