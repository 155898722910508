export const MATERIAL_SUB_CATEGORIES = {
    "aggregates-and-sand": [
        { label: "general UK, mixture of land won, marine, secondary and recycled, bulk, loose", value: "general-uk-mixture" },
        { label: "general, virgin mixture of land won and marine, bulk, loose", value: "general-virgin-mixture" },
        { label: "from virgin land won resources, bulk, loose", value: "virgin-land" },
        { label: "from virgin marine resources, bulk, loose", value: "virgin-marine" },
        { label: "from recycled resources, no heat treatment, bulk, loose", value: "recycled-resources-no-heat-treatment-bulk-loose" },
        { label: "from recycled resources, with heat treatment, bulk, loose", value: "recycled-resources-with-heat-treatment-bulk-loose" },
        { label: "expanded clay, bulk, loose", value: "expanded-clay-bulk-loose" },
        { label: "expanded foamed glass, bulk, loose", value: "expanded-foamed-glass-bulk-loose" },
        { label: "from secondary resources, bulk, loose", value: "from-secondary-resources-bulk-loose" },
        { label: "mixture of recycled and secondary resources, bulk, loose", value: "mixture-of-recycled-and-secondary-resources-bulk-loose" },
    ],
    "aluminium": [
        { label: "General, European Mix, Inc Imports", value: "general-european-imports" },
        { label: "Sheet, European Mix, Inc Imports", value: "sheet-european" },
        { label: "Foil, European Mix, Inc Imports", value: "foil-european" },
        { label: "Extruded profile, European Mix, Inc Imports", value: "extrusion-european" },
        { label: "Cast, European Mix, Inc Imports", value: "cast-european" },
        { label: "General, Worldwide", value: "general-worldwide" },
        { label: "Sheet, Worldwide", value: "sheet-worldwide" },
        { label: "Foil, Worldwide", value: "foil-worldwide" },
        { label: "Extruded profile, Worldwide", value: "extrusion-worldwide" },
        { label: "Cast, Worldwide", value: "cast-worldwide" },
        { label: "General, Europe, Excl Imports", value: "general-europe-no-imports" },
        { label: "General, North American", value: "general-north-america" },
        { label: "General, Africa", value: "general-africa" },
        { label: "General, China", value: "general-china" },
        { label: "General, Japan", value: "general-japan" },
        { label: "General, Middle East", value: "general-middle-east" },
        { label: "General, South America", value: "general-south-america" },
        { label: "General, Oceania", value: "general-oceania" },
        { label: "General, Other Asia", value: "general-other-asia" },
        { label: "General, Russia", value: "general-russia" },
        { label: "General, South Korea", value: "general-south-korea" },
    ],
    "asphalt": [
        { label: "3% (bitumen) binder content (by mass)", value: "asphalt-3", unit: "tonne" },
        { label: "3.5% binder content", value: "asphalt-3,5", unit: "tonne" },
        { label: "4% binder content", value: "asphalt-4", unit: "tonne" },
        { label: "4.5% binder content", value: "asphalt-4,5", unit: "tonne" },
        { label: "5% binder content", value: "asphalt-5", unit: "tonne" },
        { label: "5.5% binder content", value: "asphalt-5,5", unit: "tonne" },
        { label: "6% binder content", value: "asphalt-6", unit: "tonne" },
        { label: "6.5% binder content", value: "asphalt-6,5", unit: "tonne" },
        { label: "7% binder content", value: "asphalt-7", unit: "tonne" },
    ],
    "bitumen": [
        { label: "Straight-run bitumen", value: "straight-run" },
        { label: "Polymer modified bitumen (PMB)", value: "polymer-modified" },
        { label: "Bitumen emulsion", value: "emulsion" },
    ],

    "brass": [
        { label: "General", value: "general" },
        { label: "Virgin", value: "virgin" },
        { label: "Recycled", value: "recycled" },
    ],
    "brick": [
        { label: "General (Common Brick)", value: "general", },
        { label: "A Single Brick", value: "single", unit: "unit" },
    ],
    "bronze": [
        { label: "General", value: "general" },
    ],
    "carpet": [
        { label: "General Carpet", value: "general", unit: "m2" },
        { label: "Felt (Hair and Jute) Underlay", value: "felt", },
        { label: "Nylon (Polyamide), pile weight 300 g/m2", value: "nylon-300", unit: "m2" },
        { label: "Nylon (Polyamide), pile weight 500 g/m2", value: "nylon-500", unit: "m2" },
        { label: "Nylon (Polyamide), pile weight 700 g/m2", value: "nylon-700", unit: "m2" },
        { label: "Nylon (Polyamide), pile weight 900 g/m2", value: "nylon-900", unit: "m2" },
        { label: "Nylon (Polyamide), pile weight 1100 g/m2", value: "nylon-1100", unit: "m2" },
        { label: "Carpet tiles, nylon (Polyamide), pile weight 300 g/m2", value: "nylon-tiles-300", unit: "m2" },
        { label: "Carpet tiles, nylon (Polyamide), pile weight 500 g/m2", value: "nylon-tiles-500", unit: "m2" },
        { label: "Carpet tiles, nylon (Polyamide), pile weight 700 g/m2", value: "nylon-tiles-700", unit: "m2" },
        { label: "Carpet tiles, nylon (Polyamide), pile weight 900 g/m2", value: "nylon-tiles-900", unit: "m2" },
        { label: "Carpet tiles, nylon (Polyamide), pile weight 1100 g/m2", value: "nylon-tiles-1100", unit: "m2" },
    ],
    "cement": [
        { label: "General (UK average)", value: "general" },
        { label: "Average CEM I, Ordinary Portland Cement (OPC)", value: "cem-i" },
    ],
    "mortar": [
        { label: "Mortar (1:3 cement:sand mix)", value: "mortar-1-3" },
        { label: "Mortar (1:4)", value: "mortar-1-4" },
        { label: "Mortar (1:5)", value: "mortar-1-5" }, { label: "Mortar (1:6)", value: "mortar-1-6" },
        { label: "Mortar (1:0.5:4.5 Cement:Lime:Sand mix)", value: "mortar-1-0,5-4,5" },
        { label: "Mortar (1:1:6 Cement:Lime:Sand mix)", value: "mortar-1-1-6" },
        { label: "Mortar (1:2:9 Cement:Lime:Sand mix)", value: "mortar-1-2-9" },
    ],
    "admixtures": [
        { label: "General concrete admixtures – Average of data collected", value: "general" },
        { label: "Concrete admixtures – Air entrainers, Europe", value: "air-entrainers" },
        { label: "Concrete admixtures – Hardening Accelerators", value: "hardening-accelerators" },
        { label: "Concrete admixtures – Plasticisers and Superplasticisers", value: "plasticisers" },
        { label: "Concrete admixtures – Retarders", value: "retarders" },
        { label: "Concrete admixtures – Set Accelerators", value: "set-accelerators" },
        { label: "Concrete admixtures – Water Resisting Admixtures", value: "water-resisting" }
    ],
    "ceramics": [
        { label: "General", value: "general" },
        { label: "Fittings", value: "fittings" },
        { label: "Sanitary Products", value: "sanitary" },
        { label: "Tiles and Cladding Panels", value: "tiles" },
    ],
    "clay": [
        { label: "General (Simple Baked Products)", value: "general" },
        { label: "Tile", value: "tile" },
        { label: "Vitrified clay pipe DN 100 & DN 150", value: "vitrified-100-150" },
        { label: "Vitrified clay pipe DN 200 & DN 300", value: "vitrified-200-300" },
        { label: "Vitrified clay pipe DN 500", value: "vitrified-500" },
    ],
    "concrete": [
        { label: "General", value: "general" },
        { label: "GEN 0 (6/8 MPa)", value: "gen-0" },
        { label: "GEN 1 (8/10 MPa)", value: "gen-1" },
        { label: "GEN 2 (12/15 MPa)", value: "gen-2" },
        { label: "GEN 3 (16/20 MPa)", value: "gen-3" },
        { label: "20/25 MPa", value: "20-25" },
        { label: "25/30 MPa", value: "25-30" },
        { label: "28/35 MPa", value: "28-35" },
        { label: "32/40 MPa", value: "32-40" },
        { label: "35/45 MPa", value: "35-45" },
        { label: "40/50 Mpa", value: "40-50" },
        { label: "PAV1", value: "pav1" },
        { label: "PAV2", value: "pav2" },
    ],
    "concrete-blocks": [
        { label: "Medium density 75 mm thickness", value: "medium-75", unit: "unit" },
        { label: "Medium density 90 mm thickness", value: "medium-90", unit: "unit" },
        { label: "Medium density 100 mm thickness", value: "medium-100", unit: "unit" },
        { label: "Medium density 140 mm thickness", value: "medium-140", unit: "unit" },
        { label: "Medium density 190 mm thickness", value: "medium-190", unit: "unit" },
        { label: "Solid high density blocks 75 mm thickness", value: "solid-high-75", unit: "unit" },
        { label: "Solid high density blocks 90 mm thickness", value: "solid-high-90", unit: "unit" },
        { label: "Solid high density blocks 100 mm thickness", value: "solid-high-100", unit: "unit" },
        { label: "Solid high density blocks 140 mm thickness", value: "solid-high-140", unit: "unit" },
        { label: "Solid high density blocks 190 mm thickness", value: "solid-high-190", unit: "unit" },
        { label: "Solid high density blocks 215 mm thickness", value: "solid-high-215", unit: "unit" },
        { label: "High density concrete blocks - Cellular 100 mm thickness", value: "high-cellular-100", unit: "unit" },
        { label: "High density concrete blocks - Cellular 140 mm thickness", value: "high-cellular-140", unit: "unit" },
        { label: "High density concrete blocks - Hollowcore 140 mm thickness", value: "high-hollowcore-140", unit: "unit" },
        { label: "High density concrete blocks - Hollowcore 190 mm thickness", value: "high-hollowcore-190", unit: "unit" },
        { label: "High density concrete blocks - Hollowcore 215 mm thickness", value: "high-hollowcore-215", unit: "unit" },
        { label: "Lightweight concrete blocks - AAC - Autoclaved Aerated Concrete(AAC) 100 mm thickness", value: "lightweight-aac-100", unit: "unit" },
        { label: "Lightweight concrete blocks - AAC - Autoclaved Aerated Concrete(AAC) 140 mm thickness", value: "lightweight-aac-140", unit: "unit" },
        { label: "Lightweight concrete blocks - AAC - Autoclaved Aerated Concrete(AAC) 190 mm thickness", value: "lightweight-aac-190", unit: "unit" },
        { label: "Lightweight concrete blocks - AAC - Autoclaved Aerated Concrete(AAC) 215 mm thickness", value: "lightweight-aac-215", unit: "unit" },
    ],
    "copper": [
        { label: "EU Tube & Sheet", value: "eu-tube-sheet" },
        { label: "Virgin", value: "virgin" },
        { label: "Recycled", value: "recycled" },
        { label: "Recycled from high grade scrap", value: "recycled-high-grade" },
        { label: "Recycled from low grade scrap", value: "recycled-low-grade" },
    ],
    "glass": [
        { label: "General", value: "general" },
        { label: "Glazing, Double", value: "glazing-double" },
        { label: "Glazing triple", value: "glazing-triple" },
        { label: "Toughened", value: "toughened" },
        { label: "Multi layer safety, filled core, fire resistant, toughened", value: "multi-layer-safety-filled-core-fire-resistant-toughened" },
        { label: "Multi layer safety, unfilled", value: "multi-layer-safety-unfilled" },
        { label: "sky light or roof, with frame", value: "sky-light-or-roof-with-frame" },
    ],
    "insulation": [
        { label: "General Insulation", value: "general" },
        { label: "Cork", value: "cork" },
        { label: "Fibreglass (Glasswool)", value: "fibreglass" },
        { label: "Flax (Insulation)", value: "flax" },
        { label: "Mineral wool", value: "mineral-wool" },
        { label: "Paper wool", value: "paper-wool" },
        { label: "Rockwool", value: "rockwool" },
        { label: "Woodwool (Board)", value: "woodwool" },
    ],
    "iron": [
        { label: "General", value: "general" },
    ],
    "lead": [
        { label: "General", value: "general" },
        { label: "Virgin", value: "virgin" },
        { label: "Recycled", value: "recycled" },
    ],
    "lime": [
        { label: "General", value: "general" },
    ],
    "linolium": [
        { label: "General", value: "general" },
    ],
    "calcium-silicate-sheet": [
        { label: "General", value: "general" },
    ],
    "chromium": [
        { label: "General", value: "general" },
    ],
    "cotton-padding": [
        { label: "General", value: "general" },
    ],
    "cotton-fabric": [
        { label: "General", value: "general" },
    ],
    "damp-proof-course-membrane": [
        { label: "General", value: "general" },
    ],
    "flax": [
        { label: "General", value: "general" },
    ],
    "fly-ash": [
        { label: "General", value: "general" },
    ],
    "grit": [
        { label: "General", value: "general" },
    ],
    "ground-limestone": [
        { label: "General", value: "general" },
    ],
    "glass-reinforced-plastic": [
        { label: "General", value: "general" },
    ],
    "lithium": [
        { label: "General", value: "general" },
    ],
    "mandolite": [
        { label: "General", value: "general" },
    ],
    "mineral-fibre-tile": [
        { label: "General", value: "general" },
    ],
    "manganese": [
        { label: "General", value: "general" },
    ],
    "mercury": [
        { label: "General", value: "general" },
    ],
    "molybedenum": [
        { label: "General", value: "general" },
    ],
    "nickel": [
        { label: "General", value: "general" },
    ],
    "perlite-expanded": [
        { label: "General", value: "general" },
    ],
    "perlite-natural": [
        { label: "General", value: "general" },
    ],
    "quartz-powder": [
        { label: "General", value: "general" },
    ],
    "shingle": [
        { label: "General", value: "general" },
    ],
    "slag-ggbs": [
        { label: "General", value: "general" },
    ],
    "silver": [
        { label: "General", value: "general" },
    ],
    "straw": [
        { label: "General", value: "general" },
    ],
    "terrazzo-tiles": [
        { label: "General", value: "general" },
    ],
    "vanadium": [
        { label: "General", value: "general" },
    ],
    "vermiculite-expanded": [
        { label: "General", value: "general" },
    ],
    "vermiculite-natural": [
        { label: "General", value: "general" },
    ],
    "water": [
        { label: "General", value: "general" },
    ],
    "wood-stain-varnish": [
        { label: "General", value: "general" },
    ],
    "yttrium": [
        { label: "General", value: "general" },
    ],
    "zirconium": [
        { label: "General", value: "general" },
    ],
    "paint": [
        { label: "General", value: "general" },
    ],
    "plaster": [
        { label: "General(gypsum)", value: "general" },
        { label: "Plasterboard", value: "plasterboard" },
    ],
    "plastics": [
        { label: "General", value: "general" },
        { label: "ABS", value: "abs" },
        { label: "General Polyethylene", value: "general-polyethylene" },
        { label: "High Density Polyethylene (HDPE) Resin", value: "high-density-polyethylene-hdpe-resin" },
        { label: "HDPE Pipe", value: "hdpe-pipe" },
        { label: "Low Density Polyethylene (LDPE) Resin", value: "low-density-polyethylene-ldpe-resin" },
        { label: "LDPE Film", value: "ldpe-film" },
        { label: "Nylon (Polyamide) 6 Polymer", value: "nylon-polyamide-6-polymer" },
        { label: "Nylon (polyamide) 6,6 Polymer", value: "nylon-polyamide-66-polymer" },
        { label: "Polycarbonate", value: "polycarbonate" },
        { label: "Polypropylene, Orientated Film", value: "polypropylene-orientated-film" },
        { label: "Polypropylene, Injection Moulding", value: "polypropylene-injection-moulding" },
        { label: "Expanded Polystyrene", value: "expanded-polystyrene" },
        { label: "General Purpose Polystyrene", value: "general-purpose-polystyrene" },
        { label: "High Impact Polystyrene", value: "high-impact-polystyrene" },
        { label: "Thermoformed Expanded Polystyrene", value: "thermoformed-expanded-polystyrene" },
        { label: "Polyurethane Flexible Foam", value: "polyurethane-flexible-foam" },
        { label: "Polyurethane Rigid Foam", value: "polyurethane-rigid-foam" },
        { label: "PVC General", value: "pvc-general" },
        { label: "PVC Pipe", value: "pvc-pipe" },
        { label: "Calendered Sheet PVC", value: "calendered-sheet-pvc" },
        { label: "PVC Injection Moulding", value: "pvc-injection-moulding" },
        { label: "UPVC Film", value: "upvc-film" }
    ],
    "rubber": [
        { label: "General", value: "general" },
    ],
    "sealents-and-adhesives": [
        { label: "Epoxide Resin", value: "epoxide-resin" },
        { label: "Melamine Resin", value: "melamine-resin" },
        { label: "Phenol Formaldehyde", value: "phenol-formaldehyde" },
        { label: "Urea Formaldehyde", value: "urea-formaldehyde" },
    ],
    "soil": [
        { label: "General (Rammed Soil)", value: "general" },
        { label: "Cement stabilised soil @ 5%", value: "cement-stabilised-soil-5" },
        { label: "Cement stabilised soil @ 8%", value: "cement-stabilised-soil-8" },
        { label: "GGBS stabilised soil", value: "ggbs-stabilised-soil" },
        { label: "Fly ash stabilised soil", value: "fly-ash-stabilised-soil" },
    ],
    "steel": [
        { label: "UO Pipe", value: "steel-uo-pipe" },
        { label: "Tin-free Electrolytic Chrome Coated Steel Sheet - Tin-free (ECCS)", value: "steel-tin-free-electrolytic-chrome-coated-steel-sheet-tin-free-eccs" },
        { label: "electrogalvanized steel", value: "steel-electrogalvanized-steel" },
        { label: "welded pipe", value: "steel-welded-pipe" },
        { label: "Organic coated sheet", value: "steel-organic-coated-sheet" },
        { label: "Tinplate", value: "steel-tinplate" },
        { label: "finished cold-rolled coil", value: "steel-finished-cold-rolled-coil" },
        { label: "hot-dip galvanized steel", value: "steel-hot-dip-galvanized-steel" },
        { label: "Plate", value: "steel-plate" },
        { label: "Cold Rolled Coil", value: "steel-cold-rolled-coil" },
        { label: "pickled hot-rolled coil", value: "steel-pickled-hot-rolled-coil" },
        { label: "Wire rod", value: "steel-wire-rod" },
        { label: "Hot Rolled Coil", value: "steel-hot-rolled-coil" },
        { label: "Rebar", value: "steel-rebar" },
        { label: "Section", value: "steel-section" },
        { label: "Engineering steel", value: "steel-engineering-steel" },
        { label: "global seamless tube", value: "steel-global-seamless-tube" },
    ],
    "stone": [
        { label: "General", value: "general" },
        { label: "Granite", value: "granite" },
        { label: "Limestone", value: "limestone" },
        { label: "Marble", value: "marble" },
        { label: "Marble tile", value: "marble-tile" },
        { label: "Sandstone", value: "sandstone" },
        { label: "Shale", value: "shale" },
        { label: "Slate", value: "slate" },
    ],
    "timber": [
        { label: "Average", value: "timber-average" },
        { label: "Chipboard", value: "timber-chipboard" },
        { label: "Closed panel timber frame system", value: "timber-closed-panel-timber-frame-system" },
        { label: "CLT", value: "timber-clt" },
        { label: "Fibreboard", value: "timber-fibreboard" },
        { label: "Glulam", value: "timber-glulam" },
        { label: "Hardboard", value: "timber-hardboard" },
        { label: "Hardwood", value: "timber-hardwood" },
        { label: "Laminate", value: "timber-laminate" },
        { label: "Laminated strand lumber", value: "timber-laminated-strand-lumber" },
        { label: "Laminated veneer lumber", value: "timber-laminated-veneer-lumber" },
        { label: "MDF", value: "timber-mdf" },
        { label: "Open panel timber frame system", value: "timber-open-panel-timber-frame-system" },
        { label: "OSB", value: "timber-osb" },
        { label: "Parquet", value: "timber-parquet" },
        { label: "Particle Board", value: "timber-particle-board" },
        { label: "Plywood", value: "timber-plywood" },
        { label: "Softwood", value: "timber-softwood" },
        { label: "Timber, Wood I-Beam", value: "timber-timber-wood-i-beam" },
        { label: "Timber, Wood-plastic composite", value: "timber-timber-wood-plastic-composite" },
    ],
    "tin": [
        { label: "Tin Coated Plate (Steel)", value: "tin-tin-coated-plate-steel" },
        { label: "Tin", value: "tin-tin" },
    ],
    "titanium": [
        { label: "Virgin", value: "titanium-virgin" },
        { label: "Recycled", value: "titanium-recycled" },
    ],
    "tyres": [
        { label: "New", value: "tyres-new" },
    ],
    "vinyl-flooring": [
        { label: "General", value: "general" },
    ],
    "zinc": [
        { label: "General", value: "general" },
        { label: "Virgin", value: "virgin" },
        { label: "Recycled", value: "recycled" },
    ],
}

