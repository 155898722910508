import React from "react";
import ButtonWhite from "src/components/ButtonWhite/ButtonWhite";
import { useSteps } from "react-step-builder";
import styled from "styled-components";
import { CCol, CTooltip } from "@coreui/react";
import { ImArrowRight, ImArrowLeft } from "react-icons/im";
import { useTranslation } from "react-i18next";

const NavButtons = ({
  nextClickHandler,
  prevClickHandler,
  nextIsDisabled = false,
  prevIsDisabled = false,
  tooltipsVisible,
}) => {
  const { next, prev, current, total, isFirst, isLast } = useSteps();

  const { t } = useTranslation();

  return (
    <StyledDiv
      style={{ marginBottom: "4rem" }}
      className="d-flex justify-content-center align-items-center  mt-4  coreData-intro-nav-button"
    >
      {!isFirst && (
        <CTooltip visible={tooltipsVisible} content={t("shared.goBack")}>
          <ButtonWhite
            type="button"
            className="nav-button"
            aria-label="previous step"
            onClick={(e) => {
              if (prevClickHandler) {
                prevClickHandler();
              } else {
                prev(e);
              }
            }}
            disabled={isFirst || prevIsDisabled}
          >
            <ImArrowLeft />
          </ButtonWhite>
        </CTooltip>
      )}
      <div className="mx-4">
        <strong>
          {t("shared.step", "Step")} {current}/{total}
        </strong>
      </div>
      {!isLast && (
        <CTooltip
          visible={tooltipsVisible}
          content={t("shared.saveAndGo", "Save and go forward")}
        >
          <ButtonWhite
            type="button"
            className="nav-button"
            aria-label="next step"
            onClick={(e) => {
              if (nextClickHandler) {
                nextClickHandler();
              } else {
                next(e);
              }
            }}
            disabled={isLast || nextIsDisabled}
          >
            <ImArrowRight />
          </ButtonWhite>
        </CTooltip>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  .nav-button {
    button:first-of-type {
      border-start-end-radius: 0;
      border-end-end-radius: 0;
    }

    button:last-of-type {
      border-start-start-radius: 0;
      border-end-start-radius: 0;
    }
  }

  .nav-button {
    svg {
      color: #000000;
    }
  }
`;

export default NavButtons;
