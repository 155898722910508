import StyledStepCard from "./StepCard.style";

const StepCard = ({ children, padding, className = "", ...rest }) => {
  return (
    <StyledStepCard
      padding={padding}
      className={`${className} section-intro-card position-relative`}
      {...rest}
    >
      {children}
    </StyledStepCard>
  );
};

export default StepCard;
