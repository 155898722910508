import styled from "styled-components";

const StyledButton = styled.button`
  :hover {
    text-decoration: underline  !important;
  }
  transition: all 0.4s ease-in-out;
`;

const TextButton = ({ children, onClick, className, style = {}, ...rest }) => {
  return (
    <StyledButton
      className={`btn btn-link p-0 text-decoration-none   ${className}`}
      onClick={onClick}
      style={{ cursor: "pointer", ...style }}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default TextButton;
