import { CFormGroup, CInputGroup } from "@coreui/react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import {
  BasicNumberInput,
  ConditionalInput,
  DemoWarning,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  RadioButtons,
} from "src/components";
import DatePicker from "src/components/DatePicker/DatePicker";
import { InfoTooltip } from "../components";
import controller from "./SurveyController1";

const EmployeeSurvey1 = ({ data: values = {}, onChange, info, isDemo }) => {
  const { stillWorking, stillWorkingHandler, errors } = controller({
    onChange,
    values,
  });

  const { t } = useTranslation();

  return (
    <FormStepWrapper
      aboveCardSlot={
        isDemo ? (
          <>
            <DemoWarning />
            <p className="text-danger w-75 mx-auto text-center">
              {t("employeeSurvey.surveyDemo")}
            </p>
          </>
        ) : null
      }
    >
      <h1 className="text-center">{info.orgName}</h1>
      <h2 className="mb-3 text-center">
        {t("employeeSurvey.title", "Employee carbon survey")}
      </h2>
      <p className="mb-5 text-center">
        {t(
          "employeeSurvey.intro",
          "Thank you for taking the time to complete this short survey about your commute, working from home and a few other areas which may create work related emissions. This is extremely valuable input to our assessment and emission reduction plan."
        )}
      </p>

      <QuestionWrapper>
        <>
          <h3 className="mb-3">
            1a.{" "}
            {t(
              "employeeSurvey.q_startWorking",
              "When did you start working for the {{name}}?",
              { name: info.orgName }
            )}
          </h3>
          <CFormGroup className="mb-4">
            <DatePicker
              className="w-100"
              isClearable
              name="start-date"
              value={values["start-date"]}
              onChange={onChange}
            />
            <ErrorText value={errors["start-date"]} />
          </CFormGroup>
        </>
        {/* {(stillWorking === false || !!values["end-date"]) && ( */}
        <>
          <h3  className="mb-3 ">
              1b.
              {t(
                "employeeSurvey.q_endWorking",
                " If you are not currently actively working at the organisation, please give the last day that you worked."
              )}
            <InfoTooltip
              content={t(
                "employeeSurvey.endWorkingHint",
                "You may be on leave of absence, parental leave or your contract has finished for example. Please skip this question if not relevant."
              )}
            />
          </h3>
          <CFormGroup className="mb-4">
            <DatePicker
              className="w-100"
              isClearable
              name="end-date"
              value={values["end-date"]}
              onChange={onChange}
            />
            <ErrorText value={errors["end-date"]} />
          </CFormGroup>
        </>
        {/* )} */}
        <ConditionalInput
          defaultValue={values["had-leave"]}
          name="had-leave"
          onChange={onChange}
          errors={errors}
          questionSlot={
            <h3 className="mb-3">
              1c.{" "}
              {t(
                "employeeSurvey.q_leave",
                "Thinking about the whole of {{period}}, while you have been employed or contracted with the organisation, did you have any periods of leave other than annual leave (e.g. parental leave, leave of absence, sabatical)?",
                { period: info.reportingPeriod }
              )}
            </h3>
          }
          inputSlot={
            <>
              <p className="mb-3">
                {t(
                  "employeeSurvey.q_weeksLeave",
                  "How many weeks of leave did you have?"
                )}
              </p>
              <BasicNumberInput
                allowNegative={false}
                min={1}
                max={52}
                placeholder={t(
                  "employeeSurvey.q_weeksLeavePlaceholder",
                  "Enter number of weeks..."
                )}
                name="weeks-leave"
                defaultValue={values["weeks-leave"]}
                onChange={onChange}
              />
              <ErrorText value={errors["weeks-leave"]} />
            </>
          }
        />
      </QuestionWrapper>
    </FormStepWrapper>
  );
};

export default EmployeeSurvey1;
