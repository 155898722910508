import { CModal, CModalBody, CModalHeader } from "@coreui/react";
import { useSteps } from "react-step-builder";
import ButtonGhost from "src/components/ButtonGhost/ButtonGhost";

const NavModal = ({ show, setShow, navigationSteps = [] }) => {
  const { jump, current } = useSteps();
  return (
    <CModal show={show} onClose={() => setShow(false)} centered>
      <CModalBody style={{ maxHeight: "75vh", overflow: "auto" }}>
        <CModalHeader className="pb-1" closeButton>
          <h3>Select step:</h3>
        </CModalHeader>
        {navigationSteps.map((step, index) => {
          return (
            <ButtonGhost
              onClick={() => {
                jump(index + 1);
                setShow(false);
              }}
              key={index}
              className="w-100 py-2  border-bottom"
              style={{
                textAlign: "left",
                fontWeight: current === index + 1 ? 600 : 400,
              }}
            >
              {index + 1}. {step}
            </ButtonGhost>
          );
        })}
      </CModalBody>
    </CModal>
  );
};

export default NavModal;
