import { CCard, CCardBody } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ButtonBasic } from "src/components";

const ResultsCard = ({ areResultsCalculated, orgId }) => {
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <CCard className="px-3 results-box">
      <CCardBody className="d-flex flex-column align-items-center ">
        <h2 className="mb-3  text-center">{t("welcomeModal.results")}</h2>
        <p style={{ fontSize: "28px" }} className="text-center ">
          {areResultsCalculated ? (
            <b className="text-success">{t("welcomeModal.calculated")}</b>
          ) : (
            <b className="text-warning">{t("welcomeModal.noResults")}</b>
          )}
        </p>
        <ButtonBasic
          onClick={() => history.push(`/project/${orgId}/summaryDetails`)}
          className="mx-auto"
          disabled={!areResultsCalculated}
        >
          {t("welcomeModal.seeResultsSection")}
        </ButtonBasic>
      </CCardBody>
    </CCard>
  );
};

export default ResultsCard;
