import kebabcaseKeys from "kebabcase-keys";
import { SECTOR_KEYS } from "src/constants/sectorKeys";
import { useSectors } from "src/hooks";
import { convertFormatString } from "src/utils/ad-data-utils";
import { objHasValues } from "src/utils/common-utils";

const useCompletedFunctions = () => {
  const { SECTORS } = useSectors();

  /****CORE DATA****/

  const calculateCoreCompl = (coreData, hasSites) => {
    const fields = SECTORS.find(
      (item) => item.name === SECTOR_KEYS.coreData
    ).fieldsToCalculate;

    let completedFields = 0;
    fields.forEach((field) => {
      if (coreData?.[field] !== undefined) {
        completedFields++;
      }
    });
    if (hasSites || coreData?.noLocations) completedFields++;
    return completedFields / (fields.length + 1);
  };

  /****SITE DATA****/

  const calculateSiteDataCompl = (
    sites,
    hasLocations = true,
    isFree = false
  ) => {
    if (!hasLocations && !sites?.length) return { totalRate: 1 };
    if (!sites?.length) return { totalRate: 0 };

    const siteCompletionLookup = {};

    let fields = SECTORS.find(
      (item) => item.name === SECTOR_KEYS.siteData
    ).fieldsToCalculate;

    if (isFree) {
      fields = SECTORS.find(
        (item) => item.name === SECTOR_KEYS.siteData
      ).fieldsToCalculateFree;
    }

    for (let site of sites) {
      let completedFieldsNum = 0;

      const isSiteServingCustomers =
        site["site-type"] &&
        ["retail unit", "event space/venue", "mixed", "health/dental facility", "football stadium"].includes(
          site["site-type"]
        );
      if (!isSiteServingCustomers) {
        completedFieldsNum++;
      }
      if (site["waste-collected"] && Object.values(site["waste-collected"]).some(obj => Object.values(obj).length)) {
        completedFieldsNum++;
      }

      fields.forEach((field) => {
        if (typeof field === "string") {
          if (site[field] || site[field] === 0 || site[field] === false) {
            completedFieldsNum++;
          }
        } else {
          if (site[field[0]]?.[field[1]] !== undefined) {
            completedFieldsNum++;
          }
        }
      });

      siteCompletionLookup[site.name] =
        Math.min(Math.round((completedFieldsNum / fields.length) * 100) / 100, 1);
    }

    siteCompletionLookup.totalRate =
      Object.values(siteCompletionLookup).reduce((a, b) => a + b, 0) /
      sites.length;

    return siteCompletionLookup;
  };

  /****EMPLOYEE DATA****/

  const calculateEmployeeCompl = (employeeData, surveyIsOpen) => {
    if (!employeeData?.length) {
      return 0;
    } else {
      return !!employeeData?.length || surveyIsOpen ? 1 : 0;
    }
  };

  /****VOLUNTEER DATA***/

  const calculateVolunteerCompl = (volunteerData) => {
    let result = 0;
    if (!volunteerData) return 0;

    if (
      volunteerData.shifts ||
      (volunteerData.sites &&
        volunteerData.shiftsWeek &&
        volunteerData.volunteers)
    ) {
      result += 1;
    }

    if (volunteerData.carDistance || volunteerData.busDistance) {
      result += 1;
    }

    return result / 2;
  };

  /****FORECAST DATA****/

  const calculateForecastingCompl = (forecastingData) => {
    const fields = SECTORS.find(
      (item) => item.name === SECTOR_KEYS.forecastingData
    ).fieldsToCalculate;

    let completedFields = 0;
    fields.forEach((field) => {
      if (forecastingData?.[field] !== undefined) {
        completedFields++;
      }
    });
    return completedFields / fields.length;
  };

  /****DENTISTRY DATA****/

  // const calculateDentistryCompl = (forecastingData) => {
  //   const fields = SECTORS.find(
  //     (item) => item.name === SECTOR_KEYS.dentistryData
  //   ).fieldsToCalculate;

  //   let completedFields = 0;
  //   fields.forEach((field) => {
  //     if (forecastingData?.[field] !== undefined) {
  //       completedFields++;
  //     }
  //   });
  //   return completedFields / fields.length;
  // };

  /****FLEET DATA****/

  const calculateVehicleCompl = (fleetData) => {
    if (!fleetData) return 0;
    const {
      hasPassengerVehicles,
      passengerVehicles,
      hasDeliveryVehicles,
      deliveryVehicles,
    } = fleetData;

    let passengerCompletionRate =
      hasPassengerVehicles === false || passengerVehicles?.length ? 1 : 0;
    let deliveryCompletionRate =
      hasDeliveryVehicles === false || deliveryVehicles?.length ? 1 : 0;

    return (passengerCompletionRate + deliveryCompletionRate) / 2;
  };

  /****PURCHASE DATA****/

  const calculatePurchaseCompl = (hasPurchases) => {
    let completedFields = 0;
    if (hasPurchases) completedFields++;
    return completedFields;
  };

  /****MATERIALS DATA****/

  const calculateMaterialsCompl = (materialsData) => {
    let completedFields = 0;
    if (materialsData?.materials?.length) completedFields++;
    return completedFields;
  };

  /****DISTRIBUTION DATA****/

  const calculateDistributionCompl = (data) => {
    let completedFields = 0;
    if (data?.transport.length) completedFields++;
    return completedFields;
  };

  /****SUPPLIER DATA****/

  const calculateSuppliersCompl = (supplierData) => {
    if (supplierData?.length) {
      const supplierNumber = supplierData.length;
      const completedNumber = supplierData.filter(
        (supplier) => supplier.status === "complete"
      ).length;
      const notContactableNumber = supplierData.filter(
        (supplier) => supplier["can-contact"] === false
      ).length;
      const completed =
        (completedNumber + notContactableNumber) / supplierNumber;
      if (isNaN(completed)) return 0;
      return completed;
    } else {
      return 0;
    }
  };

  /****TRAVEL DATA****/

  const calculateTravelCompl = (travelData) => {
    if (!travelData) return 0;
    if (travelData.isTravelling === false) return 1;
    const fields = SECTORS.find(
      (item) => item.name === SECTOR_KEYS.travelData
    ).fieldsToCalculate;

    let completedFields = 0;
    fields.forEach((field) => {
      if (travelData?.[field] && objHasValues(travelData[field])) {
        completedFields++;
      }
    });
    if (travelData.isRoadTravelling === false) {
      completedFields++;
    }
    if (travelData.isUsingHotels === false) {
      completedFields++;
    }
    if (travelData.isFlying === false) {
      completedFields++;
    }

    return Math.min(completedFields / fields.length, 1);
  };

  /****AD DATA****/

  const calculateAdCompl = (adData) => {
    if (!adData) return 0;
    if (adData.hasAds === false) return 1;
    let completed = 0;
    const categories = adData?.formatCategories;
    if (categories) {
      const convertedCategories = categories.map((item) =>
        convertFormatString(item)
      );
      const findMatch = () => {
        return Object.keys(kebabcaseKeys(adData)).filter((item) => {
          return convertedCategories.includes(item);
        });
      };
      completed = findMatch().length / convertedCategories.length;
    }

    return completed;
  };

  /****INVESTMENT DATA****/

  const calculateInvestmentDataCompl = (
    investmentData,
    pensionProviders,
    investmentProviders
  ) => {
    const { banks, hasPension, hasInvestments } = investmentData || {};

    let completedRate = 0;
    const calculateProviderCompletion = (providers, fields) => {
      if (!providers || !fields?.length) return {};
      const lookup = {};

      providers.forEach((provider) => {
        let completedFieldsNum = 0;
        fields.forEach((field) => {
          if (provider[field] !== undefined) {
            completedFieldsNum++;
          }
        });
        lookup[provider.id] =
          Math.round((completedFieldsNum / fields.length) * 100) / 100;
      });

      return lookup;
    };

    if (banks?.length) {
      completedRate = 1 / 3;
    }

    if (!pensionProviders?.length && hasPension === false) {
      completedRate += 1 / 3;
    } else if (pensionProviders?.length) {
      const fields = [
        "provider-name",
        "scheme-name",
        "amount",
        "known-coverage",
        "known-footprint",
        "is-fossil-fuel-free",
        "enrolled-number",
        "is-default",
      ];
      const completion = calculateProviderCompletion(pensionProviders, fields);
      const pensionCompl =
        Object.values(completion)?.reduce((acc, val) => acc + val) /
        pensionProviders.length || 0;
      completedRate += pensionCompl / 3;
    }

    if (!investmentProviders?.length && hasInvestments === false) {
      completedRate += 1 / 3;
    } else if (investmentProviders?.length) {
      const fields = [
        "provider-name",
        "scheme-name",
        "amount",
        "known-coverage",
        "known-footprint",
      ];

      const completion = calculateProviderCompletion(
        investmentProviders,
        fields
      );

      const investmentCompl =
        Object.values(completion).reduce((acc, val) => acc + val) /
        investmentProviders.length || 0;

      completedRate += investmentCompl / 3;
    }
    return parseFloat(completedRate.toFixed(2));
  };

  /****WEB DATA****/

  const calculateWebDataCompl = (webData) => {
    const fields = SECTORS.find((item) => item.name === SECTOR_KEYS.webData)
      .fieldsToCalculate;


    let completedFields = 0;
    fields.forEach((field) => {
      if (webData?.[field] !== undefined) {
        completedFields++;
      }
    });
    return completedFields / fields.length;
  }

  return {
    calculateCoreCompl,
    calculateSiteDataCompl,
    calculateEmployeeCompl,
    calculateForecastingCompl,
    calculatePurchaseCompl,
    calculateSuppliersCompl,
    calculateVehicleCompl,
    calculateTravelCompl,
    calculateAdCompl,
    calculateInvestmentDataCompl,
    calculateVolunteerCompl,
    calculateMaterialsCompl,
    calculateDistributionCompl,
    calculateWebDataCompl
  };
};

export default useCompletedFunctions;
