import { CFormGroup, CInputGroup, CLabel } from "@coreui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BasicInput,
  BasicNumberInput,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  VerticalRadioButtons,
} from "src/components";
import { convertStrToBool } from "src/utils/common-utils";
import AboveCard from "../components/AboveCard";

const SupplierSurvey7 = ({ values, onChange, info, isDemo }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const [knowsEmissions, setKnowsEmissions] = useState(
    values["knows-emissions"]
  );

  const { t } = useTranslation();


  return (
    <FormStepWrapper aboveCardSlot={<AboveCard {...{ info, isDemo }} />}>
      <QuestionWrapper>
        <h3 className="mb-4">
          {t(
            "supplierSurvey.q_emissions",
            "12. Do you know you greenhouse gas emissions for {{period}}?",
            { period: info.reportingPeriod }
          )}
        </h3>

        <CInputGroup>
          <VerticalRadioButtons
            name="knows-emissions"
            onChange={(e) => {
              setKnowsEmissions(convertStrToBool(e.target.value));
              onChange(e);
            }}
            data={[
              { label: t("shared.yes"), value: true },
              { label: t("shared.no"), value: false },
            ]}
            defaultValue={values["knows-emissions"]}
          />
        </CInputGroup>
        <ErrorText value={errors["knows-emissions"]} />
      </QuestionWrapper>

      {knowsEmissions && (
        <>
          {[1, 2, 3].map((scope, idx) => {
            return (
              <QuestionWrapper>
                <h3>
                  {t(
                    "supplierSurvey.q_scopeEmisssions",
                    "What were your Scope {{num}} emissions? (kgCO2e)",
                    { num: scope }
                  )}
                </h3>
                <CInputGroup>
                  <BasicNumberInput
                    allowNegative={false}
                    min={0}
                    name={"emissions.scope" + scope}
                    defaultValue={values.emissions?.["scope" + scope]}
                    onChange={onChange}
                    placeholder={t("shared.pleaseSpecify")}
                  />
                </CInputGroup>
                <ErrorText value={errors.emissions?.["scope" + scope]} />
              </QuestionWrapper>
            );
          })}

          <QuestionWrapper>
            <h3>
              {t(
                "supplierSurvey.unknownEmissions",
                `If answers above unknown, what were your "direct" or"operational" emissions, and how do you define them?`
              )}
            </h3>
            <CFormGroup className="mb-4">
              <CLabel>{t("shared.number")} (kgCO2e)</CLabel>
              <BasicNumberInput
                allowNegative={false}
                min={0}
                name="emissions.direct"
                defaultValue={values.emissions?.direct}
                onChange={onChange}
                placeholder={t("shared.pleaseSpecify")}
              />
              <ErrorText value={errors.emissions?.direct} />
            </CFormGroup>
            <CFormGroup>
              <CLabel>{t("shared.definition", "Definition")}</CLabel>
              <BasicInput
                type="text"
                name="emissions.definition"
                defaultValue={values.emissions?.definition}
                onChange={onChange}
                placeholder={t("shared.pleaseSpecify")}
              />
              <ErrorText value={errors.emissions?.definition} />
            </CFormGroup>
            <ErrorText className="mt-3" value={errors.emissions} />
          </QuestionWrapper>
        </>
      )}
    </FormStepWrapper>
  );
};

export default SupplierSurvey7;
