import { useTranslation } from "react-i18next";
import { useSteps } from "react-step-builder";
import {
  DemoWarning,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  VerticalRadioButtons,
} from "src/components";
import { Subtitle } from "../components";
import SitesQuestion from "../components/SitesQuestion";
import controller from "./SurveyController4";

const EmployeeSurvey4 = ({
  data: values = {},
  onChange,
  onSubmit,
  prevClickHandler,
  siteData,
  isDemo,
  info
}) => {
  const {
    errors,
    usesVehicle,
    doesCommute,
    commuteDestHandler,
    usesVehicleHandler,
    setValue,
    sites,
  } = controller({
    values,
    onChange,
    siteData,
  });

  const { jump, next } = useSteps();

  const onNext = async () => {
    const noErrors = await onSubmit();
    if (!noErrors) return;
    if (doesCommute === false) {
      jump(8);
    } else if (doesCommute && usesVehicle === false) {
      jump(7);
    } else {
      next();
    }
  };

  const { t } = useTranslation();

  return (
    <FormStepWrapper
      onNext={onNext}
      aboveCardSlot={
        <>
          {isDemo && <DemoWarning />}
          <Subtitle
            content={t(
              "employeeSurvey.commute",
              "Commute to the main place of work"
            )}
          />
        </>
      }
    >
      <QuestionWrapper>
        <h3>
          8.{" "}
          {t(
            "employeeSurvey.q_commute",
            " When you usually commuted, where did you travel to?"
          )}
        </h3>
        {sites?.length && (
          <SitesQuestion
            onChange={commuteDestHandler}
            answers={sites}
            defaultValue={values.commute?.destination}
            defaultCountry={values.commute?.country}
            setValue={setValue}
          />
        )}
        <ErrorText value={errors?.commute?.destination} />
        {doesCommute === false && (
          <small className="text-info">
            {t(
              "employeeSurvey.noCommuteSkip",
              "Because you are not commuting, you will skip to step 8."
            )}
          </small>
        )}
      </QuestionWrapper>

      {doesCommute && (
        <QuestionWrapper className="mb-0 mt-4">
          <h3>
            9.{" "}
            Did you use one or more cars, vans, motorbikes or mopeds as part of your typical weekly commute in {info.reportingPeriod} ?
          </h3>
          <VerticalRadioButtons
            name="uses-vehicles"
            data={[
              { value: true, label: t("shared.yes") },
              { value: false, label: t("shared.no") },
            ]}
            onChange={usesVehicleHandler}
            defaultValue={values["uses-vehicles"]}
          />
          {usesVehicle === false && (
            <small className="text-info">
              {t(
                "employeeSurvey.noVehicleSkip",
                "Because you are not using a vehicle, you will skip to step 7."
              )}
            </small>
          )}
          <ErrorText value={errors["uses-vehicles"]} />
        </QuestionWrapper>
      )}
    </FormStepWrapper>
  );
};

export default EmployeeSurvey4;
