import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";

import {
  CCol,
  CNavLink,
  CRow,
  CSidebarClose,
  CSidebarNav,
  CSidebarNavItem,
  CSidebarNavTitle,
  CSidebarNavDropdown,
} from "@coreui/react";

import { AiOutlineReload } from "react-icons/ai";
import { FiMail } from "react-icons/fi";
import { SiLivechat } from "react-icons/si";
import { set } from "src/store/coreuiSlice";
import TheAsideController from "../controllers/theAsideController";
import { TUTORIAL_SECTIONS } from "./constants/tutorialSections";
import StyledSidebar from "./TheAside.style";
import { useTranslation } from "react-i18next";
import { GrDocumentPdf } from "react-icons/gr";
import PDFModal from "src/components/PDFModal/PDFModal";
// import test_pdf_1 from "../../assets/source/test1.pdf";
// import test_pdf_2 from "../../assets/source/test2.pdf";

const TheAside = () => {
  const show = useSelector((state) => state.coreui.asideShow);
  const [contactDropdownShow, setcontactDropdownShow] = useState(false);
  const [tutorialDropdownShow, setTutorialDropdownShow] = useState(false);
  // const [showPdfModal, setShowPdfModal] = useState(false);
  // const [pdfFile, setPdfFile] = useState(null);
  const dispatch = useDispatch();
  const setState = (state) => dispatch(set({ asideShow: state }));
  const {
    dashboardOnClick,
    tutorialOnClick,
    isChatVisible,
    chatOnClickHandle,
  } = TheAsideController();

  const { t } = useTranslation();

  return (
    <>
      <StyledSidebar
        aside
        colorScheme="light"
        size="lg"
        overlaid
        show={show}
        onShowChange={(state) => setState(state)}
      >
        <CSidebarClose onClick={() => setState(false)} />
        {/*aside content*/}
        <div className="nav-underline">
          <div className="nav nav-tabs">
            <div className="nav-item">
              <div className="nav-link">{t("shared.help", "Help")}</div>
            </div>
          </div>
        </div>
        <div>
          <CRow>
            <CCol>
              <CSidebarNav variant="tabs">
                <CSidebarNavTitle>
                  {t("aside.needHelp", "Need help? Contact us")}:
                </CSidebarNavTitle>
                <CSidebarNavDropdown
                  name="Contact"
                  className=" mt-0"
                  show={contactDropdownShow}
                >
                  <CSidebarNavItem className="w-100 p-2">
                    <CNavLink href="mailto:toby@zerobees.io">
                      <FiMail className="text-info mr-3" />
                      {t("shared.email")}
                    </CNavLink>
                  </CSidebarNavItem>
                  <CSidebarNavItem className="w-100 p-2">
                    <CNavLink
                      className="d-flex justify-content-between"
                      onClick={chatOnClickHandle}
                    >
                      <div>
                        <SiLivechat className="text-info mr-3" />{" "}
                        {t("aside.liveChat", "Live chat")}
                      </div>
                      {isChatVisible && (
                        <span className="text-muted">
                          <i>{t("aside.hide", "hide")}</i>
                        </span>
                      )}
                    </CNavLink>
                  </CSidebarNavItem>
                </CSidebarNavDropdown>

                <CSidebarNavTitle className="mt-0">
                  {t("aside.needTutorial", "Restart tutorial?")} :
                </CSidebarNavTitle>
                <CSidebarNavItem className="w-100 p-2">
                  <CNavLink
                    className="d-flex justify-content-between"
                    onClick={dashboardOnClick}
                  >
                    <div className="w-100">
                      <AiOutlineReload className="text-info mr-3" />
                      {t("aside.dashboardTutorial", "Dashboard tutorial")}
                    </div>
                  </CNavLink>
                </CSidebarNavItem>
                <CSidebarNavDropdown
                  name={t("aside.dataTutorials")}
                  className=" mt-0"
                  show={tutorialDropdownShow}
                >
                  {TUTORIAL_SECTIONS.map(({ sectionName, urlPath, dbPath }) => (
                    <CSidebarNavItem
                      className="w-100 p-2"
                      key={urlPath + "-tutorial"}
                    >
                      <CNavLink
                        className="d-flex justify-content-between"
                        onClick={() => tutorialOnClick(urlPath, dbPath)}
                      >
                        <div className="w-100">
                          <AiOutlineReload className="text-info mr-3" />
                          {t(`${sectionName || urlPath}.title`)}{" "}
                          {t("aside.tutorial")}
                        </div>
                      </CNavLink>
                    </CSidebarNavItem>
                  ))}
                </CSidebarNavDropdown>
                {/* <CSidebarNavTitle>Conditions & Privacy policy</CSidebarNavTitle>
                <CSidebarNavItem className="w-100 p-2">
                  <CNavLink
                    onClick={() => {
                      setPdfFile(test_pdf_1);
                      setShowPdfModal(true);
                    }}
                  >
                    <GrDocumentPdf className="text-info mr-3" />
                    Conditions
                  </CNavLink>
                </CSidebarNavItem>
                <CSidebarNavItem className="w-100 p-2">
                  <CNavLink
                    onClick={() => {
                      setPdfFile(test_pdf_2);
                      setShowPdfModal(true);
                    }}
                  >
                    <GrDocumentPdf className="text-info mr-3" />
                    Privacy policy
                  </CNavLink>
                </CSidebarNavItem> */}
              </CSidebarNav>
            </CCol>
          </CRow>
        </div>
      </StyledSidebar>
      {/* {pdfFile && (
        <PDFModal
          show={showPdfModal}
          toggle={() => setShowPdfModal((prev) => !prev)}
          pdfFile={pdfFile}
        />
      )} */}
      {/* <LiveChatWidget
        license={process.env.REACT_APP_LIVECHAT_LICENSE}
        group="0"
        visibility={chatVisible}
      /> */}
    </>
  );
};

export default React.memo(TheAside);
