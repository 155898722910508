import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DemoWarning,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  RangeInput,
} from "src/components";
import { Subtitle } from "../components";
import VehicleQuestion from "../components/VehicleQuestion";

const EmployeeSurvey6 = ({
  data: values = {},
  onChange,

  isDemo,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  return (
    <FormStepWrapper
      aboveCardSlot={
        <>
          {isDemo && <DemoWarning />}
          <Subtitle
            content={t(
              "employeeSurvey.aboutSecondVehicle",
              "About your second vehicle"
            )}
          />
        </>
      }
    >
      <QuestionWrapper>
        <h3>
          13.{" "}
          Of the miles you told us that you drove per week in your
          vehicle(s), what percentage is in this first vehicle that you've
          told us about above?
        </h3>
        <RangeInput
          name="first-vehicle-share"
          defaultValue={values["first-vehicle-share"] || 100}
          onChange={onChange}
        />
        <ErrorText value={errors["first-vehicle-share"]} />
      </QuestionWrapper>

      <QuestionWrapper>
        <VehicleQuestion
          {...{ values, onChange, errors }}
          name="second-vehicle"
          title={
            "14. " +
            t(
              "employeeSurvey.q_secondVehicleType",
              "Thinking about your second vehicle - what kind is it?"
            )
          }
        />
      </QuestionWrapper>
    </FormStepWrapper>
  );
};

export default EmployeeSurvey6;
