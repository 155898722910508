import { useState } from "react";
import ResizeObserver from "resize-observer-polyfill";

const useResizeObserver = () => {
  const [width, setWidth] = useState();
  const ro = new ResizeObserver((entries, observer) => {
    setWidth(entries[0].contentRect.width);
  });
  ro.observe(document.body);

  const isSmallScreen = width < 700;

  return { width, isSmallScreen };
};

export default useResizeObserver;
