import { useHistory } from "react-router-dom";
import BackButton from "./BackButton";
import HelpButton from "./HelpButton";
import MenuButton from "./MenuButton";

const TopButtons = ({
  showBackButton = true,
  onBackButtonClick,
  backButtonLabel,
  hasNav,
  onNavClick,
}) => {
  const history = useHistory();

  return (
    <div className="d-flex justify-content-between align-items-center">
      {showBackButton && (
        <BackButton
          history={history}
          onClick={onBackButtonClick}
          label={backButtonLabel}
          className="mb-3"
        />
      )}
      <HelpButton />
      {hasNav && <MenuButton onClick={onNavClick} />}
    </div>
  );
};

export default TopButtons;
