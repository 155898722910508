import React from "react";
import { StyledReactSelect } from "..";
import { CURRENCIES } from "src/constants";
import { useTranslation } from "react-i18next";

const CurrencySelect = ({
  className,
  name,
  onChange,
  value,
  defaultValue,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <StyledReactSelect
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      isSearchable
      isClearable
      backspaceRemovesValue
      className={`${className} w-100`}
      classNamePrefix="react-select"
      placeholder={t("shared.selectCurrency", "Select currency...")}
      options={Object.values(CURRENCIES).map((item) => {
        return {
          label: `${item.code} - ${item.name}`,
          value: item.code,
          target: { name, value: item.code },
        };
      })}
      value={
        value
          ? {
              label: value,
              value: value,
            }
          : undefined
      }
      defaultValue={{ label: defaultValue, value: defaultValue }}
      name={name}
      onChange={onChange}
      {...rest}
    />
  );
};

export default CurrencySelect;
