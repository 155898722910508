export const resultIntroSteps = [
  // {
  //   key: "before_start",
  //   target: ".results-welcome",
  //   disableBeacon: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   spotlightClicks: false,
  //   placement: "center",
  // },
  // {
  //   key: "welcome",
  //   target: ".results-welcome",
  //   disableBeacon: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   spotlightClicks: false,
  //   placement: "center",
  // },

  {
    key: "summary_explain",
    target: ".results-intro-summary",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "calculate_button",
    target: ".results-intro-calculate-button",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "total",
    target: ".results-intro-total",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "click_detailed_results_tab",
    target: ".results-intro-detailed-tab",
    placement: "auto",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
    styles: {
      buttonNext: {
        display: "none",
      },
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "detailed_results_explain",
    target: ".results-intro-detailed",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "chart_type",
    target: ".results-intro-chart-type",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "details_table",
    target: ".results-intro-details-table",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },

  {
    key: "click_reductions_tab",
    target: ".results-intro-reduction-tab",
    placement: "auto",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
    styles: {
      buttonNext: {
        display: "none",
      },
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "recommend_reduction_explain",
    target: ".results-intro-reductions",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "reduction_table",
    target: ".results-intro-reduction-table",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "reduction_save",
    target: ".results-intro-reduction-save",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "click_forecasts_tab",
    target: ".results-intro-forecasts-tab",
    placement: "auto",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
    styles: {
      buttonNext: {
        display: "none",
      },
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "forecasts_explain",
    target: ".results-intro-forecasts",
    placement: "center",
    disableBeacon: true,
    paragraphs: 2,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "forecast_table",
    target: ".results-intro-forecast-table",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  // {
  //   key: "click_progress_tab",
  //   target: ".results-intro-progress-tab",
  //   placement: "auto",
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   hideCloseButton: true,
  //   spotlightClicks: true,
  //   styles: {
  //     buttonNext: {
  //       display: "none",
  //     },
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  // },
  // {
  //   key: "progress_explain",
  //   target: ".results-intro-progress",
  //   placement: "center",
  //   disableBeacon: true,
  //   paragraphs: 2,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  // },
  // {
  //   key: "click_employee_highlights_tab",
  //   target: ".results-intro-employee-tab",
  //   placement: "auto",
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   hideCloseButton: true,
  //   spotlightClicks: true,
  //   styles: {
  //     buttonNext: {
  //       display: "none",
  //     },
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  // },
  // {
  //   key: "employee_highlights_explain",
  //   target: ".results-intro-employee",
  //   placement: "center",
  //   disableBeacon: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  // },
  // {
  //   key: "employee_sections",
  //   target: ".results-intro-survey-sections",
  //   placement: "auto",
  //   disableBeacon: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  // },
  {
    key: "click_help_section_tab",
    target: ".results-intro-help-tab",
    placement: "auto",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
    styles: {
      buttonNext: {
        display: "none",
      },
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "help_section_explain",
    target: ".results-intro-help",
    placement: "center",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  // {
  //   key: "click_travel_section_tab",
  //   target: ".results-intro-travel-tab",
  //   placement: "auto",
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   hideCloseButton: true,
  //   spotlightClicks: true,
  //   styles: {
  //     buttonNext: {
  //       display: "none",
  //     },
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  // },
  // {
  //   key: "travel_section_explain",
  //   target: ".results-intro-travel",
  //   placement: "center",
  //   disableBeacon: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  // },
  // {
  //   key: "click_locations_section_tab",
  //   target: ".results-intro-locations-tab",
  //   placement: "auto",
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   hideCloseButton: true,
  //   spotlightClicks: true,
  //   styles: {
  //     buttonNext: {
  //       display: "none",
  //     },
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  // },
  // {
  //   key: "locations_section_explain",
  //   target: ".results-intro-locations",
  //   placement: "center",
  //   disableBeacon: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  // },
  {
    key: "results_fab",
    target: ".results-fab",
    placement: "top",
    disableBeacon: true,
    styles: {
      spotlight: {
        display: "none",
      },
    },
  },
];
