import styled from "styled-components";

const StyledDiv = styled.div`
  /* height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem; */

  border-bottom: var(--border);

  input::placeholder {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "0.9rem")}!important;
    line-height: 24px;
    color: #b4b4b4;
    font-weight: 300;
  }

  input,
  input:focus {
    outline: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "1rem")}!important;
    font-weight: 500;
    color: var(--primary);
  }

  input:disabled {
    border: none;
    cursor: not-allowed;
  }

  input::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

  .c-dark-theme input {
    input::-webkit-calendar-picker-indicator {
      color-scheme: dark;
    }
  }

  button {
    font-size: 10px;
    padding-block: 0;
    border-radius: 0;
    margin-bottom: 0.2rem;
  }
`;

export default StyledDiv;
