import { camelCase } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SECTOR_KEYS } from "src/constants/sectorKeys";
import {
  selectInvestmentProviders,
  selectPensionProviders,
  selectSites,
} from "src/store/projectSelectors";
import { convertKebabToDisplay } from "src/utils/common-utils";

const useConvertTaskName = () => {
  const sites = useSelector(selectSites);
  const { t } = useTranslation();
  const pensionProviders = useSelector(selectPensionProviders);
  const investmentProviders = useSelector(selectInvestmentProviders);

  const convertTaskName = (taskName) => {
    if (!taskName) return {};
    let task = {};
    const sector = taskName.split(".")[0];

    if (sector === SECTOR_KEYS.siteData) {
      const [sector, siteId, field] = taskName.split(".");

      const siteName = sites?.find((site) => site.id === siteId)?.name || "";

      task = {
        sector: t(`${camelCase(sector)}.title`),
        site: siteName,
        field: convertKebabToDisplay(field),
      };
    } else if (sector === SECTOR_KEYS.investmentData) {
      const [sector, subsector, field] = taskName.split(".");

      const providerName =
        pensionProviders?.find((provider) => provider.id === field)?.[
          "provider-name"
        ] ||
        investmentProviders.find((provider) => provider.id === field)?.[
          "provider-name"
        ];
      task = {
        sector: t(`${camelCase(sector)}.title`),
        subsector: convertKebabToDisplay(subsector),
        field: providerName,
      };
    } else {
      const [sector, field] = taskName.split(".");

      task = {
        sector: t(`${camelCase(sector)}.title`),
        field: convertKebabToDisplay(field),
      };
    }

    return task;
  };

  return { convertTaskName };
};

export default useConvertTaskName;
