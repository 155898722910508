import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCallout,
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CPopover,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/ai";
import { DB_PATHS } from "src/constants";
import { useDatabasePaths, useUpdateDatabase } from "src/hooks";
import { useDoc } from "src/hooks/databaseHooks";
import { NewMessageCircle, StyledMessageDelete } from "./Message.style";

const MessageDropdown = () => {
  const { t } = useTranslation();

  const { getPath } = useDatabasePaths();

  const { setOrUpdateDocument } = useUpdateDatabase();

  const { data } = useDoc(getPath(DB_PATHS.user));

  const deleteOnClick = async (id) => {
    if (!id) return;
    if (!data?.notification?.length) return;

    const { notification } = data;

    const filter = notification.filter(({ notifyID }) => notifyID !== id);

    await setOrUpdateDocument(DB_PATHS.user, { notification: filter });
  };

  const clearAllClick = async () => {
    await setOrUpdateDocument(DB_PATHS.user, { notification: [] });
  };

  return (
    <CPopover content={t("header.notifications")}>
      <CDropdown>
        <CDropdownToggle
          style={{ border: "none", boxShadow: "none" }}
          caret={false}
          inHeader
          className="c-header-toggler"
        >
          <CIcon
            className="notification-intro"
            name="cil-bell"
            size="lg"
            alt="message"
          />
        </CDropdownToggle>
        <CDropdownMenu
          className=" p-2 "
          style={{
            width: "400px",
            maxHeight: "400px",
            overflowY: "scroll",
            right: "0",
          }}
        >
          {data?.notification?.length ? (
            <>
              <CButton
                className="w-100 "
                size="sm"
                variant="outline"
                color="danger"
                onClick={clearAllClick}
              >
                {t("header.clearAll")}
              </CButton>
              {data?.notification?.map(({ body, title, notifyID }) => (
                <CCallout
                  className="my-2 pb-1  border w-100 shadow"
                  key={notifyID}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <small className="text-success mt-2">{title}</small>
                    <CPopover content={t("shared.delete")}>
                      <StyledMessageDelete
                        onClick={(e) => {
                          deleteOnClick(notifyID);
                        }}
                        className="text-danger"
                      >
                        <AiFillCloseCircle size={18} />
                      </StyledMessageDelete>
                    </CPopover>
                  </div>
                  <p style={{ fontSize: "0.9rem" }}>{body}</p>
                </CCallout>
              ))}
            </>
          ) : (
            <h5 className="text-center text-info mt-2">
              {" "}
              {t("header.empty")}
            </h5>
          )}
        </CDropdownMenu>
        {!!data?.notification?.length && <NewMessageCircle />}
      </CDropdown>
    </CPopover>
  );
};

export default MessageDropdown;
