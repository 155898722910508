import { CAlert, CSpinner } from "@coreui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBasic } from "src/components";
import { useClassificationTask } from "src/hooks";

const SchedulePrompt = () => {

  const [schedulingTask, setSchedulingTask] = useState(false);
  const onConfirmLedger = async () => {
    setSchedulingTask(true);
    await setClassifyTask();
    setSchedulingTask(false);
  };

  const { classifyTask, setClassifyTask, runningQueue } =
    useClassificationTask();

  const { t } = useTranslation();

  return (
    <CAlert
      style={{ maxWidth: "600px" }}
      className="text-center mb-5 mx-auto"
      color="success"
    >
      {!classifyTask && !runningQueue ? (
        <>
          <h2 className="text-primary">{t("purchaseData.isLedgerCorrect")}</h2>
          <h5 className="text-info">{t("purchaseData.reviewLedger")}</h5>
          <p className="text-center text-primary">
            {t("purchaseData.confirmHint")}
          </p>
          <ButtonBasic
            size="md"
            bgcolor="green"
            hovercolor="darkgreen"
            onClick={onConfirmLedger}
            disabled={schedulingTask}
          >
            {schedulingTask ? (
              <span>
                <CSpinner
                  className="mb-1"
                  style={{ width: "1rem", height: "1rem", fontSize: "10px" }}
                />{" "}
                {t("purchaseData.scheduling")}
              </span>
            ) : (
              <span>{t("shared.confirm")}</span>
            )}
          </ButtonBasic>
        </>
      ) : !runningQueue ? (
        <>
          <h2 className="text-primary">{t("purchaseData.ledgerConfirmed")}</h2>
          <h5 className="text-primary">
            {t("purchaseData.classificationScheduled")}
          </h5>
          <p className="text-center text-primary mb-0">
            {t("purchaseData.changesHint")}{" "}
          </p>
        </>
      ) : (
        <>
          <h2 className="text-primary">{t("purchaseData.classifying")}</h2>
          <h5 className="text-primary">
            {t("purchaseData.classificationInProgress")}{" "}
          </h5>
        </>
      )}
    </CAlert>
  );
};

export default SchedulePrompt;
