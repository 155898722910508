import { CForm } from "@coreui/react";
import React from "react";
import { FormProvider } from "react-hook-form";
import { Steps, useSteps } from "react-step-builder";
import { Loading, Progress, ProgressBar } from "src/components";
import controller from "./controllers/EmployeeSurveyController";
import EmployeeSurvey1 from "./EmployeeSurvey1/EmployeeSurvey1";
import EmployeeSurvey10 from "./EmployeeSurvey10/EmployeeSurvey10";
import EmployeeSurvey2 from "./EmployeeSurvey2/EmployeeSurvey2";
import EmployeeSurvey3 from "./EmployeeSurvey3/EmployeeSurvey3";
import EmployeeSurvey4 from "./EmployeeSurvey4/EmployeeSurvey4";
import EmployeeSurvey5 from "./EmployeeSurvey5/EmployeeSurvey5";
import EmployeeSurvey6 from "./EmployeeSurvey6/EmployeeSurvey6";
import EmployeeSurvey7 from "./EmployeeSurvey7/EmployeeSurvey7";
import EmployeeSurvey8 from "./EmployeeSurvey8/EmployeeSurvey8";
import EmployeeSurvey9 from "./EmployeeSurvey9/EmployeeSurvey9";
import EmployeeSurveyOffset from "./EmployeeSurveyOffset/EmployeeSurveyOffset";

const EmployeeSurvey = ({ isDemo }) => {
  const {
    status,
    data,
    onChange,
    onSubmit,
    formMethods,
    nextClickHandler,
    prevClickHandler,
    info,
    siteData,
    workDays,
    orgId,
    optionalQuestions,
    reportingPeriod,
    initiatives
  } = controller({ isDemo });

  const hideStepOffset =
    !isDemo &&
    optionalQuestions.q17 === false &&
    optionalQuestions.q18 === false;

  const hideStep8 =
    !isDemo &&
    optionalQuestions.q19 === false &&
    optionalQuestions.q20 === false &&
    optionalQuestions.q21 === false;

  const hideStep9 =
    !isDemo &&
    optionalQuestions.q22 === false &&
    optionalQuestions.q23 === false;

  const { current } = useSteps();

  const stepAttr = {
    data,
    onChange,
    info,
    nextClickHandler,
    prevClickHandler,
    onSubmit,
    isDemo,
  };

  const navigationSteps = [
    "Work period",
    "Work days",
    "Electricity and paper",
    "Travel",
    "Primary vehicle",
    "Secondary vehicle",
    "Public transport and overnight stays",
    "Carbon offset",
    "Views on climate change",
    "Carbon reduction initiatives",
  ]

  const stepsArr = [
    <EmployeeSurvey1 {...stepAttr} />,
    <EmployeeSurvey2 {...stepAttr} workDays={workDays} />,
    <EmployeeSurvey3 {...stepAttr} />,
    <EmployeeSurvey4 {...stepAttr} siteData={siteData} />,
    <EmployeeSurvey5 {...stepAttr} />,
    <EmployeeSurvey6 {...stepAttr} />,
    <EmployeeSurvey7 {...stepAttr} workDays={workDays} />,
    <EmployeeSurveyOffset {...stepAttr} />,
    <EmployeeSurvey8 {...stepAttr} />,
    <EmployeeSurvey9 {...stepAttr} />,
    <EmployeeSurvey10 {...stepAttr} isDemo={isDemo} />,
  ];

  if (hideStepOffset) {
    stepsArr.splice(7, 1);
  }
  if (hideStep8) {
    stepsArr.splice(8, 1);
  }
  if (hideStep9) {
    stepsArr.splice(9, 1);
  }

  return (
    <FormProvider
      {...{
        ...formMethods,
        nextClickHandler,
        prevClickHandler,
        orgName: info.orgName,
        hideStepComplete: true,
        showBackButton: false,
        isSurvey: true,
        isDemo,
        orgId,
        optionalQuestions,
        reportingPeriod,
        initiatives
      }}
      navigationSteps={isDemo ? navigationSteps : undefined}

    >
      <Progress className="position-absolute">
        <ProgressBar value={current * 10} />
      </Progress>
      <CForm
        className="w-100 h-100 d-flex flex-column justify-content-center"
        onSubmit={onSubmit}
      >
        {status !== "success" ? (
          <Loading />
        ) : (
          <Steps>
            {stepsArr.map((component, index) =>
              React.cloneElement(component, { key: component + index })
            )}
          </Steps>
        )}
      </CForm>
    </FormProvider>
  );
};

export default EmployeeSurvey;
