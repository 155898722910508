import React from "react";
import { useState } from "react";
import RadioButtons from "../RadioButtons/RadioButtons";
import { convertStrToBool } from "src/utils/common-utils";
import ErrorText from "../ErrorText/ErrorText";
import { CInputGroup } from "@coreui/react";

const ConditionalInput = ({
  defaultValue,
  questionSlot,
  name,
  onChange,
  inputSlot,
  errors,
}) => {
  const [isVisible, setIsVisible] = useState(!!defaultValue);

  return (
    <div>
      {questionSlot}
      <CInputGroup className="mb-3">
        <RadioButtons
          data={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          onChange={(e) => {
            const value = convertStrToBool(e.target.value);
            setIsVisible(value);
            onChange({ target: { name, value } });
          }}
          defaultValue={defaultValue}
          name={name}
        />
        <ErrorText value={errors["had-leave"]} />
      </CInputGroup>

      {isVisible && inputSlot}
    </div>
  );
};

export default ConditionalInput;
