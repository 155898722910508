import { css } from "styled-components";

const placeholderStyle = css`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "0.9rem")}!important;
  line-height: 24px;
  color: #b4b4b4;
  font-weight: 300;
`;

export default placeholderStyle;
