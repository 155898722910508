import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ButtonBasic, ButtonGhost } from "../index";

const StepComplete = ({
  onComplete = () => {},
  onSaveAndLeave,
  showPressEnter = true,
  disabled = false,
  buttonType = "submit",
}) => {
  const history = useHistory();

  const formContext = useFormContext();
  const onSubmit = formContext?.onSubmit;

  const saveAndLeaveHandle = async () => {
    let noErrors;
    if (onSaveAndLeave) {
      noErrors = await onSaveAndLeave();
    } else if (onSubmit) {
      noErrors = await onSubmit();
    } else {
      noErrors = await onComplete();
    }

    if (noErrors) {
      history.push("/dashboard");
    }
  };

  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between mt-4 align-items-baseline w-100">
      <div className="d-flex align-items-baseline">
        <ButtonBasic
          type={buttonType}
          onClick={onComplete}
          disabled={disabled}
          className="intro-save-btn"
        >
          {t("shared.ok", "OK")}
        </ButtonBasic>
        {showPressEnter && (
          <span className="ml-3">
            <Trans i18nKey="shared.orPressEnter">
              or press <strong>Enter</strong>
            </Trans>
          </span>
        )}
      </div>
      <ButtonGhost
        className="d-flex align-items-center"
        onClick={saveAndLeaveHandle}
        type="button"
      >
        <u>{t("shared.saveAndLeave", "save &amp; return later")}</u>
      </ButtonGhost>
    </div>
  );
};

export default StepComplete;
