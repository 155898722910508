import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { usePermissions } from "src/hooks";
import { setAlertWithTimeout } from "src/store/operations";

function PrivateRoute({ restrictedTo, Component, ...rest }) {
  const dispatch = useDispatch();

  const { isSuperAdmin, hasExtendedPermissions } = usePermissions();

  const isAllowed =
    (restrictedTo === "superAdmin" && isSuperAdmin) ||
    (restrictedTo === "extended" && hasExtendedPermissions);

  const { t } = useTranslation();

  if (!isAllowed) {
    dispatch(
      setAlertWithTimeout({
        type: "error",
        text: t(
          "errors.unauthorized",
          "You are not authorized to access this section."
        ),
      })
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAllowed ? (
          // <CFade className={"fill-space"}>
            <Component {...props} />
          // </CFade>
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
}

export default PrivateRoute;
