import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  firstName: null,
  organisation: null,
  uid: null,
  token: null,
  role: null,
  email: null,
  secondaryOrganisations: [],
  acceptedTerms: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetUserData: (stat, action) => {
      return action.payload;
    },
    clearUserData: () => {
      return initialState;
    },
  },
});

export const { setUserData, resetUserData, clearUserData } = userSlice.actions;
export const selectUserData = (state) => state.user;
export const selectUserEmail = (state) => state.user.email;
export const selectUserId = (state) => state.user?.uid || "none";
export const selectUserName = (state) => state.user?.firstName;
export const selectUserRole = (state) => state.user?.role;
export const selectPrimaryOrg = (state) => state.user?.organisation;
export const selectSecondaryOrgs = (state) =>
  state.user?.secondaryOrganisations;

export const selectAcceptedTerms = (state) => state.user?.acceptedTerms;

export default userSlice.reducer;
