import React from "react";
import { CFormGroup, CLabel, CInputCheckbox } from "@coreui/react";

const CheckboxItem = ({
  item,
  index,
  name,
  onChange,
  defaultValues,
  checked,
  className = "mt-3",
  style,
}) => {
  return (
    <CFormGroup className={className} key={index} variant="custom-checkbox">
      <CInputCheckbox
        custom
        id={`${name}-${index}`}
        name={`${name}-${index}`}
        value={item.value}
        onChange={onChange}
        defaultChecked={defaultValues?.includes(item.value)}
        style={{ borderRadius: 0 }}
        checked={checked}
      />
      <CLabel
        variant="custom-checkbox"
        style={style}
        htmlFor={`${name}-${index}`}
      >
        {item.label}
      </CLabel>
    </CFormGroup>
  );
};

export default CheckboxItem;
