import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { selectPeriod } from "src/store/projectSelectors";

/**
 * Hook for creating a url for a specific endpoint
 * 
 * @returns {{createPath: (function(string): string)}}
 */


const useCreateUrl = () => {
  const {
    url,
    params: { id },
  } = useRouteMatch();

  const periodId = useSelector(selectPeriod);

  const createPath = (endpoint) => {
    const absoluteUrl = window.location.href;
    return absoluteUrl.replace(url, `/${id}/${periodId}/${endpoint}`);
  };
  return { createPath };
};

export default useCreateUrl;
