import { CProgress } from "@coreui/react";
import { useTranslation } from "react-i18next";

const CompletionBar = ({ completionRate, isSmall = false, color = "success", label, scale = 1 }) => {

    const { t } = useTranslation()

    const largeFont = 40 * scale + "px"
    const smallFont = Math.max(18 * scale, 10) + "px"
    const height = scale + "rem"

    return (
        <>
            <p style={{ fontSize: largeFont }} className={`text-${color} mb-0`}>
                {completionRate} %
            </p>
            <CProgress
                style={{ height }} className="progress"
                precision={1}
                color={color}
                value={completionRate}
            />
            <p style={{ fontSize: smallFont }} className={`text-${color} mt-2 mb-0`}>
                {label || t("welcomeModal.completed")}
            </p></>
    )
}

export default CompletionBar