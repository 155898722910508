import CIcon from "@coreui/icons-react";
import { CButton, CCollapse, CDataTable, CForm, CTooltip } from "@coreui/react";
import { upperFirst } from "lodash";
import { useTranslation } from "react-i18next";
import {
  BasicNumberInput,
  NestedTablesContainer,
  SaveButton,
  SquareButtonOutline
} from "src/components";
import { useTableDetails } from "src/hooks";
import { timestampToString } from "src/utils/common-utils/helpers";
import UnsavedAlert from "../StepWrapper/components/UnsavedAlert";
import AddNewFactor from "./components/AddNewFactor";
import { useConstants } from "./constants";
import controller from "./ConstantsTableController";
import { sentenceCase } from "./utils";

export const ConstantsTable = ({
  existingConstants,
  pathDB,
  arrayName,
  addedColumns = [],
  canAddFactors = false,
  inputStep = 0.001,
  ...rest
}) => {
  const {
    onFormSubmit,
    tableItems,
    changeHandler,
    addHandler,
    deleteCustomFactor,
    isFormTouched,
  } = controller({
    existingConstants,
    pathDB,
    arrayName,
  });

  const { t } = useTranslation();

  const { details, toggleDetails } = useTableDetails();

  const { CONSTANT_TABLE_COLUMNS } = useConstants();

  const tableColumns = [...addedColumns, ...CONSTANT_TABLE_COLUMNS];



  return (
    <CForm onSubmit={onFormSubmit} {...rest} className="position-relative">
      {canAddFactors && (
        <AddNewFactor
          onAdd={(item) => {
            addHandler(item, true);
            onFormSubmit();
          }}
        />
      )}
      <NestedTablesContainer>
        <CDataTable
          itemsPerPageSelect={{ label: t("shared.itemsPerPage") }}
          pagination
          columnFilter
          columnFilterSlot={{
            history: <span></span>,
            date: <span></span>,
          }}
          itemsPerPage={10}
          addTableClasses="upper"
          items={tableItems}
          fields={tableColumns}
          scopedSlots={{
            path4: (item, index) => {
              return (
                <td>
                  <span>{item.path4}</span>
                </td>
              );
            },
            path3: (item, index) => {
              return (
                <td>
                  <span>{upperFirst(item.path3)}</span>
                </td>
              );
            },
            path2: (item, index) => {
              return (
                <td>
                  <span>{upperFirst(item.path2)}</span>
                </td>
              );
            },
            path1: (item, index) => {
              return (
                <td>
                  <div className="d-flex align-items-center">
                    <span>{sentenceCase(item.path1)}</span>
                    {item.custom && (
                      <CTooltip
                        content={t(
                          "settings.deleteFactor",
                          "Delete custom factor"
                        )}
                      >
                        <CButton
                          onClick={() => deleteCustomFactor(item.path)}
                          size="sm"
                          className="ml-3"
                          variant="outline"
                          color="danger"
                        >
                          <CIcon name="cil-trash" />
                        </CButton>
                      </CTooltip>
                    )}
                  </div>
                </td>
              );
            },
            current: (item, index) => {
              return (
                <td>
                  <div style={{ minWidth: "100px" }}>
                    <BasicNumberInput
                      name="current"
                      inputStep={inputStep}
                      value={parseFloat(item.current) || 0}
                      onChange={(e) =>
                        changeHandler(index, "current", e.target.value)
                      }
                      decimalScale={6}
                    />
                  </div>
                </td>
              );
            },
            date: (item, index) => {
              return <td>{timestampToString(item.date)}</td>;
            },
            history: (item, index) => {
              return (
                <td>
                  <SquareButtonOutline
                    style={{ width: "65px" }}
                    onClick={() => {
                      toggleDetails(index);
                    }}
                  >
                    {details.includes(index)
                      ? t("shared.hide", "Hide")
                      : t("share.show", "Show")}
                  </SquareButtonOutline>
                </td>
              );
            },
            details: (constant, constIndex) => {
              return (
                <CCollapse
                  className="mb-0 pb-0"
                  show={details.includes(constIndex)}
                >
                  <CDataTable
                    addTableClasses="details lower"
                    items={constant.values}
                    fields={tableColumns}
                    scopedSlots={{
                      path1: (item, index) => {
                        return <td></td>;
                      },
                      path2: (item, index) => {
                        return <td></td>;
                      },
                      path3: (item, index) => {
                        return <td></td>;
                      },
                      path4: (item, index) => {
                        return <td></td>;
                      },
                      current: (item, index) => {
                        return (
                          <td>
                            <div
                              className="ml-auto ml-lg-0"
                              style={{ width: "100px" }}
                            >
                              <span style={{ paddingLeft: "12px" }}>
                                {item.value}
                              </span>
                            </div>
                          </td>
                        );
                      },
                      date: (item, index) => {
                        return <td>{timestampToString(item.date)}</td>;
                      },
                      history: (item, index) => {
                        return (
                          <td>
                            <div
                              style={{ width: "60px" }}
                              className=" d-flex justify-content-center"
                            >
                              <CTooltip
                                content={t(
                                  "settings.revertFactor",
                                  "Revert to this value"
                                )}
                              >
                                <CButton
                                  onClick={() => {
                                    changeHandler(
                                      constIndex,
                                      "current",
                                      item.value
                                    );
                                  }}
                                  variant="outline"
                                  color="success"
                                  className="w-100"
                                >
                                  <CIcon name="cil-action-undo" />
                                </CButton>
                              </CTooltip>
                            </div>
                          </td>
                        );
                      },
                    }}
                  />
                </CCollapse>
              );
            },
          }}
        />
      </NestedTablesContainer>
      <SaveButton className="mt-4" disabled={!isFormTouched} />
      {isFormTouched && <UnsavedAlert />}
    </CForm>
  );
};

export default ConstantsTable;
