import styled from "styled-components";
import { CModal } from "@coreui/react";

const StyledModal = styled(CModal)`
  header {
    border: none;
    font-weight: 600;
    font-size: 18px;
    position: relative;
    color: #000000;
  }

  select {
    &:hover,
    :focus {
      border: 0.5px solid var(--primary);
    }
  }

  button.close {
    position: absolute;
    right: 1rem;
    font-size: 36px;
    font-weight: 100;
  }
`;

export default StyledModal;
