import firebase from "firebase/compat/app";
import {
  adapator_createDatabaseRef,
  adaptor_deleteDocument,
  adaptor_deleteField,
  adaptor_setOrOverride,
  adaptor_updateFirebase,
  adaptor_updateOrSetPath,
} from "src/adapters";
import { useDatabasePaths } from "..";

const useUpdateDatabase = () => {
  const { getPath } = useDatabasePaths();

  const getRef = (...args) => {
    if (!args?.[0]) return;
    const inputsPath = getPath(...args);
    return adapator_createDatabaseRef(inputsPath);
  };

  const updateDocument = async (path, uploadObj) => {
    const dbRef = getRef(path);
    await adaptor_updateFirebase(dbRef, uploadObj);
  };

  const setOrUpdateDocument = async (path, uploadObj) => {
    const dbRef = getRef(path);
    await adaptor_updateOrSetPath(dbRef, uploadObj);
  };

  const overrideOrSetDocument = async (path, uploadObj) => {
    const dbRef = getRef(path);
    await adaptor_setOrOverride(dbRef, uploadObj);
  };

  const deleteDocument = async (path) => {
    const dbRef = getRef(path);
    await adaptor_deleteDocument(dbRef);
  };

  const deleteField = async (pathDB, fieldName, nestedPath) => {
    const ref = getRef(pathDB);
    await adaptor_deleteField({ ref, objectName: fieldName, path: nestedPath });
  };

  const addDocumentToCollection = async (path, uploadObj) => {
    const collectionRef = getRef(path);
    const newDoc = collectionRef.doc();
    await adaptor_updateOrSetPath(collectionRef.doc(newDoc.id), {
      ...uploadObj,
      id: newDoc.id,
    });
    return newDoc.id;
  };

  const addToArray = async (path, arrayName, newEntry) => {
    const ref = getRef(path);

    await adaptor_updateFirebase(ref, {
      [arrayName]: firebase.firestore.FieldValue.arrayUnion(newEntry),
    });
  };

  return {
    updateDocument,
    setOrUpdateDocument,
    overrideOrSetDocument,
    deleteDocument,
    addDocumentToCollection,
    getRef,
    deleteField,
    addToArray,
  };
};

export default useUpdateDatabase;
