import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { DB_PATHS } from "src/constants";
import { useBasicForm, useTable, useUpdateDatabase } from "src/hooks";
import { checkIsFormTouched } from "src/utils/common-utils";
import { convertArrayEntryToDB, convertFromDbToArray } from "./utils";

const EMPTY_ITEM = {
  path1: "",
  current: 0,
  path2: "",
  isNew: true,
  values: [],
};

const ConstantsTableController = ({
  existingConstants,
  pathDB,
  arrayName = "constants",
}) => {
  const { setOrUpdateDocument, deleteField } = useUpdateDatabase();

  const writeToDB = async (values, touchedFields) => {
    const newConstants = cloneDeep(existingConstants);

    const constantsArr = values.constants || [];

    constantsArr.forEach(async (entry, index) => {
      if (!touchedFields[index] && !entry.isNew) return;
      convertArrayEntryToDB(entry, newConstants);
    });

    await setOrUpdateDocument(pathDB, { [arrayName]: newConstants });
  };

  const { formMethods, onFormSubmit } = useBasicForm({ writeToDB });

  const {
    setValue,
    formState: { touchedFields },
  } = formMethods;

  const createUpperTableValues = (constantsObj) => {
    if (!constantsObj) return [];

    const arr = convertFromDbToArray(constantsObj).sort(
      (a, b) => a.index - b.index
    );
    return arr;
  };

  const initValues = useMemo(
    () => createUpperTableValues(existingConstants),
    [existingConstants]
  );
  const { tableItems, changeHandler, addHandler } = useTable({
    name: "constants",
    initValues,
    formMethods,
    emptyItem: EMPTY_ITEM,
  });

  const onChange = (index, fieldName, fieldValue) => {
    touchedFields[index] = true;
    changeHandler(index, fieldName, fieldValue);
  };

  const deleteCustomFactor = async (path) => {
    await deleteField(DB_PATHS.emissionFactors, arrayName, path);
  };

  const isFormTouched = checkIsFormTouched(touchedFields);

  return {
    onFormSubmit,
    tableItems,
    changeHandler: onChange,
    deleteCustomFactor,
    addHandler,
    isFormTouched,
    setValue,
  };
};

export default ConstantsTableController;
