import styled from "styled-components";
import { CSidebar } from "@coreui/react";

const StyledSidebar = styled(CSidebar)`
  /* font-size: 16px; */

  .c-sidebar-nav-item:hover {
    background-color: gainsboro;
  }

  .c-sidebar-nav-dropdown-toggle {
    font-size: 80%;
    font-weight: 700;
    text-transform: uppercase;
  }

  /* scrollbar */

  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0.45rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(27, 27, 27, 0.4);
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export default StyledSidebar;
