import CIcon from "@coreui/icons-react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { storage } from "src/adapters/common-set-up/firebase";
import { BasicInput, ButtonRound } from "src/components";
import { useToasts } from "src/hooks";

const UploadPDF = ({ supplierID, orgId, isDemo }) => {
  const { t } = useTranslation();

  const fileInputRef = useRef();

  const { tryAndNotify } = useToasts();

  const [hasPolicy, setHasPolicy] = useState(false);

  const storageRef = storage.ref();

  const checkHasPolicy = () => {
    const policyRef = storageRef.child(`policies/${orgId}/${supplierID}`);
    policyRef.listAll().then((res) => {
      if (res.items?.length) {
        setHasPolicy(true);
      }
    });
  };

  useEffect(() => {
    checkHasPolicy();
  }, []);

  const uploadFile = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const policyRef = storageRef.child(
      `policies/${orgId}/${supplierID}/${file.name}`
    );

    tryAndNotify(
      async () => policyRef.put(file),
      t("supplierSurvey.uploadSuccess"),
      t("supplierSurvey.uploadError")
    );
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-3">
      <ButtonRound
        className="mx-auto"
        size="lg"
        onClick={() => {
          fileInputRef.current.click();
        }}
        disabled={isDemo}
      >
        <BasicInput
          innerRef={fileInputRef}
          type="file"
          accept=".pdf"
          style={{ display: "none" }}
          onChange={uploadFile}
        />
        <CIcon name="cilCloudUpload" className="mr-2" />
        {t("supplierSurvey.uploadPDF")}
      </ButtonRound>
      {hasPolicy && (
        <small className="text-info mt-2">
          {t("supplierSurvey.policyUploaded")}
        </small>
      )}
    </div>
  );
};

export default UploadPDF;
