import { useSelector } from "react-redux";
import { SECTOR_KEYS } from "src/constants/sectorKeys";
import { useSectors } from "src/hooks";
import {
  selectCoreData,
  selectHasPurchases,
  selectIsFreemium,
  selectSuppliers,
} from "src/store/projectSelectors";
import useSectionSettings from "../useSectionSettings/useSectionSettings";

const useSectionOpen = () => {
  const coreData = useSelector(selectCoreData);
  const { SECTORS } = useSectors();

  const hasPurchases = useSelector(selectHasPurchases);
  const selectedSuppliers = useSelector(selectSuppliers)?.filter(
    (supplier) => supplier.selected
  );

  const { sectionSettings: settings } = useSectionSettings();

  const isFreemium = useSelector(selectIsFreemium);

  const calculateCoreCompl = () => {
    const fields = SECTORS.find(
      (item) => item.name === SECTOR_KEYS.coreData
    ).fieldsToCalculate;

    let completedFields = 0;
    fields.forEach((field) => {
      if (coreData?.[field] !== undefined) {
        completedFields++;
      }
    });
    return completedFields / fields.length;
  };

  const coreDataIsComplete = calculateCoreCompl() === 1;

  const checkIsHidden = (sectionName) => settings?.[sectionName]?.hidden;

  const checkIsOpen = (sectionName) => {
    const isLocked = settings?.[sectionName]?.open === false;
    const isHidden = settings?.[sectionName]?.hidden;

    const noPurchases =
      sectionName === SECTOR_KEYS.suppliersData && !hasPurchases;
    const noSuppliers =
      sectionName === SECTOR_KEYS.suppliersData && !selectedSuppliers?.length;

    const isNotAvailableInFreemium =
      isFreemium &&
      ![
        SECTOR_KEYS.coreData,
        SECTOR_KEYS.siteData,
        SECTOR_KEYS.fleetData,
        SECTOR_KEYS.forecastingData,
      ].includes(sectionName);

    const isOpen =
      sectionName === SECTOR_KEYS.coreData ||
      (coreDataIsComplete &&
        !isLocked &&
        !isHidden &&
        !noPurchases &&
        !noSuppliers &&
        !isNotAvailableInFreemium);

    return {
      coreDataIsComplete,
      isLocked,
      isOpen,
      isHidden,
      noPurchases,
      noSuppliers,
      isNotAvailableInFreemium,
    };
  };

  return {
    checkIsOpen,
    checkIsHidden,
  };
};

export default useSectionOpen;
