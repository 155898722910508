export const siteDataIntroSteps = [
  {
    key: "welcome",
    target: ".site-intro-welcome",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    spotlightClicks: false,
    placement: "center",
  },
  {
    key: "site_card",
    target: ".intro-progress-bar",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "fill_button",
    target: ".site-intro-fill-btn",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "delete_button",
    target: ".site-intro-delete-btn",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "add_button",
    target: ".site-intro-add-btn",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];
