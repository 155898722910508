import { useTranslation } from "react-i18next";
import constants from "src/i18n/en/arrays.json";

const useSurveyConstants = () => {
  const { t } = useTranslation();

  //employeeSurvey

  const arrays = constants.arrays;

  const homeEnergy = arrays.homeEnergy.map((value, index) => {
    return { value, label: t(`arrays.homeEnergy.${index}`) };
  });

  const greenTariff = arrays.greenTariff.map((value, index) => {
    return { value, label: t(`arrays.greenTariff.${index}`) };
  });

  const emissionImportance = arrays.emissionImportance.map((value, index) => {
    return { value, label: t(`arrays.emissionImportance.${index}`) };
  });

  const pensionScheme = arrays.pensionScheme.map((value, index) => {
    return { value, label: t(`arrays.pensionScheme.${index}`) };
  });

  const concernLevel = arrays.concernLevel.map((value, index) => {
    return { value, label: t(`arrays.concernLevel.${index}`) };
  });

  const initatives = arrays.initiatives.map((value, index) => {
    return { value, label: t(`arrays.initiatives.${index}`) };
  });

  // supplierSurvey
  const suppliedServices = arrays.suppliedServices.map((value, index) => {
    return { value, label: t(`arrays.suppliedServices.${index}`) };
  });

  const commutePatterns = arrays.commutePatterns.map((value, index) => {
    return { value, label: t(`arrays.commutePatterns.${index}`) };
  });

  const flightPatterns = arrays.flightPatterns.map((value, index) => {
    return { value, label: t(`arrays.flightPatterns.${index}`) };
  });

  const usageIT = arrays.usageIT.map((value, index) => {
    return { value, label: t(`arrays.usageIT.${index}`) };
  });

  return {
    homeEnergy,
    greenTariff,
    pensionScheme,
    emissionImportance,
    concernLevel,
    initatives,
    suppliedServices,
    commutePatterns,
    flightPatterns,
    usageIT,
  };
};

export default useSurveyConstants;
