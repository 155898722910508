import Select from "react-select";
import styled, { css } from "styled-components";
import placeholderStyle from "../Placeholder/Placeholder.style";

export const reactSelectStyles = css`
  border-bottom: 0.1px solid hsla(0, 0%, 20%, 0.5);
  font-weight: 500;

  .react-select__control {
    background-color: transparent !important;
    border-radius: 0;
    border: none;
    /* border-bottom:  0.1px solid hsla(0, 0%, 20%, 0.1); */
    border-color: black;
    box-shadow: none;
  }

  .react-select__placeholder {
    ${placeholderStyle}
  }

  .react-select__menu {
    border-radius: 0;
    background-color: #f5f5f5;
  }

  .react-select__multi-value {
    background-color: #181743;
    color: white;
  }

  .react-select__multi-value__label {
    color: white;
  }

  .react-select__option:hover {
    background-color: #e8e8e8;
  }

  .react-select__option--is-focused {
    background-color: #e8e8e8;
  }

  .react-select__option--is-selected {
    background-color: #808080;
  }

  .react-select__option--is-selected:hover {
    background-color: #909090;
  }

  .react-select__indicator {
    color: var(--color-secondary);
  }

  .c-dark-theme & {
    border: 1px solid #ffffff;
    color: white;

    .react-select__single-value {
      color: #ffffff;
    }

    .react-select__input {
      color: #ffffff !important;
    }
  }
`;

const StyledReactSelect = styled(Select)`
  ${reactSelectStyles}
`;
export default StyledReactSelect;
