import styled from "styled-components";

const StyledTableContainer = styled.div`
  th {
    color: black;
    border: transparent;
    font-size: 16px;
  }
  thead {
    border: transparent;
  }

  td {
    color: black;
  }

  .c-dark-theme & {
    th {
      color: white !important;
    }
  }
`;

export default StyledTableContainer;
