import { useTranslation } from "react-i18next";

export const useConstants = () => {
  const { t } = useTranslation();

  const CONSTANT_TABLE_COLUMNS = [
    {
      label: t("shared.value", "Value"),
      key: "current",
      _style: { width: "20%" },
    },
    {
      label: t("shared.dateSet", "Date set"),
      key: "date",
      _style: { width: "10%" },
    },
    {
      label: t("shared.history", "History"),
      key: "history",
      _style: { width: "10%" },
    },
  ];

  return { CONSTANT_TABLE_COLUMNS };
};
