import { StyledCreatable } from "src/components";

const MaterialsCreatable = ({
  value,
  onChange,
  name,
  defaultValue,
  category,
  idx,
  options = [],
  ...rest
}) => {
  if (!category) return null;

  return (
    <StyledCreatable
      backspaceRemovesValue
      isClearable
      isSearchable
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      classNamePrefix="react-select"
      placeholder={"Please select..."}
      options={options.filter((m) => m.category === category)}
      value={{
        label: options.find((m) => m.value === value)?.label || value,
        value: value,
      }}
      onChange={(e) => {
        onChange({ target: { name, value: e?.value || null } });
      }}
      defaultValue={{ label: defaultValue, value: defaultValue }}
      name={name}
      {...rest}
    />
  );
};

export default MaterialsCreatable;
