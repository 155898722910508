import { useSelector } from "react-redux";
import { selectCoreData, selectIsFreemium } from "src/store/projectSelectors";

import { useSectors, useSectionOpen } from "src/hooks";

const useNextSector = () => {
  const { SECTORS } = useSectors();
  const isFree = useSelector(selectIsFreemium);
  const { checkIsOpen } = useSectionOpen();
  const coreData = useSelector(selectCoreData);
  const hasNoLocations = coreData?.noLocations;

  const findNextSector = (location) => {
    const currentPath = location.pathname;
    const pathSegments = currentPath.split("/");
    const currentSector = pathSegments[pathSegments.length - 1];

    let sectors = SECTORS.filter(
      (sector) => checkIsOpen(sector.name).isOpen
    ).map((sector) => sector.path);
    if (isFree) {
      sectors = ["coreData", "siteData", "forecastingData", "fleetData"];
    }

    let nextSector = sectors[sectors.indexOf(currentSector) + 1];
    if (currentSector === "coreData" && hasNoLocations) {
      nextSector = sectors[2];
    }
    const nextPath = currentPath.replace(currentSector, nextSector || "");

    return { nextSector, nextPath };
  };

  return { findNextSector };
};

export default useNextSector;
