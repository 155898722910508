import styled from "styled-components";
import BasicInput from "../BasicInput/BasicInput.style";

export const RangeWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .range-value {
    position: absolute;
    top: -25px;
    margin-right: 15px;
    width: 50px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background-color: var(--secondary);
    color: #ffffff;
    font-size: 1rem;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 6px;
  }
`;

export const StyledInput = styled(BasicInput)`
  padding: 0;
  align-self: center;
  margin-inline: 0.25rem;

  appearance: none;
  width: 100%;
  height: 1px;
  opacity: 0.8;
  transition: opacity 0.2s;
  -webkit-transition: 0.2s;
  -webkit-appearance: none;
  .c-dark-theme & {
    border-color: #ffffff;
  }

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ color }) => color || "green"};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ color }) => color || "green"};
    cursor: pointer;
  }
`;
