import styled from "styled-components";
import BaseContainer from "../BaseContainer/BaseContainer.style";

const StyledBaseContainer = styled(BaseContainer)`
  justify-content: space-between;
  margin-block: auto;

  p {
    margin-bottom: 1.5rem;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: black;
    background-color: black;
  }

  .nav-row span {
    font-size: 14px;
  }

  td {
    appearance: none;
  }

  @media screen and (max-width: 576px) {

    .summary-btn {
      margin-left: 20px;
    }
    .nav-row {
      padding: 20px;
    }
  }
`;

export default StyledBaseContainer;
