import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
} from "@coreui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import zeroBeesLogo from "../../assets/images/ZeroBees_logo_new.svg";

import { usePermissions } from "src/hooks";
import { set } from "src/store/coreuiSlice";
import navController from "../controllers/sidebarNavController";
import AdminToolsDropdown from "./components/AdminToolsDropdown";
import CollaborationToolsMenu from "./components/CollaborationToolsMenu";
import DataCollectionDropdown from "./components/DataCollectionDropdown";
import { useTranslation } from "react-i18next";
import ResultsDropdown from "./components/ResultsDropdown";
import GlobalSettingsDropdown from "./components/GlobalSettingsDropdown";
import { selectOrgData } from "src/store/projectSelectors";

const TheSidebar = () => {
  const dispatch = useDispatch();

  const [showDataCollection, setShowDataCollection] = useState(true);
  const [showAdminTools, setShowAdminTools] = useState(true);
  const [showResults, setShowResults] = useState(true);
  const [showGlobal, setShowGlobal] = useState(false);

  const show = useSelector((state) => state.coreui.sidebarShow);

  const { isSuperAdmin } = usePermissions();

  const orgData = useSelector(selectOrgData)

  const { hideResults } = orgData

  const { _DataCollectionNav } = navController();

  const { t } = useTranslation();

  const dropdownOnClick = (e) => {
    if (e.target.className !== "c-sidebar-nav-dropdown-toggle") return;

    const innerText = e.target.innerText;

    switch (innerText) {
      case t("sidebar.dataCollection", "Data Collection"):
        setShowDataCollection((prev) => !prev);
        break;

      case t("sidebar.adminTools", "Admin Tools"):
        setShowAdminTools((prev) => !prev);
        break;

      case t("routes.globalSettings"):
        setShowGlobal((prev) => !prev);
        break;
      default:
        setShowResults((prev) => !prev);

        break;
    }
  };

  return (
    <>
      <CSidebar
        show={show}
        onShowChange={(val) => dispatch(set({ sidebarShow: val }))}
      >
        <CSidebarBrand className="d-md-down-none" to="/">
          <img src={zeroBeesLogo} alt="ZeroBees Logo" />
        </CSidebarBrand>
        <CSidebarNav>
          {isSuperAdmin && (
            <>
              <GlobalSettingsDropdown
                show={showGlobal}
                toggle={dropdownOnClick}
              />

              <AdminToolsDropdown
                show={showAdminTools}
                toggle={dropdownOnClick}
              />
            </>
          )}

          <div className="sidebar-intro-data-collection">
            {/* <CSidebarNavDivider /> */}

            <DataCollectionDropdown
              show={showDataCollection}
              toggle={dropdownOnClick}
              item={_DataCollectionNav}
            />
          </div>
          <div className="sidebar-intro-summary ">
            {/* <CSidebarNavDivider /> */}
            {!hideResults && <ResultsDropdown show={showResults} toggle={dropdownOnClick} />}
            <CSidebarNavDivider />
          </div>
          <CollaborationToolsMenu className="sidebar-intro-collaboration-tools" />
          <CSidebarNavDivider />
        </CSidebarNav>
      </CSidebar>
    </>
  );
};

export default React.memo(TheSidebar);
