import { CButton } from "@coreui/react";
import styled from "styled-components";

const StyledButton = styled(CButton)`
  color:black ;
  
  .c-dark-theme & {
    color: white;
  }
`;

export default StyledButton;
