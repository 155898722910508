import CIcon from "@coreui/icons-react";
import { useEffect, useRef, useState } from "react";
import { storage } from "src/adapters/common-set-up/firebase";
import {
  BasicInput,
  ButtonRound,
  DeleteButton,
  TextButton,
} from "src/components";
import { useToasts } from "src/hooks";

const UploadFileToStorage = ({ storagePath, label, acceptFormats }) => {
  const fileInputRef = useRef();

  const { showToast } = useToasts();

  const [fileRefs, setFileRefs] = useState([]);

  const storageRef = storage.ref();

  const checkHasFiles = async (storagePath) => {
    const fileStorageRef = storage.ref().child(storagePath);
    const res = await fileStorageRef.listAll();
    if (res.items?.length) {
      const fileRefs = [];
      for (let item of res.items) {
        const url = await item.getDownloadURL();
        const fileRef = storage.refFromURL(url);
        fileRefs.push(fileRef);
      }
      setFileRefs(fileRefs);
    }
  };

  useEffect(() => {
    if (!storagePath) return;
    checkHasFiles(storagePath);
  }, [storagePath]);

  const [uploading, setUploading] = useState(false);

  const uploadFile = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const fileStorageRef = storageRef.child(`${storagePath}/${file.name}`);
    setUploading(true);
    try {
      await fileStorageRef.put(file);
      checkHasFiles(storagePath);
      showToast({
        type: "success",
        text: "File uploaded successfully",
      });
    } catch (err) {
      console.log(err);
      showToast({
        type: "error",
        text: "Error uploading file",
      });
    } finally {
      setUploading(false);
    }
  };

  const downloadFile = async (fileRef) => {
    if (!fileRef) return;
    try {
      const url = await fileRef.getDownloadURL();
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: fileRef.name,
      });

      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = async (event) => {
        var blob = xhr.response;

        const writable = await fileHandle.createWritable();
        await writable.write(blob);
        await writable.close();
      };
      xhr.open("GET", url);
      xhr.send();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteFile = async (fileRef) => {
    if (!fileRef) return;
    try {
      await fileRef.delete();
      setFileRefs((fileRefs) => fileRefs.filter((f) => f.name !== fileRef.name));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-3">
      <ButtonRound
        className="mx-auto"
        size="lg"
        onClick={() => {
          fileInputRef.current.click();
        }}
        disabled={uploading}
      >
        <BasicInput
          innerRef={fileInputRef}
          type="file"
          accept={acceptFormats}
          style={{ display: "none" }}
          onChange={uploadFile}
        />
        <CIcon name="cilCloudUpload" className="mr-2" />
        {!uploading ? label : "Uploading..."}
      </ButtonRound>
      <div className="d-flex flex-column align-items-start mt-2">
        {fileRefs.map((fileRef, idx) => (
          <small key={idx} className="text-info mt-2">
            File:{" "}
            <TextButton
              type="button"
              className={"text-info font-bold"}
              onClick={() => downloadFile(fileRef)}
            >
              {fileRef.name}
            </TextButton>
            <DeleteButton
              size="sm"
              style={{ border: "none" }}
              className="ml-1 p-1"
              onClick={() => deleteFile(fileRef)}
            />
          </small>
        ))}
      </div>
    </div>
  );
};

export default UploadFileToStorage;
