import React, { useEffect, useState } from "react";
import { CiLock } from "react-icons/ci";
import { CiUnlock } from "react-icons/ci";
import { useUpdateDatabase } from "src/hooks";
import { DB_PATHS } from "src/constants";
import { CSwitch } from "@coreui/react";

const LockSwitch = ({ item }) => {
  const [locked, setLocked] = useState(!!item.locked);
  const { updateDocument } = useUpdateDatabase();

  useEffect(() => {
    setLocked(!!item.locked);
  }, [item]);


  return (
    <div className="d-flex justify-content-start align-items-center mb-1">
      <span className={`mr-2 ${!locked && "text-secondary"}`}>
        <CiLock size={28} />
      </span>
      <CSwitch
        color="dark"
        variant="3d"
        size="sm"
        checked={!locked}
        onChange={() => {
          const newVal = !locked;
          setLocked((prev) => !prev);
          updateDocument([DB_PATHS.periods, item.id], { locked: newVal });
        }}
      />
      <span className={`ml-2 ${locked && "text-secondary"}`}>
        <CiUnlock size={28} />
      </span>
    </div>
  );
};

export default LockSwitch;
