import { useTranslation } from "react-i18next";
import StyledSelect from "./BasicSelect.style";

const BasicSelect = ({
  name,
  defaultValue,
  value,
  onChange,
  options,
  className,
  disabled = false,
  placeholder,
  showNone = true,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <StyledSelect
      className={className}
      type="text"
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
      {...rest}
    >
      {showNone && (
        <option value="" hidden>
          {placeholder || t("shared.none", "none")}
        </option>
      )}
      {options.map((item, i) => {
        const value = item.value ?? item;
        return (
          <option value={value} key={i}>
            {item.label ?? item}
          </option>
        );
      })}
    </StyledSelect>
  );
};

export default BasicSelect;
