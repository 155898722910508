import React from "react";
import StyledButton from "./ButtonGhost.style";

const ButtonGhost = ({ children, ...rest }) => {
  return (
    <StyledButton variant="ghost" color="primary" {...rest}>
      {children}
    </StyledButton>
  );
};

export default ButtonGhost;
