import { CButton } from "@coreui/react";
import { kebabCase } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BasicNumberInput } from "src/components";
import BasicInput from "src/components/BasicInput/BasicInput.style";

const EMPTY_ITEM = {
  path1: "",
  current: 0,
  path2: "Custom",
  values: [],
  isNew: true,
};

const AddNewFactor = ({ className, onAdd }) => {
  const [name, setName] = useState("");

  const [value, setValue] = useState();

  const { t } = useTranslation();

  return (
    <div
      className={`d-flex  align-self-start justify-content-between p-2 align-items-center border bg-light rounded ${className}`}
    >
      <span className="mr-1 " style={{ width: "300px" }}>
        Add new factor:
      </span>
      <BasicInput
        className=" mr-3 mb-0"
        placeholder={t("shared.enterName")}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <BasicNumberInput
        className=" mr-3"
        inputStep={0.001}
        value={parseFloat(value) || 0}
        decimalScale={6}
        onChange={(e) => setValue(e.target.value)}
      />
      <CButton
        className="ml-3"
        disabled={!name || !value}
        color="success"
        variant="outline"
        onClick={() => {
          const item = { ...EMPTY_ITEM };
          item.path1 = kebabCase(name);
          item.current = value;
          onAdd(item);
          setName("");
          setValue(0);
        }}
      >
        {t("shared.add", "Add")}
      </CButton>
    </div>
  );
};

export default AddNewFactor;
