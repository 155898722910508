import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DemoWarning,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  RadioWithOther,
  VerticalRadioButtons,
} from "src/components";
import { useSurveyConstants } from "src/hooks";
import { Subtitle } from "../components";
import OptionalQuestion from "../components/OptionalQuestion";
import OptionalQuestionNumber from "../components/OptionalQuestionNumber";

const EmployeeSurvey8 = ({
  data: values = {},
  onChange,
  info: { orgName },
  isDemo,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { emissionImportance, pensionScheme, concernLevel } =
    useSurveyConstants();

  const { t } = useTranslation();

  return (
    <FormStepWrapper
      aboveCardSlot={
        <>
          {isDemo && <DemoWarning />}

          <Subtitle content={"Views on climate change"} />
        </>
      }
    >
      <OptionalQuestion name="q19">
        <QuestionWrapper>
          <h3>
            <OptionalQuestionNumber questionNumber={19} />.{" "}
            {t(
              "employeeSurvey.q_views",
              "What are your views on climate change?"
            )}
          </h3>
          <VerticalRadioButtons
            name="climate-views"
            onChange={onChange}
            defaultValue={values["climate-views"]}
            data={concernLevel}
          />
          <ErrorText value={errors["climate-views"]} />
        </QuestionWrapper>
      </OptionalQuestion>

      <OptionalQuestion name="q20">
        <QuestionWrapper>
          <h3>
            <OptionalQuestionNumber questionNumber={20} />.{" "}
            {t(
              "employeeSurvey.q_impact",
            )}
          </h3>
          <VerticalRadioButtons
            name="impact-importance"
            defaultValue={values["impact-importance"]}
            onChange={onChange}
            data={emissionImportance}
          />
          <ErrorText value={errors["impact-importance"]} />
        </QuestionWrapper>
      </OptionalQuestion>

      <OptionalQuestion name="q21">
        <QuestionWrapper>
          <h3>
            <OptionalQuestionNumber questionNumber={21} />.{" "}
            {t(
              "employeeSurvey.q_awarness",
              { orgName }
            )}
          </h3>
          <RadioWithOther
            name="impact-aware"
            answers={pensionScheme}
            onChange={onChange}
            values={values}
          />
          <ErrorText value={errors["impact-aware"]} />
        </QuestionWrapper>
      </OptionalQuestion>
    </FormStepWrapper>
  );
};

export default EmployeeSurvey8;
