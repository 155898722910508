import { useTranslation } from "react-i18next";

const useRecommendationTabs = () => {
  const { t } = useTranslation();
  const keys = [
    "locations",
    "home",
    "commute",
    "fleet",
    "travel",
    "investments",
    "purchases",
    "dentistry",
  ];

  const RECOMMENDATIONS_TABS = keys.map((tab) => {
    return { name: tab, label: t(`adminRecommendations.tabs.${tab}`) };
  });

  return { RECOMMENDATIONS_TABS };
};

export default useRecommendationTabs;
