import { convertStrToBool } from "./common-utils";
import { emailRegex, urlRegex } from "./common-utils/regex";
import { t } from "i18next";

export const isRequired = {
  validate: {
    isRequired: (v) => {
      if (!v) return t("validation.enterAnswer", "Please enter answer");
      return true;
    },
  },
};

export const isEmail = {
  pattern: {
    value: emailRegex,
    message: t("validation.enterEmail", "Please enter valid email"),
  },
};

export const isUrl = {
  pattern: {
    value: urlRegex,
    message: t("validation.enterUrl", "Please enter valid url"),
  },
};

export const isRequiredUrl = {
  validate: {
    isUrl: (v) => {
      if (!v) return t("validation.enterAnswer", "Please enter answer");
      if (!urlRegex.test(v))
        return t("validation.enterUrl", "Please enter valid url");
      return true;
    },
  },

};


export const isBetween = (min, max, customMsg = "") => {
  return {
    valueAsNumber: true,
    validate: {
      isBetween: (v) => {
        if (!v) return true;
        if (v < min || v > max)
          return customMsg || t("validation.numberBetween", { min, max });
        return true;
      },
    },
  };
};

export const isPastDate = {
  validate: {
    isPastDate: (date) => {
      if (Date.parse(date) - Date.now() > 0) {
        return t("validation.enterPastDate");
      }
      return true;
    },
  },
};

export const isNonNegativeNum = {
  valueAsNumber: true,
  validate: {
    isPositive: (v) => {
      if (v === undefined) return true;
      if (!isNaN(v) && v < 0) return t("validation.enterValid");
      return true;
    },
  },
};

export const isRequiredNum = {
  valueAsNumber: true,
  min: { value: 0, message: t("validation.enterValid") },
  validate: {
    required: (v) =>
      (v !== undefined && !isNaN(v)) || t("validation.enterValue"),
  },
};

export const isRequiredBetween = (min, max, customMsg = "") => {
  return {
    valueAsNumber: true,
    validate: {
      required: (v) =>
        (v !== undefined && !isNaN(v)) ||
        t("validation.numberBetween", { min, max }),
      isBetween: (v) => {
        if (v < min || v > max)
          return customMsg || t("validation.numberBetween", { min, max });
        return true;
      },
    },
  };
};

export const isBool = {
  setValueAs: (v) => convertStrToBool(v),
};

export const isRequiredBool = {
  setValueAs: (v) => convertStrToBool(v),
  validate: {
    required: (v) =>
      v !== undefined || t("validation.enterAnswer", "Please enter answer"),
  },
};

export const isNotEmptyArray = {
  validate: {
    notEmpty: (v) =>
      !!v?.length || t("validation.enterAnswer", "Please enter answer"),
  },
};

export const isConditionallyRequiredStr = (getValues, dependsOn) => {
  return {
    validate: {
      isRequired: (v) => {
        if (!getValues(dependsOn)) return true;
        if (v === undefined || v === "") return t("validation.enterValue");
        return true;
      },
    },
  };
};

export const isConditionallyRequiredSelect = (getValues, dependsOn) => {
  return {
    validate: {
      isRequired: (v) => {
        if (!getValues(dependsOn)) return true;
        if (v === undefined || v === "") return "Please select option";
        return true;
      },
    },
  };
};

export const isConditionallyRequiredBool = (getValues, dependsOn) => {
  return {
    setValueAs: (v) => convertStrToBool(v),
    validate: {
      isRequired: (v) => {
        if (!getValues(dependsOn)) return true;
        if (v === undefined) return t("validation.enterValue");
        return true;
      },
    },
  };
};

export const isConditionallyRequiredNum = (getValues, dependsOn) => {
  return {
    valueAsNumber: true,
    validate: {
      isRequired: (v) => {
        if (!getValues(dependsOn)) return true;
        if (v === undefined || isNaN(v)) return t("validation.enterValue");
        if (v < 0) return t("validation.enterValid");
        return true;
      },
    },
  };
};

export const isNumWorkingDays = {
  setValueAs: (v) => (!!v ? parseInt(v) : 0),
  min: { value: 0, message: t("validation.numberBetween", { min: 0, max: 5 }) },
  max: { value: 5, message: t("validation.numberBetween", { min: 0, max: 5 }) },
};
