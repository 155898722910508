import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setAlertWithTimeout } from "src/store/operations";
import { useSteps } from "react-step-builder";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { checkIsFormTouched } from "src/utils/common-utils";

/**
 *  A custom hook for handling forms based on React Form Hooks and React Steps.
 *
 * @param {Function} writeToDB - A function which receives form values and is responsible from writing them to database
 * @param {Function} validateFields - A function which receives trigger (Function) from React Form Hooks as argument for validation
 * @param {Object} messages - custom messages on submission success and error
 * @param {Boolean} showMessages - A flag indicating whether to show toas notifications on submission
 * @param {Boolean} canAdvanceEmpty - If true, form can advance without validating the fields
 *
 *
 * @return {{formMethods:object,
 * onFormFieldChange:Function,
 * onFormSubmit:Function,
 * nextClickHandler:Function,
 * prevClickHandler:Function}} An object with the following:
 *
 * - `formMethods (Object)` - form methods from useForm hook
 * - `onFormFieldChange (Function)` - change handler
 * - `onFormSubmit (Function)` - submission handler
 * - `nextClickHandler (Function)` - next handler
 * - `prevClickHandler (Function)` - prev handler
 *
 *
 */

const useBasicForm = ({
  writeToDB,
  validateFields = async (trigger) => {
    return await trigger();
  },
  messages = {},
  canAdvanceEmpty = true,
  showMessages = true,
}) => {
  const formMethods = useForm();
  const { next, prev, hasPrev, hasNext } = useSteps();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const {
    setValue,
    getValues,
    formState: { touchedFields },
    trigger,
    clearErrors,
  } = formMethods;

  const onFormFieldChange = (e) => {
    if (!e?.target) return;
    setValue(e.target.name, e.target.value, { shouldTouch: true });
  };

  const onFormSubmit = async (e) => {
    e?.preventDefault();

    const isTouched = areFieldsTouched();

    const _touchedFields = cloneDeep(touchedFields);

    const currentFormValues = cloneDeep(getValues());

    // console.log("currentFormValues", currentFormValues);

    if (canAdvanceEmpty) {
      if (!isTouched) return true;
      const areFieldsValid = await validateFields(trigger, currentFormValues);
      if (!areFieldsValid) return false;
    } else {
      const areFieldsValid = await validateFields(trigger, currentFormValues);
      if (!areFieldsValid) return false;
      if (!isTouched) return true;
    }
    resetTouchedFields();

    try {
      await writeToDB(currentFormValues, _touchedFields);

      if (showMessages) {
        dispatch(
          setAlertWithTimeout({
            type: "success",
            text:
              messages.success ||
              t(
                "notifications.excelSuccess",
                "Data has been updated successfully!"
              ),
          })
        );
      }

      return true;
    } catch (err) {
      console.error(err);

      if (showMessages) {
        dispatch(
          setAlertWithTimeout({
            type: "error",
            text:
              messages.error ||
              err.message ||
              t(
                "errors.excelError",
                "Something went wrong. Data has not been updated!"
              ),
          })
        );
      }

      return false;
    } finally {
      clearErrors();
    }
  };

  const areFieldsTouched = () => {
    return checkIsFormTouched(touchedFields);
  };

  const resetTouchedFields = () => {
    for (let key in touchedFields) {
      delete touchedFields[key];
    }
  };

  const nextClickHandler = async (e) => {
    const noErrors = await onFormSubmit(e);
    if (noErrors && hasNext) {
      next(e);
    }
  };

  const prevClickHandler = async (e) => {
    const noErrors = await onFormSubmit(e);
    if (noErrors && hasPrev) {
      prev(e);
    }
  };

  const isFormTouched = areFieldsTouched();

  return {
    formMethods,
    onFormFieldChange,
    onFormSubmit,
    nextClickHandler,
    prevClickHandler,
    isFormTouched,
  };
};

export default useBasicForm;
