import { CButton, CFormGroup, CInput, CLabel } from "@coreui/react";
import { useSelector } from "react-redux";
import { selectUserData } from "src/store/userSlice";
import { BasicInput } from "..";

import { getAuth, sendEmailVerification } from "firebase/auth";
import { phone } from "phone";
import { useEffect, useState } from "react";
import { useFirebaseApp } from "reactfire";
import { useToasts } from "src/hooks";
import useMfa from "src/hooks/useMfa";
import { useMediaQuery } from "usehooks-ts";

function validatePhoneNumber(phoneNumber) {
  return phone(phoneNumber).isValid;
}

function convertPhoneNumber(phoneNumber) {
  return phone(phoneNumber).phoneNumber;
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const Set2fa = ({ onClose }) => {
  let app = useFirebaseApp();
  const auth = getAuth(app);
  const { emailVerified } = useSelector(selectUserData) || {};

  const [phoneNumber, setPhoneNumber] = useState("");
  const [is2faSet, setIs2faSet] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const { showToast } = useToasts();

  useEffect(() => {
    if (!auth?.currentUser) return;
    const phoneNum = auth.currentUser?.reloadUserInfo?.mfaInfo?.[0]?.phoneInfo;
    if (phoneNum) {
      setPhoneNumber(phoneNum);
      setIs2faSet(true);
    }
  }, [auth]);

  const verifyEmail = async () => {
    try {
      setLoading(true);
      await sendEmailVerification(auth.currentUser);
      showToast({
        text: "Verification email sent! Please check your inbox",
        type: "success",
      });
      onClose();
    } catch (err) {
      console.log(err);
      showToast({
        text: "Failed to send verification email",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const {
    startEnrollMultiFactor,
    finishEnrollMultiFactor,
    error,
    unEnrollMultiFactor,
  } = useMfa();

  const handleStartEnroll = async () => {
    setLoading(true);
    try {
      await startEnrollMultiFactor(convertPhoneNumber(phoneNumber));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFinishEnroll = async () => {
    setLoading(true);
    try {
      await finishEnrollMultiFactor(code);
      await sleep(6000);
      window.location.reload();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUnEnroll = async () => {
    setLoading(true);
    try {
      await unEnrollMultiFactor();
      await sleep(5000);
      window.location.reload();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="rounded bg-light p-3">
      {!emailVerified ? (
        <div>
          <p className="mb-2">
            You need to verify your email address in order to set up 2FA
          </p>
          <CButton color="success" onClick={verifyEmail}>
            Send verification email
          </CButton>
        </div>
      ) : (
        <div>
          <div
            className={`mb-2 d-flex ${
              isMobile ? "flex-direction-row" : "flex-direction-column"
            } align-items-center justify-content-between`}
          >
            <CFormGroup className={"w-100 mr-3"}>
              <CLabel>Phone number</CLabel>
              <BasicInput
                disabled={is2faSet}
                id="recaptcha"
                type="text"
                name="phoneNumber"
                placeholder={"+44 123 456 789"}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              {error && (
                <p
                  style={{
                    fontSize: "12px",
                  }}
                  className="text-danger mt-2"
                >
                  {error}
                </p>
              )}
            </CFormGroup>
            {is2faSet ? (
              <CButton
                type="button"
                color="warning"
                className="py-3"
                disabled={loading}
                onClick={handleUnEnroll}
              >
                Unenroll&nbsp;2FA
              </CButton>
            ) : (
              <CButton
                type="button"
                color="info"
                className="py-3"
                disabled={loading || !validatePhoneNumber(phoneNumber)}
                onClick={handleStartEnroll}
              >
                Send&nbsp;SMS
              </CButton>
            )}
          </div>
          {!is2faSet && (
            <div>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
                className="text-center mb-2"
              >
                Enter SMS code:
              </p>
              <CInput
                disabled={!validatePhoneNumber(phoneNumber) || loading}
                type="text"
                style={{ margin: "0 auto", maxWidth: "200px" }}
                name="code"
                onChange={(e) => setCode(e.target.value)}
              />
              <CButton
                disabled={!validatePhoneNumber(phoneNumber) || loading}
                type="button"
                color="success"
                size="lg"
                className="py-2 mx-auto d-block mt-3"
                onClick={handleFinishEnroll}
              >
                Verify phone number
              </CButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Set2fa;
