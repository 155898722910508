import {
  CButton,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import { t } from "i18next";
import { upperFirst } from "lodash";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import DatePicker from "src/components/DatePicker/DatePicker";
import ErrorText from "src/components/ErrorText/ErrorText";
import { useEmail } from "src/hooks";
import { selectOrgName } from "src/store/projectSelectors";
import { selectUserEmail } from "src/store/userSlice";
import { emailRegex } from "src/utils/common-utils/regex";

const RemindEmailModal = ({ show, toggle, task, duration }) => {
  const [loading, setLoading] = useState(false);
  const { sendRemindEmail } = useEmail();
  const userEmail = useSelector(selectUserEmail);
  const dateInstance = new Date();
  const TODAY = dateInstance.toISOString().split("T")[0];
  const orgName = useSelector(selectOrgName);

  const INITIAL_STATE = {
    email: "",
    date: TODAY,
    time: "",
  };

  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: INITIAL_STATE,
  });

  const defaultValue = getValues();

  const onChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value, { shouldTouch: true });
  };

  register("email", {
    required: t("validation.enterEmail"),
    pattern: {
      value: emailRegex,
      message: t("validation.enterEmail", "Please enter valid email"),
    },
  });
  register("date", {
    required: t("sectionModal.selectDate"),
  });
  register("time", {
    required: t("sectionModal.selectTime"),
  });

  const onSubmit = async (data) => {
    const { email, date, time } = data;

    const SCHEDULED_TIME = new Date(`${date} ${time}`).getTime();
    const CURRENT_TIME = new Date().getTime();

    if (CURRENT_TIME >= SCHEDULED_TIME) {
      setError("time", {
        type: "custom",
        message: t("sectionModal.scheduleError"),
      });

      return;
    }

    setLoading(true);

    if (!email || !date || !time) return;

    try {
      await sendRemindEmail({
        email,
        date,
        time,
        subject: `⏰ ZeroBees Scheduler | ${task}`,
        task,
        userEmail,
        duration,
        orgName,
      });
      toggle();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CModal
      show={show}
      closeOnBackdrop={false}
      onClose={toggle}
      style={{ zIndex: "1030" }}
    >
      <CModalHeader className="bg-light text-black" closeButton>
        <h5 className="m-0">{t("sectionModal.sendEmail")}</h5>
      </CModalHeader>
      <CModalBody>
        <CFormGroup>
          <CLabel>{t("shared.email")}</CLabel>
          <CInput
            type="email"
            name="email"
            placeholder="email@example.com"
            onChange={onChange}
          />
          <ErrorText value={errors?.email} />
        </CFormGroup>
        <CFormGroup>
          <CLabel>{t("shared.date")}</CLabel>

          <DatePicker
            name="date"
            minDate={new Date()}
            value={defaultValue?.["date"]}
            onChange={onChange}
          />
          <ErrorText value={errors?.date} />
        </CFormGroup>
        <CFormGroup>
          <CLabel>
            {t("sectionModal.time")} (<span className="text-info">UTC</span>)
          </CLabel>
          <CInput type="time" name="time" onChange={onChange} />
          <ErrorText value={errors?.time} />
        </CFormGroup>
      </CModalBody>
      <CModalFooter className="bg-secondary">
        <CButton
          color="success"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          {t("shared.submit")}
        </CButton>
        <CButton color="warning" onClick={toggle} disabled={loading}>
          {upperFirst(t("shared.close"))}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default RemindEmailModal;
