import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DB_PATHS } from "src/constants";
import { selectOrgData } from "src/store/projectSelectors";

import {
  useCompletedRate,
  useOwnerNotifications,
  useSectors,
  useUpdateDatabase,
} from "src/hooks";

const useUpdateCompletion = ({ sectionName }) => {
  const { calculateSectorCompletion } = useCompletedRate();
  const orgData = useSelector(selectOrgData);
  const completedSections = orgData?.completedSections;

  const { addToDailyUpdateEmail } = useOwnerNotifications();

  const { SECTORS } = useSectors();
  const hasUpdated = useRef(false);

  const { t } = useTranslation();

  const { updateDocument } = useUpdateDatabase();

  useEffect(() => {
    const updateSection = async (sections) => {
      await updateDocument(DB_PATHS.organisation, {
        completedSections: sections,
      });
    };

    return () => {
      if (hasUpdated.current) return;
      const completionRate = calculateSectorCompletion(sectionName);
      let sections;
      if (!completedSections) {
        sections = {};
      } else {
        sections = { ...completedSections };
      }

      if (completionRate !== 1) return;
      if (completedSections?.[sectionName]) return;
      sections[sectionName] = true;

      const sectionLabel = SECTORS.find(
        (item) => item.name === sectionName
      ).title;

      addToDailyUpdateEmail({
        key: sectionName,
        text: t("notifications.dailyUpdate.newUser", { sectionLabel }),
      });
      hasUpdated.current = true;

      updateSection(sections);
    };
  }, [calculateSectorCompletion]);
  return {};
};

export default useUpdateCompletion;
