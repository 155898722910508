import { CCallout, CPopover } from "@coreui/react";
import { IoMdPersonAdd } from "react-icons/io";
import { ButtonBasic } from "src/components";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import SignupModal from "./SignupModal";

const OrgsPanel = ({ allOrgs }) => {
  const [showSignup, setShowSignup] = useState(false);
  const signupToggle = () => {
    setShowSignup((prev) => !prev);
  };

  const updatedOrg = allOrgs?.filter(
    (org) => org["suppliers-changed"] || org["categories-changed"]
  );

  const { t } = useTranslation();

  return (
    <>
      <CCallout
        color="info"
        className="bg-light py-1 mb-5 mx-auto w-50"
        style={{ minWidth: "350px" }}
      >
        <div className="d-flex justify-content-between">
          <div className="mx-2">
            <span className="text-muted">{t("superAdmin.active")}</span>
            <br />
            <strong className="h3">
              {allOrgs?.filter((item) => !item.archived).length || 0}
            </strong>
          </div>

          <div className="mx-2">
            <span className="text-muted">{t("superAdmin.updated")}</span>
            <br />
            {updatedOrg?.length ? (
              <CPopover
                header="Updated"
                content={updatedOrg.map(({ name }) => (
                  <strong className="d-block h6">{name}</strong>
                ))}
              >
                <div>
                  <strong className="h3 text-info">{updatedOrg.length}</strong>
                </div>
              </CPopover>
            ) : (
              <strong className="text-info">0</strong>
            )}
          </div>
          <div className="mx-2">
            <span className="text-muted">{t("superAdmin.archived")}</span>
            <br />
            <strong className="h3">
              {allOrgs?.filter((item) => !!item.archived).length || 0}
            </strong>
          </div>
          <ButtonBasic className="ml-2 rounded" onClick={signupToggle}>
            <IoMdPersonAdd size="1.5rem" />
            <span>{t("shared.createNew")}</span>
          </ButtonBasic>
        </div>
      </CCallout>
      <SignupModal showModal={showSignup} toggle={signupToggle} />
    </>
  );
};

export default OrgsPanel;
