// import firebase from 'firebase';
import firebase from "firebase/compat/app";

import {
  useFirestoreDocData,
  useFirestoreCollectionData,
  useUser,
} from "reactfire";

import React, { useEffect } from "react";
import useState from "react-usestateref";
import { useDispatch } from "react-redux";
import {
  adapator_createDatabaseRef,
  adaptor_createRefAsPath,
  adaptor_createReplacementGuide,
} from "src/adapters";
import { useReducer } from "react";

/*********** HELPERS *******/
/**
 *
 * @category Hooks
 * @module Database Hooks
 *
 */

/**
 * <p> A custom hook based off <a href="https://github.com/FirebaseExtended/reactfire">reactFire </a> for donwloading single documents.  </p>
 *
 *
 * @param {Arr} pathArr - An array representing the path. This gets joined into a string
 * @param {Arr} constantsArr - Any dynamic values that can be repaced in the path arr. Easier to replace here that making a correct path arr on input
 * @return {Object} <ul> status ( STRING ) - "error" | "loading" | "success" -  a status on the success of the download</ul>
 *                  <ul> data (Object) - the data returned. </ul>
 *                  <ul> ref -(Firestore REF) - in case the refrence is needed for writing to later</ul>
 *
 *
 *
 *
 *
 */
const useDoc = (pathArr, constantsArr) => {
  let ref;
  ref = adapator_createDatabaseRef(pathArr, constantsArr);

  const { status, data } = useFirestoreDocData(ref);
  return { status, data, ref };
};

/**
 * <p> An addition to useDoc. Regulary we want to download data and immeidatly dispatch it to redux. </p>
 * - this takes an arg of an array of redux actions. Please note that the data is sent unedited to the action so consider altering your actions if necesarry
 * - for more complicated actions you should use useDoc and then dispatch fromt the component. This is just a convinence DRY Method.
 *
 *
 * @param {Arr} pathArr - An array representing the path. This gets joined into a string
 * @param {Arr} constantsArr - Any dynamic values that can be repaced in the path arr. Easier to replace here that making a correct path arr on input
 * @return {Object} <ul> status ( STRING ) - "error" | "loading" | "success" -  a status on the success of the download</ul>
 *                  <ul> data (Object) - the data returned. </ul>
 *                  <ul> ref -(Firestore REF) - in case the refrence is needed for writing to later</ul>
 *
 *
 *
 *
 *
 */
const useDocAndDispatchDataToRedux = (pathArr, constantsArr, dispatchFuncs) => {
  const dispatch = useDispatch();
  const ref = adapator_createDatabaseRef(pathArr, constantsArr);
  const { status, data } = useFirestoreDocData(ref);

  useEffect(() => {
    if (status !== "success") {
      return;
    }

    for (var idx in dispatchFuncs) {
      dispatch(dispatchFuncs[idx](data));
    }
  }, [status]);

  return { status, data, ref };
};

/**
 * <p> A custom hook based off <a href="https://github.com/FirebaseExtended/reactfire">reactFire </a> or download COLLECTIONS </p>
 *
 *
 * @param {Arr} pathArr - An array representing the path. This gets joined into a string
 * @param {Arr} constantsArr - Any dynamic values that can be repaced in the path arr. Easier to replace here that making a correct path arr on input
 * @return {Object} <ul> status ( STRING ) - "error" | "loading" | "success" -  a status on the success of the download</ul>
 *                  <ul> data (Object) - the data returned. </ul>
 *                  <ul> ref -(Firestore REF) - in case the refrence is needed for writing to later</ul>
 *
 *
 *
 *
 *
 */
const useCollection = (pathArr, constantsArr) => {
  const ref = adapator_createDatabaseRef(pathArr, constantsArr);
  const { status, data } = useFirestoreCollectionData(ref);
  return { status, data, ref };
};

/**
 * <p> An Addition to {@Link useCollection}. Use when you want a mutatable state based off the initial database value.
 *
 *
 * @param {Arr} pathArr - An array representing the path. This gets joined into a string
 * @param {Arr} constantsArr - Any dynamic values that can be repaced in the path arr. Easier to replace here that making a correct path arr on input
 * @param {Any} initialVal - An intialValue for the mutatable State. Defaults to an empty array. As this represents a collection makes sense.
 * @return {Object} <ul> status ( STRING ) - "error" | "loading" | "success" -  a status on the success of the download</ul>
 *                  <ul> projectref -(Firestore REF) - in case the refrence is needed for writing to later</ul>
 *                  <ul> downloadedState (ANY) - the intial donwnloaded data </ul>
 *                  <ul> state (ANY) - the mutable state </ul>
 *                  <ul> setState (FUNCTION) - sets the local state, use state arg 2 </ul>
 *                  <ul> stateRef (ANY:REF) -a ref to the state from react-usestateref. In case used within a callback </ul>
 *
 *
 *
 *
 *
 */
const useCollectionAsLocalState = (pathArr, constantsArr, initialVal = []) => {
  const { status, data, ref } = useCollection(pathArr, constantsArr);
  const [localState, setLocalState, localStateRef] = useState(initialVal);

  useEffect(() => {
    if (status === "loading") {
      return;
    }
    setLocalState(data);
  }, [status, data]);

  return {
    status,
    projectref: ref,
    downloadedState: data,
    state: localState,
    setState: setLocalState,
    stateRef: localStateRef,
  };
};

/**
 * <p>Takes an arr of paths and will fetch all paths and return them</p>
 * <p>Note this is different from useNestedCollection because you know everything you need to make a path. You just
 * have a more than 1 path you want to fetch/p>
 *
 * @param {Arr} arrOFPaths - An array of OBJECTS. looks like {path (Arr representing the each directory of the DB),id (An Id of the Path), replacementArr (If there are replacment paths in the Path Arr these replace from here)}
 * @param {Arr} pathsAreCollections - Paths to download can be collections of docs. Collections are returned as an arr of obhects {[id]:id, data: [id: docId, data: docData ]}
 *
 *
 *
 *
 */
const useBatchDocuments = (arrOFPaths, pathsAreCollections = false) => {
  /*********** Vars *******/
  const [status, setStatus] = React.useState("loading");
  const [data, setData] = React.useState([]);

  /*********** LCM *******/
  useEffect(() => {
    setStatus("loading");
    setData([]);
    _downloadBatch();
  }, [arrOFPaths]);

  /*********** MEthods *******/

  const _downloadBatch = async () => {
    var buffer = [];

    var resultCounter = arrOFPaths.length - 1;

    while (resultCounter >= 0) {
      let id = arrOFPaths[resultCounter].id;
      let pathArr = arrOFPaths[resultCounter].path;
      let replacementArr = arrOFPaths[resultCounter].replacementArr;

      let firebasePath = adaptor_createRefAsPath(pathArr, replacementArr);

      if (pathsAreCollections) {
        let collectionRef = firebase.firestore().collection(firebasePath);
        let collectionDocs = await collectionRef.get();
        let results = [];
        for (var idx in collectionDocs.docs) {
          let doc = collectionDocs.docs[idx];
          results.push({ id: doc.id, data: doc.data() });
        }
        buffer.push({ id: id, docs: results });
      } else {
        let docRef = firebase.firestore().doc(firebasePath);
        let doc = await docRef.get();
        if (doc != undefined) {
          buffer.push({ data: doc.data(), id: id });
        }
      }
      resultCounter--;
    }

    setStatus("success");
    setData(buffer);
  };

  return { status, data };
};

/**
 * <p> As A NoSQL database complex queries cannot be done. This serves us to allow us to download simply a nested query on the component side </p>
 * <ul> Often we download a collection from a firstPath. and then from that path we need to get al the documents with in a second path made
 * a value in the firstPath results </ul>
 * <ul> This is what is provided here a first collection is downloaded </ul>
 * <ul> For each doc in collection a new call is made based on the second path and the secondPathVariables to fill any DB_CONSTANTS.guideItem</ul>
 * <ul> Each secondpath doc is downloaded and then an array of these is returned</ul>
 * <ul> The secondpath can also be a collection </ul>
 * <ul>Mocks to Reactfire's {status, data} return</ul>
 *
 *
 * @param {Arr} pathArr - An array representing the first path
 * @param {Arr} secondPathArr - Any dynamic values that can be repaced in the path arr. Easier to replace here that making a correct path arr on input
 * @param {Arr} secondPathVariables - The secondPathArr contains DBCONSTANTS.guideItems. This is the matching array to fill those items
 * @param {Boolean} secondPathIsCollection - Indicates if the second path is a collection or a doc
 *
 * @return {Object} <ul> status ( STRING ) - "error" | "loading" | "success" -  a status on the success of the download</ul>
 *                  <ul> Data (Arr) - All of the secondpath docs/collection in an array
 *
 *
 *
 *
 */

const useNestedCollection = ({
  pathArr,
  secondPathArr,
  secondPathVariables,
  secondPathIsCollection = false,
  firstPathIsCollection = true,
}) => {
  /*********** Vars *******/
  // const user = useUser();
  // const [status, setStatus] = React.useState("loading");
  // const [firstPathData, setFirstPathData] = React.useState([]);
  // const [data, setData] = React.useState([]);
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "setData":
          return {
            ...state,
            ...action.payload,
            status: "success",
          };
        case "error":
          return { ...state, status: "error" };
        default:
          return state;
      }
    },
    {
      status: "loading",
      firstPathData: [],
      data: [],
    }
  );

  const [counter, setCounter] = React.useState(0);

  const forceUpdate = () => {
    setCounter(counter + 1);
  };

  /*********** LCM *******/
  useEffect(() => {
    let path = adaptor_createRefAsPath(pathArr);
    if (path == null) {
      return null;
    }
    collectDocuments(path);
  }, [counter]);

  /*********** METHODS *******/
  const getTopTierDocs = async (path) => {
    let topRef = firebase.firestore().collection(path);
    let initialDocs = await topRef.get();

    let toptierResults = [];
    for (var idx in initialDocs.docs) {
      let doc = initialDocs.docs[idx];
      toptierResults.push({ id: doc.id, data: doc.data() });
    }
    return toptierResults;
  };

  const getTopTierSingluarDoc = async (path) => {
    let topRef = firebase.firestore().doc(path);
    let doc = await topRef.get();

    let toptierResults = [];
    toptierResults.push({ id: doc.id, data: doc.data() });
    return toptierResults;
  };

  const getBottomTierDoc = async (docDetails) => {
    let replacementParams = adaptor_createReplacementGuide(
      secondPathVariables,
      docDetails
    );
    let docPath = adaptor_createRefAsPath(secondPathArr, replacementParams);
    let ref = firebase.firestore().doc(docPath);
    let doc = await ref.get();

    if (doc !== undefined) {
      return {
        id: doc.id,
        data: doc.data(),
        genericPath: secondPathArr,
        pathVariables: replacementParams,
      };
    } else {
      return null;
    }
  };

  const getBottomTierCollection = async (docDetails) => {
    let replacementParams = adaptor_createReplacementGuide(
      secondPathVariables,
      docDetails
    );

    let collectionPath = adaptor_createRefAsPath(
      secondPathArr,
      replacementParams
    );
    let ref = firebase.firestore().collection(collectionPath);
    let colllectionDocs = await ref.get();

    let results = [];
    for (var idx in colllectionDocs.docs) {
      let doc = colllectionDocs.docs[idx];
      results.push({
        id: doc.id,
        genericPath: secondPathArr,
        replacementParams: replacementParams,
        data: doc.data(),
      });
    }

    return results;
  };

  const collectDocuments = async (path) => {
    let topTierResults;
    let bottomTierDocsBuffer = [];

    try {
      if (firstPathIsCollection) {
        topTierResults = await getTopTierDocs(path);
        // setFirstPathData(topTierResults);
      } else {
        topTierResults = await getTopTierSingluarDoc(path);
      }

      var resultCounter = topTierResults.length - 1;
      while (resultCounter >= 0) {
        let docDetails = topTierResults[resultCounter];

        let doc = secondPathIsCollection
          ? await getBottomTierCollection(docDetails.data)
          : await getBottomTierDoc(docDetails.data);
        bottomTierDocsBuffer.push(doc);
        resultCounter--;
      }
    } catch (err) {
      dispatch({ type: "error" });
    }

    if (!firstPathIsCollection) {
      if (bottomTierDocsBuffer.length > 0) {
        dispatch({
          type: "setData",
          payload: {
            firstPathData: topTierResults[0],
            data: bottomTierDocsBuffer[0],
          },
        });
      }
    } else {
      dispatch({
        type: "setData",
        payload: {
          firstPathData: topTierResults,
          data: bottomTierDocsBuffer,
        },
      });
    }
  };

  return { ...state, forceUpdate };
};

export {
  useDoc,
  useDocAndDispatchDataToRedux,
  useCollection,
  useCollectionAsLocalState,
  useNestedCollection,
  useBatchDocuments,
};
