import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
  cilSwapVertical,
  cilCode,
  cilBrowser,
  cilHospital,
  cilClock,
  cilHome,
  cilLockUnlocked,
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilPencil,
  cilMenu,
  cilArrowLeft,
  cilArrowRight,
  cilArrowBottom,
  cilArrowTop,
  cilLink,
  cilPlus,
  cilInfo,
  cilCheckCircle,
  cilWarning,
  cilPuzzle,
  cilPeople,
  cilDescription,
  cilCarAlt,
  cilTv,
  cilBuilding,
  cilXCircle,
  cilDelete,
  cilSettings,
  cilMoney,
  cilTask,
  cilEnvelopeLetter,
  cilNewspaper,
  cilAirplaneMode,
  cilTrash,
  cibXero,
  cilActionUndo,
  cilInput,
  cilX,
  cilCalculator,
  cilEnvelopeClosed,
  cilUser,
  cilCommentBubble,
  cilCommentSquare,
  cilSpreadsheet,
  cilListNumbered,
  cilFile,
  cilHandPointUp,
  cilHistory,
  cilBarChart,
  cilChartPie,
  cilChartLine,
  cilSmilePlus,
  cilCalendar,
  cilThumbUp,
  cilReload,
  cilSend,
  cilMediaPlay,
  cilBell,
  cilBullhorn,
  cilCloudUpload,
  cilSquare,
  cilTruck,
  cilCircle,
  cilMedicalCross,
  cilCopy,
  cilMobile
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilMobile,
    cilCode,
    cilSwapVertical,
    cilBrowser,
    cilCopy,
    cilMedicalCross,
    cilCircle,
    cilTruck,
    cilSquare,
    cilHospital,
    cilCloudUpload,
    cilClock,
    cilHome,
    cilLockUnlocked,
    cilSend,
    cilUser,
    cilX,
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilPencil,
    cilMenu,
    cilArrowLeft,
    cilArrowRight,
    cilArrowBottom,
    cilArrowTop,
    cilLink,
    cilPlus,
    cilInfo,
    cilCheckCircle,
    cilWarning,
    cilPuzzle,
    cilPeople,
    cilDescription,
    cilCarAlt,
    cilTv,
    cilBuilding,
    cilXCircle,
    cilDelete,
    cilSettings,
    cilMoney,
    cilTask,
    cilEnvelopeLetter,
    cilNewspaper,
    cilAirplaneMode,
    cilTrash,
    cibXero,
    cilActionUndo,
    cilInput,
    cilCalculator,
    cilEnvelopeClosed,
    cilSpreadsheet,
    cilCommentBubble,
    cilCommentSquare,
    cilListNumbered,
    cilFile,
    cilHandPointUp,
    cilHistory,
    cilBarChart,
    cilChartPie,
    cilChartLine,
    cilSmilePlus,
    cilCalendar,
    cilThumbUp,
    cilReload,
    cilMediaPlay,
    cilBell,
    cilBullhorn,
  }
);
