import React from "react";
import StyledButton from "./ButtonWhite.style";

const ButtonWhite = ({ children, ...rest }) => {
  return (
    <StyledButton
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default ButtonWhite;
