import { CButton, CPopover, CSelect } from "@coreui/react";
import { getMonth, getYear } from "date-fns";
import _ from "lodash";
import { forwardRef, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { BsCalendar3 } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import styled from "styled-components";

const DatePicker = ({
  name,
  value,
  onChange,
  isClearable,
  className,
  disabled,
  yearsRange,
  ...rest
}) => {
  const convertDateToString = (date) => {
    if (!date) return null;
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0];
  };

  const [localDate, setLocalDate] = useState(
    value ? new Date(value) : undefined
  );

  useEffect(() => {
    if (!value) {
      setLocalDate(undefined);
    } else {
      setLocalDate(new Date(value));
    }
  }, [value]);

  const defaultYears = _.range(1990, getYear(new Date()) + 1, 1);
  const years = yearsRange || defaultYears;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <StyledCustomInput
      className={` d-flex align-items-center justify-content-between p-2 custom__date__input ${className}`}
      onClick={onClick}
      ref={ref}
      style={{ cursor: "pointer" }}
    >
      <p className="mb-0" style={{ fontWeight: "500" }}>
        {value}
      </p>
      {isClearable && !disabled && !!value ? (
        <CPopover content="Clear">
          <MdClear
            size={20}
            color="gray"
            onClick={(e) => {
              e.stopPropagation();
              onChange({ target: { name, value: null } });
              setLocalDate(null);
            }}
          />
        </CPopover>
      ) : (
        <BsCalendar3 />
      )}
    </StyledCustomInput>
  ));

  return (
    <StyledDatePicker
      dateFormat="dd/MM/yyyy"
      onChange={(date) => {
        onChange({ target: { name, value: convertDateToString(date) } });
        setLocalDate(date);
      }}
      customInput={<CustomInput />}
      selected={localDate}
      withPortal
      placeholderText="dd/mm/yyyy"
      disabled={disabled}
      {...rest}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="m-2 d-flex justify-content-center align-items-center">
          <CButton
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            color="light"
          >
            {"<"}
          </CButton>
          <div className="d-flex mx-2" style={{ gap: "0.5rem" }}>
            <CSelect
              className="bg-success text-white"
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </CSelect>

            <CSelect
              className="bg-success text-white"
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
              style={{ minWidth: "130px" }}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </CSelect>
          </div>

          <CButton
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            color="light"
          >
            {">"}
          </CButton>
        </div>
      )}
    />
  );
};

const StyledCustomInput = styled.div`
  border: 1px solid var(--light);
  border-radius: 5px;
`;

const StyledDatePicker = styled(ReactDatePicker)`
  .c-dark-theme & {
    color: #fff;
    border-color: var(--light);
  }
`;

export default DatePicker;
