import { CButton, CCol, CModal, CModalHeader, CPopover } from "@coreui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineRotateRight } from "react-icons/ai";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Document, Page, pdfjs } from "react-pdf";
import { useResizeDetector } from "react-resize-detector";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFModal = ({ show, toggle, pdfFile }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [rotate, setRotate] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { width, height, ref } = useResizeDetector();

  const onCloseHandler = () => {
    setNumPages(null);
    setPageNumber(1);
    setRotate(0);

    toggle();
  };

  return (
    <>
      <CModal
        show={show}
        size="xl"
        innerRef={ref}
        onClose={onCloseHandler}
        closeOnBackdrop={false}
        scrollable
        className="overflow-hidden"
      >
        <CModalHeader closeButton>
          <CCol xs={11}>
            <div className="d-flex align-items-center justify-content-center">
              <CPopover content="Previous page">
                <CButton
                  onClick={() => {
                    setPageNumber(
                      pageNumber === 1 ? pageNumber : pageNumber - 1
                    );
                  }}
                >
                  <FaMinus />
                </CButton>
              </CPopover>
              <h6 className="mb-0 mx-2">
                Page {pageNumber} of {numPages}
              </h6>
              <CPopover content="Next page">
                <CButton
                  onClick={() => {
                    setPageNumber(
                      numPages === pageNumber ? pageNumber : pageNumber + 1
                    );
                  }}
                >
                  <FaPlus size="1rem" />
                </CButton>
              </CPopover>
              <CPopover content="Rotate">
                <CButton
                  className="ml-2"
                  onClick={() => {
                    setRotate((prev) =>
                      prev === 0
                        ? 90
                        : prev === 90
                        ? 180
                        : prev === 180
                        ? 270
                        : 0
                    );
                  }}
                >
                  <AiOutlineRotateRight size="1rem" />
                </CButton>
              </CPopover>
            </div>
          </CCol>
        </CModalHeader>

        {show && pdfFile && (
          <div style={{ overflowY: "scroll", overflowX: "hidden" }}>
            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                rotate={rotate}
                width={width}
                height={height}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                pageNumber={pageNumber}
              />
            </Document>
          </div>
        )}
      </CModal>
    </>
  );
};

export default PDFModal;
