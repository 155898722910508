import { CSwitch } from "@coreui/react";
import React from "react";

const BasicSwitch = ({
  value,
  setValue,
  optionA,
  optionB,
  label,
  className,
  onChange,
  mb=4,
  ...rest
}) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center mx-auto mb-${mb}  ${className}`}
      {...rest}
    >
      {!!label && <b className="mr-2">{label}:</b>}
      <span className={`mr-2 ${!value && "text-secondary"}`}>{optionA}</span>
      <CSwitch
        color="dark"
        variant="3d"
        size="sm"
        checked={!value}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          } else {
            setValue?.((prev) => !prev);
          }
        }}
      />
      <span className={`ml-2 ${value && "text-secondary"}`}>{optionB}</span>
    </div>
  );
};

export default BasicSwitch;
