import { DB_PATHS } from 'src/constants';
import { useCollection } from '../databaseHooks';
import useDatabasePaths from '../useDatabasePaths/useDatabasePaths';

const useGetPurchases = () => {
    const { getPath } = useDatabasePaths();

    const { data: purchaseData, status } = useCollection(
        getPath(DB_PATHS.purchases)
    );

    return { purchases: purchaseData || [], loading: status === 'loading' };
}

export default useGetPurchases