import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BasicNumberInput,
  DemoWarning,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  VerticalRadioButtons
} from "src/components";
import { useSurveyConstants } from "src/hooks";
import { InfoTooltip, Subtitle } from "../components";

const EmployeeSurvey3 = ({
  data: values = {},
  onChange,
  info,

  isDemo,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  const { greenTariff } = useSurveyConstants();

  return (
    <FormStepWrapper
      aboveCardSlot={
        <>
          {isDemo && <DemoWarning />}
          <Subtitle
            content={t("employeeSurvey.fromHomeToWork", "From home to work")}
          />
        </>
      }
    >
      <QuestionWrapper>
        <h3>
          5.{" "}
          {t(
            "employeeSurvey.q_greenTariff",
            "Was your home electricity on a green tariff during {{period}}?",
            { period: info.reportingPeriod }
          )}
          <InfoTooltip
            content={t(
              "employeeSurvey.greenTariff",
              "A green tariff is one backed 100% by renewable electricity - from wind, solar etc. This should be clearly stated on your tariff and by your supplier."
            )}
          />
        </h3>
        <VerticalRadioButtons
          name="green-tariff"
          onChange={onChange}
          defaultValue={values["green-tariff"]}
          data={greenTariff}
        />
        <ErrorText value={errors["green-tariff"]} />
      </QuestionWrapper>

      <QuestionWrapper>
        <h3>
          6.{" "}
          {t(
            "employeeSurvey.q_printerPaper",
            `How many packs of printer paper did you personally purchase and
                use for work purposes in {{period}} at home (to the
                nearest pack of 500 sheets of printer paper)?`,
            { period: info.reportingPeriod }
          )}
        </h3>
        <BasicNumberInput
          allowNegative={false}
          min={0}
          max={1000}
          placeholder={t(
            "employeeSurvey.q_printerPaperPlaceholder",
            "Enter number of packs of paper..."
          )}
          name="printer-paper"
          defaultValue={values["printer-paper"]}
          onChange={onChange}
        />
        <ErrorText value={errors["printer-paper"]} />
      </QuestionWrapper>

      <QuestionWrapper>
        <h3>
          7.{" "}
          {t(
            "employeeSurvey.q_notepads",
            `Approximately how many notepads did you personally purchase and
                use for work purposes in {{period}} at home?`,
            { period: info.reportingPeriod }
          )}
          <InfoTooltip
            content={t(
              "employeeSurvey.q_notepadsHint",
              "Approximate to the nearest A4 pad (200 page); consider a A5 pad (120 page with hard cover) to be roughly half a 200 sheet A4 pad for example."
            )}
          />
        </h3>
        <BasicNumberInput
          allowNegative={false}
          min={0}
          max={1000}
          placeholder={t(
            "employeeSurvey.q_notepadsPlaceholder",
            "Enter number of notepads..."
          )}
          name="notepads"
          defaultValue={values.notepads}
          onChange={onChange}
        />
        <ErrorText value={errors["notepads"]} />
      </QuestionWrapper>
    </FormStepWrapper>
  );
};

export default EmployeeSurvey3;
