import styled from "styled-components";

const StyledDiv = styled.div`
  button {
    margin: 0;
  }

  @media screen and (max-width: 992px) {
    button {
      width: 100% !important;
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
`;

export default StyledDiv;
