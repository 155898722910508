import {
  CForm,
  CFormGroup,
  CInputCheckbox,
  CLabel,
  CModalBody,
  CModalHeader,
} from "@coreui/react";
import { generate } from "generate-password-browser";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ErrorText } from "src/components";
import { selectAssignModal, setAssignModal } from "src/store/modalSlice";
import {
  BasicSelect,
  BasicTableContainer,
  ButtonBasic,
  LoadingSpinner,
} from "..";
import BasicInput from "../BasicInput/BasicInput.style";
import StyledModal from "../BasicModal/BasicModal.style";
import ControlledInput from "../ControlledInput/ControlledInput";
import DataTable from "../DataTable/DataTable";
import controller from "./AssignModalController";

const EMPTY_USER = {
  email: "",
  firstName: "",
  lastName: "",
  role: "user",
};

const AssignModal = () => {
  const { showAssignModal: show, taskToAssign } =
    useSelector(selectAssignModal);
  const dispatch = useDispatch();
  const {
    loading,
    handleSubmit,
    onSubmit,
    onChange,
    formMethods,
    setSendEmails,
  } = controller({ taskToAssign });

  const closeModal = () => {
    dispatch(setAssignModal({ show: false, taskToAssign: "" }));
  };

  const { t } = useTranslation();

  const initValues = useMemo(() => [EMPTY_USER], []);

  const {
    formState: { touchedFields, errors },
    trigger,
    control,
    getValues,
  } = formMethods;

  const [password, setPassword] = useState();

  useEffect(() => {
    const pass = generate({ length: 10, numbers: true });
    setPassword(pass);
    onChange({ target: { name: "password", value: pass } });
  }, []);

  return (
    <StyledModal size="xl" show={show} onClose={closeModal} centered>
      {loading && <LoadingSpinner asOverlay />}
      <CModalHeader closeButton className="d-flex justify-content-center pb-0">
        <h3 className="my-4"> {t("assign.addCollaborators")}</h3>
      </CModalHeader>
      <CModalBody className="px-5 pb-5 pt-0">
        <CForm onSubmit={handleSubmit(onSubmit)}>
          <h5>{t("assign.newUsers")}</h5>

          <BasicTableContainer minWidth="900px">
            <DataTable
              tableName="users"
              emptyItem={EMPTY_USER}
              pagination
              itemsPerPage={10}
              showDelete={false}
              fields={[
                { label: t("shared.email"), key: "email" },
                { label: t("settings.firstName"), key: "firstName" },
                { label: t("settings.lastName"), key: "lastName" },
                { label: t("assign.permissions"), key: "role" },
              ]}
              {...{ initValues, formMethods }}
              scopedSlots={{
                email: (item, index) => {
                  return (
                    <td>
                      <ControlledInput
                        Component={BasicInput}
                        name={`users.${index}.email`}
                        placeholder={t("assign.emailPlaceholder")}
                        {...{ control, trigger, getValues }}
                      />
                    </td>
                  );
                },
                firstName: (item, index) => {
                  return (
                    <td>
                      <ControlledInput
                        Component={BasicInput}
                        name={`users.${index}.firstName`}
                        placeholder={t("assign.firstNamePlaceholder")}
                        {...{ control, trigger, getValues }}
                      />
                    </td>
                  );
                },
                lastName: (item, index) => {
                  return (
                    <td>
                      <ControlledInput
                        Component={BasicInput}
                        name={`users.${index}.lastName`}
                        placeholder={t("assign.lastNamePlaceholder")}
                        {...{ control, trigger, getValues }}
                      />
                    </td>
                  );
                },
                role: (item, index) => {
                  return (
                    <td>
                      <ControlledInput
                        Component={BasicSelect}
                        name={`users.${index}.role`}
                        {...{ control, trigger, getValues }}
                        options={[
                          {
                            label: t("shared.permissionTypes.regular", "Regular"),
                            value: "user",
                          },
                          {
                            label: t("shared.permissionTypes.extended", "Extended"),
                            value: "superUser",
                          },
                        ]}
                      />
                    </td>
                  );
                },
              }}
            />
          </BasicTableContainer>
          <div className="d-flex flex-column w-100 justify-content-between mt-4 bg-light p-3 rounded text-center">
            <h5 className="mt-3">{t("assign.generatedPassword")}</h5>
            <h3 className="my-4">{password}</h3>
            <Trans
              i18nKey="assign.passwordExplanation"
              components={{
                1: <p className="text-info mb-2"></p>,
                2: <p className="text-info mb-2"></p>,
                3: (
                  <p
                    style={{ fontWeight: "bold" }}
                    className="text-info mb-2"
                  ></p>
                ),
              }}
            ></Trans>
          </div>
          <ErrorText value={errors?.password} />
          <CFormGroup className="my-3" variant="custom-checkbox">
            <CInputCheckbox
              custom
              id="emails-checkbox"
              onChange={(e) => {
                setSendEmails(!e.target.checked);
              }}
              defaultChecked={false}
              style={{ borderRadius: 0 }}
              disabled={process.env.REACT_APP_SEND_EMAILS === "false"}
            />
            <CLabel variant="custom-checkbox" htmlFor="emails-checkbox">
              Don't send invitation email
            </CLabel>
          </CFormGroup>
          <ButtonBasic
            type="submit"
            className="w-100"
            disabled={!touchedFields.users || !touchedFields.password}
          >
            {t("assign.addUsers")}
          </ButtonBasic>
        </CForm>
      </CModalBody>
    </StyledModal>
  );
};

export default AssignModal;
