const { roundNumber } = require("src/utils/common-utils");
const { calculateReductionPercentage } = require("./general");



export const calculateSwitchDomesticAirToRail = (
    results,
    recommendation,
    year,
    log,
    emissionFactors,
    baseYear
) => {
    if (!results) return 0;

    const {
        specifics: { amount: reduceBy },
        shareImplemented,
        yearImplemented,
    } = recommendation;

    const reductionPercentage = calculateReductionPercentage(
        reduceBy,
        shareImplemented,
        year,
        yearImplemented,
        baseYear
    );

    if (!reductionPercentage) return 0;

    const generalLog = log?.log;

    const railEF = emissionFactors?.["land-travel"]?.rail;

    if (!railEF) return 0;

    const { CO2E_PER_KM, WTT_CO2E_PER_KM } = railEF;

    const domesticFlights = generalLog?.find(record => record.category === "Business air travel" && record.subcategory === "DOMESTIC")

    if (!domesticFlights) return 0
    const distanceToSwitch = domesticFlights.value * reductionPercentage

    const emissionsReduction = domesticFlights.emissions * reductionPercentage

    const emissionIncrease = distanceToSwitch * (CO2E_PER_KM + WTT_CO2E_PER_KM)

    const netReduction = emissionsReduction - emissionIncrease

    return roundNumber(netReduction || 0, 2)
}
