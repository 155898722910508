import CIcon from "@coreui/icons-react";
import { CButton, CDataTable, CTooltip } from "@coreui/react";
import { upperFirst } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BasicTableContainer, LoadingSpinner } from "src/components";
import AddNewFactor from "src/components/ConstantsTable/components/AddNewFactor";
import { sentenceCase } from "src/components/ConstantsTable/utils";
import { DB_PATHS } from "src/constants";
import { useHttp, useToasts, useUpdateDatabase } from "src/hooks";
import { convertKebabToDisplay } from "src/utils/common-utils";

const convertFromDbToArray = (obj) => {
  const arr = [];

  const convertEntries = (obj, parent = "") => {
    if (!obj) return;

    Object.entries(obj).forEach((entry) => {
      const [key, value] = entry;
      if (typeof value === "string") return;

      if (typeof value === "number") {
        const path = parent + key;
        const pathArr = path.split(".").reverse();

        const arrayRow = { value, path };

        pathArr.forEach((segment, index) => {
          arrayRow[`path${index + 1}`] = segment;
        });
        arr.push(arrayRow);
      } else {
        convertEntries(value, parent + key + ".");
      }
    });
  };
  convertEntries(obj);
  return arr;
};

const ReadOnlyConstantsTable = ({
  addedColumns,
  existingConstants,
  canAddFactors = false,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const tableColumns = [
    ...addedColumns,
    {
      label: t("shared.value", "Value"),
      key: "value",
      _style: { width: "20%" },
    },
  ];

  const { setOrUpdateDocument, deleteField } = useUpdateDatabase();

  const { sendRequest } = useHttp();

  const { showToast } = useToasts();

  const addHandler = async (item) => {
    setLoading(true);
    try {
      const { path1, current } = item;

      await sendRequest(
        "/app/pinecone/index",
        {
          method: "POST",
          body: JSON.stringify({ category: path1 }),
        },
        true
      );

      await setOrUpdateDocument(DB_PATHS.addedFactors, {
        "purchase-categories": {
          custom: {
            [path1]: current,
          },
        },
      });
      showToast({
        text: "Factor added",
        type: "success",
      });
    } catch (err) {
      console.error(err);
      showToast({
        text: "Error adding factor",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteHandler = async (path1) => {
    setLoading(true);
    try {
      await sendRequest(
        "/app/pinecone/index",
        {
          method: "DELETE",
          body: JSON.stringify({ category: path1 }),
        },
        true
      );

      await deleteField(
        DB_PATHS.addedFactors,
        "purchase-categories",
        `custom.${path1}`
      );

      showToast({
        text: "Factor deleted",
        type: "success",
      });
    } catch (err) {
      console.error(err);
      showToast({
        text: "Error deleting factor",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingSpinner asOverlay />}
      {canAddFactors && (
        <AddNewFactor
          onAdd={(item) => {
            addHandler(item);
          }}
        />
      )}
      <BasicTableContainer>
        <CDataTable
          itemsPerPageSelect={{ label: t("shared.itemsPerPage") }}
          pagination
          columnFilter
          columnFilterSlot={{
            history: <span></span>,
            date: <span></span>,
          }}
          itemsPerPage={10}
          addTableClasses="upper"
          items={convertFromDbToArray(existingConstants)}
          fields={tableColumns}
          scopedSlots={{
            path4: (item, index) => {
              return (
                <td>
                  <span>{item.path4}</span>
                </td>
              );
            },
            path3: (item, index) => {
              return (
                <td>
                  <span>{upperFirst(item.path3)}</span>
                </td>
              );
            },
            path2: (item, index) => {
              return (
                <td>
                  <span>{sentenceCase(item.path2)}</span>
                </td>
              );
            },
            path1: (item, index) => {
              return (
                <td>
                  <div className="d-flex align-items-center">
                    <span>{sentenceCase(item.path1)}</span>
                    {canAddFactors && (
                      <CTooltip
                        content={t(
                          "settings.deleteFactor",
                          "Delete custom factor"
                        )}
                      >
                        <CButton
                          onClick={() => deleteHandler(item.path1)}
                          size="sm"
                          className="ml-3"
                          variant="outline"
                          color="danger"
                        >
                          <CIcon name="cil-trash" />
                        </CButton>
                      </CTooltip>
                    )}
                  </div>
                </td>
              );
            },
          }}
        />
      </BasicTableContainer>
    </>
  );
};

export default ReadOnlyConstantsTable;
