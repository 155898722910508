import { CLink } from "@coreui/react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SECTOR_KEYS } from "src/constants/sectorKeys";
import { useCompletedRate, useSectionOpen, useSectors } from "src/hooks";
import {
  selectHasPurchases,
  selectOrgId,
  selectPeriodSettings,
  selectPurchaseData,
  selectRecommendations,
  selectSuppliers,
} from "src/store/projectSelectors";

const useNextActions = ({ areResultsCalculated }) => {
  const { t } = useTranslation();
  const suppliers = useSelector(selectSuppliers);
  const recommendations = useSelector(selectRecommendations);

  const hasPurchases = useSelector(selectHasPurchases);

  const { purchasesClassified } = useSelector(selectPurchaseData) || {};

  const projectSettings = useSelector(selectPeriodSettings);
  const { classifyTask, runningQueue } = useSelector(selectPurchaseData) || {};

  const { calculateSectorCompletion } = useCompletedRate();
  const { SECTORS } = useSectors();

  const flattenedRecomms = Object.entries(recommendations || {})
    .map((entry) => {
      const [sector, items] = entry;

      return items.map((item) => {
        return { ...item, sector };
      });
    })
    .reduce((acc, val) => [...acc, ...val], []);

  const recommendationsExist = !!flattenedRecomms?.length;

  const recommendationsAreAccepted = !!flattenedRecomms.filter(
    (recomm) => !!Number(recomm.shareImplemented) && !!recomm.yearImplemented
  )?.length;

  const arePurchasesUploaded = hasPurchases;

  const isPurchaseClassificationInProgress = !!classifyTask || !!runningQueue;

  const noUnclassifiedPurchases = purchasesClassified;

  const isPurchaseAutoClassificationComplete =
    arePurchasesUploaded && noUnclassifiedPurchases;

  // const arePurchaseCategoriesSelected =
  //   arePurchasesUploaded &&
  //   !purchases?.filter((item) => item.confidence === "weak")?.length;

  const areProjectCategoriesSorted = !!projectSettings?.categories;

  const areSuppliersSelected = !!suppliers?.filter(
    (supplier) => supplier.selected
  )?.length;

  const isSupplierSurveyComplete =
    calculateSectorCompletion(SECTOR_KEYS.suppliersData) == 1;

  const hasAdminReviewedSuppliers = !useSelector(
    (state) => state.project?.data?.["suppliers-changed"]
  );

  const orgId = useSelector(selectOrgId);

  const { checkIsOpen } = useSectionOpen();

  const createYourActions = () => {
    const yourActions = SECTORS.filter(
      (sector) =>
        ![SECTOR_KEYS.purchaseData, SECTOR_KEYS.suppliersData].includes(
          sector.name
        )
    )
      .filter((sector) => checkIsOpen(sector.name).isOpen)
      .filter((sector) => calculateSectorCompletion(sector.name) !== 1)
      .map((sector) => (
        <p>
          <Trans
            i18nKey="welcomeModal.actions.completeSection"
            values={{ section: sector.title }}
            components={{
              1: (
                <CLink
                  className="text-info"
                  to={`project/${orgId}/${sector.path}`}
                ></CLink>
              ),
            }}
          />
        </p>
      ));

    if (checkIsOpen(SECTOR_KEYS.purchaseData).isOpen && !arePurchasesUploaded) {
      yourActions.push(
        <p>
          Upload{" "}
          <CLink className="text-info" to={`project/${orgId}/purchaseData`}>
            Purchases
          </CLink>{" "}
          ledger.
        </p>
      );
    }

    if (
      arePurchasesUploaded &&
      !isPurchaseClassificationInProgress &&
      !isPurchaseAutoClassificationComplete
    ) {
      yourActions.push(
        <p>
          Confirm the uploaded ledger is correct in{" "}
          <CLink className="text-info" to={`project/${orgId}/purchaseData`}>
            Purchases
          </CLink>{" "}
          section.
        </p>
      );
    }

    if (areSuppliersSelected && !isSupplierSurveyComplete) {
      yourActions.push(
        <p>
          <Trans
            i18nKey="welcomeModal.actions.sendOut"
            components={{
              1: (
                <CLink
                  className="text-info"
                  to={`project/${orgId}/supplierData`}
                ></CLink>
              ),
            }}
          ></Trans>
        </p>
      );
    }

    if (!yourActions.length && !areResultsCalculated) {
      yourActions.push(
        <p>
          <Trans
            i18nKey="welcomeModal.actions.calculate"
            components={{
              1: (
                <CLink
                  className="text-info"
                  to={`project/${orgId}/summaryDetails`}
                ></CLink>
              ),
            }}
          ></Trans>
        </p>
      );
    }

    if (recommendationsExist && !recommendationsAreAccepted) {
      yourActions.push(
        <p>
          <Trans
            i18nKey="welcomeModal.actions.reviewRecomms"
            components={{
              1: (
                <CLink
                  className="text-info"
                  to={`project/${orgId}/summaryDetails`}
                ></CLink>
              ),
            }}
          ></Trans>
        </p>
      );
    }
    return yourActions;
  };

  const createOurActions = () => {
    const ourActions = [];

    if (arePurchasesUploaded) {
      // if (
      //   isPurchaseClassificationInProgress ||
      //   !arePurchaseCategoriesSelected
      // ) {
      //   ourActions.push("Assign correct categories to purchases.");
      // }
      if (!areProjectCategoriesSorted) {
        ourActions.push(t("welcomeModal.actions.assignCategories"));
      }

      if (!areSuppliersSelected) {
        ourActions.push(t("welcomeModal.actions.selectSuppliers"));
      }
    }

    if (isSupplierSurveyComplete && !hasAdminReviewedSuppliers) {
      ourActions.push(t("welcomeModal.actions.reviewSurvey"));
    }
    if (areResultsCalculated && !recommendationsExist) {
      ourActions.push(t("welcomeModal.actions.reviewResults"));
    }

    return ourActions;
  };

  return { yourActions: createYourActions(), ourActions: createOurActions() };
};

export default useNextActions;
