import { CInputGroup } from "@coreui/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  VerticalRadioButtons,
} from "src/components";
import { useSurveyConstants } from "src/hooks";
import AboveCard from "../components/AboveCard";

const SupplierSurvey5 = ({ values, onChange, info, isDemo }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();

  const { usageIT } = useSurveyConstants();

  return (
    <FormStepWrapper aboveCardSlot={<AboveCard {...{ info, isDemo }} />}>
      <QuestionWrapper>
        <h3 className="mb-4">
          {t(
            "supplierSurvey.q_it",
            `8. Thinking about your internet usage as an organisation over
              {{ period }}, do you think you use little, medium or a high volume
              of digital data?`,
            { period: info.reportingPeriod }
          )}
        </h3>
        <CInputGroup>
          <VerticalRadioButtons
            name="internet-usage"
            onChange={onChange}
            data={usageIT}
            defaultValue={values["internet-usage"]}
          />
        </CInputGroup>
        <ErrorText value={errors["internet-usage"]} />
      </QuestionWrapper>
    </FormStepWrapper>
  );
};

export default SupplierSurvey5;
