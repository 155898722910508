import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
};

export const coreuiSlice = createSlice({
  name: "coreui",
  initialState,
  reducers: {
    set: (state, action) => {
      state = { ...state, ...action.payload };
      return state;
    },
    toggleDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
  },
});

export const selectSidebarShow = (state) => state.coreui.sidebarShow;

export const { set, toggleDarkMode } = coreuiSlice.actions;
export default coreuiSlice.reducer;
