import { t } from "i18next";
import { objHasValues } from "src/utils/common-utils";
import {
  isNonNegativeNum,
  isRequiredBool,
  isRequired,
  isNotEmptyArray,
  isConditionallyRequiredStr,
  isConditionallyRequiredNum,
  isRequiredBetween,
  isUrl,
  isBetween,
} from "src/utils/validationOptions";

const getSupplierFields = (getValues) => {
  const fields = {
    2: [
      { name: "name", options: isRequired },
      { name: "services", options: isNotEmptyArray },
    ],
    3: [
      { name: "employees-number", options: isRequired },
      {
        name: "days-from-home",
        options: isRequiredBetween(0, 7),
      },
      { name: "commuting-pattern", options: isRequired },
    ],
    4: [
      { name: "country", options: isRequired },
      { name: "work-travel", options: isRequired },
    ],
    5: [{ name: "internet-usage", options: isRequired }],
    6: [
      { name: "provides-turnover", options: isRequiredBool },
      {
        name: "turnover",
        options: isConditionallyRequiredNum(getValues, "provides-turnover"),
      },
      {
        name: "currency",
        options: isConditionallyRequiredStr(getValues, "provides-turnover"),
      },
      {
        name: "share-from-client",
        options: {
          validate: {
            isRequiredIfNoTurnover: (value) => {
              if (getValues("provides-turnover")) return true;
              if (!value) return t("validation.enterValues");
              return true;
            },
          },
        },
      },
    ],
    7: [
      { name: "knows-emissions", options: isRequiredBool },
      {
        name: "emissions",
        options: {
          validate: {
            isNotEmpty: (obj) => {
              if (!getValues("knows-emissions")) return true;
              if (!objHasValues(obj)) return t("validation.enterValues");
              return true;
            },
          },
        },
      },
      { name: "emissions.scope1", options: isNonNegativeNum },
      { name: "emissions.scope2", options: isNonNegativeNum },
      { name: "emissions.scope3", options: isNonNegativeNum },
      { name: "emissions.direct", options: isNonNegativeNum },
    ],
    8: [
      // { name: "buys-ads", options: isRequiredBool },
      {
        name: "ads",
        options: {
          validate: {
            isNotEmpty: (obj) => {
              if (!getValues("buys-ads")) return true;
              if (!objHasValues(obj)) return t("validation.enterValues");
              return true;
            },
          },
        },
      },
      { name: "ads.mobile", options: isNonNegativeNum },
      { name: "ads.desktop", options: isNonNegativeNum },
      { name: "ads.laptop", options: isNonNegativeNum },
      { name: "ads.tablet", options: isNonNegativeNum },
      { name: "lab-trips-week" },
      { name: "lab-trip" },
      { name: "post-trip" },
      { name: "lab-inbound" },
      { name: "lab-outbound" },
      { name: "lab-energy", options: isBetween(0, 1e6) },
      { name: "lab-gas", options: isBetween(0, 1e6) },
    ],
    9: [
      { name: "produces-goods" },
      { name: "products" },
      { name: "construction-materials" },
    ],
    10: [{ name: "policy-link", options: isUrl }],
  };

  return fields;
};

export default getSupplierFields;
