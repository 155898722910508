import styled from "styled-components";

const StyledDiv = styled.div`
  .pagination {
    justify-content: end !important;
  }

  .nav-link {
    font-size: 14px;
  }

  .upper > thead {
    background-color: var(--clr-table-light-1);
  }
  table {
    box-sizing: border-box;
    tbody {
      tr:nth-child(4n + 1),
      tr:nth-child(4n + 2) {
        background-color: var(--clr-table-light-2);
      }
    }

    label {
      font-size: 11px;
      font-weight: 700;
    }

    th,
    td {
      padding: 1rem;
      padding-right: 0.5rem;
      text-align: start;
      border-top: none;
      border-bottom: none;
      vertical-align: middle !important;
    }

    td:focus {
    }

    th {
      font-size: 11px;
      font-weight: 700;
    }
  }
`;

export default StyledDiv;
