export const purchaseDataIntroSteps = [
  {
    key: "welcome",
    target: ".purchaseData-welcome",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    spotlightClicks: false,
    placement: "center",
  },
  {
    key: "accounting_software_selection",
    target: ".purchaseData-intro-upload-accounting",
    placement: "auto",
    disableBeacon: true,
    paragraphs: 2,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "download_upload_button",
    target: ".purchaseData-intro-upload-excel",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "save_button",
    target: ".intro-save-btn",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "click_view_purchases_tab",
    target: ".purchaseData-intro-view-tab",
    placement: "auto",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
    styles: {
      buttonNext: {
        display: "none",
      },
      options: {
        zIndex: 10000,
      },
    },
  },

  {
    key: "results_table",
    target: ".purchaseData-intro-results-table",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "select_category",
    target: ".purchaseData-intro-results-category",
    placement: "auto",
    disableBeacon: true,
    paragraphs: 2,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "save_changes_button",
    target: ".purchaseData-intro-results-save",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];
