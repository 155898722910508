import React from "react";
import { CCollapse } from "@coreui/react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { useState } from "react";
import { ButtonGhost } from "src/components";

const BasicCollapse = ({
  title,
  hideTitle,
  children,
  className = "",
  buttonClass,
  defaultShow,
}) => {
  const [showCollapse, setShowCollapse] = useState(defaultShow || false);

  const textToDisplay = hideTitle && showCollapse ? hideTitle : title;

  return (
    <div className={className}>
      <ButtonGhost
        className={`w-100 d-flex justify-content-between align-items-end text-left py-3 ${buttonClass}`}
        onClick={() => setShowCollapse((prev) => !prev)}
      >
        <h5 className="m-0">{textToDisplay}</h5>
        {showCollapse ? (
          <IoMdArrowDropup fontSize={26} />
        ) : (
          <IoMdArrowDropdown fontSize={26} />
        )}
      </ButtonGhost>

      {/* !! conditional render - performance issues */}
      {showCollapse && (
        <CCollapse show={showCollapse} className="pt-3 pb-3">
          {children}
        </CCollapse>
      )}
    </div>
  );
};

export default BasicCollapse;
