export const OTHER_MATERIALS = [

    {
        category: "Other",
        label: "Books",
        value: "books"

    },
    {
        category: "Other",
        label: "Glass",
        value: "glass"

    },
    {
        category: "Other",
        label: "Clothing",
        value: "clothing"

    },
    {
        category: "Other",
        label: "Food and drink",
        value: "food-drink"

    },
    {
        category: "Organic",
        label: "Compost derived from garden waste",
        value: "compost-garden"

    },
    {
        category: "Organic",
        label: "Compost derived from food and garden waste",
        value: "compost-food-garden"

    },
    {
        category: "Electrical items",
        label: "fridges and freezers",
        value: "fridges-freezers"

    },
    {
        category: "Electrical items",
        label: "large",
        value: "large"

    },
    {
        category: "Electrical items",
        label: "IT",
        value: "IT"

    },
    {
        category: "Electrical items",
        label: "small",
        value: "small"

    },
    {
        category: "Electrical items",
        label: "Batteries - Alkaline",
        value: "batteries-alkaline"

    },
    {
        category: "Electrical items",
        label: "Batteries - Li ion",
        value: "batteries-li-ion"

    },
    {
        category: "Electrical items",
        label: "Batteries - NiMh",
        value: "batteries-nimh"

    },
    {
        category: "Metal",
        label: "aluminium cans and foil (excl. forming)",
        value: "aluminium"

    },
    {
        category: "Metal",
        label: "mixed cans",
        value: "mixed-cans"

    },
    {
        category: "Metal",
        label: "scrap metal",
        value: "scrap-metal"

    },
    {
        category: "Metal",
        label: "steel cans",
        value: "steel-cans"

    },
    {
        category: "Plastic",
        label: "average plastics",
        value: "average"

    },
    {
        category: "Plastic",
        label: "average plastic film",
        value: "film"

    },
    {
        category: "Plastic",
        label: "average plastic rigid",
        value: "rigid"

    },
    {
        category: "Plastic",
        label: "HDPE (incl. forming)",
        value: "HDPE"

    },
    {
        category: "Plastic",
        label: "LDPE and LLDPE (incl. forming)",
        value: "LDPE"

    },
    {
        category: "Plastic",
        label: "PET (incl. forming)",
        value: "PET"

    },
    {
        category: "Plastic",
        label: "PP (incl. forming)",
        value: "PP"

    },
    {
        category: "Plastic",
        label: "PS (incl. forming)",
        value: "PS"

    },
    {
        category: "Plastic",
        label: "PVC (incl. forming)",
        value: "PVC"

    },
    {
        category: "Paper and board",
        label: "Board",
        value: "board"

    },
    {
        category: "Paper and board",
        label: "Mixed",
        value: "mixed"

    },
    {
        category: "Paper and board",
        label: "Paper",
        value: "paper"

    }
]