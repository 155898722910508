import { cloneDeep } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DB_PATHS } from "src/constants";
import { NOTIFICATION_KEYS } from "src/constants/notificationKeys";
import {
  useClassificationTask,
  useCollectionData,
  useOwnerNotifications,
  usePurchaseFlags,
  useUpdateDatabase,
  useWriteLatestStep,
} from "src/hooks";

import { PURCHASE_CATEGORIES } from "src/constants/purchaseCategories";
import { useDoc } from "src/hooks/databaseHooks";
import {
  selectOrgData,
  selectOrgId,
  selectOrgName,
} from "src/store/projectSelectors";
import { convertKebabToDisplay } from "src/utils/common-utils";
import { groupByNameAndCurrency } from "src/utils/purchase-utils";

import averageConstants from "src/constants/average.json";
import beisConstants from "src/constants/beis.json";
import epaConstants from "src/constants/epa.json";
import exioConstants from "src/constants/exio.json";
import excelParser from "src/pages/purchaseData/PurchaseData1/PurchaseExcelParser";

const EMPTY_PURCHASE = {
  name: "",
  category: [],
  description: "",
  spending: 0,
  currency: "GBP",
  isNew: true,
};

const usePurchaseController = ({ isInAdminTools = false, purchases: purchasesInDB }) => {
  const { "purchases-loaded": purchasesLoaded } =
    useSelector(selectOrgData) || {};

  const orgId = useSelector(selectOrgId)

  const orgName = useSelector(selectOrgName);


  const [arePurchasesGrouped, setArePurchasesGrouped] = useState(false);

  const { sendAndScheduleReminderEmail, addToDailyUpdateEmail } =
    useOwnerNotifications();

  const { updateAllFlags } = usePurchaseFlags();

  const { writeLatestStepToDB } = useWriteLatestStep();

  const { setOrUpdateDocument, updateDocument } = useUpdateDatabase();

  const { deleteClassifyTaskIfExists } = useClassificationTask();

  const { t } = useTranslation();

  const purchases = useMemo(() => {
    const data = purchasesInDB?.length ? purchasesInDB : [EMPTY_PURCHASE];
    const sortedPurchases = cloneDeep(data).sort((a, b) =>
      a.date > b.date ? -1 : 1
    );



    const convertedPurchases = sortedPurchases.map((purchase) => ({
      ...purchase,
      delete: false,
    }));

    return arePurchasesGrouped
      ? groupByNameAndCurrency(convertedPurchases)
      : convertedPurchases;
  }, [purchasesInDB, arePurchasesGrouped]);

  const {
    downloadExistingPurchases
  } = excelParser({
    orgId,
    purchases,
  });

  const ledgerIsEmpty = !purchasesInDB?.length;

  const showConfirmationPrompt =
    !ledgerIsEmpty &&
    !!purchases?.filter((item) => !item.classified?.length).length;

  const logCategoryChange = (purchase, newCategory) => {
    if (!newCategory) return;
    const { name, category, description } = purchase;
    const firstCategory = category[0];

    const id = purchase.id;

    setValue(
      `log.${id}`,
      {
        input: `${name},${firstCategory},${description}`,
        category: newCategory,
      },
      { shouldTouch: true }
    );
  };

  const onWrite = async (values) => {
    try {
      await deleteClassifyTaskIfExists();
    } catch (err) {
      console.log(err);
    }

    if (arePurchasesGrouped) {
      const records = values?.purchases?.filter((item) => item.changed) || {};
      let log = {};
      if (records) {
        for (let record of records) {
          for (let purchaseId of record.ids) {
            await updateDocument([...DB_PATHS.purchases, purchaseId], {
              classified: record.classified,
              confidence: "strong",
            });
          }
          const { name, category, orgDescription } = record;
          const firstCategory = category[0];
          log[record.id] = {
            input: `${name},${firstCategory},${orgDescription}`,
            category: record.classified,
          };
        }
      }
      if (log && Object.keys(log).length) {
        await setOrUpdateDocument(["global", "categories-log"], {
          "categories-changes": log,
        });
      }

      return true;
    }

    if (!isInAdminTools) {
      const flagsToWrite = {
        "suppliers-changed": true,
        "categories-changed": true,
      };
      if (!purchasesLoaded) {
        flagsToWrite["purchases-loaded"] = true;
        sendAndScheduleReminderEmail({
          key: NOTIFICATION_KEYS.purchases,
          subject: t("purchaseData.reminderEmail.subject", { orgName }),
          text: t("purchaseData.reminderEmail.text", { orgName }),
        });
      } else {
        addToDailyUpdateEmail({
          key: NOTIFICATION_KEYS.purchases,
          text: t("notifications.dailyUpdate.purchasesUpdate", { orgName }),
        });
      }
      await updateAllFlags(flagsToWrite);

      await writeLatestStepToDB({ step: 2, sector: DB_PATHS.purchaseData });
    }

    if (values.log && Object.keys(values.log).length) {
      await setOrUpdateDocument(["global", "categories-log"], {
        "categories-changes": values.log,
      });
    }
  };

  const { onSubmit, formMethods, isSaving } = useCollectionData({
    collectionPath: DB_PATHS.purchases,
    name: "purchases",
    onWrite,
  });

  const { getValues, setValue } = formMethods;

  const onCategorisation = (e) => {
    if (arePurchasesGrouped) {
      setValue(e.target.name, e.target.value, {
        shouldTouch: true,
      });
      setValue(`${e.target.name.replace("classified", "changed")}`, true, {
        shouldTouch: true,
      });
      return;
    }

    const purchase = getValues(e.target.name.replace(".classified", ""));

    const newCategory = e.target.value?.[0];

    logCategoryChange(purchase, newCategory);

    if (newCategory) {
      setValue(e.target.name.replace("classified", "confidence"), "strong", {
        shouldTouch: true,
      });
    } else {
      setValue(e.target.name.replace("classified", "confidence"), "", {
        shouldTouch: true,
      });
    }

    setValue(e.target.name, e.target.value, { shouldTouch: true });
  };


  const { data: addedEf } = useDoc(["emission-factors", "added"]);


  // custom ef: {key:kebab-case-string,value:number}
  const addedEmissionFactors = addedEf?.["purchase-categories"]?.custom || {};

  // custom ef : {label:string, key:kebab-case-string}
  const convertedAddedEmissionFactors = Object.keys(addedEmissionFactors).map((key) => ({
    value: convertKebabToDisplay(key),
    label: convertKebabToDisplay(key),
  }));

  // const allPurchaseCategories = [
  //   ...new Set([...PURCHASE_CATEGORIES.map(c => c.label), ...additionalFactors, "Ignore"]),
  // ];

  const oldFactors = PURCHASE_CATEGORIES.map((item) => ({
    label: item.label,
    value: item.label,
  }))

  const beisFactors = beisConstants.map(i => i.Micro).map((item) => ({
    label: item,
    value: item,
  }))

  const epaFactors = epaConstants.map((item) => ({
    label: item,
    value: item,
  }))

  const exioFactors = exioConstants.map((item) => ({
    label: item,
    value: item,
  }))

  const averageFactors = averageConstants.map((item) => ({
    label: item,
    value: item,
  }))

  function convertFactors(factors, addAverage = true) {

    let result = [...factors, { label: "Ignore", value: "Ignore" }]

    if (addAverage) {
      result = [...result, ...averageFactors, ...convertedAddedEmissionFactors]
    } else {
      result = [...result, ...convertedAddedEmissionFactors.filter(obj => !PURCHASE_CATEGORIES.find(c => c.value.toLowerCase() === obj
        .value.toLowerCase()))]
    }

    return result.sort((a, b) => {
      try {
        return a.label.localeCompare(b.label);
      } catch (e) {
        return -1;
      }
    })
  }



  return {
    initValues: purchases,
    onSubmit,
    arePurchasesGrouped,
    setArePurchasesGrouped,
    formMethods,
    onCategorisation,
    showConfirmationPrompt,
    isSaving,
    ledgerIsEmpty,
    epaFactors: convertFactors(epaFactors),
    beisFactors: convertFactors(beisFactors),
    exioFactors: convertFactors(exioFactors),
    oldFactors: convertFactors(oldFactors, false),
    downloadExistingPurchases
  };
};

export default usePurchaseController;
