import { CCol, CRow } from "@coreui/react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BasicNumberInput,
  DistanceUnitSelect,
  ErrorText,
  QuestionWrapper,
} from "src/components";

const PostQuestion = ({
  values,
  onChange,
  errors,
  control,
  register,
  getValues,
}) => {
  const { t } = useTranslation();

  const [labInboud, labOutbound] = useWatch({
    control,
    name: ["lab-inbound", "lab-outbound"],
  });

  const usesPost = labInboud?.post > 0 || labOutbound?.post > 0;

  if (!usesPost) {
    return null;
  }

  register("post-trip", {
    validate: {
      required: (value) => {
        if (!getValues("lab-inbound.post") && !getValues("lab-outbound.post"))
          return true;
        if (!value?.distance) return "Please specify the distance";
        if (value.distance < 0 || value.distance > 500)
          return "The distance must be between 0 and 500";
        return true;
      },
    },
  });

  return (
    <div className="rounded bg-light px-3 pt-3 pb-4 mb-4">
      <p className="text-info font-bold mb-2">Courier/post use</p>
      <QuestionWrapper>
        <h3 className="mb-2">
          What is the average distance between your lab and the main practice
          you work with?
        </h3>

        <CRow style={{ marginLeft: "-15px" }}>
          <CCol className="col-12 col-md-9  ">
            <BasicNumberInput
              allowNegative={false}
              min={0}
              name={"post-trip.distance"}
              defaultValue={values["post-trip"]?.distance}
              onChange={onChange}
              placeholder={"Please enter distance"}
            />
          </CCol>
          <CCol className="col-12 col-md-3  ">
            <DistanceUnitSelect
              onChange={onChange}
              name={"post-trip.unit"}
              defaultValue={values["post-trip"]?.unit || "mi"}
              className="font-weight-bold"
            />
          </CCol>
        </CRow>
        <ErrorText value={errors["post-trip"]} />
      </QuestionWrapper>
    </div>
  );
};

export default PostQuestion;
