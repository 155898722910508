import { CFormGroup, CLabel } from "@coreui/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BasicNumberInput,
  DistanceUnitSelect,
  ErrorText
} from "src/components";
import { isBetween } from "src/utils/validationOptions";

const DistanceQuestion = ({
  onChange,
  name,
  defaultValue,
  errorValue,
  className = "",
}) => {
  const { register } = useFormContext();

  register(`${name}.distance`, isBetween(0, 1e3));

  const { t } = useTranslation();

  return (
    <div className={`d-flex w-100 justify-content-between ${className}`}>
      <CFormGroup className="col-8 pl-0 mr-1">
        <CLabel>{t("shared.distance", "Distance")}</CLabel>
        <BasicNumberInput
          allowNegative={false}
          min={0}
          decimalScale={2}
          name={`${name}.distance`}
          max={1000}
          placeholder={t("shared.enterDistance", "enter distance...")}
          onChange={(e) => {
            if (!defaultValue?.unit) {
              onChange({ target: { name: `${name}.unit`, value: "mi" } });
            }
            onChange({
              target: {
                name: `${name}.distance`,
                value: parseFloat(e.target.value),
              },
            });
          }}
          defaultValue={defaultValue?.distance}
        />
        <ErrorText value={errorValue?.distance} />
      </CFormGroup>
      <CFormGroup className="col-4 pr-0">
        <CLabel>{t("shared.unit", "Unit")}</CLabel>
        <DistanceUnitSelect
          onChange={onChange}
          name={`${name}.unit`}
          defaultValue={defaultValue?.unit || "mi"}
          className="font-weight-bold"
        />
        <ErrorText value={errorValue?.unit} />
      </CFormGroup>
    </div>
  );
};

export default DistanceQuestion;
