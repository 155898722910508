const { useTranslation } = require("react-i18next");

const useConstants = () => {
  const { t } = useTranslation();

  const SUPER_COLUMN = [
    {
      key: "freemium",
      label: t("superAdmin.isFree") + " (Y/N)",
      filter: false,
      _style: { width: "11%" },
    },
    {
      key: "name",
      label: t("shared.name", "Name"),
    },

    {
      key: "last-updated",
      label: t("shared.lastUpdate", "Last update"),
      filter: false,
      _style: { width: "15%" },
    },
    {
      key: "created",
      label: t("superAdmin.created"),
      filter: false,
      _style: { width: "15%" },
    },
    {
      key: "owner",
      label: t("superAdmin.owner"),
      filter: false,
      sorter: false,
    },

    {
      key: "archive",
      label: t("superAdmin.moveToArchive"),
      filter: false,
      _style: { width: "18%" },
    },
  ];

  const SUPER_NO_ITEM_MSG = {
    noItems: t("superAdmin.noOrgs", "No organisations created yet"),
  };

  return { SUPER_COLUMN, SUPER_NO_ITEM_MSG };
};
export default useConstants;
