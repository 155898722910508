import { CButton } from "@coreui/react";
import styled from "styled-components";

const StyledButton = styled(CButton)`
  background-color: ${({ bgcolor }) => bgcolor || "#181743"};
  color: ${({ color }) => color || "#ffffff"};
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  border-radius: ${({ borderRadius }) => borderRadius || "12px"};
  border: none !important;

  padding: 8px 20px;
  letter-spacing: 1px;

  :hover {
    color: white;
    background-color: ${({ hovercolor }) => hovercolor || "#050422"};
  }

  :disabled {
    cursor: not-allowed;
    color: white !important;
    border: 1px solid #999999 !important;
    background-color: #909090 !important;
  }

  .c-dark-theme & {
    :disabled {
      background-color: var(--clr-table-header-dark) !important;
    }
  }
`;

export default StyledButton;
