import { CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ButtonBasic, SaveButton } from "src/components";
import { useNextSector } from "src/hooks";

const CompleteButtons = ({ isTouched, ...rest }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { findNextSector } = useNextSector();

  const location = useLocation();
  const { nextPath } = findNextSector(location);

  return (
    <div className="d-flex justify-content-center w-100 mt-4">
      {isTouched ? (
        <SaveButton className="mb-2" {...rest} />
      ) : (
        <CRow className="d-flex flex-column align-items-center flex-lg-row align-items-lg-baseline ">
          <ButtonBasic
            className="mb-2"
            onClick={() => history.push("/dashboard")}
          >
            {t("shared.backToSummary")}
          </ButtonBasic>
          <>
            <div className="mx-3 mb-2">{t("shared.or")}</div>
            <ButtonBasic onClick={() => history.push(nextPath)}>
              {t("shared.nextStep")}
            </ButtonBasic>
          </>
        </CRow>
      )}
    </div>
  );
};

export default CompleteButtons;
