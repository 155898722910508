import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  useExcel,
  useHttp,
  usePurchaseFlags,
  useUpdateDatabase,
  useWriteLatestStep,
} from "src/hooks";
import { setAlertWithTimeout } from "src/store/operations";

import { useTranslation } from "react-i18next";
import { DB_PATHS } from "src/constants";
import { useExcelConstants } from "src/hooks";

import { selectOrgName, selectPeriod, selectProjectCategories } from "src/store/projectSelectors";
import usePurchaseConstants from "../constants/usePurchaseConstants";

const PurchaseExcelParser = ({ orgId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const periodId = useSelector(selectPeriod)

  const { sendRequest } = useHttp();

  const { writeLatestStepToDB } = useWriteLatestStep();

  const { getRef } = useUpdateDatabase();

  const { updateAllFlags } = usePurchaseFlags();

  const {
    createAndSaveExcelSheet,
    excelLog,
    clearExcelLog,
    setExcelLog,

  } = useExcel();

  const { HEADER_STYLES } = useExcelConstants();

  const {
    CURRENCY_WORKSHEET,
    PURCHASE_EXCEL_COLUMNS,
    PURCHASE_EXCEL_PROPS,
    XLSX_PURCHASE_DATA,
  } = usePurchaseConstants();

  /***************EXCEL LOADING & MODAL**************/

  const [showExcelModal, setShowExcelModal] = useState(false);

  const toggleExcelModal = () => {
    clearExcelLog();
    setShowExcelModal((prev) => !prev);
  };


  /***************DOWNLOAD EXISTING PURCHASES AS EXCEL**************/

  const orgName = useSelector(selectOrgName)

  const projectCategories = useSelector(selectProjectCategories)



  const downloadExistingPurchases = async () => {
    try {

      const purchasesSnap = await getRef(DB_PATHS.purchases).get()

      const purchases = purchasesSnap.docs.map((doc) => doc.data())

      const purchasesToDisplayExcel = purchases.length
        ? purchases.map((purchase) => {
          const { id, name, spending, category, description, currency, date, classified } =
            purchase;

          const categoryStr = category
            ? Array.isArray(category)
              ? category.join(", ")
              : category
            : "";

          const classifiedStr = classified
            ? Array.isArray(classified)
              ? classified.join(", ")
              : classified
            : "";

          const macroCategory = projectCategories?.find((cat) => cat.subcategories.includes(classifiedStr))?.name || ""

          return {
            id,
            name,
            category: categoryStr,
            description,
            spending,
            currency,
            date,
            classified: classifiedStr,
            projectCategory: macroCategory
          };
        })
        : XLSX_PURCHASE_DATA;



      const columns = [...PURCHASE_EXCEL_COLUMNS, { header: "Category", key: "classified", width: 30 }, { header: "Project category", key: "projectCategory", width: 30 }]
      await createAndSaveExcelSheet({
        columns,
        dataAoA: convertDataIntoExcel(purchasesToDisplayExcel, columns),
        headerStyles: HEADER_STYLES,
        sheetName: "Purchases",
        fileName: `${orgName}-purchases`,
        applyWorksheetProperties: PURCHASE_EXCEL_PROPS,
      });
    } catch (err) {
      console.log(err)
    }
  };

  /***************DATA INTO EXCEL**************/

  const downloadExcel = async () => {
    try {
      await createAndSaveExcelSheet({
        columns: PURCHASE_EXCEL_COLUMNS,
        dataAoA: [],
        headerStyles: HEADER_STYLES,
        sheetName: "Purchases",
        fileName: "purchases",
        applyWorksheetProperties: PURCHASE_EXCEL_PROPS,
        addWorksheet: CURRENCY_WORKSHEET,
      });
    } catch (err) {
      console.log(err)
    }
  };

  const convertDataIntoExcel = (arrayOfObjects, columns = PURCHASE_EXCEL_COLUMNS) => {
    const arrayOfArrays = arrayOfObjects.map((employeeObj) => {
      const row = [];
      columns.forEach((column) => {
        const { key } = column;

        row.push(employeeObj[key] || "");
      });

      return row;
    });
    return arrayOfArrays;
  };




  /***************DATA FROM EXCEL**************/

  const updateDbWithExcel = async (data) => {
    if (!data) return;
    try {

      const response = await sendRequest("/uploadPurchases", {
        body: JSON.stringify({ purchases: data, orgId, periodId }),
      }, true)

      const { excelLog } = response

      if (excelLog) {
        setExcelLog(excelLog);
      }


      await updateAllFlags({
        "suppliers-changed": true,
        "categories-changed": true,
      });

      await writeLatestStepToDB({ step: 2, sector: DB_PATHS.purchaseData });

      dispatch(
        setAlertWithTimeout({
          type: "success",
          text: t(
            "notifications.excelSuccess",
            "Data has been updated successfully!"
          ),
        })
      );
    } catch (err) {
      console.err(err);
      dispatch(
        setAlertWithTimeout({
          type: "error",
          text: t(
            "errors.excelError",
            "Something went wrong. Data has not been updated!"
          ),
        })
      );
    }
    finally {
      setShowExcelModal(true);
    }
  };

  return {
    downloadExcel,
    updateDbWithExcel,
    excelLog,
    toggleExcelModal,
    showExcelModal,
    downloadExistingPurchases
  };
};

export default PurchaseExcelParser;
