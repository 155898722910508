export const CONSTRUCTION_MATERIALS = [
    { label: "Aggregates and sand", value: "aggregates-and-sand" },
    { label: "Aluminium", value: "aluminium" },
    { label: "Asphalt", value: "asphalt" },
    { label: "Bitumen", value: "bitumen" },
    { label: "Brass", value: "brass" },
    { label: "Brick", value: "brick" },
    { label: "Bronze", value: "bronze" },
    { label: "Carpet", value: "carpet" },
    { label: "Cement", value: "cement" },
    { label: "Mortar", value: "mortar" },
    { label: "Admixtures", value: "admixtures" },
    { label: "Ceramics", value: "ceramics" },
    { label: "Clay", value: "clay" },
    { label: "Concrete", value: "concrete" },
    { label: "Concrete blocks", value: "concrete-blocks" },
    { label: "Copper", value: "copper" },
    { label: "Glass", value: "glass" },
    { label: "Insulation", value: "insulation" },
    { label: "Iron", value: "iron" },
    { label: "Lead", value: "lead" },
    { label: "Lime", value: "lime" },
    { label: "Linolium", value: "linolium" },
    { label: "Calcium Silicate Sheet", value: "calcium-silicate-sheet" },
    { label: "Chromium", value: "chromium" },
    { label: "Cotton, Padding", value: "cotton-padding" },
    { label: "Cotton, Fabric", value: "cotton-fabric" },
    { label: "Damp Proof Course/Membrane", value: "damp-proof-course-membrane" },
    { label: "Flax", value: "flax" },
    { label: "Fly Ash", value: "fly-ash" },
    { label: "Grit", value: "grit" },
    { label: "Ground Limestone", value: "ground-limestone" },
    { label: "Glass Reinforced Plastic - GRP - Fibreglass", value: "glass-reinforced-plastic-fibreglass" },
    { label: "Lithium", value: "lithium" },
    { label: "Mandolite", value: "mandolite" },
    { label: "Mineral Fibre Tile (Roofing)", value: "mineral-fibre-tile-roofing" },
    { label: "Manganese", value: "manganese" },
    { label: "Mercury", value: "mercury" },
    { label: "Molybedenum", value: "molybedenum" },
    { label: "Nickel", value: "nickel" },
    { label: "Perlite - Expanded", value: "perlite-expanded" },
    { label: "Perlite - Natural", value: "perlite-natural" },
    { label: "Quartz powder", value: "quartz-powder" },
    { label: "Shingle", value: "shingle" },
    { label: "Slag (GGBS)", value: "slag-ggbs" },
    { label: "Silver", value: "silver" },
    { label: "Straw", value: "straw" },
    { label: "Terrazzo Tiles", value: "terrazzo-tiles" },
    { label: "Vanadium", value: "vanadium" },
    { label: "Vermiculite - Expanded", value: "vermiculite-expanded" },
    { label: "Vermiculite - Natural", value: "vermiculite-natural" },
    { label: "Water", value: "water" },
    { label: "Wood stain/Varnish", value: "wood-stain-varnish" },
    { label: "Yttrium", value: "yttrium" },
    { label: "Zirconium", value: "zirconium" },
    { label: "Paint", value: "paint" },
    { label: "Plaster", value: "plaster" },
    { label: "Plastics", value: "plastics" },
    { label: "Rubber", value: "rubber" },
    { label: "Sealents and adhesives", value: "sealents-and-adhesives" },
    { label: "Soil", value: "soil" },
    { label: "Steel", value: "steel" },
    { label: "Stone", value: "stone" },
    { label: "Timber", value: "timber" },
    { label: "Tin", value: "tin" },
    { label: "Titanium", value: "titanium" },
    { label: "Tyres", value: "tyres" },
    { label: "Vinyl flooring", value: "vinyl-flooring" },
    { label: "Zinc", value: "zinc" },
]