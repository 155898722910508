import { useParams } from "react-router-dom";
import { useSteps } from "react-step-builder";
import { useFirestoreDocDataOnce } from "reactfire";
import { DB_PATHS } from "src/constants";
import { SUPPLIER_STATUS } from "src/constants/supplierStatus";
import { useDatabasePaths, useMasterForm, useUpdateDatabase } from "src/hooks";
import { useDoc } from "src/hooks/databaseHooks";
import getSupplierFields from "./getSupplierFields";


const SupplierSurveyController = ({ isDemo }) => {
  const { orgId, supplierID, periodId } = useParams();

  const { getPath } = useDatabasePaths();
  const { updateDocument, getRef } = useUpdateDatabase();

  const { data: periodData } = useFirestoreDocDataOnce(
    getRef(getPath(DB_PATHS.period, orgId, periodId))
  );

  const reportingPeriod = periodData
    ? periodData.startDate + " - " + periodData.endDate
    : "";

  const { data: orgData } = useDoc(getPath(DB_PATHS.organisation, orgId));

  const info = {
    orgName: orgData?.name,
    orgId,
    supplierID,
    reportingPeriod,
    periodId,
  };

  const surveyPath = getPath(
    [...DB_PATHS.supplierSurvey, supplierID || "demo"],
    orgId,
    periodId
  );

  const { status, data: dataDB } = useDoc(surveyPath);

  const masterFormMethods = useMasterForm({
    pathArr: surveyPath,
    getFieldsObject: getSupplierFields,
    dataDB,
    canAdvanceEmpty: false,
    isDemo,
  });

  const onSurveyCompleted = async (dataId) => {
    console.log(dataId);
    if (!dataId) return;

    await updateDocument(
      getPath([...DB_PATHS.suppliers, dataId], orgId, periodId),
      {
        status: SUPPLIER_STATUS.complete,
      }
    );

    await updateDocument(
      getPath(
        [...DB_PATHS.supplierSurvey, supplierID || "demo"],
        orgId,
        periodId
      ),
      {
        status: SUPPLIER_STATUS.complete,
      }
    );
  };

  const { prev } = useSteps();

  return {
    ...masterFormMethods,
    prevClickHandler: prev,
    info,
    status,
    onSurveyCompleted,
    reportingPeriod,
  };
};

export default SupplierSurveyController;
