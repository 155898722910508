export const TUTORIAL_SECTIONS = [
  {
    urlPath: "coreData",
    dbPath: "completeCoreDataIntro",
  },
  {
    urlPath: "siteData",
    dbPath: "completeSiteDataIntro",
  },
  {
    urlPath: "employeeData",
    dbPath: "completeEmployeeDataIntro",
  },
  {
    urlPath: "forecastingData",
    dbPath: "completeForecastingDataIntro",
  },
  {
    urlPath: "fleetData",
    dbPath: "completeFleetDataIntro",
  },
  {
    urlPath: "purchaseData",
    dbPath: "completePurchaseDataIntro",
  },
  {
    urlPath: "supplierData",
    dbPath: "completeSupplierDataIntro",
  },
  {
    urlPath: "travelData",
    dbPath: "completeTravelDataIntro",
  },
  {
    urlPath: "adData",
    dbPath: "completeAdDataIntro",
  },
  {
    urlPath: "investmentData",
    dbPath: "completeInvestmentDataIntro",
  },
  {
    sectionName: "summary",
    urlPath: "summaryDetails",
    dbPath: "completeResultsIntro",
  },
];
