import stringHash from "string-hash";
import { transform, isEqual, isArray, isObject, cloneDeep, set } from "lodash";

/**
 *
 * Returns Random String of a-z. Used in keys
 *
 * @param {Int} len - The length of the Returned String
 * @returns {Str}
 */
export const generateRandomKey = (len) => {
  return Math.random().toString(36).substring(len);
};

/**
 *
 * Recurssive function that will remove key and value of any undefined item in an object
 * important as firebaase will not allow undefined writes.
 * ignores arrays
 * @param {Obj} obj
 * @returns {obj}
 */
export const removeUndefinedFromObjectValues = (obj) => {
  let newObj = {};

  for (var key in obj) {

    if (key === "undefined") {
      continue;
    } else if (Array.isArray(obj[key])) {

      newObj[key] = obj[key].map(item => {

        if (typeof item === "object") {
          return removeUndefinedFromObjectValues(item)
        } else {
          return item
        }
        ;
      })
    }
    else if (obj[key] === Object(obj[key])) {
      //console.log("removeUndefinedFromObjectValues", key, obj[key])
      newObj[key] = removeUndefinedFromObjectValues(obj[key]);
    } else if (obj[key] !== undefined) newObj[key] = obj[key];
  }

  return newObj;
};

export const convertStrToBool = (str) => {
  if (str === "undefined") return;
  if (typeof str === "boolean") return str;
  if (str === "true") {
    return true;
  } else {
    return false;
  }
};

export const objHasValues = (obj) => {
  if (typeof obj !== "object") return false;
  if (!obj) return false;

  for (let i of Object.keys(obj)) {
    if (obj[i]) {
      if (typeof obj[i] !== "object") {
        return true;
      } else {
        if (objHasValues(obj[i])) return true;
      }
    }
  }
  return false;
};

export const simpleHash = (str) => {
  return stringHash(str).toString();
};

export const convertKebabToDisplay = (str) => {
  if (!str) return;
  return (str[0].toUpperCase() + str.slice(1)).replaceAll("-", " ");
};

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} findDiffObjs
 */

export function findDiffObj(origObj, newObj) {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0;
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key])) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] =
          isObject(value) && isObject(origObj[key])
            ? changes(value, origObj[key])
            : value;
      }
    });
  }
  return changes(newObj, origObj);
}

export const convertSpaceToDash = (str) => {
  return str.replaceAll(" ", "-");
};

export const sortByNamesAsc = (a, b) => {
  if (a.name > b.name) {
    return 1;
  }
  if (b.name > a.name) {
    return -1;
  }
  return 0;
};

export function checkArrayHasDuplicates(array) {
  if (array.length !== new Set(array).size) {
    return true;
  }

  return false;
}

export const setFieldInArrayOfObjects = (
  array,
  objectIndex,
  fieldName,
  fieldValue
) => {
  const newArr = [...array];
  newArr[objectIndex] = { ...newArr[objectIndex], [fieldName]: fieldValue };
  return newArr;
};

export function setWithExpiry(key, value, ttl) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function roundNumber(number, toDigits = 1) {
  if (number === undefined || isNaN(number)) return number;

  const parsed = parseFloat(number);
  if (isNaN(parsed)) return "";

  return +parsed.toFixed(toDigits).replace(/\.?0+$/, "");
}

export function sumValuesInArray(array, keyToSum) {
  if (!array?.length) return 0;
  return array.reduce((acc, val) => acc + (val[keyToSum] || 0), 0);
}

export const prettifyNum = (num, decimals = 2) => {
  if (num === undefined) return "";
  if (isNaN(num)) return 0;
  if (typeof num === "string") return num;

  let roundNum;
  if (Math.abs(num) >= 100) {
    roundNum = Math.round(num);
  } else if (Math.abs(num) >= 10) {
    roundNum = Math.round(num * 10) / 10;
  } else {
    roundNum = Math.round(num * (10 ** decimals)) / (10 ** decimals);
  }

  return roundNum.toLocaleString("en-US");
};

export const sumObjectValues = (obj) => {
  if (!obj) return 0;
  if (!objHasValues(obj)) return 0;
  const result = Object.values(obj).reduce((acc, curr) => acc + curr, 0);
  return Math.round(result);
};

export const checkIsFormTouched = (touchedFields) =>
  !!Object.keys(touchedFields || {}).length;

export const calculateAvgEmployeeNum = (
  employeeCountStart,
  employeeCountEnd
) => {
  if (employeeCountStart && employeeCountEnd) {
    return Math.round((employeeCountStart + employeeCountEnd) / 2);
  } else if (employeeCountStart || employeeCountEnd) {
    return employeeCountStart || employeeCountEnd;
  }
};

const calculatePeriodLength = (reportDateFrom, reportDateTo) => {
  if (!reportDateFrom || !reportDateTo) return 365;
  return (
    (Date.parse(reportDateTo) - Date.parse(reportDateFrom)) /
    (1000 * 60 * 60 * 24) +
    1
  );
};

export const getAnnualizeFn = (startDate, endDate) => {
  const periodLength = calculatePeriodLength(startDate, endDate);

  return (total) => Math.round((total * 365) / periodLength);
};

export const multiplyObj = (data, multiplyBy) => {
  if (!data) return {};
  const copyObj = cloneDeep(data);

  const multiply = (data) => {
    Object.entries(data).forEach((entry) => {
      const [key, value] = entry;
      if (typeof value !== "object") {
        set(copyObj, key, value * multiplyBy);
      } else {
        set(copyObj, key, this.multiplyObj(value, multiplyBy));
      }
    });
  };

  multiply(copyObj);
  return copyObj;
};

const KM_PER_MILE = 1.609344;

export const distanceConversion = (unit) => (unit !== "km" ? KM_PER_MILE : 1);
