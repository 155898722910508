import { CPopover, CSidebarNavItem, CSidebarNavTitle } from "@coreui/react";
import { useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import LogModal from "src/components/LogModal/LogModal";
import { usePermissions } from "src/hooks";
import {
  selectEsgSurveyOpen,
  selectOrgId,
  selectPeriod,
} from "src/store/projectSelectors";

const CollaborationToolsMenu = ({ className }) => {
  const [showLogModal, setShowLogModal] = useState(false);
  const { hasExtendedPermissions } = usePermissions();
  const organisation = useSelector(selectOrgId);

  const period = useSelector(selectPeriod);

  const { t } = useTranslation();

  const esgSurveyOpen = useSelector(selectEsgSurveyOpen);

  return (
    <>
      <div className={className}>
        <CSidebarNavTitle className="mt-0">
          {t("sidebar.collabTools", "Collaboration tools")}
        </CSidebarNavTitle>
        {hasExtendedPermissions && (
          <>
            <CPopover content={t("sidebar.viewCollab", "View collaborators")}>
              <CSidebarNavItem
                name={t("sidebar.collaborators", "Collaborators")}
                icon="cilUser"
                to={`/project/${organisation}/collaborators`}
              />
            </CPopover>
            <CPopover content={t("sidebar.assignTasks", "Assign tasks")}>
              <CSidebarNavItem
                name={t("sidebar.assignTasks", "Assign tasks")}
                icon="cilTask"
                to={`/project/${organisation}/tasks`}
              />
            </CPopover>
          </>
        )}
        <CPopover content={t("sidebar.myTasksPopover", "Tasks assigned to me")}>
          <CSidebarNavItem
            name={t("sidebar.myTasks", "My tasks")}
            icon="cilHandPointUp"
            to={`/project/${organisation}/assigned`}
          />
        </CPopover>

        <CPopover content={t("sidebar.history", "History of changes")}>
          <CSidebarNavItem
            name={t("sidebar.log", "Log")}
            icon="cilNewspaper"
            onClick={() => setShowLogModal(true)}
          />
        </CPopover>
        {esgSurveyOpen && (
          <CPopover content={"ESG self-assessment"}>
            <CSidebarNavItem
              name={"ESG survey"}
              icon="cilCheckCircle"
              to={`/project/${organisation}/${period}/self-assessment`}
            />
          </CPopover>
        )}
      </div>
      {showLogModal && (
        <LogModal
          show={showLogModal}
          toggle={() => setShowLogModal((prev) => !prev)}
        />
      )}
    </>
  );
};

export default CollaborationToolsMenu;
