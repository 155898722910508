import { CSpinner } from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledLoading } from "./Loading.styled";

export const Loading = () => {
  const { t } = useTranslation();
  return (
    <StyledLoading>
      <h1>{t("shared.loading", "Loading...")}</h1>
      <CSpinner />
    </StyledLoading>
  );
};

export default Loading;
