import { findDiffObj } from "src/utils/common-utils/index";
import { useSelector } from "react-redux";
import {
  selectInvestmentProviders,
  selectPensionProviders,
  selectSites,
  selectTasks,
} from "src/store/projectSelectors";
import { convertTaskObjToEmailLookup } from "../utils";
import { selectUserName } from "src/store/userSlice";
import useEmail from "../../useEmail/useEmail";

const useTaskEmail = () => {
  const tasks = useSelector(selectTasks);
  const sites = useSelector(selectSites);
  const pensionProvs = useSelector(selectPensionProviders);
  const investmentProvs = useSelector(selectInvestmentProviders);
  const { sendNewTasksEmail } = useEmail();

  const adminName = useSelector(selectUserName);

  const sendTaskEmails = (values) => {
    const newTasks = findDiffObj(tasks || {}, values);
    const tasksByEmailLookup = convertTaskObjToEmailLookup(
      newTasks,
      sites,
      pensionProvs,
      investmentProvs
    );

    for (let userEmail in tasksByEmailLookup) {
      try {
        sendNewTasksEmail({
          email: userEmail,
          tasks: tasksByEmailLookup[userEmail],
          adminName,
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  return { sendTaskEmails };
};

export default useTaskEmail;
