import { cloneDeep } from "lodash";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setAlertWithTimeout } from "src/store/operations";
import { removeUndefinedFromObjectValues } from "src/utils/common-utils";
import { useUpdateDatabase } from "..";

/**
 * Custom React hook that provides functionality for handling data from a Firestore collection. It is used with CollectionTable component.
 *
 * @param {string} name - is used to identify the collection in the form data.
 * @param {string[]} collectionPath - An array of strings that represents the path to the collection in the Firestore database.
 * @param {function} onWrite - A function that gets called when a document is written to the database. It returns a boolean that indicates if the writing process should be stopped.
 * @param {function} convertItemBeforeSaving - A function that gets called before saving an item to the database. It can be used to modify the item before saving it.
 * @param {function} onDelete - A function that gets called when an item is deleted from the database.
 *
 * @return {{onSubmit:function,formMethods:object,isSaving:boolean,addItemToDelete:function}} - An object with the following properties:
 * - `onSubmit` - A function that gets called when the form is submitted.
 * - `formMethods` - An object that contains methods for handling the form.
 * - `isSaving` - A boolean that indicates if the form is currently being saved.
 * - `addItemToDelete` - A function that adds an item to the list of items that will be deleted from the database.
 *
 */

const useCollectionData = ({
  name: tableName,
  collectionPath,
  onWrite,
  convertItemBeforeSaving = async (item) => item,
  onDelete,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const itemsToDelete = useRef([]);

  const formMethods = useForm();

  const {
    formState: { touchedFields },
  } = formMethods;

  const { setOrUpdateDocument, addDocumentToCollection, deleteDocument } =
    useUpdateDatabase();

  const { getValues, trigger, clearErrors } = formMethods;

  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async (e) => {
    e?.preventDefault();

    setIsSaving(true);

    const currentFormValues = cloneDeep(getValues());

    const areFieldsTouched = currentFormValues?.[tableName]?.some(
      (item) => item.isChanged || item.isNew || item.delete
    );

    if (!areFieldsTouched) {
      setIsSaving(false);
      return true;
    }

    const areFieldsValid = await validateFields(trigger, currentFormValues);
    if (!areFieldsValid) {
      setIsSaving(false);
      return false;
    }

    try {
      await writeToDB(currentFormValues);

      dispatch(
        setAlertWithTimeout({
          type: "success",
          text: t(
            "notifications.excelSuccess",
            "Data has been updated successfully!"
          ),
        })
      );

      return true;
    } catch (err) {
      console.error(err);

      dispatch(
        setAlertWithTimeout({
          type: "error",
          text: t(
            "errors.excelError",
            "Something went wrong. Data has not been updated!"
          ),
        })
      );

      return false;
    } finally {
      clearErrors();
      for (let key in touchedFields) {
        delete touchedFields[key];
      }
      setIsSaving(false);
    }
  };

  const changeInDB = async (item) => {
    if (!item.id) return;

    await setOrUpdateDocument(
      [...collectionPath, item.id],
      removeUndefinedFromObjectValues({ ...item, isChanged: undefined })
    );
  };

  const addToDB = async (item) => {
    if (item.id) return;

    await addDocumentToCollection(
      collectionPath,
      removeUndefinedFromObjectValues({
        ...item,
        isNew: undefined,
        isChanged: undefined,
      })
    );
  };

  const deleteFromDB = async ({ id, name = "" }) => {
    onDelete?.(id);
    try {
      if (id) {
        await deleteDocument([...collectionPath, id]);
      }

      dispatch(
        setAlertWithTimeout({
          type: "success",
          text: t("notifications.itemDeleted", "Deleted {{name}}!", { name }),
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        setAlertWithTimeout({
          type: "error",
          text: t("notifications.itemDeleteFail", "Failed to delete {{name}}", {
            name,
          }),
        })
      );
    }
  };

  async function writeToDB(values) {
    const shouldStop = await onWrite?.(values);

    if (shouldStop) return;

    for (let item of itemsToDelete.current) {
      await deleteFromDB(item);
    }

    for (let entry of Object.entries(values[tableName])) {
      const [index, item] = entry;
      const itemToSave = await convertItemBeforeSaving(
        item,
        index,
        values[tableName]
      );
      if (itemToSave.delete) {
        await deleteFromDB(itemToSave);
      } else if (itemToSave.isNew && !itemToSave.delete) {
        await addToDB(itemToSave);
      } else if (itemToSave.isChanged) {
        await changeInDB(itemToSave);
      }
    }
    resetItemsToDelete();
  }

  async function validateFields(trigger, values) {
    let fieldsAreValid = true;
    for (let index in values[tableName]) {
      const item = values[tableName][index];
      if ((item.isNew || item.isChanged) && !item.delete) {
        const isValid = await trigger(`${tableName}.${index}`);
        if (!isValid) fieldsAreValid = false;
      }
    }
    return fieldsAreValid;
  }

  function resetItemsToDelete() {
    itemsToDelete.current = [];
  }

  function addItemToDelete(item) {
    const current = itemsToDelete.current;
    itemsToDelete.current = [...current, item];
  }

  return {
    onSubmit,
    formMethods,
    addItemToDelete,
    isSaving,
  };
};

export default useCollectionData;
