import { useDispatch } from "react-redux";
import { setAlertWithTimeout } from "src/store/operations";

const useToasts = () => {
  const dispatch = useDispatch();

  const showToast = ({ text, type }) => {
    dispatch(
      setAlertWithTimeout({
        type,
        text,
      })
    );
  };

  const tryAndNotify = async (operation, msgSuccess, msgFailure) => {
    try {
      await operation();
      dispatch(
        setAlertWithTimeout({
          type: "success",
          text: msgSuccess,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        setAlertWithTimeout({
          type: "error",
          text: msgFailure,
        })
      );
    }
  };

  return { showToast, tryAndNotify };
};

export default useToasts;
