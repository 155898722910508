import styled, { keyframes } from "styled-components";

export const StyledSuperTable = styled.div`
  table {
    thead {
      font-size: 0.8rem;
    }
    tbody {
      :hover {
        cursor: pointer;
      }
      td {
        font-size: 14px;
        vertical-align: middle;
      }
    }
  }

  .pagination {
    justify-content: center !important;
  }
`;

const color = keyframes`
  33%{
    color: var(--danger);
  }

  66%{
    color: var(--info);
  }

  99%{
    color: var(--success);
  }
`;

export const HighlightText = styled.div`
  .highlight-text {
    animation: ${color} 2s linear infinite;
    -webkit-animation: ${color} 2s ease-in-out infinite;
  }
`;
