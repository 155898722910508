import { useTranslation } from "react-i18next";
import { StyledCreatable } from "src/components";

const SupplierSelect = ({
  value,
  defaultValue,
  customDefault,
  onChange,
  name,
  purchases,
  ...rest
}) => {
  const suppliers = [...new Set(purchases.map((purchase) => purchase.name))];

  const { t } = useTranslation();
  return (
    <StyledCreatable
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      isSearchable
      isClearable
      backspaceRemovesValue
      classNamePrefix="react-select"
      placeholder={t("materialsData.selectSupplier")}
      options={suppliers.map((item) => {
        return { label: item, value: item };
      })}
      defaultValue={{ label: defaultValue, value: defaultValue }}
      value={{
        label: value,
        value: value,
      }}
      onChange={(e) => {
        onChange({ target: { name, value: e?.value || null } });
      }}
      className="w-100"
      name={name}
      {...rest}
    />
  );
};

export default SupplierSelect;
