import { CPopover, CSidebarNavDropdown, CSidebarNavItem } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectOrgId } from "src/store/projectSelectors";

const AdminToolsDropdown = ({ show, toggle }) => {
  const areCategoriesChanged = useSelector(
    (state) => state.project?.data?.["categories-changed"]
  );
  const areSuppliersChanged = useSelector(
    (state) => state.project?.data?.["suppliers-changed"]
  );

  const { t } = useTranslation();

  const organisation = useSelector(selectOrgId);

  return (
    <CSidebarNavDropdown
      name={t("sidebar.adminTools", "Admin Tools")}
      className={show ? "c-show" : ""}
      show={show}
      onClick={toggle}
    >
      <CPopover content={t("sidebar.editConstants", "Edit project constants")}>
        <CSidebarNavItem
          name={"Project constants"}
          icon="cilInput"
          to={`/project/${organisation}/constants`}
        />
      </CPopover>

      <CPopover content={t("sidebar.addCommentary", "Commentary")}>
        <CSidebarNavItem
          name={t("sidebar.addCommentary", "Commentary")}
          icon="cilCommentBubble"
          to={`/project/${organisation}/commentary`}
        />
      </CPopover>
      <CPopover content={t("sidebar.editCategory", "Edit categories")}>
        <CSidebarNavItem
          name={t("sidebar.categorySettings", "Category Settings")}
          icon="cilSettings"
          to={`/project/${organisation}/categories`}
          badge={
            areCategoriesChanged
              ? { color: "info", text: t("sidebar.newUpdate", "NEW UPDATE") }
              : {}
          }
        />
      </CPopover>
      <CPopover
        content={t(
          "sidebar.selectSuppliers",
          "Select suppliers and their emission factors"
        )}
      >
        <CSidebarNavItem
          name={t("sidebar.supplierSettings", "Supplier Settings")}
          icon="cilActionUndo"
          to={`/project/${organisation}/supplierSettings`}
          badge={
            areSuppliersChanged
              ? { color: "info", text: t("sidebar.newUpdate", "NEW UPDATE") }
              : {}
          }
        />
      </CPopover>
      <CPopover content={t("sidebar.recommendationsExpl")}>
        <CSidebarNavItem
          name={t("adminRecommendations.title")}
          icon="cilThumbUp"
          to={`/project/${organisation}/recommendations`}
        />
      </CPopover>
      <CPopover
        content={t("sidebar.overridePopover", "Override calculation results")}
      >
        <CSidebarNavItem
          name={t("sidebar.resultsOverride", "Results Override")}
          icon="cilCalculator"
          to={`/project/${organisation}/calculations`}
        />
      </CPopover>

      <CPopover content={t("sidebar.logPopover", "Logs of calculations")}>
        <CSidebarNavItem
          name={t("sidebar.logs", "Calculation Logs")}
          icon="cilFile"
          to={`/project/${organisation}/logs`}
        />
      </CPopover>
      <CPopover content={t("adminSections.explanation")}>
        <CSidebarNavItem
          name={t("adminSections.title")}
          icon="cilLockUnlocked"
          to={`/project/${organisation}/sections`}
        />
      </CPopover>
      <CPopover content="Evidence">
        <CSidebarNavItem
          name="Evidence"
          icon="cilBuilding"
          to={`/project/${organisation}/evidence`}
        />
      </CPopover>
      <CPopover content="Miscellanous settings">
        <CSidebarNavItem
          name="Organisation Settings"
          icon="cilBuilding"
          to={`/project/${organisation}/orgSettings`}
        />
      </CPopover>
      <CPopover content="Send and review survey">
        <CSidebarNavItem
          name="ESG Survey"
          icon="cilCheckCircle"
          to={`/project/${organisation}/admin-survey`}
        />
      </CPopover>
    </CSidebarNavDropdown>
  );
};

export default AdminToolsDropdown;
