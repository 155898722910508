import { CCard, CCardBody } from "@coreui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const YourActionsCard = ({ ourActions, yourActions }) => {
  const { t } = useTranslation();

  return (
    <CCard className="px-0 px-sm-3">
      <CCardBody>
        <h2 className="mb-3 text-center">
          <Trans
            i18nKey="welcomeModal.yourNextActions"
            components={{ 1: <span className="text-info "></span> }}
          ></Trans>
        </h2>
        {!!yourActions?.length ? (
          <ul className="  mb-0" style={{ fontSize: "16px" }}>
            {yourActions.map((action, idx) => {
              return <li key={`your-${idx}`}>{action}</li>;
            })}
          </ul>
        ) : !!ourActions?.length ? (
          <h5 className="text-info text-center">{t("welcomeModal.pleaseWait")}</h5>
        ) : (
          <h5 className="text-success text-center">{t("welcomeModal.nothingToDo")}</h5>
        )}
      </CCardBody>
    </CCard>
  );
};

export default YourActionsCard;
