import { set, upperFirst } from "lodash";

export const sentenceCase = (str) => {
  if (!str) return;
  return upperFirst(str.replaceAll("-", " "));
};

export const convertArrayEntryToDB = (entry, constObj) => {
  const {
    index,
    current,
    group,
    isNew,
    path,
    values,
    path1,
    path2,
    path3,
    path4,
  } = entry;

  const indexToSave =
    index !== undefined ? index : Object.keys(constObj).length;

  const newPath = [path4, path3, path2, path1]
    .filter((item) => !!item)
    .join(".");

  const currentDate = Date.now();

  const newObj = {
    index: indexToSave,
    current,
    date: currentDate,
    values: [{ value: current, date: currentDate }, ...values],
  };
  if (group) {
    newObj.group = group;
  }
  if (isNew) {
    newObj.custom = true;
    set(constObj, newPath, newObj);
    return;
  }
  set(constObj, path, newObj);
};

export const convertFromDBToSimpleObj = (obj) => {
  const result = {};
  Object.entries(obj).forEach((entry) => {
    const [key, value] = entry;
    if (value.current !== undefined) {
      result[key] = value.current;
    } else {
      result[key] = convertFromDBToSimpleObj(value);
    }
  });
  return result;
};

export const convertFromSimpleToDbObj = (obj) => {
  let currIdx = 0;
  const date = Date.now();

  const convertValues = (obj) => {
    const result = {};
    Object.entries(obj).forEach((entry) => {
      const [key, value] = entry;
      if (typeof value !== "object") {
        result[key] = {
          current: value,
          index: currIdx++,
          date,
          values: [{ value, date }],
        };
      } else {
        result[key] = convertValues(value);
      }
    });
    return result;
  };

  return convertValues(obj);
};

export const convertFromDbToArray = (obj) => {
  const arr = [];

  const convertEntries = (obj, parent = "") =>
    Object.entries(obj).forEach((entry) => {
      const [key, value] = entry;
      if (value.current !== undefined) {
        const path = parent + key;
        const pathArr = path.split(".").reverse();

        const arrayRow = { ...value, path };

        pathArr.forEach((segment, index) => {
          arrayRow[`path${index + 1}`] = segment;
        });
        arr.push(arrayRow);
      } else {
        convertEntries(value, parent + key + ".");
      }
    });
  convertEntries(obj);
  return arr;
};

export const convertFromArrayToDb = (arr) => {
  const result = {};
  const date = Date.now();
  arr.forEach((entry) => {
    const { value, path, values } = entry;
    const newObj = { ...entry, date, values: [{ value, date }, ...values] };
    set(result, path, newObj);
  });

  return result;
};
