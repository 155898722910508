import { useTranslation } from "react-i18next";
import { ButtonBasic } from "..";

const SaveButton = ({
  className,
  type = "submit",
  label,
  size = "lg",
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex w-100">
      <ButtonBasic
        size={size}
        className={`mx-auto px-5 ${className}`}
        type={type}
        {...rest}
      >
        {label || t("shared.saveChanges", "Save changes")}
      </ButtonBasic>
    </div>
  );
};

export default SaveButton;
