import React from "react";
import {
  BasicNumberInput,
  BasicSelect,
  BasicTableContainer,
  YesOrNoQuestion,
} from "..";
import DataTable from "../DataTable/DataTable";
import constants from "src/i18n/en/other/fuelTypes.json";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { convertStrToBool } from "src/utils/common-utils";
import ControlledInput from "../ControlledInput/ControlledInput";
import { isRequired, isRequiredBetween } from "src/utils/validationOptions";

const EMPTY_ITEM = { fuel: "", amount: "", unit: "kWh" };

const FuelsTable = ({
  onChange,
  formMethods,
  tableName = "fuels",
  itemName = "",
  product,
}) => {
  const { t } = useTranslation();

  const FUEL_USED_TABLE_COLUMNS = [
    {
      label: t("shared.fuel_type", "Fuel type"),
      key: "fuel",
      _style: { width: "50%" },
    },
    { label: t("shared.amount", "Amount"), key: "amount" },
    {
      label: t("shared.unit", "Unit"),
      key: "unit",
      _style: { width: "20%" },
    },
  ];

  const { getValues, trigger, control, watch } = formMethods;

  const fuelTypes = constants.fuelTypes;
  const SITE_FUEL_TYPE = Object.keys(fuelTypes).map((key) => {
    return { value: key, label: t(`fuelTypes.${key}`) };
  });

  const initValues = useMemo(() => getValues(tableName) || [EMPTY_ITEM], []);

  // const [hasFuelUsed, setHasFuelUsed] = useState(false);

  const hasFuelUsed = watch(`${itemName}.fuel-used`);

  // useEffect(() => {
  //   console.log(product?.["fuel-used"]);
  //   setHasFuelUsed(product?.["fuel-used"]);
  // }, [product, product?.["fuel-used"]]);

  const radioButtonChangeHandle = (e) => {
    const value = convertStrToBool(e.target.value);
    // setHasFuelUsed(value);
    onChange({ target: { name: `${itemName}.fuel-used`, value } });
  };

  return (
    <>
      <YesOrNoQuestion
        className="mb-3 mr-auto"
        name={`${itemName}.fuel-used`}
        onChange={radioButtonChangeHandle}
        defaultValue={hasFuelUsed}
      />
      {hasFuelUsed && (
        <BasicTableContainer>
          <DataTable
            emptyItem={EMPTY_ITEM}
            {...{ initValues, formMethods }}
            fields={FUEL_USED_TABLE_COLUMNS}
            addMoreLabel={"Add more fuels"}
            tableName={tableName}
            itemsPerPage={10}
            pagination
            scopedSlots={{
              fuel: (item, index) => {
                return (
                  <td>
                    <ControlledInput
                      Component={BasicSelect}
                      name={`${tableName}.${index}.fuel`}
                      rules={isRequired}
                      {...{ control, trigger, getValues }}
                      options={SITE_FUEL_TYPE}
                    />
                  </td>
                );
              },
              amount: (item, index) => {
                return (
                  <td style={{ width: "25%" }}>
                    <ControlledInput
                      Component={BasicNumberInput}
                      min={0}
                      inputStep={0.1}
                      decimalScale={3}
                      name={`${tableName}.${index}.amount`}
                      rules={isRequiredBetween(0.01, 1e6)}
                      allowNegative={false}
                      {...{ control, trigger, getValues }}
                    />
                  </td>
                );
              },
              unit: (item, index) => {
                return (
                  <td>
                    <ControlledInput
                      Component={BasicSelect}
                      name={`${tableName}.${index}.unit`}
                      options={[
                        "kWh",
                        { label: t("shared.litre"), value: "litre" },
                      ]}
                      {...{ control, trigger, getValues }}
                    />
                  </td>
                );
              },
            }}
          />
        </BasicTableContainer>
      )}
    </>
  );
};

export default FuelsTable;
