import { CInputGroup } from "@coreui/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  CountrySelect,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  RadioWithOther,
} from "src/components";
import { useSurveyConstants } from "src/hooks";
import AboveCard from "../components/AboveCard";

const SupplierSurvey4 = ({ values, onChange, info, isDemo }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();

  const { flightPatterns } = useSurveyConstants();

  return (
    <FormStepWrapper aboveCardSlot={<AboveCard {...{ info, isDemo }} />}>
      <QuestionWrapper>
        <h3>
          {t(
            "supplierSurvey.q_country",
            "6. Thinking about your work for {{ name }}, in what country are most of your resources that are utilised to deliver your work?",
            { name: info.orgName }
          )}
        </h3>
        <CInputGroup>
          <CountrySelect
            defaultValue={values["country"]}
            onChange={(e) =>
              onChange({ target: { name: "country", value: e.value } })
            }
            className="w-100"
          />
        </CInputGroup>
        <ErrorText value={errors["country"]} />
      </QuestionWrapper>

      <QuestionWrapper>
        <h3 className="mb-4">
          {t(
            "supplierSurvey.q_travel",
            `7. Thinking about work travel that your team undertook in
              {{ period }}, was it very little (local work travel only within our
              city) or a great deal (multiple international flights for many of
              the team)?`,
            { period: info.reportingPeriod }
          )}
        </h3>
        <RadioWithOther
          name="work-travel"
          values={values}
          onChange={onChange}
          answers={flightPatterns}
        />
        <ErrorText value={errors["work-travel"]} />
      </QuestionWrapper>
    </FormStepWrapper>
  );
};

export default SupplierSurvey4;
