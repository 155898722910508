import { cloneDeep } from "lodash";

const filterRecord = (record, columnName, filterFn) => {
  const column = record[columnName];
  if (!column) return true;
  if (typeof column === "string") {
    return filterFn(column);
  }
  if (Array.isArray(column)) {
    return column.every(filterFn);
  }
};

const parseSpending = (spending) => {
  if (!spending) return 0;
  if (typeof spending === "number") return spending;
  return parseFloat(spending.replace(",", "."));
};

//filter purchases by: name, category, description and currency
export const filterPurchaseData = (purchases, filters) => {
  if (!filters) return purchases;
  let results = cloneDeep(purchases);
  Object.entries(filters).forEach((entry) => {
    const [columnName, columnFilters] = entry;

    if (["name", "category", "description"].includes(columnName)) {
      Object.entries(columnFilters).forEach((filter) => {
        const [filterName, filterValues] = filter;

        if (!filterValues || !Array.isArray(filterValues)) return;

        if (filterName === "starts-with") {
          filterValues.forEach((phrase) => {
            results = results.filter((record) =>
              filterRecord(
                record,
                columnName,
                (el) => !el.toLowerCase().startsWith(phrase.toLowerCase())
              )
            );
          });
        }
        if (filterName === "ends-with") {
          filterValues.forEach((phrase) => {
            results = results.filter((record) =>
              filterRecord(
                record,
                columnName,
                (el) => !el.toLowerCase().endsWith(phrase.toLowerCase())
              )
            );
          });
        }
        if (filterName === "includes") {
          filterValues.forEach((phrase) => {
            results = results.filter((record) =>
              filterRecord(
                record,
                columnName,
                (el) => !el.toLowerCase().includes(phrase.toLowerCase())
              )
            );
          });
        }
      });
    }

    if (columnName === "currency") {
      let includeArray = columnFilters.include;
      let excludeArray = columnFilters.exclude;

      if (includeArray?.length && Array.isArray(includeArray)) {
        includeArray = includeArray.map((item) => item.toLowerCase());
        results = results.filter((record) =>
          includeArray.includes(record.currency.toLowerCase())
        );
      }

      if (excludeArray?.length && Array.isArray(excludeArray)) {
        excludeArray = excludeArray.map((item) => item.toLowerCase());
        results = results.filter(
          (record) => !excludeArray.includes(record.currency.toLowerCase())
        );
      }
    }
  });

  return results;
};

//filter grouped purchases by spending
export const filterTotalSpending = (purchases, filters) => {
  let results = cloneDeep(purchases);
  const max = +filters?.spending?.["greater-than"];
  const min = +filters?.spending?.["lesser-than"];

  if (max) {
    results = results.filter((record) => +record.spending <= max);
  }

  if (min) {
    results = results.filter((record) => +record.spending >= min);
  }
  return results;
};

//group purchases
export const groupByNameAndCurrency = (purchases) => {
  if (!purchases) return [];
  let results = cloneDeep(purchases);

  for (let i = 0; i < results.length; i++) {
    if (results[i].isGrouped) continue;
    results[i].records = 1;
    results[i].ids = [results[i].id];
    for (let j = i + 1; j < results.length; j++) {
      if (
        results[i].name === results[j].name &&
        results[i].currency === results[j].currency
      ) {
        results[i].spending =
          parseSpending(results[i].spending) +
          parseSpending(results[j].spending);
        results[i].category = [
          ...new Set([
            ...(results[i].category || []),
            ...(results[j].category || []),
          ]),
        ];
        results[i].orgDescription = results[i].description;

        results[i].description = "Multiple records";
        results[i].records++;

        results[i].classified = [
          ...new Set([
            ...(results[i].classified || []),
            ...(results[j].classified || []),
          ]),
        ];

        results[i].ids.push(results[j].id);

        results[j].isGrouped = true;
      }
    }
    results[i].currency = results[i].currency || "";
    results[i].spending = Math.round(results[i].spending * 1e4) / 1e4;
  }
  return results.filter((result) => !result.isGrouped);
};
