import React from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";

const StyledReactQuill = styled(ReactQuill)`
  .ql-editor {
    padding: 0;

    ul,
    ol {
      padding: 0.1rem;
    }

    li {
      margin-top: 0.75rem;

      &.ql-indent-1 {
        margin-top: 0.4rem;
      }
    }

    li::before {
      /* color: #fbb03b; */
      font-size: 18px;
      padding-right: 0.25rem;
    }

    li.ql-indent {
      margin-bottom: 0.25rem !important;
    }
  }

  .ql-container {
    font-size: 16px;
    font-family: "Hind Madurai", sans-serif;
  }

  p {
    font-size: ${({ fontSize }) => fontSize || "1rem"};
  }
`;

const DisplayRichText = ({ value, fontSize = "16px" }) => {
  return (
    <StyledReactQuill
      className="p-0"
      value={value}
      readOnly={true}
      theme={"bubble"}
      fontSize={fontSize}
    />
  );
};

export default DisplayRichText;
