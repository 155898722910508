import { useUpdateDatabase } from "..";

const useWriteLatestStep = () => {
  const { setOrUpdateDocument } = useUpdateDatabase();

  const writeLatestStepToDB = async ({ sector, step }) => {
    await setOrUpdateDocument(sector, { "latest-step": step });
  };
  return { writeLatestStepToDB };
};

export default useWriteLatestStep;
