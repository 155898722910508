const { CTextarea } = require("@coreui/react");
const { default: styled } = require("styled-components");

const StyledTextArea = styled(CTextarea)`

&,
  &:focus{
    outline: none;
    background-color: transparent;
    border: var(--border);
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "1rem")}!important;
    font-weight: 500;
    color: var(--primary);
  }

  &:focus{
    outline: var(--border);
  }



`

export default StyledTextArea