import { adaptor_getUserUID } from 'src/adapters/auth-adaptors/read';
import firebaseInstance from 'src/adapters/common-set-up/firebase';
import 'firebase/compat/firestore';
import { DB_CONSTANTS } from 'src/constants/databaseConstants';

const HELPER_TAG = 'DATABASE_ADAPTORS__PATH';

/**
 *
 * @category Adaptors
 *
 * @module database-adaptors
 *
 *
 *
 */

/**
 *
 * adapator_createDatabaseRef
 *
 * -Takes two arrays or potentially just the pathArr.
 * -Merges them if there are any dynamic items to merge, See params
 * - returns a refrence to the Firestore where they are.
 *
 *
 * @param {Arr} pathArr - REQURIED An Arr of strings that represents each collection and path of a ref. Contains Marker strings
 * to dynamic items that need replacting. see DB_CONSTANTS.
 * @param {Arr} constantsArr -OPT An Arr of Strings that will fill any DB_CONSTANTS.guideItem in pathArr. Imagine a pathArr with 2
 * dynamic items or  DB_CONSTANTS.guideItems. A constantsArr will have a length of 2. The first element of this array will replace the
 * first guide item, the second - the second etc.
 * @returns {FirestoreRef}
 */
export const adapator_createDatabaseRef = (pathArr, constantsArr) => {
  if (!pathArr) {
    return null;
  }
  if (pathArr?.length === 0) {
    return null;
  }

  let buffer = firebaseInstance.firestore();
  let guideItemCount = 0;

  for (let i = 0; i < pathArr.length; i++) {
    let path = pathArr[i];

    if (i === 0 || i % 2 === 0) {
      buffer = buffer.collection(path);
    } else {
      if (path === DB_CONSTANTS.userID) {
        buffer = buffer.doc(adaptor_getUserUID());
      } else if (path === DB_CONSTANTS.guideItem) {
        let guideItem = constantsArr[guideItemCount];
        buffer = buffer.doc(guideItem);
        guideItemCount++;
      } else {
        buffer = buffer.doc(path);
      }
    }
  }

  return buffer;
};

/**
 * Same as adapator_createDatabaseRef except returns a An Arr rathen than a FirestoreRef
 * @param {Arr} pathArr - REQURIED An Arr of strings that represents each collection and path of a ref. Contains Marker strings
 * to dynamic items that need replacting. see DB_CONSTANTS.
 * @param {Arr} constantsArr -OPT An Arr of Strings that will fill any DB_CONSTANTS.guideItem in pathArr. Imagine a pathArr with 2
 * dynamic items or  DB_CONSTANTS.guideItems. A constantsArr will have a length of 2. The first element of this array will replace the
 * first guide item, the second - the second etc.
 * @returns {FirestoreRef}
 * @returns {Arr}
 */
export const adaptor_createRefAsPathArr = (pathArr, user, constantsArr) => {
  if (pathArr === undefined) {
    return null;
  }
  if (pathArr.length === 0) {
    return null;
  }

  let buffer = [];
  let guideItemCount = 0;

  for (let i = 0; i < pathArr.length; i++) {
    let path = pathArr[i];

    if (i === 0 || i % 2 === 0) {
      buffer.push(path);
    } else {
      if (path === adaptor_getUserUID) {
        buffer.push(user.data.uid);
      } else if (path === DB_CONSTANTS.guideItem) {
        let guideItem = constantsArr[guideItemCount];
        buffer.push(guideItem);
        guideItemCount++;
      } else {
        buffer.push(path);
      }
    }
  }

  return buffer;
};

/**
 * 
 * Same as adapator_createDatabaseRef except returns a a String rathen than a FirestoreRefSame as adaptor_createRefAsPath except takes a more complicated path with replacements
 * 
 * 
 * @param {ARR} REQURIED An Arr of strings that represents each collection and path of a ref. Contains Marker strings
 * to dynamic items that need replacting. see DB_CONSTANTS. 

 * @returns {String}
 */
export const adaptor_createRefAsPath = (pathArr, replacementParts) => {
  let replacementPartsCounter = 0;
  let pathBuffer = '';
  for (let i = 0; i < pathArr.length; i++) {
    let segment = pathArr[i];

    if (segment === DB_CONSTANTS.guideItem) {
      let replacement = replacementParts[replacementPartsCounter];
      pathBuffer = pathBuffer + '/' + replacement;
      replacementPartsCounter++;
    } else if (segment === DB_CONSTANTS.userID) {
      pathBuffer = pathBuffer + '/' + adaptor_getUserUID();
    } else {
      pathBuffer = pathBuffer + '/' + segment;
    }
  }
  return pathBuffer;
};

/**
 *
 * Take an array of keys and an object.
 * For each of the array keys will return a new array of the same length but rather than the keys will return the values
 * - used in useNestedCollection
 *
 * @param {Arr} variableKeys - The keys from the replacementObj that we need the values for
 * @param {obj} replacementObj - An object with known keys
 * @returns {Arr}
 */
export const adaptor_createReplacementGuide = (
  variableKeys,
  replacementObj
) => {
  let buffer = [];

  for (let i = 0; i < variableKeys.length; i++) {
    let key = variableKeys[i];
    buffer.push(replacementObj[key]);
  }
  return buffer;
};
