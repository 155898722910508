import styled, { css } from "styled-components";

export const basicTableStyles = css`
  overflow-x: auto !important;
  width: 100%;
    max-width: 100vw;


  .pagination {
    justify-content: end !important;
    gap: 0 !important;
  }

  .page-link {
    color: black;
  }

  .page-item.active .page-link {
    background-color: #dadada !important;
    color: black;
    border-color: hsla(0, 0%, 20%, 0.5);
  }

  table {
    margin-bottom: 0.5rem;
    border: 0.1px solid var(--clr-border-light);
    min-width: ${({ minWidth }) => (minWidth ? minWidth : "600px")};
    overflow-x: hidden !important;
  }

  th,
  td {
    padding-block: 1rem;
    padding-inline: 1rem;
    text-align: start;
    vertical-align: middle;
    border-top: none;
    border-bottom: none !important;
    font-size: ${({ fontSize }) => fontSize || "1rem"};
  }

  th {
    font-size: ${({ headerFontSize }) => headerFontSize || "1rem"};
    font-weight: 700;
  }

  .row-error {
    border: 1px solid red;
  }

  div .is-invalid {
    border: 1px solid red;
  }

  .table-total {
    background-color: black !important;
    color: white;
    font-weight: bold;
  }

  .c-dark-theme & {
    table {
      border: 0.1px solid var(--clr-border-dark);
    }

    input {
      color: #ffffff !important;
    }

    input:disabled {
      color: gray !important;
    }
    select {
      color: #ffffff;
    }

    .react-select__single-value {
      color: #ffffff;
    }

    .page-item.active .page-link {
      background-color: var(--clr-table-dark-1) !important;
      color: white;
    }

    
  }
`;

const BasicTableContainer = styled.div`
  ${basicTableStyles}

  .table-sector {
    background-color: gainsboro !important;
    font-weight: bold;
  }

  thead {
    background-color: var(--clr-table-header);
    border-bottom: 0.1px solid var(--clr-border-light);

    .table-sm {
      th {
        padding: 0.5rem 1rem;
      }
    }
  }



  tbody {
    tr {
      border-bottom: 0.1px solid var(--clr-border-light);


    }

    tr:nth-child(odd) {
      background-color: var(--clr-table-light-1);
    }
    tr:nth-child(even) {
      background-color: var(--clr-table-light-2);
    }
  }

  .c-dark-theme & {
    thead {
      background-color: var(--clr-table-header-dark);
      border-bottom: 0.1px solid var(--clr-border-dark);
    }

    tr {
      border-bottom: 0.1px solid var(--clr-border-dark);
    }

    tbody {
      tr:nth-child(odd) {
        background-color: var(--clr-table-dark-1);
      }
      tr:nth-child(even) {
        background-color: var(--clr-table-dark-2);
      }
    }
    .table-sector {
    background-color: black !important;
    font-weight: bold;
  }



  }

  .font-small{
    th,
  td {
    font-size: 12px !important;
  }
  }
`;
export default BasicTableContainer;
