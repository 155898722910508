import { CPopover } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { AiFillQuestionCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setFreemiumModal } from "src/store/modalSlice";
import FreemiumModal from "../FreemiumModal/FreemiumModal";

const FreemiumText = ({ text, className, onClick, ...rest }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  return (
    <>
      <p
        className={`text-muted mb-0 ${className}`}
        {...rest}
        style={{ fontSize: "20px" }}
      >
        <i>{text || t("superAdmin.questionFreemium")}</i>
        <CPopover
          header={t("freemium.popoverTitle")}
          content={t("freemium.popoverText")}
        >
          <AiFillQuestionCircle
            className="ml-1 mb-1"
            // color="var(--secondary)"
            size={20}
            onClick={() => {
              onClick?.()
              dispatch(setFreemiumModal({ show: true }))
            }}
            style={{ cursor: "pointer" }}
          />
        </CPopover>
      </p>
    </>
  );
};

export default FreemiumText;
