import React from "react";
import StyledButton from "./ButtonRound.style";

const ButtonRound = ({ children, ...rest }) => {
  return (
    <StyledButton
      variant="outline"
      color="dark"
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default ButtonRound;
