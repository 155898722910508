import { CInputGroup } from "@coreui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BasicInput,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  YesOrNoQuestion,
} from "src/components";
import { convertStrToBool } from "src/utils/common-utils";
import AboveCard from "../components/AboveCard";
import UploadPDF from "../components/UploadPDF";

const SupplierSurvey10 = ({
  values,
  onChange,
  nextClickHandler,
  isDemo,
  info,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();

  const { supplierID = "demo", orgId } = info;

  const [hasPolicy, setHasPolicy] = useState(values["has-policy"]);

  const radioButtonChangeHandle = (e) => {
    const value = convertStrToBool(e.target.value);
    setHasPolicy(value);
    onChange({ target: { name: "has-policy", value } });
  };

  return (
    <FormStepWrapper aboveCardSlot={<AboveCard {...{ info, isDemo }} />}>
      <QuestionWrapper>
        <h3>{t("supplierSurvey.carbonPolicy")}</h3>
        <CInputGroup>
          <YesOrNoQuestion
            className="mb-4 mr-auto"
            name="has-policy"
            onChange={radioButtonChangeHandle}
            defaultValue={hasPolicy}
          />
        </CInputGroup>
        <ErrorText value={errors["has-policy"]} />
        {hasPolicy && (
          <>
            <p>{t("supplierSurvey.carbonPolicy2")}</p>
            <CInputGroup>
              <BasicInput
                type="text"
                name="policy-link"
                defaultValue={values["policy-link"]}
                onChange={onChange}
                placeholder="Provide link..."
              />
            </CInputGroup>
            <UploadPDF supplierID={supplierID} orgId={orgId} isDemo={isDemo} />
          </>
        )}
      </QuestionWrapper>
    </FormStepWrapper>
  );
};

export default SupplierSurvey10;
