import CIcon from "@coreui/icons-react";
import { CPopover } from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setAccountSettingsModal } from "src/store/modalSlice";
import { selectUserData } from "src/store/userSlice";
import {
  AlertCircle,
  StyledAccountSettingsButton,
} from "./AccountSettingsButton.style";



const AccountSettingsButton = () => {
  const { firstName } = useSelector(selectUserData);
  const dispatch = useDispatch();

  const trimName = (name) => {
    return name[0].toUpperCase();
  };

  const toggle = () => {
    dispatch(setAccountSettingsModal(true));
  };

  const { t } = useTranslation();
  return (
    <>
      <CPopover content={t("settings.accountSettings", "Account settings")}>
        <StyledAccountSettingsButton
          onClick={toggle}
          className="rounded-pill mr-3 account-settings"
          color="info"
        >
          {firstName ? trimName(firstName) : <CIcon name="cilSettings" />}
          {!firstName && <AlertCircle />}
        </StyledAccountSettingsButton>
      </CPopover>
    </>
  );
};

export default AccountSettingsButton;
