import firebase from "firebase/compat/app";
import { useEffect, useReducer } from "react";
import { DB_PATHS } from "src/constants";
import { useCollection } from "src/hooks/databaseHooks";

const initState = {
  logs: [],
  totalPages: 0,
  status: "loading",
};

function reducer(state, action) {
  const { logs, totalPages } = action.payload;

  switch (action.type) {
    case "loaded":
      return {
        ...state,
        logs: [...state.logs, ...logs],
        status: "success",
      };
    case "newPages":
      return {
        ...state,
        totalPages,
      };
    case "error":
      return {
        status: "error",
      };
    default:
      throw new Error();
  }
}

const GlobalLogController = () => {
  const [state, dispatch] = useReducer(reducer, initState);

  const { status: orgStatus, data: orgs } = useCollection(
    DB_PATHS.organisations
  );

  const fetchLogs = async (lastTimestamp = Date.now()) => {
    const db = firebase.firestore();

    const querySnapshot = await db
      .collectionGroup("logs")
      .orderBy("timestamp", "desc")
      .startAfter(lastTimestamp)
      .limit(50)
      .get();

    const getOrgName = (doc) => {
      const orgId = doc.ref.path.split("/")[1];
      const orgName = orgs?.find((org) => org.id === orgId)?.name || "";
      return orgName;
    };

    const convertedDocs = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      project: getOrgName(doc),
    }));

    dispatch({
      type: "loaded",
      payload: { logs: convertedDocs },
    });
  };

  useEffect(() => {
    if (orgStatus !== "success") return;
    try {
      fetchLogs();
    } catch (err) {
      console.log(err);
      dispatch({ type: "error" });
    }
  }, [orgStatus]);

  const onPagesChange = (pagesNum) => {
    dispatch({ type: "newPages", payload: { totalPages: pagesNum } });
  };

  const fetchNextPage = async () => {
    const lastTimestamp = state.logs.at(-1).timestamp;
    try {
      await fetchLogs(lastTimestamp);
    } catch (err) {
      console.log(err);
    }
  };

  const onPageChange = (pageNum) => {
    if (pageNum === state.totalPages) {
      fetchNextPage();
    }
  };

  return {
    logs: state.logs,
    onPageChange,
    onPagesChange,
    status: state.status,
  };
};

export default GlobalLogController;
