import { CButton, CModalBody, CModalFooter } from "@coreui/react";
import { useTranslation } from "react-i18next";

import { BasicModal } from "src/components";

const DeleteCollectionModal = ({
  show,
  toggle,
  onConfirm,
  warningText,
  buttonText,
}) => {
  const { t } = useTranslation();
  return (
    <BasicModal show={show} onClose={toggle} centered>
      <CModalBody>
        <h2
          style={{ lineHeight: "1.5" }}
          className="my-4 mx-auto w-75 text-center"
        >
          {warningText}
        </h2>

        <h3 className="w-100 text-center text-danger">
          {t("shared.warningPrompt", "Are you sure you want to continue?")}
        </h3>
      </CModalBody>
      <CModalFooter className="d-flex flex-column bg-light">
        <CButton className="mb-4" color="danger" onClick={onConfirm}>
          {buttonText}
        </CButton>
        <CButton color="success" size="lg" onClick={toggle}>
          {t("shared.cancel", "Cancel")}
        </CButton>
      </CModalFooter>
    </BasicModal>
  );
};

export default DeleteCollectionModal;
