import { get } from "lodash";
import { Controller } from "react-hook-form";
import ErrorText from "../ErrorText/ErrorText";

const ControlledInput = ({
  Component,
  name,
  control,
  rules,
  showError = false,
  getValues,
  className,
  refName,
  trigger,
  refresh,
  changeHandler,
  setValue,
  convertValue = (value) => value,
  customChangeHandler,
  customDefault,
  changedName,
  ...rest
}) => {
  const isChangedName = changedName || name.replace(/\.[\w-]+$/, ".isChanged");

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => {
        let val = convertValue(getValues?.(name) ?? value) ?? customDefault;

        return (
          <>
            <Component
              {...{
                onBlur,
                name,
                [refName]: !!refName ? ref : undefined,
                customDefault,
              }}
              value={val}
              className={`${!!error && "is-invalid"} ${className}`}
              {...rest}
              onChange={(e) => {
                refresh?.();
                setValue?.(isChangedName, true);
                if (customChangeHandler) {
                  customChangeHandler(e);
                } else {
                  onChange(e);
                }
                if (!!error && !!trigger) {
                  trigger(name);
                }
              }}
            />
            {showError && <ErrorText value={error} />}
          </>
        );
      }}
    />
  );
};

export default ControlledInput;
