import { CCol, CRow } from "@coreui/react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BasicNumberInput,
  DistanceUnitSelect,
  ErrorText,
  QuestionWrapper,
} from "src/components";

const VanQuestions = ({
  info,
  values,
  onChange,
  errors,
  control,
  register,
  getValues,
}) => {
  const { t } = useTranslation();

  const [labInboud, labOutbound] = useWatch({
    control,
    name: ["lab-inbound", "lab-outbound"],
  });

  const usesVan = labInboud?.van > 0 || labOutbound?.van > 0;

  if (!usesVan) {
    return null;
  }

  register("lab-trips-week", {
    validate: {
      required: (value) => {
        if (!getValues("lab-inbound.van") && !getValues("lab-outbound.van"))
          return true;
        if (!value) return "Please specify the number of trips";
        if (value < 0 || value > 100)
          return "The number of trips must be between 0 and 100";
        return true;
      },
    },
  });

  register("lab-trip", {
    validate: {
      required: (value) => {
        if (!getValues("lab-inbound.van") && !getValues("lab-outbound.van"))
          return true;
        if (!value?.distance) return "Please specify the distance";
        if (value.distance < 0 || value.distance > 200)
          return "The distance must be between 0 and 200";
        return true;
      },
    },
  });

  return (
    <div className="rounded bg-light px-3 pt-3 pb-4 mb-4">
      <p className="text-info font-bold mb-2">Van use</p>
      <QuestionWrapper>
        <h3 className="mb-2">
          How many trips per week do you make to {info.orgName}?
        </h3>
        <BasicNumberInput
          allowNegative={false}
          min={0}
          max={100}
          name={"lab-trips-week"}
          defaultValue={values["lab-trips-week"]}
          onChange={onChange}
          placeholder={t("shared.pleaseSpecify")}
        />
        <ErrorText value={errors["lab-trips-week"]} />
      </QuestionWrapper>
      <QuestionWrapper>
        <h3 className="mb-2">What is the average round trip distance?</h3>
        <CRow style={{ marginLeft: "-15px" }}>
          <CCol className="col-12 col-md-9  ">
            <BasicNumberInput
              allowNegative={false}
              min={0}
              name={"lab-trip.distance"}
              defaultValue={values["lab-trip"]?.distance}
              onChange={onChange}
              placeholder={"Please enter distance"}
            />
          </CCol>
          <CCol className="col-12 col-md-3  ">
            <DistanceUnitSelect
              onChange={onChange}
              name={"lab-trip.unit"}
              defaultValue={values["lab-trip"]?.unit || "mi"}
              className="font-weight-bold"
            />
          </CCol>
        </CRow>
        <ErrorText value={errors["lab-trip"]} />
      </QuestionWrapper>
      <QuestionWrapper>
        <h3 className="mb-2">
          How many other dentist practices do you typically visit per round
          trip?
        </h3>
        <BasicNumberInput
          allowNegative={false}
          min={0}
          name={"lab-visited-num"}
          defaultValue={values["lab-visited-num"]}
          onChange={onChange}
          placeholder={t("shared.pleaseSpecify")}
        />
      </QuestionWrapper>
    </div>
  );
};

export default VanQuestions;
