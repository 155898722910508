import React from "react";
import { VerticalRadioButtons, BasicInput } from "src/components";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const RadioWithOther = ({
  values,
  answers,
  onChange,
  name,
  otherText,
  defaultValue = null,
}) => {
  const { t } = useTranslation();

  const other = otherText || t("shared.otherOption", "Other (please specify)");

  const convertedAnswers = [
    ...answers.map((item) => {
      const { value, label } = item;
      if (value !== undefined && label !== undefined) {
        return item;
      } else {
        return { label: item, value: item };
      }
    }),
    { label: other, value: "other" },
  ];
  const localDefault = defaultValue || values[name];

  const [localState, setLocalState] = useState(localDefault);

  const [isOther, setIsOther] = useState(
    localDefault &&
      !convertedAnswers.map((item) => item.value).includes(localDefault)
  );

  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (!isTouched) return;
    onChange({ target: { name, value: localState } });
  }, [localState, onChange, name, isTouched]);

  const localStateHandler = (e) => {
    setIsTouched(true);
    const { value } = e.target;
    if (value !== "other") {
      setLocalState(value);
      setIsOther(false);
    } else {
      setLocalState("");
      setIsOther(true);
    }
  };

  return (
    <>
      <VerticalRadioButtons
        name={name}
        onChange={localStateHandler}
        data={convertedAnswers}
        defaultValue={!isOther ? localDefault : "other"}
      />

      {isOther && (
        <BasicInput
          type="text"
          name={name}
          onChange={(e) => {
            setIsTouched(true);
            setLocalState(e.target.value);
          }}
          defaultValue={localState}
          placeholder={t("shared.pleaseSpecify", "Please specify")}
        />
      )}
    </>
  );
};

export default RadioWithOther;
