

export const coreDataIntroSteps = [
  {
    key: "welcome",
    target: ".coreData-welcome",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    spotlightClicks: false,
    placement: "center",
  },
  {
    key: "data_card",
    target: ".section-intro-card",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "nav_button",
    target: ".coreData-intro-nav-button",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "to_dashboard",
    target: ".coreData-intro-back-button",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];

export const coreDataIntroCollaboratorStep = {
  key: "assign_collaborator",
  target: ".coreData-intro-collaborator",
  placement: "auto",
  disableBeacon: true,
  styles: {
    options: {
      zIndex: 10000,
    },
  },
};
