import {
    getAuth,
    getMultiFactorResolver,
    multiFactor,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier
} from 'firebase/auth';
import React from 'react';
import { useFirebaseApp } from "reactfire";
import useToasts from '../useToasts/useToasts';


const useMfa = () => {
    let app = useFirebaseApp();
    const auth = getAuth(app);

    const [verificationId, setVerificationId] = React.useState(null);
    const [multiFactorResolver, setMultiFactorResolver] = React.useState(null);
    const [error, setError] = React.useState(null);

    const { showToast } = useToasts()

    const startEnrollMultiFactor = async (
        phoneNumber
    ) => {
        const recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha",
            {
                size: "invisible",
            },
            auth
        );

        if (auth.currentUser) {
            const verificationId = await multiFactor(auth.currentUser)
                .getSession()
                .then(function (multiFactorSession) {

                    // Specify the phone number and pass the MFA session.
                    const phoneInfoOptions = {
                        phoneNumber: phoneNumber,
                        session: multiFactorSession,
                    };

                    const phoneAuthProvider = new PhoneAuthProvider(auth);

                    // Send SMS verification code.
                    return phoneAuthProvider.verifyPhoneNumber(
                        phoneInfoOptions,
                        recaptchaVerifier
                    );
                })
                .catch(function (error) {
                    console.log(error);
                    if (error.code == "auth/invalid-phone-number") {
                        showToast({
                            text: "Error with phone number formatting. Phone numbers must start with +",
                            type: "error"
                        })
                    } else if (error.code == "auth/requires-recent-login") {
                        setError("This operation requires recent login. Please log out and log in again to set up 2FA")
                        showToast({
                            text: "This operation requires recent login. Please re-login to set up 2FA",
                            type: "error"
                        })
                    } else {
                        showToast({
                            text: "Error enrolling second factor. Please try again",
                            type: "error"
                        })
                    }
                    throw error;
                });

            showToast({
                text: "Verification code sent to your phone",
                type: "success"
            })

            setVerificationId(verificationId)
        } else {
            // The user is not verified.
            console.log('no user');
            return
        }
    }

    const finishEnrollMultiFactor = async (
        verificationCode
    ) => {
        if (verificationId && auth.currentUser) {
            const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

            try {
                await multiFactor(auth.currentUser)
                    .enroll(multiFactorAssertion, "My cellphone number")

                showToast({
                    text: "Second factor successfully enrolled",
                    type: "success"
                })
            } catch (error) {
                console.log(error);
                showToast({
                    text: "Error finishing second factor enrollment. Please try again",
                    type: "error"
                })
                throw error;
            }
            setVerificationId(null)
        }
    }

    const unEnrollMultiFactor = async () => {
        if (!auth.currentUser) return

        try {
            const checkMfaEnrollment = multiFactor(auth.currentUser).enrolledFactors;

            for (let i = 0; i < checkMfaEnrollment.length; i++) {
                await multiFactor(auth.currentUser).unenroll(checkMfaEnrollment[i].uid)
            }

            showToast({
                text: "Second factor successfully unenrolled",
                type: "success"
            })
        } catch (error) {
            if (error.code == "auth/requires-recent-login") {
                setError("This operation requires recent login. Please log out and log in again to set up 2FA")
                showToast({
                    text: "This operation requires recent login. Please re-login to set up 2FA",
                    type: "error"
                })
                return
            }
            console.log(error);
            showToast({
                text: "Error unenrolling second factor",
                type: "error"
            })

            throw error;
        }
    }

    const getMfaResolver = (error) => {
        const resolver = getMultiFactorResolver(auth, error)
        setMultiFactorResolver(resolver)
        return resolver
    }
    const startMfaSignin = async (
        multiFactorHint,
        session
    ) => {
        const recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha",
            {
                size: "invisible",
            },
            auth
        );

        if (multiFactorHint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
            const phoneInfoOptions = {
                multiFactorHint: multiFactorHint,
                session: session
            };
            const phoneAuthProvider = new PhoneAuthProvider(auth);
            // Send SMS verification code
            const verificationId = await phoneAuthProvider
                .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                .catch(function (error) {
                    alert(`Error verifying phone number. ${error}`);
                    throw error;
                });

            setVerificationId(verificationId)
        } else {
            alert("Only phone number second factors are supported.");
        }
    }

    const finishMfaSignIn = async (
        verificationCode
    ) => {
        // Get the SMS verification code sent to the user.
        if (verificationId && multiFactorResolver) {
            const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

            // Complete sign-in.
            await multiFactorResolver
                .resolveSignIn(multiFactorAssertion)
                .then(function (userCredential) {
                    // User successfully signed in with the second factor phone number.
                })
                .catch(function (error) {
                    showToast({
                        text: "Error completing sign in. Please try again",
                        type: "error"
                    })
                    throw error;
                });
        }

        setMultiFactorResolver(null)
        setVerificationId(null)
    }
    return {
        startEnrollMultiFactor,
        finishEnrollMultiFactor,
        unEnrollMultiFactor,
        getMfaResolver,
        startMfaSignin,
        finishMfaSignIn,
        error
    }
}

export default useMfa