import React from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }, { color: [] }],
  ],
};

const formats = [
  "font",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "blockquote",
  "indent",
  "align",
  "color",
];
const StyledReactQuill = styled(ReactQuill)`
  .ql-editor {
    font-size: 16px;
    ul,
    ol {
      padding: 0.1rem;
    }

    li:not(:first-child) {
      margin-top: 0.75rem;

      &.ql-indent-1 {
        margin-top: 0.4rem;
      }
    }

    li::before {
      /* color: #fbb03b; */
      font-size: 18px;
      padding-right: 0.25rem;
    }

    li.ql-indent {
      margin-bottom: 0.25rem !important;
    }
  }
`;

const RichTextEditor = ({ value, onChange }) => {
  return (
    <StyledReactQuill
      theme="snow"
      modules={modules}
      formats={formats}
      value={value}
      onChange={onChange}
    />
  );
};

export default RichTextEditor;
