import { useTranslation } from "react-i18next";
import { convertStrToBool } from "src/utils/common-utils";
import { emailRegex, urlRegex } from "src/utils/common-utils/regex";

const useValidation = () => {
  const { t } = useTranslation();

  const maxLength = (v) => {
    if (v?.length > 50)
      return "Please enter string shorter than 50 characters.";
    return true;
  };

  const isRequired = {
    validate: {
      isRequired: (v) => {
        if (!v) return t("validation.enterAnswer", "Please enter answer");
        return true;
      },
    },
  };

  const isEmail = {
    pattern: {
      value: emailRegex,
      message: t("validation.enterValidEmail", "Please enter valid email"),
    },
    validate: {
      maxLength,
    },
  };
  const isUrl = {
    pattern: {
      value: urlRegex,
      message: t("validation.enterUrl", "Please enter valid url"),
    },
    validate: {
      maxLength,
    },
  };

  const isBetween = (min, max, customMsg = "") => {
    return {
      valueAsNumber: true,
      validate: {
        isBetween: (v) => {
          if (v < min || v > max)
            return customMsg || t("validation.numberBetween", { min, max });
          return true;
        },
      },
    };
  };

  const isPastDate = {
    validate: {
      isPastDate: (date) => {
        if (Date.parse(date) - Date.now() > 0) {
          return t("validation.enterPastDate");
        }
        return true;
      },
    },
  };

  const isNonNegativeNum = {
    valueAsNumber: true,
    validate: {
      isPositive: (v) => {
        if (isNaN(v) || 0 > v) return t("validation.enterValid");
        // if (v === undefined) return true;
        return true;
      },
    },
  };

  const isRequiredNum = {
    valueAsNumber: true,
    min: { value: 0, message: t("validation.enterValid") },
    validate: {
      required: (v) =>
        (v !== undefined && !isNaN(v)) || t("validation.enterValue"),
    },
  };

  const isRequiredBetween = (min, max, customMsg = "") => {
    return {
      valueAsNumber: true,
      validate: {
        required: (v) =>
          (v !== undefined && !isNaN(v)) ||
          t("validation.numberBetween", { min, max }),
        isBetween: (v) => {
          if (v < min || v > max)
            return customMsg || t("validation.numberBetween", { min, max });
          return true;
        },
      },
    };
  };

  const isBool = {
    setValueAs: (v) => convertStrToBool(v),
  };

  const isRequiredBool = {
    setValueAs: (v) => convertStrToBool(v),
    validate: {
      required: (v) =>
        v !== undefined || t("validation.enterAnswer", "Please enter answer"),
    },
  };

  const isNotEmptyArray = {
    validate: {
      notEmpty: (v) =>
        !!v?.length || t("validation.enterAnswer", "Please enter answer"),
    },
  };

  const isConditionallyRequiredSelect = (getValues, dependsOn) => {
    return {
      validate: {
        isRequired: (v) => {
          if (!getValues(dependsOn)) return true;
          if (v === undefined || v === "") return "Please select option";
          return true;
        },
      },
    };
  };

  const isConditionallyRequiredStr = (getValues, dependsOn) => {
    return {
      validate: {
        isRequired: (v) => {
          if (!getValues(dependsOn)) return true;
          if (v === undefined || v === "") return t("validation.enterValue");
          return true;
        },
      },
    };
  };

  const isConditionallyRequiredBool = (getValues, dependsOn) => {
    return {
      setValueAs: (v) => convertStrToBool(v),
      validate: {
        isRequired: (v) => {
          if (!getValues(dependsOn)) return true;
          if (v === undefined) return t("validation.enterValue");
          return true;
        },
      },
    };
  };

  const isConditionallyRequiredNum = (getValues, dependsOn) => {
    return {
      valueAsNumber: true,
      validate: {
        isRequired: (v) => {
          if (!getValues(dependsOn)) return true;
          if (v === undefined || isNaN(v)) return t("validation.enterValue");
          if (v < 0) return t("validation.enterValid");
          return true;
        },
      },
    };
  };

  const isNumWorkingDays = {
    setValueAs: (v) => (!!v ? parseInt(v) : 0),
    min: {
      value: 0,
      message: t("validation.numberBetween", { min: 0, max: 5 }),
    },
    max: {
      value: 5,
      message: t("validation.numberBetween", { min: 0, max: 5 }),
    },
  };

  return {
    isRequired,
    isRequiredBetween,
    isRequiredNum,
    isRequiredBool,
    isBetween,
    isBool,
    isEmail,
    isUrl,
    isConditionallyRequiredBool,
    isConditionallyRequiredNum,
    isConditionallyRequiredStr,
    isConditionallyRequiredSelect,
    isNonNegativeNum,
    isNotEmptyArray,
    isNumWorkingDays,
    isPastDate,
    maxLength,
  };
};

export default useValidation;
