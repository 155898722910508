import { CDataTable, CModal, CModalBody, CModalHeader } from "@coreui/react";

// routes config
import CIcon from "@coreui/icons-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DB_PATHS } from "src/constants";
import { useDatabasePaths, usePermissions, useUpdateDatabase } from "src/hooks";
import { useCollection } from "src/hooks/databaseHooks";
import {
  selectSwitchPeriodModal,
  setSwitchPeriodModal,
} from "src/store/modalSlice";
import { selectPeriod } from "src/store/projectSelectors";
import AddNewPeriod from "./AddNewPeriod";
import PeriodNameInput from "./PeriodNameInput";
import LockSwitch from "./LockSwitch";
import { CiLock } from "react-icons/ci";
import { CiUnlock } from "react-icons/ci";

const PeriodSwitchModal = () => {
  const currentPeriod = useSelector(selectPeriod);
  const show = useSelector(selectSwitchPeriodModal);
  const dispatch = useDispatch();

  const { getPath } = useDatabasePaths();
  const history = useHistory();

  const { updateDocument } = useUpdateDatabase();

  const { data: periods } = useCollection(getPath(DB_PATHS.periods));

  const { isSuperAdmin } = usePermissions();

  const switchPeriod = async (period) => {
    if (!isSuperAdmin && period.locked) return;
    await updateDocument(DB_PATHS.organisation, { period: period.id });
    history.push("/");
  };

  const { t } = useTranslation();

  const COLUMNS = [
    { key: "name", label: t("shared.name") },
    { key: "startDate", label: t("shared.startDate") },
    { key: "endDate", label: t("shared.endDate") },
    { key: "current", label: t("shared.current") },
    { key: "locked", label: "Locked" },
  ];

  const sortPeriods = (a, b) => {
    if (!a.startDate) return 1;
    if (!b.startDate) return 1;
    return new Date(a.startDate) - new Date(b.startDate);
  };

  return (
    <>
      {show && (
        <CModal
          size="lg"
          show={show}
          centered
          onClose={() => dispatch(setSwitchPeriodModal(false))}
        >
          <CModalHeader className="bg-light py-4" closeButton>
            <h2 className="text-black mb-0">{t("header.switchPeriod")}</h2>
          </CModalHeader>
          <CModalBody className="py-4">
            {!!periods?.length && (
              <>
                <CDataTable
                  fields={COLUMNS}
                  items={periods.sort(sortPeriods)}
                  hover
                  clickableRows
                  onRowClick={(period) => switchPeriod(period)}
                  scopedSlots={{
                    name: (item, index) => {
                      return (
                        <td>
                          <PeriodNameInput item={item} />
                        </td>
                      );
                    },
                    startDate: (item, index) => {
                      return (
                        <td>{item.startDate || t("header.periodNotSet")}</td>
                      );
                    },
                    endDate: (item, index) => {
                      return (
                        <td>{item.endDate || t("header.periodNotSet")}</td>
                      );
                    },
                    current: (item, index) => {
                      return (
                        <td>
                          {item.id === currentPeriod && (
                            <CIcon
                              className="text-success"
                              name="cil-check-circle"
                              size="xl"
                            />
                          )}
                        </td>
                      );
                    },
                    locked: (item, index) => {
                      return (
                        <td onClick={(e) => e.stopPropagation()}>
                          {isSuperAdmin ? (
                            <LockSwitch item={item} />
                          ) : item.locked ? (
                            <CiLock size={28} />
                          ) : (
                            <CiUnlock size={28} />
                          )}
                        </td>
                      );
                    },
                  }}
                />
                <AddNewPeriod periods={periods} />
              </>
            )}
          </CModalBody>
        </CModal>
      )}
    </>
  );
};

export default PeriodSwitchModal;
