import styled from "styled-components";

export const StyledAddMoreButton = styled.div`
  svg {
    /* border: 0.1px solid white; */
    /* border-radius: 100%; */
    /* width: "28px"; */
    /* height: "28px"; */
    /* margin-left: -0.75rem; */
  }

  .c-dark-theme & {
    button {
      &:hover {
        background-color: #181743;
      }
      /* svg {
        border: 0.1px solid #ffffff;
        color: #ffffff;
      } */
    }
  }
`;
