import { CContainer } from "@coreui/react";

import styled from "styled-components";

const BaseContainer = styled(CContainer)`
  font-size: 16px;

  .is-invalid {
    border: 1px solid red;
  }

  .form-group {
    margin-bottom: 0;
  }

  min-height: calc(
    100vh - var(--header-height) - var(--footer-height) -
      var(--main-padding-top)
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* flex-grow: 1; */

  h1 {
    font-weight: 400;
    font-size: 30px;
    width: 100%;
    line-height: 1.5;
  }
  h2,
  h3 {
    font-weight: 400;
    font-size: 22px;
    width: 100%;
    line-height: 1.5;
  }

  h5 {
    line-height: 1.5 !important;
  }

  p {
    width: 100%;
  }

  p,
  span {
    font-size: 16px;
  }

  // so input doesn't skip to new line on focus
  .input-group-prepend + input:focus {
    width: 1% !important;
  }

  .input-group-text {
    border-bottom: var(--border);
    color: white;
    background-color: black;
  }

  // customization for tooltipInfo icon
  .info-icon {
    border: var(--border);
    border-radius: 50%;
  }
  .info-icon path:first-of-type {
    fill: transparent;
  }

  // sharp corners for checkboxes
  .custom-checkbox .custom-control-label::before {
    border-radius: 0;
  }

  @media screen and (max-width: 576px) {
    padding: 0;
  }
`;

export default BaseContainer;
