import { CButton } from "@coreui/react";
import styled from "styled-components";

const StyledSquareButtonOutline = styled(CButton)`
  .c-dark-theme & {
    color: #fff;
    border-color: #fff;
  }
`;

export default StyledSquareButtonOutline;
