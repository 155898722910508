import { useState } from "react";
import { convertStrToBool } from "src/utils/common-utils";
import { useFormContext } from "react-hook-form";

const SurveyController1 = ({ onChange, values }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const [stillWorking, setStillWorking] = useState(values["still-working"]);

  const stillWorkingHandler = (e) => {
    const isWorking = convertStrToBool(e.target.value);
    setStillWorking(isWorking);
    onChange(e);
  };

  return { stillWorking, stillWorkingHandler, errors, values, setValue };
};

export default SurveyController1;
