import React from 'react'
import { ButtonBasic } from '..'

const CTAButton = ({ children, type = "button", ...rest }) => {

  return (
    <ButtonBasic
      size="lg"
      type={type}
      bgcolor="green"
      hovercolor="darkgreen"
      {...rest}
    >
      {children}            </ButtonBasic>
  )
}

export default CTAButton