import { CLabel } from "@coreui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicInput from "../BasicInput/BasicInput.style";
import StyledSelect from "../BasicSelect/BasicSelect.style";

const SelectWithOther = ({
  values,
  onChange,
  answers,
  name,
  className = "",
  addedSlot = null,
  ...rest
}) => {
  const { t } = useTranslation();

  const convertedAnswers = [
    ...answers.map((item) => {
      const { value, label } = item;
      if (value !== undefined && label !== undefined) {
        return item;
      } else {
        return { label: item, value: item };
      }
    }),
    { label: t("shared.other"), value: "other" },
  ];

  const [localState, setLocalState] = useState(values[name]);

  const [isOther, setIsOther] = useState(
    values[name] && !answers.map((item) => item.value).includes(values[name])
  );

  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (!isTouched) return;
    onChange({ target: { name, value: localState } });
  }, [localState, onChange, isTouched, name]);

  return (
    <div className={`w-100 ${className}`} {...rest}>
      <StyledSelect
        defaultValue={!isOther ? values[name] : "other"}
        onChange={(e) => {
          setIsTouched(true);
          const { value } = e.target;
          if (value !== "other") {
            setLocalState(value);
            setIsOther(false);
          } else {
            setLocalState("");
            setIsOther(true);
          }
        }}
        name={name}
      >
        <option className="text-muted" hidden>
          {t("shared.pleaseSelect")}
        </option>
        {convertedAnswers.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </StyledSelect>
      {isOther && (
        <>
          <CLabel className="mt-3 mb-2">
            {t("shared.otherOption", "Other (please specify)")}
          </CLabel>
          <BasicInput
            type="text"
            name={name}
            defaultValue={localState}
            onChange={(e) => {
              setIsTouched(true);
              setLocalState(e.target.value);
            }}
            placeholder={t("shared.pleaseSpecify")}
          />
          {addedSlot}
        </>
      )}
    </div>
  );
};

export default SelectWithOther;
