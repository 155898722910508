import { CInput } from "@coreui/react";
import styled from "styled-components";

const BoarderInput = styled(CInput)`
  border: 1px solid var(--secondary) !important;
  border-radius: 0.25rem !important;
  transition: all 0.25s ease-in;

  :hover {
    border: 1px solid var(--primary) !important;
  }

  :focus {
    border: 1.5px solid var(--info) !important;
  }
`;

export default BoarderInput;
