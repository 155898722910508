import { DB_PATHS } from "src/constants";
import { useCollection } from "src/hooks/databaseHooks";
import { useDatabasePaths } from "src/hooks";

/**
 * Hook which returns the list of users for the current organisation
 *
 * @returns {Array} collaborators - List of collaborators(users)
 *
 */

const useCollaborators = () => {
  const { getPath } = useDatabasePaths();

  const { data: userList } = useCollection(getPath(DB_PATHS.adminUsers));
  const collaborators =
    userList?.filter((user) => !!user.email).map((user) => user.email) || [];

  return { collaborators };
};

export default useCollaborators;
