import React from "react";
import { TAB_NAMES } from "./summaryTabNames";


const Summary = React.lazy(() => import("../pages/summaryDetails/tabs/Summary"));
const DetailedResults = React.lazy(() => import("../pages/summaryDetails/tabs/DetailedResults"));
const EmployeeHighlights = React.lazy(() => import("../pages/summaryDetails/tabs/EmployeeHighlights"));
const Forecasts = React.lazy(() => import("../pages/summaryDetails/tabs/Forecasts"));
const HelpSection = React.lazy(() => import("../pages/summaryDetails/tabs/HelpSection"));
const Recommend = React.lazy(() => import("../pages/summaryDetails/tabs/Recommend"));
const CustomerTravel = React.lazy(() => import("../pages/summaryDetails/tabs/CustomerTravel"));
const Dentistry = React.lazy(() => import("../pages/summaryDetails/tabs/Dentistry"));
const Locations = React.lazy(() => import("../pages/summaryDetails/tabs/Locations"));
const Purchases = React.lazy(() => import("../pages/summaryDetails/tabs/Purchases"));
const AssessmentSurvey = React.lazy(() => import("../pages/summaryDetails/tabs/AssessmentSurvey"));
const Progress = React.lazy(() => import("../pages/summaryDetails/tabs/Progress"));

export const DENTISTRY_TAB = {
  name: TAB_NAMES.DENTISTRY,
  icon: "cil-medical-cross",
  className: "dentistry-tab",
  Component: Dentistry
}

export const SURVEY_TAB = {
  name: TAB_NAMES.ASSESSMENT_SURVEY,
  icon: "cilChartPie",
  className: "results-intro-assessment-tab",
  Component: AssessmentSurvey
}

export const PROGRESS_TAB = {
  name: TAB_NAMES.PROGRESS,
  icon: "cilThumbUp",
  className: "results-intro-progress-tab",
  Component: Progress
}

export const LOCATIONS_TAB = {
  name: TAB_NAMES.LOCATIONS,
  icon: "cil-building",
  className: "results-intro-locations-tab",
  Component: Locations
}

export const CUSTOMER_TRAVEL_TAB = {
  name: TAB_NAMES.CUSTOMER_TRAVEL,
  icon: "cil-car-alt",
  className: "results-intro-travel-tab",
  Component: CustomerTravel
}

export const PURCHASES_TAB = {
  name: TAB_NAMES.PURCHASES,
  icon: "cil-money",
  className: "results-intro-purchase-tab",
  Component: Purchases
}

export const EMPLOYEE_HIGHLIGHTS_TAB = {
  name: TAB_NAMES.EMPLOYEE_HIGHLIGHTS,
  icon: "cilChartPie",
  className: "results-intro-employee-tab",
  Component: EmployeeHighlights
}

export const SUMMARY_TABS = [
  {
    name: TAB_NAMES.SUMMARY,
    icon: "cilSpeedometer",
    className: "summary-tab",
    Component: Summary
  },
  {
    name: TAB_NAMES.DETAILED_RESULTS,
    icon: "cilBarChart",
    className: "results-intro-detailed-tab",
    Component: DetailedResults
  },

  {
    name: TAB_NAMES.RECOMMEND,
    icon: "cilChartLine",
    className: "results-intro-reduction-tab",
    Component: Recommend
  },
  {
    name: TAB_NAMES.FORECASTS,
    icon: "cilHistory",
    className: "results-intro-forecasts-tab",
    Component: Forecasts
  },
  {
    name: TAB_NAMES.HELP_SECTION,
    icon: "cilSmilePlus",
    className: "results-intro-help-tab",
    Component: HelpSection
  },
];




