import { CNav } from "@coreui/react";
import styled from "styled-components";

export const StyledNav = styled(CNav)`
  margin-top: -32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-block: 0.5rem;
  margin-bottom: 1rem;

  &.bg-white {
    background-color: white;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15) !important;
  }

  .nav-item {
    border-width: 1px;
    border-style: solid;
    border-color: ${({ color }) => color || "#181743"};
    border-radius: 20px;
    font-weight: 600;
    margin: 0.5rem 1rem;

    .nav-link {
      padding: 0.5rem 2rem;
      color: ${({ color }) => color || "#181743"};
    }

    .active {
      background: ${({ color }) => color || "#181743"};
      border-width: 1px;
      border-style: solid;
      border-color: ${({ color }) => color || "#181743"};
      border-radius: 20px;
      color: #ffffff;

      scale: 1.15;
    }
  }

  .c-dark-theme & {
    background-color: var(--dark) !important;

    .nav-item {
      border-color: var(--light);
    }
  }

  .c-dark-theme &.bg-white {
    background-color: #24252f!important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15) !important;
  }
`;

export default StyledNav;
