import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHttp } from "src/hooks";
import {
  selectAccountSettingsModal,
  setAccountSettingsModal,
} from "src/store/modalSlice";
import { selectUserData } from "src/store/userSlice";
import { checkIsFormTouched } from "src/utils/common-utils";

const AccountSettingsModalController = () => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showChangeName, setShowChangeName] = useState(false);

  const [loading, setLoading] = useState(false);
  const { showAccountSettingsModal: show } = useSelector(
    selectAccountSettingsModal
  );
  const userData = useSelector(selectUserData);

  const { sendRequest } = useHttp();

  const { t } = useTranslation();

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { touchedFields, errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();

  const toggle = () => {
    setShowChangeName(false);
    setShowChangePassword(false);
    dispatch(setAccountSettingsModal(false));
  };

  const enterKeyPrevent = (e) => {
    e.key === "Enter" && e.preventDefault();
  };

  const onChange = (e) => {
    setValue(e.target.name, e.target.value, { shouldTouch: true });
  };

  const onSubmit = async (values) => {
    setLoading(true);
    delete values["confirm-password"];

    let successMsg = values.password
      ? t("settings.changeSuccess1", "Name and password changed successfully!")
      : t("settings.changeSuccess2", "Name changed successfully!");

    try {
      if (Object.values(values).some((value) => !!value)) {
        await sendRequest("/app/users/user", {
          method: "PATCH",
          body: JSON.stringify(values),
          successMsg,
        });
        reset();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  register("password", {
    validate: {
      passwordMatches: (v) => {
        if (!v) return true;
        if (v.length < 6)
          return t(
            "validation.passwordLength",
            "Password has to have at least 6 characters"
          );
        return (
          v === getValues("confirm-password") ||
          t("validation.passwordMatch", "Passwords have to match")
        );
      },
    },
  });
  register("confirm-password");

  const hasValueToSave = checkIsFormTouched(touchedFields);

  return {
    show,
    toggle,
    enterKeyPrevent,
    onChange,
    onSubmit,
    handleSubmit,
    errors,
    touchedFields,
    showChangePassword,
    setShowChangePassword,
    showChangeName,
    setShowChangeName,
    userData,
    loading,
    hasValueToSave,
  };
};

export default AccountSettingsModalController;
