import { t } from "i18next";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { convertStrToBool } from "src/utils/common-utils";

const SurveyController5 = ({
  values,

  onChange,
}) => {
  const {
    formState: { errors },
    setValue,
  } = useFormContext();

  const [usesSecond, setUsesSecond] = useState(values["has-second-vehicle"]);

  const secondVehicleHandler = (e) => {
    const value = convertStrToBool(e.target.value);
    if (!value) {
      setValue("first-vehicle-share", 100);
      setValue("second-mode-commute", t("shared.none"));
    }
    setUsesSecond(value);
    onChange(e);
  };

  return { usesSecond, errors, secondVehicleHandler };
};

export default SurveyController5;
