import { cloneDeep, merge } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { DB_PATHS } from "src/constants";
import {
  selectOverrides,
  selectProjectCategories,
} from "src/store/projectSelectors";
import { useDatabasePaths } from "..";
import { useCollection } from "../databaseHooks";
import { convertResults } from "./utils/convertResults";

const useResults = () => {
  const projectCategoriesRedux = useSelector(selectProjectCategories);

  const projectCategories = useMemo(
    () => projectCategoriesRedux || [],
    [projectCategoriesRedux]
  );

  const overrides = useSelector(selectOverrides);

  const { getPath } = useDatabasePaths();

  const { data: results, status } = useCollection(getPath(DB_PATHS.results));

  const resultWithoutOverrides = useMemo(() => {
    if (status !== "success") return [];
    return convertResults(results, projectCategories);
  }, [status, results, projectCategories]);

  const resultsObj = useMemo(() => {
    if (status !== "success") return [];
    const mergedResults = overrides
      ? merge(cloneDeep(resultWithoutOverrides), overrides)
      : resultWithoutOverrides;
    return mergedResults;
  }, [status, resultWithoutOverrides, overrides]);

  return {
    resultWithoutOverrides,
    data: resultsObj,
    status,
  };
};

export default useResults;
