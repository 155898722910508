import {
  CModal,
  CModalBody,
  CModalHeader
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import SignupForm from "src/components/SignupForm/SignupForm";

const SignupModal = ({ showModal, toggle }) => {
  const closeHandler = () => {
    toggle();
  };

  const { t } = useTranslation();

  const onSignup = () => {
    closeHandler();
  };

  return (
    <CModal  size="lg" show={showModal} onClose={closeHandler}>
      <CModalHeader className="px-4" closeButton>
        <div className="d-flex flex-column">
          <h1>{t("signup.title", "Sign up")}</h1>
          <p>{t("signup.createNew", "Create new organisation")}</p>
        </div>
      </CModalHeader>
      <CModalBody className="px-4">
        <SignupForm onSignup={onSignup} />
      </CModalBody>
    </CModal>
  );
};

export default SignupModal;
