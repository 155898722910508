import React from "react";
import { CFormGroup, CInputRadio, CLabel } from "@coreui/react";

const VerticalRadioButtons = ({
  data,
  name,
  onChange = () => {},
  className,
  defaultValue,
  otherIsChecked = false,
  ...rest
}) => {
  return (
    <div
      className={`d-flex flex-column align-self-start ${className}`}
      {...rest}
    >
      {data.map((item, index) => {
        return (
          <CFormGroup key={index} variant="custom-radio" className="mb-3">
            <CInputRadio
              custom
              id={`${name}-${index}`}
              name={name}
              value={item.value}
              onChange={onChange}
              defaultChecked={item.value === defaultValue}
              checked={
                otherIsChecked && item.value.includes("Other")
                  ? true
                  : undefined
              }
            />
            <CLabel variant="custom-checkbox" htmlFor={`${name}-${index}`}>
              {item.label}
            </CLabel>
          </CFormGroup>
        );
      })}
    </div>
  );
};

export default VerticalRadioButtons;
