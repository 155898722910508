import { CDataTable, CModalBody, CModalHeader } from "@coreui/react";
import formatDistance from "date-fns/formatDistance";
import { camelCase } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DB_PATHS } from "src/constants";
import { useDatabasePaths, usePermissions } from "src/hooks";
import { useCollection } from "src/hooks/databaseHooks";
import { selectProjectData } from "src/store/projectSelectors";
import { selectUserData } from "src/store/userSlice";
import { LoadingSpinner } from "..";
import StyledModal from "../BasicModal/BasicModal.style";
import ClearLogs from "./components/ClearLogs";
import StyledTableContainer from "./components/TableContainer.style";

const LogModal = ({ show, toggle }) => {
  const { email, uid } = useSelector(selectUserData);
  const { name: orgName } = useSelector(selectProjectData);
  const { isSuperAdmin } = usePermissions();

  const { getPath } = useDatabasePaths();

  const { data, status } = useCollection(getPath(DB_PATHS.logs));

  const convertTimestamp = (seconds) => {
    return formatDistance(new Date(seconds * 1000), new Date(), {
      addSuffix: true,
    });
  };

  let filteredData = [];
  if (status === "success") {
    if (data && !isSuperAdmin) {
      filteredData = data
        .filter((entry) => entry.uid === uid)
        .sort((a, b) => b.timestamp - a.timestamp);
    } else if (data) {
      filteredData = data.sort((a, b) => b.timestamp - a.timestamp);
    }
  }

  const { t } = useTranslation();

  let tableColumns = [
    { label: t("log.change"), key: "step" },
    { label: t("log.changedSector", "Changed sector"), key: "sector" },
    { label: t("log.timeOfChange", "Time of change"), key: "timestamp" },
  ];
  if (isSuperAdmin) {
    tableColumns = [
      { label: t("shared.user", "User"), key: "email" },
      ...tableColumns,
    ];
  } else {
  }

  return (
    <StyledModal size="lg" show={show} onClose={toggle} centered>
      <>
        <CModalHeader
          closeButton
          className="pb-0 pt-4 flex-column align-items-center text-center"
        >
          <h3 style={{ fontWeight: 400 }} className="mb-3">
            {t("log.historyOfChanges", "History of changes")}{" "}
            {t("shared.in", "in")} <b>{orgName}</b>
          </h3>
          {!isSuperAdmin && (
            <h4 className="mb-0">
              {t("shared.by", "by")} <strong>{email}</strong>
            </h4>
          )}
        </CModalHeader>
        {status === "loading" ? (
          <LoadingSpinner style={{
            borderRightColor: "#181743",
            marginBottom: "2rem",
          }} />
        ) : (
          <CModalBody>

            <StyledTableContainer>
              <CDataTable
                pagination
                itemsPerPage={6}
                fields={tableColumns}
                items={filteredData}
                scopedSlots={{
                  email: (item, index) => {
                    return (
                      <td>
                        <span>{item.email}</span>
                      </td>
                    );
                  },

                  step: (item, index) => {
                    return (
                      <td>
                        <p className="mb-2">
                          {item.step
                            ? t("shared.step") + " " + item.step
                            : item.change || "N/A"}
                        </p>
                        {!!item.site && (
                          <span>
                            {" "}
                            {t("shared.in", "in")} <b>{item.site}</b>
                          </span>
                        )}
                        {!!item.provider && (
                          <span>
                            {" "}
                            {t("shared.in", "in")} <b>{item.provider}</b>
                          </span>
                        )}
                      </td>
                    );
                  },
                  sector: (item, index) => {
                    return (
                      <td style={{ color: "#5768FF", fontWeight: 700 }}>
                        {t(`${camelCase(item.sector)}.title`)}
                      </td>
                    );
                  },
                  timestamp: (item, index) => {
                    return (
                      <td>
                        <span>{convertTimestamp(item.timestamp)}</span>
                      </td>
                    );
                  },
                }}
              />
            </StyledTableContainer>
            <ClearLogs />
          </CModalBody>)}
      </>
    </StyledModal>
  );
};

export default LogModal;
