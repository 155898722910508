import React from "react";
import StyledSquareButtonOutline from "./SquareButtonOutline.style";

const SquareSquareButtonOutline = ({ children, ...rest }) => {
  return (
    <StyledSquareButtonOutline
      color="primary"
      variant="outline"
      shape="square"
      {...rest}
    >
      {children}
    </StyledSquareButtonOutline>
  );
};

export default SquareSquareButtonOutline;
