import { createSlice } from "@reduxjs/toolkit";

const initialState = { step: 0, site: null, tab: "", summaryTab: "summary" };

const navSlice = createSlice({
  name: "nav",
  initialState: initialState,
  reducers: {
    setStep(state, action) {
      state.step = action.payload;
    },
    setSite(state, action) {
      state.site = action.payload;
    },
    setSiteStep(state, action) {
      state.site = action.payload.site;
      state.step = action.payload.step;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setSummaryTab(state, action) {
      state.summaryTab = action.payload;
    },
  },
});

export const { setStep, setSite, setSiteStep, setTab, setSummaryTab } =
  navSlice.actions;

export const selectSummaryTab = (state) => state.nav.summaryTab;

export default navSlice.reducer;
