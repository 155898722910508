import {
  CBadge,
  CButton,
  CDataTable,
  CFormGroup,
  CInputCheckbox,
  CLabel,
  CPopover,
  CTooltip,
} from "@coreui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { StepCard } from "src/components";
import { timestampToString } from "src/utils/common-utils/helpers";

import CIcon from "@coreui/icons-react";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { MdArchive, MdUnarchive } from "react-icons/md";
import { selectUserEmail } from "src/store/userSlice";
import useConstants from "../constants/tableConstants";
import { StyledSuperTable } from "../SuperAdminPage.style";
import ChangeNameModal from "./ChangeNameModal";

const OrgsCard = ({
  allOrgs = [],
  archiveOrg,
  unarchiveOrg,
  selectOrg,
  assignOwner,
  unassignOwner,
  setFreemium,
}) => {
  const [showArchive, setShowArchive] = useState(false);
  const { t } = useTranslation();
  const userEmail = useSelector(selectUserEmail);

  const { SUPER_COLUMN } = useConstants();

  const convertOrgForTable = (org) => {
    const hasUpdated = org["suppliers-changed"] || org["categories-changed"];
    const owner = org.owner || "";
    const created = org.created || "";
    const freemium = org.freemium ? "YES" : "NO";
    return { ...org, hasUpdated, owner, created, freemium };
  };

  const tableItems = !showArchive
    ? allOrgs.map(convertOrgForTable).filter((item) => !item.archived)
    : allOrgs.map(convertOrgForTable).filter((item) => !!item.archived);

  const [orgToChange, setOrgToChange] = useState(null);

  const toggle = (org) => setOrgToChange(org);

  return (
    <>
      <StepCard>
        <h3 style={{ fontSize: "30px" }}>
          {!showArchive
            ? t("superAdmin.activeOrgs")
            : t("superAdmin.archivedOrgs")}
        </h3>
        <p className="text-info">{t("superAdmin.clickToSelect")}</p>
        {!showArchive ? (
          <CButton
            color="info"
            variant="outline"
            onClick={() => setShowArchive(true)}
          >
            {t("superAdmin.showArchived")} <AiOutlineArrowRight />
          </CButton>
        ) : (
          <CButton
            color="success"
            variant="outline"
            onClick={() => setShowArchive(false)}
          >
            <AiOutlineArrowLeft /> {t("superAdmin.showActive")}
          </CButton>
        )}
        <StyledSuperTable className="w-100">
          <CDataTable
            itemsPerPageSelect={{ label: t("shared.itemsPerPage") }}
            pagination
            sorter
            hover
            columnFilter
            noItemsView={{
              noItems: !showArchive
                ? t("superAdmin.noOrgs")
                : t("superAdmin.noArchivedOrgs"),
            }}
            onRowClick={(item) => selectOrg(item)}
            fields={SUPER_COLUMN}
            items={tableItems}
            columnHeaderSlot={{
              archive: !showArchive ? (
                <span>{t("superAdmin.moveArchBtn")}</span>
              ) : (
                <span>{t("superAdmin.moveActiveBtn")}</span>
              ),
            }}
            scopedSlots={{
              name: (item) => (
                <td
                  className="py-4 d-flex justify-content-between"
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{item.name}</span>
                    {item["hasUpdated"] && (
                      <CBadge color="info">
                        <small>{t("shared.updated", "Updated")}</small>
                      </CBadge>
                    )}
                  </div>
                  <CPopover content="Change name">
                    <CButton
                      variant="outline"
                      color="success"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggle(item);
                      }}
                    >
                      <CIcon name="cil-pencil" />
                    </CButton>
                  </CPopover>
                </td>
              ),
              "last-updated": (item) => (
                <td>{timestampToString(item["last-updated"]) || ""}</td>
              ),
              created: (item) => (
                <td>{timestampToString(item["created"]) || ""}</td>
              ),
              owner: (item, index) => {
                const isOwnerMe = item.owner === userEmail;

                return (
                  <td>
                    {item.owner ? (
                      <div className="d-flex align-items-center">
                        <span className={isOwnerMe ? "font-bold" : ""}>
                          {item.owner}
                        </span>
                        {isOwnerMe && (
                          <CTooltip content={t("superAdmin.unassignTooltip")}>
                            <CButton
                              size="sm"
                              variant="outline"
                              color="danger"
                              className=" ml-1"
                              style={{ lineHeight: 1 }}
                              onClick={(e) => unassignOwner(e, item.id)}
                            >
                              <CIcon name="cilX" size="sm" />
                            </CButton>
                          </CTooltip>
                        )}
                      </div>
                    ) : (
                      <CButton
                        size="sm"
                        variant="outline"
                        color="primary"
                        className="d-flex align-items-center"
                        onClick={(e) => assignOwner(e, item.id)}
                      >
                        {t("superAdmin.assignBtn")}
                        <CIcon name="cilPlus" className="ml-1" />
                      </CButton>
                    )}
                  </td>
                );
              },
              archive: (item, index) => {
                return (
                  <td>
                    {!item.archived ? (
                      <CButton
                        color="info"
                        variant="outline"
                        onClick={(e) => archiveOrg(e, item.id)}
                      >
                        <MdArchive fontSize={22} />
                      </CButton>
                    ) : (
                      <CButton
                        color="success"
                        variant="outline"
                        onClick={(e) => unarchiveOrg(e, item.id)}
                      >
                        <MdUnarchive fontSize={22} />
                      </CButton>
                    )}
                  </td>
                );
              },
              freemium: (item, index) => {
                return (
                  <td onClick={(e) => e.stopPropagation()}>
                    <CFormGroup
                      className="d-flex justify-content-center"
                      variant="custom-checkbox"
                    >
                      <CInputCheckbox
                        custom
                        id={`freemium-${item.name}`}
                        onChange={(e) => {
                          setFreemium(e, item.id, e.target.checked);
                        }}
                        checked={item.freemium === "YES"}
                        style={{ borderRadius: 0 }}
                      />
                      <CLabel
                        variant="custom-checkbox"
                        htmlFor={`freemium-${item.name}`}
                      ></CLabel>
                    </CFormGroup>
                  </td>
                );
              },
            }}
          />
        </StyledSuperTable>
      </StepCard>
      <ChangeNameModal
        showModal={!!orgToChange}
        org={orgToChange}
        toggle={toggle}
        orgNames={allOrgs.map((org) => org.name)}
      />
    </>
  );
};

export default OrgsCard;
