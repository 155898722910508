import styled from "styled-components";
import BasicInput from "../BasicInput/BasicInput.style";

export const RangeWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .range-value {
    position: absolute;
    top: -25px;
    margin-right: 15px;
    span {
      width: 50px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background-color: var(--secondary);
      color: #ffffff;
      font-size: 1rem;
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 6px;
      /* :before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 10px solid #03a9f4;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        margin-top: -1px;
      } */
    }
  }
`;

export const StyledInput = styled(BasicInput)`
  padding: 0;
  align-self: center;
  margin-inline: 0.25rem;

  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 3px;
  background-color: green;
  opacity: 0.8;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-bottom: none;

  &:hover {
    opacity: 1;
  }

  &:focus {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 3px;
    background-color: darkgreen;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    border-bottom: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: green;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: green;
    cursor: pointer;
  }
`;
