import { convertKebabToDisplay } from "src/utils/common-utils";

import { camelCase, isObject } from "lodash";

import { t } from "i18next";
import { SECTOR_KEYS } from "src/constants/sectorKeys";

export const convertTaskObjToEmailLookup = (
  findDiffObj,
  sites,
  pensionProvs,
  investmentProvs
) => {
  const emailObject = {};
  for (let sectorName in findDiffObj) {
    for (let fieldName in findDiffObj[sectorName]) {
      const field = findDiffObj[sectorName][fieldName];
      if (!field || field === "none") continue;
      if (!isObject(field)) {
        if (!emailObject[field]) {
          emailObject[field] = [
            {
              sector: t(`${camelCase(sectorName)}.title`),
              field: convertKebabToDisplay(fieldName),
            },
          ];
        } else {
          emailObject[field].push({
            sector: t(`${camelCase(sectorName)}.title`),
            field: convertKebabToDisplay(fieldName),
          });
        }
      } else if (sectorName === SECTOR_KEYS.siteData) {
        const siteName =
          sites?.find((site) => site.id === fieldName)?.name || "";

        for (let subFieldName in field) {
          const email = field[subFieldName];
          if (email === "none") continue;
          if (!emailObject[email]) {
            emailObject[email] = [
              {
                sector: t(`${camelCase(sectorName)}.title`),
                site: siteName,
                field: convertKebabToDisplay(subFieldName),
              },
            ];
          } else {
            emailObject[email].push({
              sector: t(`${camelCase(sectorName)}.title`),
              site: siteName,
              field: convertKebabToDisplay(subFieldName),
            });
          }
        }
      } else if (sectorName === SECTOR_KEYS.investmentData) {
        for (let subFieldName in field) {
          const providerName =
            pensionProvs?.find((provider) => provider.id === subFieldName)?.[
              "provider-name"
            ] ||
            investmentProvs.find((provider) => provider.id === subFieldName)?.[
              "provider-name"
            ];

          const email = field[subFieldName];
          if (email === "none") continue;
          if (!emailObject[email]) {
            emailObject[email] = [
              {
                sector: t(`${camelCase(sectorName)}.title`),
                subsector: convertKebabToDisplay(fieldName),
                field: providerName,
              },
            ];
          } else {
            emailObject[email].push({
              sector: t(`${camelCase(sectorName)}.title`),
              subsector: convertKebabToDisplay(fieldName),
              field: providerName,
            });
          }
        }
      }
    }
  }

  return emailObject;
};
