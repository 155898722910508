import React from "react";
import { ButtonBasic } from "..";
import { useAuth } from "reactfire";
import firebase from "firebase/compat/app";
import { analytics } from "src/adapters/common-set-up/firebase";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { clearUserData } from "src/store/userSlice";

const SignOutButton = ({ className, size }) => {
  const auth = useAuth();

  const dispatch = useDispatch();

  const signOut = async () => {
    await auth.signOut().then(() => {
      firebase.firestore().terminate();
      dispatch(clearUserData());
    });

    analytics.logEvent("sing_out", { method: "sign_out_button" });
  };

  const { t } = useTranslation();

  return (
    <ButtonBasic className={className} size={size} onClick={signOut}>
      {t("shared.signOut", "Sign out")}
    </ButtonBasic>
  );
};

export default SignOutButton;
