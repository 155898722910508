import { CFormGroup, CInputCheckbox, CLabel } from "@coreui/react";
import { useFormContext } from "react-hook-form";
import { DB_PATHS } from "src/constants";
import { useUpdateDatabase } from "src/hooks";

const OptionalQuestion = ({ name, children }) => {
  const { isDemo, optionalQuestions } = useFormContext();

  const { setOrUpdateDocument } = useUpdateDatabase();

  const updateSetting = async (shouldShow) => {
    await setOrUpdateDocument(DB_PATHS.project, {
      optionalQuestions: { [name]: shouldShow },
    });
  };

  return (
    <>
      {isDemo && (
        <CFormGroup
          className="mr-auto mb-3 "
          variant="custom-checkbox"
          style={{ fontSize: "14px" }}
        >
          <CInputCheckbox
            custom
            id={`opinion-questions-${name}`}
            onChange={(e) => {
              const { checked } = e.target;
              updateSetting(checked);
            }}
            checked={optionalQuestions?.[name] ?? true}
          />
          <CLabel
            variant="custom-checkbox"
            htmlFor={`opinion-questions-${name}`}
            className="text-muted"
          >
            Show this question in survey?
          </CLabel>
        </CFormGroup>
      )}
      {(optionalQuestions?.[name] !== false || isDemo) && children}
    </>
  );
};

export default OptionalQuestion;
