import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";

import { FirebaseAppProvider } from "reactfire";
import { firebaseConfig } from "src/adapters/common-set-up/firebase";

import { StepsProvider } from "react-step-builder";

import store from "./store/store";

import { icons } from "./assets/icons";
import "./i18n/config";
import ErrorBoundary from "./components/ErrorBoundary";

import { ResizeObserver as Polyfill } from "@juggle/resize-observer";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";



window.ResizeObserver = window.ResizeObserver || Polyfill;

React.icons = icons;

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <StepsProvider>
      <Provider store={store}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Provider>
    </StepsProvider>
  </FirebaseAppProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
