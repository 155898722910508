import { CButton } from "@coreui/react";
import styled from "styled-components";

const StyledButton = styled(CButton)`


  border-radius: 200px;
  border: 0.1px solid hsla(0, 0%, 20%, 0.5);

  .c-dark-theme & {
    color: white;
    border-color: white !important;
  }
`;

export default StyledButton
