import React from "react";

const SectionTitle = ({ title, margin = "mb-2", className = "", ...rest }) => {
  return (
    <h1 className={`w-100 text-center ${className} ${margin}`} {...rest}>
      {title}
    </h1>
  );
};

export default SectionTitle;
