import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CImg,
  CModalBody,
  CModalFooter,
  CRow,
} from "@coreui/react";
import { upperFirst } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import ReactJoyride from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { analytics } from "src/adapters/common-set-up/firebase";
import { DB_PATHS } from "src/constants";
import { usePermissions, useDatabasePaths } from "src/hooks";
import { useDoc } from "src/hooks/databaseHooks";
import {
  selectWelcomeModalState,
  setFreemiumModal,
  setWelcomeModal,
} from "src/store/modalSlice";
import {
  selectIsFreemium,
  selectOrgId,
  selectOrgName,
} from "src/store/projectSelectors";
import logo from "../../assets/images/ZeroBees_logo_new_white.svg";
import CTAButton from "../CTAButton/CTAButton";
import {
  DataCollectionCard,
  EmployeesCard,
  OurActionsCard,
  ResultsCard,
  StartTutorialButton,
  SuppliersCard,
  YourActionsCard,
} from "./components";
import useNextActions from "./hooks/useNextActions";
import StyledModal from "./WelcomeModal.style";

const WelcomeModal = ({
  steps,
  stepIndex,
  callbackHandler,
  watchTutorial,
  tutorialToggle,
}) => {
  const show = useSelector(selectWelcomeModalState);
  const dispatch = useDispatch();

  const orgName = useSelector(selectOrgName);
  const orgId = useSelector(selectOrgId);

  const { isSuperAdmin } = usePermissions();
  const { getPath } = useDatabasePaths();

  const { data: results } = useDoc(getPath([...DB_PATHS.results, "scope-3"]));

  const areResultsCalculated = !!results;

  const { yourActions, ourActions } = useNextActions({
    areResultsCalculated,
  });

  const closeModal = (e, closeForToday = false) => {
    if (closeForToday) {
      const today = new Date();

      // Set hour to today midnight
      today.setHours(24, 0, 0, 0);

      const timestamp = today / 1000;
      localStorage.setItem("welcomeModalCookie", timestamp);
    }

    dispatch(setWelcomeModal({ show: false, hasShown: true }));
  };

  const tutorialClickHandler = () => {
    closeModal();
    tutorialToggle();
    analytics.logEvent("tutorial_begin");
  };

  const { t } = useTranslation();

  const isFreemium = useSelector(selectIsFreemium);

  return (
    <>
      <StyledModal
        show={show}
        onClose={closeModal}
        centered
        size="xl"
        className="w-100 "
      >
        <CModalBody
          style={{ maxHeight: "85vh", overflowY: "scroll" }}
          className="py-0 px-sm-5 bg-light position-relative"
        >
          <CButton
            style={{ position: "absolute", top: "1rem", right: "1rem" }}
            variant="outline"
            type="button"
            color="primary"
            onClick={closeModal}
          >
            <CIcon name="cilX" />
          </CButton>
          <h1 style={{ fontWeight: "bold" }} className="mt-5 text-center mb-3">
            <Trans
              i18nKey="welcomeModal.title"
              components={{ 1: <span className="text-info"></span> }}
              values={{ orgName }}
            ></Trans>
          </h1>
          {isFreemium && (
            <div className="d-flex justify-content-center w-100 mb-3">
              <CTAButton
                onClick={() => {
                  dispatch(setWelcomeModal({ show: false }));
                  dispatch(setFreemiumModal({ show: true }));
                }}
              >
                {t("welcomeModal.joinPremium")}
              </CTAButton>
            </div>
          )}

          <CRow className="text-center px-xl-5">
            <CCol>
              <CCard>
                <CCardBody>
                  <h5 className="m-0">{t("welcomeModal.intro1")}</h5>
                  <p
                    style={{ fontSize: "16px", maxWidth: "600px" }}
                    className="my-1 mx-auto"
                  >
                    {t("welcomeModal.intro2")}
                  </p>
                  <p
                    style={{ fontSize: "16px", maxWidth: "600px" }}
                    className="m-0 mx-auto"
                  >
                    {t("welcomeModal.intro3")}
                  </p>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>

          <CRow className="justify-content-between px-xl-5 ">
            <CCol lg={8}>
              <DataCollectionCard />
            </CCol>
            <CCol lg={4}>
              <ResultsCard {...{ areResultsCalculated, orgId }} />
            </CCol>
          </CRow>
          <CRow className=" justify-content-between px-xl-5">
            <CCol className="d-flex" lg={6}>
              <YourActionsCard {...{ yourActions, ourActions }} />
            </CCol>
            <CCol lg={6}>
              <OurActionsCard {...{ yourActions, ourActions }} />
            </CCol>
          </CRow>
          <CRow className="justify-content-between px-xl-5 ">
            <CCol lg={6}>
              <EmployeesCard />
            </CCol>
            <CCol lg={6}>
              <SuppliersCard />
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter
          className="d-flex flex-column justify-content-between align-items-center flex-md-row "
          style={{ backgroundColor: "#009245" }}
        >
          <CButton
            color="dark border"
            onClick={(e) => closeModal(e, true)}
            className="text-white"
          >
            {t("welcomeModal.dontShow")}
          </CButton>
          <div>
            <CImg
              className="d-sm-down-none footer-logo"
              width="120px"
              src={logo}
              alt="Zerobees logo"
            />
          </div>
          <div
            className="d-flex flex-column align-items-center justify-content-center flex-md-row"
            style={{ gap: "0.5rem" }}
          >
            {!isSuperAdmin && (
              <StartTutorialButton {...{ tutorialClickHandler }} />
            )}
            <CButton color="warning" onClick={closeModal}>
              {upperFirst(t("shared.close"))}
            </CButton>
          </div>
        </CModalFooter>
      </StyledModal>
      <ReactJoyride
        showSkipButton
        steps={steps}
        stepIndex={stepIndex}
        run={watchTutorial}
        callback={callbackHandler}
        scrollToFirstStep
        hideCloseButton
        locale={{
          close: t("shared.next"),
        }}
        styles={{
          buttonNext: { backgroundColor: "var(--info)" },
        }}
      />
    </>
  );
};

export default WelcomeModal;
