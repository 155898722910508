import { CPopover, CSidebarNavDropdown, CSidebarNavItem } from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const GlobalSettingsDropdown = ({ show, toggle }) => {
  const { t } = useTranslation();

  return (
    <CSidebarNavDropdown
      name={t("routes.globalSettings")}
      className={show ? "c-show" : ""}
      show={show}
      onClick={toggle}
    >
      <CPopover content={t("routes.constants")}>
        <CSidebarNavItem
          name={t("routes.constants")}
          icon="cilInput"
          to="/global/constants"
        />
      </CPopover>
      <CPopover content={t("routes.categories")}>
        <CSidebarNavItem
          name={t("routes.categories")}
          icon="cilInput"
          to="/global/categories"
        />
      </CPopover>
      <CPopover content={t("routes.factors")}>
        <CSidebarNavItem
          name={t("routes.factors")}
          icon="cilInput"
          to="/global/factors"
        />
      </CPopover>
      <CPopover content={t("routes.defaultSections")}>
        <CSidebarNavItem
          name={t("routes.defaultSections")}
          icon="cilInput"
          to="/global/sections"
        />
      </CPopover>
      <CPopover content="Rate settings">
        <CSidebarNavItem
          name="Rate settings"
          icon="cilInput"
          to="/global/rate"
        />
      </CPopover>
      <CPopover content="Ignore purchases">
        <CSidebarNavItem
          name="Ignore purchases"
          icon="cilInput"
          to="/global/ignore"
        />
      </CPopover>
    </CSidebarNavDropdown>
  );
};

export default GlobalSettingsDropdown;
