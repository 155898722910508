import { CFormGroup } from "@coreui/react";
import { BasicNumberInput, YesOrNoQuestion } from "src/components";
import FuelsTable from "src/components/FuelsTable";
import { convertStrToBool } from "src/utils/common-utils";

const EnergyQuestions = ({ product, onChange, idx, formMethods }) => {
  return (
    <div className="d-flex flex-column w-100 mt-4" style={{ gap: "1.5rem" }}>
      <CFormGroup className="w-100">
        <p className="mb-0">
          What’s the electricity consumed per unit produced/processed? (kWh)
        </p>
        <BasicNumberInput
          placeholder={"Enter value"}
          defaultValue={product.electricity}
          name={`products.${idx}.electricity`}
          onChange={onChange}
          allowNegative={false}
          min={0}
        />
      </CFormGroup>
      <CFormGroup className="w-100">
        <p className="mb-1">Is this renewably sourced?</p>
        <YesOrNoQuestion
          className=" mr-auto"
          onChange={(e) => {
            const value = convertStrToBool(e.target.value);
            onChange({
              target: { name: `products.${idx}.green-tariff`, value },
            });
          }}
          defaultValue={product["green-tariff"]}
          name={`products.${idx}.green-tariff`}
        />
      </CFormGroup>
      <CFormGroup className="w-100">
        <p className="mb-0">
          What’s the gas consumed per unit produced/processed? (kWh)
        </p>
        <BasicNumberInput
          placeholder={"Enter value"}
          defaultValue={product.gas}
          name={`products.${idx}.gas`}
          onChange={onChange}
          allowNegative={false}
          min={0}
        />
      </CFormGroup>
      <div>
        <p className="mb-2">
          Were there any other fuels burnt per unit processed/produced, and if
          so, how much?
        </p>
        <FuelsTable
          itemName={`products.${idx}`}
          tableName={`products.${idx}.fuels`}
          onChange={onChange}
          formMethods={formMethods}
        />
      </div>
    </div>
  );
};

export default EnergyQuestions;
