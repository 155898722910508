import { CAlert } from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const OfflineAlert = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <CAlert color="danger" className=" w-75 text-center">
        {t("errors.offline", "You are offline!")}
      </CAlert>
    </Wrapper>
  );
};

export default OfflineAlert;

const Wrapper = styled.div`
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;
