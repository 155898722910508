import { CProgress, CProgressBar } from "@coreui/react";
import styled from "styled-components";

/* container & background */
export const Progress = styled(CProgress)`
  inset: 0;
  background-color: lightgrey;
  border-radius: 0;

  .c-dark-theme & {
    background-color: #ffffff !important;
  }
`;

export const ProgressBar = styled(CProgressBar)`
  background-color: #181743;
  .c-dark-theme & {
    background-color: var(--info) !important;
  }
`;
