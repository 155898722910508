import styled from "styled-components";

const StyledDiv = styled.div`


  &.overlay {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
`;

export default StyledDiv
