import React from "react";
import {
  CButton,
  CForm,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import { BasicInput, ErrorText } from "src/components";
import { useForm } from "react-hook-form";
import { useUpdateDatabase } from "src/hooks";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { checkIsFormTouched } from "src/utils/common-utils";

const ChangeNameModal = ({ showModal, toggle, org, orgNames }) => {
  const closeHandler = () => {
    toggle(null);
  };

  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, touchedFields },
    register,
  } = useForm();

  useEffect(() => {
    if (!org) return;
    reset({ orgName: org.name });
  }, [org]);

  const { updateDocument } = useUpdateDatabase();

  const onSubmit = async (values) => {
    if (!values?.orgName) return;

    if (!org?.id) return;

    await updateDocument(["organisations", org.id], { name: values.orgName });
    toggle(null);
  };

  const { t } = useTranslation();

  register("orgName", {
    validate: {
      noDuplicates: (name) => {
        if (orgNames.includes(name)) return t("superAdmin.duplicateName");
        return true;
      },
    },
  });

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <CModal show={showModal} onClose={closeHandler} centered>
        <CModalHeader className="px-4" closeButton>
          <h2>{t("superAdmin.changeName")}</h2>
        </CModalHeader>
        <CModalBody className="px-4">
          <BasicInput
            value={watch("orgName")}
            onChange={(e) =>
              setValue("orgName", e.target.value, { shouldTouch: true })
            }
          />
          <ErrorText value={errors?.orgName} />
        </CModalBody>
        <CModalFooter className="d-flex  bg-light">
          <CButton
            color="success"
            type="submit"
            disabled={!checkIsFormTouched(touchedFields)}
          >
            {t("shared.save")}
          </CButton>
          <CButton color="warning" type="button" onClick={closeHandler}>
            {t("shared.cancel", "Cancel")}
          </CButton>
        </CModalFooter>
      </CModal>
    </CForm>
  );
};

export default ChangeNameModal;
