import { CButton, CPopover } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { GoVerified } from "react-icons/go";
import { useDispatch } from "react-redux";
import { setFreemiumModal } from "src/store/modalSlice";
import styled from "styled-components";

const HeaderFreemiumText = ({
  fontSize = "18px",
  isFreemium,
  iconSize = "1.3rem",
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center ml-1">
      {isFreemium ? (
        <CPopover
          header={t("freemium.popoverTitle")}
          content="Click to find out more"
        >
          <StyledButton
            variant="ghost"
            onClick={() => dispatch(setFreemiumModal({ show: true }))}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize,
              }}
              className="text-info"
            >
              {t("freemium.freemium")}
            </span>
          </StyledButton>
        </CPopover>
      ) : (
        <>
          <span
            style={{
              fontWeight: "bold",
              fontSize,
            }}
            className="text-success"
          >
            {t("freemium.premium")}
          </span>
          <GoVerified className="ml-1" color="var(--success)" size={iconSize} />
        </>
      )}
    </div>
  );
};

const StyledButton = styled(CButton)`
  padding: 0;
  opacity: 0.75;

  /* &:hover {
    opacity: 1;
    text-decoration: underline;
  } */
`;

export default HeaderFreemiumText;
