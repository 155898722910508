import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSteps } from "react-step-builder";
import {
  DemoWarning,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  VerticalRadioButtons,
} from "src/components";
import { DistanceQuestion, InfoTooltip, Subtitle } from "../components";
import VehicleQuestion from "../components/VehicleQuestion";
import controller from "./SurveyController5";

const EmployeeSurvey5 = ({ data: values = {}, onChange, onSubmit, isDemo }) => {
  const { usesSecond, errors, secondVehicleHandler } = controller({
    values,
    onChange,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { jump, next } = useSteps();

  const onNext = async () => {
    const noErrors = await onSubmit();
    if (!noErrors) return;
    if (usesSecond === false) {
      jump(7);
    } else {
      next();
    }
  };

  const { t } = useTranslation();

  return (
    <FormStepWrapper
      onNext={onNext}
      aboveCardSlot={
        <>
          {isDemo && <DemoWarning />}
          <Subtitle
            content={t(
              "employeeSurvey.aboutVehicles",
              "About the vehicles you use in your commute"
            )}
          />
        </>
      }
    >
      <QuestionWrapper>
        <h3>
          10.{" "}
          You've told us that you had at least one motor vehicle in your commute. How far did you commute in an average week by motor vehicle - total for the week?
          <InfoTooltip
            content={t(
              "employeeSurvey.q_distanceTooltip",
              "Total for the week, both to and from work (car or motorcycle), including all vehicles you usually use."
            )}
          />
        </h3>
        <DistanceQuestion
          onChange={onChange}
          name="commute"
          defaultValue={values.commute}
          errorValue={errors?.commute}
        />
      </QuestionWrapper>

      <QuestionWrapper>
        <VehicleQuestion
          {...{ values, onChange, errors }}
          name="first-vehicle"
          title={
            "11. " +
            t(
              "employeeSurvey.q_mainVehicle",
              "You gave a distance for car or motorcycle. Please tell us more about your main commuting vehicle."
            )
          }
        />
      </QuestionWrapper>

      <QuestionWrapper>
        <h3>
          12.{" "}
          Is this the only vehicle that you used for your commute or do you have a second vehicle that you were using in a typical week?
        </h3>
        <VerticalRadioButtons
          name="has-second-vehicle"
          data={[
            { value: true, label: t("arrays.onlyVehicle.1") },
            { value: false, label: t("arrays.onlyVehicle.0") },
          ]}
          onChange={secondVehicleHandler}
          defaultValue={values["has-second-vehicle"]}
        />
        {usesSecond === false && (
          <small className="text-info">
            {t(
              "employeeSurvey.q_noSecondVehicleSkip",
              " Because you are not using a second vehicle, you will skip to step 7."
            )}
          </small>
        )}
        <ErrorText value={errors["has-second-vehicle"]} />
      </QuestionWrapper>
    </FormStepWrapper>
  );
};

export default EmployeeSurvey5;
