import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DB_PATHS } from "src/constants";
import { useHttp, useUpdateDatabase } from "src/hooks";
import { selectOrgId, selectPeriod } from "src/store/projectSelectors";
import { useFactorSets } from "../useFactorSets.js";

const useClassificationTask = () => {
  const { deleteField, setOrUpdateDocument } = useUpdateDatabase();

  const { classifyTask, runningQueue } =
    useSelector((state) => state.project?.details?.purchaseData) || {};

  const orgId = useSelector(selectOrgId);
  const periodId = useSelector(selectPeriod);
  const { sendRequest } = useHttp();
  const { primaryCountry, calcVersion = 1 } = useFactorSets();

  const { t } = useTranslation();

  const setClassifyTask = async () => {
    const { taskId } = await sendRequest("/scheduleClassificationTask", {
      method: "POST",
      body: JSON.stringify({ orgId, periodId, primaryCountry, calcVersion }),
      successMsg: t("requests.classificationSuccess"),
      errorMsg: t("requests.classificationError"),
    });

    if (!taskId) return;

    await setOrUpdateDocument(DB_PATHS.purchaseData, {
      "classify-task": taskId,
    });
  };

  const deleteClassifyTaskIfExists = async () => {
    if (!classifyTask) return;
    try {
      await sendRequest(
        "/deleteClassificationTask",
        {
          method: "POST",
          body: JSON.stringify({ taskId: classifyTask }),
          successMsg: t("requests.classDeleteSuccess"),
          errorMsg: t("requests.classDeleteError"),
        },
        true
      );
    } catch (e) {
      console.log(e);
    }

    await deleteField(DB_PATHS.purchaseData, "classify-task");
  };

  return {
    setClassifyTask,
    deleteClassifyTaskIfExists,
    classifyTask,
    runningQueue,
  };
};

export default useClassificationTask;
