import React from "react";
import { CRow, CFormGroup, CInputRadio, CLabel } from "@coreui/react";

const RadioButtons = ({
  data,
  name,
  onChange = () => {},
  className = "",
  buttonClass = "",
  defaultValue,
  ...rest
}) => {
  return (
    <div className={className} {...rest}>
      {data.map((item, index) => {
        return (
          <CFormGroup
            key={index}
            variant="custom-radio"
            inline
            className={buttonClass}
          >
            <CInputRadio
              custom
              id={`${name}-${index}`}
              name={name}
              value={item.value}
              onChange={onChange}
              defaultChecked={item.value == defaultValue}
            />
            <CLabel variant="custom-checkbox" htmlFor={`${name}-${index}`}>
              {item.label}
            </CLabel>
          </CFormGroup>
        );
      })}
    </div>
  );
};

export default RadioButtons;
