import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { useSteps } from "react-step-builder";
import { DemoWarning } from "src/components";

const AboveCard = ({ isDemo, info }) => {
  const { current } = useSteps();
  const { t } = useTranslation();
  return (
    <>
      {isDemo && <DemoWarning />}
      <h1 className="m-0 text-center text-info">{info.orgName}</h1>
      <h2 className="mb-4 text-center">{t("shared.supplierSurvey")}</h2>
      {current === 2 && (
        <div className="text-center px-2">
          <Trans
            i18nKey="supplierSurvey.intro2"
            values={{
              name: info.orgName,
              period: info.reportingPeriod,
            }}
            components={{
              1: <p className="mb-1"></p>,
              2: <p className="mb-1"></p>,
              3: <p className="mb-3"></p>,
            }}
          />
        </div>
      )}
    </>
  );
};

export default AboveCard;
