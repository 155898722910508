import { useTranslation } from "react-i18next";
import constants from "src/i18n/en/arrays.json";

const useTranslatedConstants = () => {
  const { t } = useTranslation();
  const arrays = constants.arrays;

  const convertConstants = (arrayName) =>
    arrays[arrayName]?.map((value, index) => {
      return { value, label: t(`arrays.${arrayName}.${index}`) };
    }) || [];

  return { convertConstants };
};

export default useTranslatedConstants;
