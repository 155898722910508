export const SECTOR_KEYS = {
  coreData: "core-data",
  siteData: "site-data",
  employeeData: "employee-data",
  forecastingData: "forecasting-data",
  purchaseData: "purchase-data",
  suppliersData: "supplier-data",
  fleetData: "fleet-data",
  travelData: "travel-data",
  adData: "ad-data",
  investmentData: "investment-data",
  volunteerData: "volunteer-data",
  dentistryData: "dentist-data",
  materialsData: "materials-data",
  packagingData: "packaging-data",
  distributionData: "distribution-data",
  webData: "web-data",
};
