/**
 * Used to indicate a dynamic element in an Arr path to DB Ref that will be replaced
 * -userID will be replaced by UID of admin user
 * -guideItem wil be replaced by an item from a secondary array
 * -NOTE: THis variable could have been stored in the adaptors. Maybe it makes more sense there. Its used heavily in the product
 * itself so felt easier to store here.
 */
 export const DB_CONSTANTS = { userID: 'USER_ID', guideItem: 'GUIDE_ITEM' };



 export const FIREBASE_WRITE_TYPES = Object.freeze({
    update: 'update',
    write: 'write',
  });
  