import { useEffect } from "react";
import { useSteps } from "react-step-builder";
import { removeUndefinedFromObjectValues } from "src/utils/common-utils";
import { useBasicForm, useUpdateDatabase } from "..";

/**
 * <p> A custom hook for handling multi-step forms based on React Form Hooks.  </p>
 *
 *
 * @param {Arr} pathArr - An array representing the path. This gets joined into a string
 * @param {Object} dataDB - An object representing values to populate the form
 * @param {Boolean} canAdvanceEmpty - If true, form can advance without validating the fields

 * @param {Function} getFieldsObject - A function which receives getValues (Function) from React Form Hooks as argument for conditional validation,
 * and returns an object where:
 *
 *  - keys are steps
 *  - values are arrays of field objects {name:fieldName, options:validationOptions}
 *
 *  ex.
 *
 *    {
 *      1:[{ name:field1, options:options1}, { name:field2, options:options2 }],
 *
 *      2:[{ name:field3, options:options3 }]
 *
 *    }
 *
 *
 * @return {Object} <ul> data (Object) - the data returned. </ul>
 *                  <ul> formMethods (Object) - form methods from useForm hook </ul>
 *                  <ul> onChange (Function) - change handler </ul>
 *                  <ul> nextClickHandler (Function) - next handler </ul>
 *                  <ul> prevClickHandler (Function) - prev handler </ul>
 *
 *
 */

const useMasterForm = ({
  pathArr,
  dataDB,
  getFieldsObject,
  canAdvanceEmpty = true,
  isDemo = false,
  onWrite,
}) => {
  const { current: currentStep } = useSteps();

  const { setOrUpdateDocument } = useUpdateDatabase();

  const validateFields = async () => {
    if (isDemo) return true;
    if (stepsArray[currentStep]) {
      let hasErrors = false;
      for (let field of stepsArray[currentStep]) {
        const isValid = await trigger(field.name);
        if (!isValid) hasErrors = true;
      }
      return !hasErrors;
    }
    return true;
  };

  const writeToDB = async (values) => {
    if (isDemo) return;
    onWrite?.(values);

    const newValues = values;
    setHighestCompletedStep(newValues);
    setLatestCompletedStep(newValues);

        await setOrUpdateDocument(
      pathArr,
      removeUndefinedFromObjectValues(newValues)
    );
  };

  const setHighestCompletedStep = (formValues) => {
    const highestCompletedStep = Math.max(
      formValues["highest-completed"] || 0,
      currentStep
    );
    formValues["highest-completed"] = highestCompletedStep;
  };

  const setLatestCompletedStep = (formValues) => {
    formValues["latest-step"] = currentStep || 0;
  };

  const {
    formMethods,
    onFormFieldChange,
    onFormSubmit,
    nextClickHandler,
    prevClickHandler,
  } = useBasicForm({
    writeToDB,
    validateFields,
    canAdvanceEmpty,
    messages: isDemo
      ? { success: "This is a demo version. Values are not saved." }
      : undefined,
  });

  const {
    register,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = formMethods;

  let stepsArray = [];

  /***************REGISTER FIELDS**************/

  if (getFieldsObject) {
    stepsArray = getFieldsObject(getValues, errors);

    const allFieldsArray = Object.values(stepsArray).reduce((a, b) =>
      a.concat(b)
    );
    allFieldsArray.forEach((field) => register(field.name, field.options));
  }

  /***************SET FORM VALUES**************/

  useEffect(() => {
    if (!dataDB) return;
    const values = getValues();
    reset({ ...values, ...dataDB });
  }, [dataDB, reset, getValues]);

  formMethods.onSubmit = onFormSubmit;

  return {
    data: dataDB,
    onChange: onFormFieldChange,
    formMethods,
    nextClickHandler,
    prevClickHandler,
  };
};

export default useMasterForm;
