import React from "react";
import styled from "styled-components";
import CIcon from "@coreui/icons-react";
import { CPopover } from "@coreui/react";
import { ButtonBasic } from "..";
import { useTranslation } from "react-i18next";

const DisabledButton = ({ header, content, buttonText }) => {
  const { t } = useTranslation();

  return header || content ? (
    <CPopover header={header} content={content}>
      <Wrapper className="tool-tip w-100">
        <ButtonBasic disabled fontWeight="600" className=" my-2 mx-auto w-100 ">
          <span className="ml-2">
            {buttonText || t("shared.disabled", "Disabled")}
          </span>
          <CIcon name="cil-warning" className="ml-2 mb-2" />
        </ButtonBasic>
      </Wrapper>
    </CPopover>
  ) : (
    <ButtonBasic disabled fontWeight="600" className=" my-2 mx-auto w-100 ">
      <span className="ml-2">
        {buttonText || t("shared.disabled", "Disabled")}
      </span>
      <CIcon name="cil-warning" className="ml-2" />
    </ButtonBasic>
  );
};

export default DisabledButton;

// Tooltip doesn't show when it's disable
const Wrapper = styled.div`
  .tool-tip {
    display: inline-block;
    :disabled {
      pointer-events: none;
    }
  }
`;
