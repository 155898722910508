import styled from "styled-components";

export const StyledMessageDelete = styled.div`
  svg {
    :hover {
      scale: 1.2;
    }
  }
`;

export const NewMessageCircle = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  top: 15%;
  right: 30%;
  border-radius: 100%;
  background-color: var(--info);
  z-index: 999;
`;
