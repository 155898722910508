import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DB_PATHS } from "src/constants";
import { useUpdateDatabase } from "src/hooks";
import { set } from "src/store/coreuiSlice";
import { selectOrgId } from "src/store/projectSelectors";

const TheAsideController = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.coreui.asideShow);
  const setState = (state) => dispatch(set({ asideShow: state }));

  const orgId = useSelector(selectOrgId);
  const history = useHistory();

  const { updateDocument } = useUpdateDatabase();

  const [isChatVisible, setIsChatVisible] = useState(false);

  const { t } = useTranslation();

  const chatOnClickHandle = () => {
    if (isChatVisible) {
      window.HubSpotConversations.widget.remove();
    } else {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.load();
      } else {
        alert(
          t(
            "aside.chatBlocked",
            "Help live chat may have been blocked by Ad block"
          )
        );
      }
    }
    setIsChatVisible((prev) => !prev);
  };

  const dashboardOnClick = () => {
    show && dispatch(setState(false));

    history.push("/dashboard");
    updateDocument(DB_PATHS.user, { completeDashboardIntro: false });
  };

  const tutorialOnClick = (urlPath, dbPath) => {
    show && dispatch(setState(false));

    history.push(`/project/${orgId}/${urlPath}`);
    updateDocument(DB_PATHS.user, { [dbPath]: false });
  };

  return {
    dashboardOnClick,
    tutorialOnClick,
    isChatVisible,
    chatOnClickHandle,
  };
};

export default TheAsideController;
