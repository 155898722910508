import { CCol } from "@coreui/react";
import styled from "styled-components";

const TableContainer = styled(CCol)`
  margin-top: 2rem;
  label {
    font-weight: 600;
  }
  table {
    border: 0.1px solid #d8dbe0;
    margin: 1rem 0;

    th,
    td {
      padding: 5px;
      vertical-align: top;
      text-align: center;
      background-color: transparent;
    }

    th div {
      font-size: 14px;
      font-weight: 700;
      margin-top: 0.5rem;
      margin-bottom: 0.25rem;
      line-height: 16.41px;
    }

    th {
      font-size: 11px;
      font-weight: 400;
    }

    input {
      padding: 0;
      text-align: center;

      &:hover {
        border: 1px solid #bbb;
        transition: none;
      }
    }

    select,
    input {
      background-color: transparent;
      border: none;
      border-radius: 0;
      box-shadow: none;
    }

    select {
      padding: 0;
    }

    .digital-th-1 {
      max-width: 80px;
    }
    .digital-th-3 {
      min-width: 100px;
      overflow: hidden;
    }
  }

  .c-dark-theme & {
    table {
      border: var(--border);
    }
    input {
      &:hover {
        border: none;
      }
      &:focus {
        border: 1px solid #ebebeb;
      }
    }
  }

  @media screen and (max-width: 992px) {
    form {
      overflow-x: auto;
      white-space: nowrap;
    }
  }
`;

export default TableContainer;
