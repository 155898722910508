import { createSlice } from "@reduxjs/toolkit";

const initialModalState = {
  assignModal: {
    showAssignModal: false,
    createdAccount: "",
    taskToAssign: "",
  },
  accountSettingsModal: {
    showAccountSettingsModal: false,
  },
  welcomeModal: {
    show: false,
    hasShown: false,
  },
  freemiumModal: {
    show: false,
    showFrom: null,
    hasShownInDashboard: false,
    hasShownInResults: false,
  },

  welcomeTutorial: {
    show: false,
  },
  welcomeSectionModal: {
    show: false,
  },
  switchPeriodModal: {
    show: false,
  },
};

const modalSlice = createSlice({
  name: "modal",
  initialState: initialModalState,
  reducers: {
    setAssignModal: (state, action) => {
      if (action.payload) {
        state.assignModal.showAssignModal = action.payload.show;
        state.assignModal.taskToAssign = action.payload.taskToAssign;
      } else {
        state.assignModal.showAssignModal = !state.assignModal.showAssignModal;
      }
    },
    setCreatedAccount: (state, action) => {
      state.assignModal.createdAccount = action.payload;
    },
    setAccountSettingsModal: (state, action) => {
      if (action.payload) {
        state.accountSettingsModal.showAccountSettingsModal = action.payload;
      } else {
        state.accountSettingsModal.showAccountSettingsModal =
          !state.accountSettingsModal.showAccountSettingsModal;
      }
    },
    setWelcomeModal: (state, action) => {
      state.welcomeModal = { ...state.welcomeModal, ...action.payload };
    },
    showWelcomeTutorial: (state, action) => {
      state.welcomeTutorial.show = action.payload;
    },
    showWelcomeSectionModal: (state, action) => {
      state.welcomeSectionModal.show = action.payload;
    },
    setFreemiumModal: (state, action) => {
      state.freemiumModal = { ...state.freemiumModal, ...action.payload };
    },
    resetModals: (state, action) => {
      state.freemiumModal = initialModalState.freemiumModal;
      state.welcomeModal = initialModalState.welcomeModal;
    },
    setSwitchPeriodModal: (state, action) => {
      state.switchPeriodModal.show = action.payload;
    },
  },
});

export const selectAssignModal = (state) => state.modal.assignModal;
export const selectAccountSettingsModal = (state) =>
  state.modal.accountSettingsModal;

export const selectWelcomeModalState = (state) => state.modal.welcomeModal.show;
export const selectWelcomeSectionModalState = (state) =>
  state.modal.welcomeSectionModal.show;
export const selectWelcomeTutorialState = (state) =>
  state.modal.welcomeTutorial.show;

export const selectFreemiumModal = (state) => state.modal.freemiumModal;
export const selectWelcomeModal = (state) => state.modal.welcomeModal;
export const selectSwitchPeriodModal = (state) =>
  state.modal.switchPeriodModal.show;

export const {
  setAssignModal,
  setCreatedAccount,
  setAccountSettingsModal,
  setWelcomeModal,
  showWelcomeSectionModal,
  showWelcomeTutorial,
  setFreemiumModal,
  setSwitchPeriodModal,
  resetModals,
} = modalSlice.actions;
export default modalSlice.reducer;
