import { useEffect, useState } from "react";
import { DB_PATHS } from "src/constants";
import { timestampToString } from "src/utils/common-utils/helpers";
import { useDatabasePaths, usePermissions } from "..";
import { useDoc } from "../databaseHooks";

const useReportingPeriod = () => {
  // const orgId = useSelector(selectOrgId);
  const { isSurveyRespondent } = usePermissions();

  // if orgId or set undefined, otherwise useDoc will throw error
  // const orgToUse = orgId || undefined;

  const [reportingPeriod, setReportingPeriod] = useState("");
  const [periodName, setPeriodName] = useState("");
  const [isSurvey, setIsSurvey] = useState(false);

  const { getPath } = useDatabasePaths();

  const { data } = useDoc(getPath(DB_PATHS.coreData));
  const { data: periodData } = useDoc(getPath(DB_PATHS.period));

  useEffect(() => {
    if (isSurveyRespondent) {
      setIsSurvey(true);
    }
  }, [isSurveyRespondent]);

  useEffect(() => {
    if (!periodData?.["period-name"]) return;
    setPeriodName(periodData?.["period-name"]);
  }, [periodData]);

  useEffect(() => {
    if (!data?.["report-date-from"] && !data?.["report-date-to"]) {
      setReportingPeriod(null);
      return;
    }

    setReportingPeriod(
      `${timestampToString(data["report-date-from"])} - ${timestampToString(
        data["report-date-to"]
      )}`
    );
  }, [data]);

  return { reportingPeriod, periodName, isSurvey };
};

export default useReportingPeriod;
