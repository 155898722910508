import { useEffect, useRef, useState } from "react";
import { useSteps } from "react-step-builder";

/**
 * Custom React hook that provides functionality for handling back button navigation in a multi-step form.
 *
 * @returns {{nextDecorator: Function, prevDecorator: Function}} An object with two functions:
 * - `nextDecorator` - Function that returns a decorated function that increments the current step number and calls a given function.
 * - `prevDecorator` - Function that returns a decorated function that decrements the current step number and calls a given function.
 */

const useBackStep = () => {
  const { jump } = useSteps();
  const stepNum = useRef(1);
  const pageLocation = useRef(window.location.href);
  const { history, location } = window;

  const [changeCount, setChangeCount] = useState(0);

  useEffect(() => {
    if (!changeCount) return;
    jump(stepNum.current);
  }, [changeCount]);

  /**
   * Event handler function that gets called when the browser's back button is clicked.
   * If the current step number is 1, it navigates back to the previous page in the browser's history.
   * Otherwise, it decrements the step number and triggers a re-render.
   * @param {Event} e - The event object.
   */
  const handlePopState = (e) => {
    if (stepNum.current === 1) {
      window.removeEventListener("popstate", handlePopState);
      if (location.href === pageLocation.current) {
        history.back();
      }
    } else {
      history.pushState({ block: true }, document.title, location.href);
      --stepNum.current;
      setChangeCount((prev) => prev + 1);
    }
  };

  // Add event listener for the popstate event when the component mounts
  useEffect(() => {
    history.pushState({ block: true }, document.title, location.href);
    window.addEventListener("popstate", handlePopState);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const nextDecorator = (func) => {
    return async function (e) {
      stepNum.current++;
      func(e);
    };
  };

  const prevDecorator = (func) => {
    return async function (e) {
      stepNum.current--;
      func(e);
    };
  };

  return { prevDecorator, nextDecorator };
};

export default useBackStep;
