import { calculateReductionPercentage } from "./general";

const { RESULT_KEYS } = require("src/constants/resultsSections");
const { roundNumber } = require("src/utils/common-utils");

export const calculateHomeEnergySwitchToGreen = (
  results,
  recommendation,
  year,
  emissionFactors,
  baseYear
) => {
  const {
    specifics: { amount: reduceBy },
    shareImplemented,
    yearImplemented,
  } = recommendation;

  const reductionPercentage = calculateReductionPercentage(
    reduceBy,
    shareImplemented,
    year,
    yearImplemented,
    baseYear
  );

  const elecDirectEf =
    emissionFactors?.location?.electricity?.CO2E_PER_KWH_ELECTRICITY?.["United Kingdom"]
  const elecTransmissionEf =
    emissionFactors?.location?.electricity
      ?.CO2E_PER_KWH_ELECTRICITY_TRANSMISSION?.["United Kingdom"]

  const directHomeElec =
    (results?.[RESULT_KEYS.homeElec] * elecDirectEf) /
    (elecDirectEf + elecTransmissionEf);

  const reduction = directHomeElec * reductionPercentage;

  if (isNaN(reduction)) return 0;
  return roundNumber(reduction, 2);
};
