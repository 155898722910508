import { CRow, CTextarea } from "@coreui/react";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BsEmojiHeartEyesFill,
  BsFillEmojiAngryFill,
  BsFillEmojiFrownFill,
  BsFillEmojiNeutralFill,
  BsFillEmojiSmileFill,
} from "react-icons/bs";
import {
  DemoWarning,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  RadioButtons,
} from "src/components";
import { useSurveyConstants } from "src/hooks";
import { shuffleArray } from "src/utils/common-utils/helpers";
import { Subtitle } from "../components";
import OptionalQuestion from "../components/OptionalQuestion";
import OptionalQuestionNumber from "../components/OptionalQuestionNumber";

const EmployeeSurvey9 = ({
  data: values = {},
  onChange,
  info: { orgName },
  showOpinionQuestions,
  isDemo,
}) => {
  const {
    formState: { errors },
    initiatives: customInitatives,
  } = useFormContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const { initatives } = useSurveyConstants();

  const initativeOptions = useMemo(() => {
    const convertedCustomInitatives = customInitatives
      ?.filter((i) => i.show)
      .map((initative) => ({
        value: initative.text,
        label: initative.text,
      }));

    return [...shuffleArray(convertedCustomInitatives || initatives)];
  }, [language, customInitatives]);

  return (
    <FormStepWrapper
      aboveCardSlot={
        <>
          {isDemo && <DemoWarning />}

          <Subtitle content={"Carbon reduction initiatives"} />
        </>
      }
    >
      <OptionalQuestion name="q22">
        <QuestionWrapper>
          <h3>
            <OptionalQuestionNumber questionNumber={22} />.{" "}
            {t(
              "employeeSurvey.q_initiatives",
              "Which of the following initiatives would you want {{orgName}} to implement in our carbon reduction plan?",
              { orgName }
            )}
          </h3>
          {initativeOptions.map(({ value, label }) => (
            <div className="mt-4" key={value}>
              <li>
                <h5 className="d-inline">{label}</h5>
              </li>
              <RadioButtons
                name={`carbon-initiatives.${value}`}
                className="d-flex flex-column justify-content-center align-items-start mt-3"
                buttonClass="d-flex align-items-center justify-content-center mb-3"
                onChange={(e) =>
                  onChange({
                    target: {
                      name: `carbon-initiatives.${value}`,
                      value: Number(e.target.value),
                    },
                  })
                }
                defaultValue={values?.["carbon-initiatives"]?.[label]}
                data={[
                  {
                    value: 1,
                    label: (
                      <CRow className="align-items-center">
                        <BsFillEmojiAngryFill color="#e53935" size={25} />
                        <span className="ml-1">Strongly Disagree</span>
                      </CRow>
                    ),
                  },
                  {
                    value: 2,
                    label: (
                      <CRow className="align-items-center">
                        <BsFillEmojiFrownFill color="#ffb74d" size={25} />
                        <span className="ml-1">Disagree</span>
                      </CRow>
                    ),
                  },
                  {
                    value: 3,
                    label: (
                      <CRow className="align-items-center">
                        <BsFillEmojiNeutralFill
                          color="var(--secondary)"
                          size={25}
                        />
                        <span className="ml-1">Neutral</span>
                      </CRow>
                    ),
                  },
                  {
                    value: 4,
                    label: (
                      <CRow className="align-items-center">
                        <BsFillEmojiSmileFill color="#388e5a" size={25} />
                        <span className="ml-1">Agree</span>
                      </CRow>
                    ),
                  },
                  {
                    value: 5,
                    label: (
                      <CRow className="align-items-center">
                        <BsEmojiHeartEyesFill color="#42a5f5" size={25} />

                        <span className="ml-1">Strongly Agree</span>
                      </CRow>
                    ),
                  },
                ]}
              />
            </div>
          ))}
          <ErrorText value={errors["carbon-initiatives"]} />
        </QuestionWrapper>
      </OptionalQuestion>

      <OptionalQuestion name="q23">
        <QuestionWrapper>
          <h3>
            <OptionalQuestionNumber questionNumber={23} />.{" "}
            {t(
              "employeeSurvey.q_otherThoughts",
              "If you have any other thoughts or comments, please let us know here!"
            )}
          </h3>
          <CTextarea
            name="comments"
            id="comments"
            onChange={onChange}
            defaultValue={values["comments"]}
            rows="6"
            placeholder={t(
              "employeeSurvey.spaceForComments",
              "Space for your comments..."
            )}
          />
        </QuestionWrapper>
      </OptionalQuestion>
    </FormStepWrapper>
  );
};

export default EmployeeSurvey9;
