import CIcon from "@coreui/icons-react";
import { CButton, CDataTable } from "@coreui/react";
import { useEffect, useState } from "react";
import AddMoreButton from "../AddMoreButton/AddMoreButton";
import { cloneDeep, set } from "lodash";

const DataTable = ({
  tableName,
  initValues,
  formMethods,
  emptyItem,
  fields = [],
  scopedSlots = {},
  refresh,
  showDelete = true,
  onDelete,
  addMoreLabel,
  onAdd,
  ...rest
}) => {
  const [local, setLocal] = useState([]);

  const { getValues, setValue, reset } = formMethods;

  useEffect(() => {
    setLocal(initValues);
    setValue(tableName, initValues, { shouldTouch: false });
  }, [initValues]);

  const addHandler = () => {
    refresh?.();
    const values = getValues();
    const tableValues = getValues(tableName);
    const newItem = onAdd?.(emptyItem) || emptyItem;
    const newTableValues = [...(tableValues || []), newItem];
    const newValues = cloneDeep(values);
    set(newValues, tableName, newTableValues);
    reset(newValues, { keepTouched: true });
    setValue(tableName, newTableValues, { shouldTouch: true });
    setLocal(newTableValues);
  };

  const deleteHandler = (index) => {
    refresh?.();
    const values = getValues();
    const tableValues = getValues(tableName);
    const newTableValues = tableValues?.filter((v, i) => i !== index);
    const value = tableValues?.[index];

    const newValues = cloneDeep(values);
    set(newValues, tableName, newTableValues);
    reset(newValues, { keepTouched: true });
    setValue(tableName, newTableValues, { shouldTouch: true });
    setLocal(newTableValues);
    onDelete?.(value);
  };

  const columns = showDelete ? [...fields, "delete"] : fields;

  return (
    <>
      <CDataTable
        fields={columns}
        items={local}
        {...rest}
        scopedSlots={{
          ...scopedSlots,
          delete: (_, index) => {
            return (
              <td>
                <CButton
                  onClick={() => {
                    deleteHandler(index);
                  }}
                  className="delete-button "
                  variant="outline"
                  color="danger"
                >
                  <CIcon name="cil-trash" />
                </CButton>
              </td>
            );
          },
        }}
      />
      <AddMoreButton label={addMoreLabel} onClick={addHandler} />
    </>
  );
};

export default DataTable;
