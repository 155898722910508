import { adaptor_getUserData } from "src/adapters/auth-adaptors/read";

import { FIREBASE_WRITE_TYPES } from "src/constants/databaseConstants";
import { set } from "lodash";
import firebase from "firebase/compat/app";

const HELPER_TAG = "DATABASE_ADAPTORS__WRITE";

/**
 *
 * -Checks to see if there is any past data.
 * -If there is some then updates firstore
 * -If not writes
 * -Try catch used to pick up any error in the writing.
 *
 * -Returns An Arr of the [WriteType (string), WriteSuccess(Bool)]
 *
 * @param {FireStoreRef} ref - A Refrence to a firestore document
 * @param {obj} uploadData -  an object to upload
 * @param {obj} pastData - The value previously held at the ref
 * @returns {Arr}-[String, Bool]
 */
export const adaptor_updateOrSetPath = async (
  ref,
  uploadData,
  pastData = {}
) => {
  uploadData["last-updated"] = Date.now();
  const { uid, email } = adaptor_getUserData();
  uploadData["last-updated-uid"] = uid;
  uploadData["last-updated-email"] = email;

  try {
    await ref.set(uploadData, { merge: true });
    console.log([FIREBASE_WRITE_TYPES.write, true]);
    return [FIREBASE_WRITE_TYPES.write, true];
  } catch (error) {
    console.log(error);
    return [FIREBASE_WRITE_TYPES.write, false];
  }
};

export const adaptor_setOrOverride = async (ref, uploadData) => {
  uploadData["last-updated"] = Date.now();
  const { uid, email } = adaptor_getUserData();
  uploadData["last-updated-uid"] = uid;
  uploadData["last-updated-email"] = email;

  try {
    await ref.set(uploadData);
    console.log([FIREBASE_WRITE_TYPES.write, true]);
    return [FIREBASE_WRITE_TYPES.write, true];
  } catch (error) {
    console.log(error);
    return [FIREBASE_WRITE_TYPES.write, false];
  }
};

/**
 *
 * Updates DB rather than sets
 * - As with all writes will assign last updated time and user
 * - returns  An Arr of the [WriteType (string), WriteSuccess(Bool)]
 *
 * @param {FirestoreDBref} ref - DB ref to upload to.
 * @param {obj} uploadData - Object to upload to DB
 * @returns {Arr}
 */
export const adaptor_updateFirebase = async (ref, uploadData) => {
  uploadData["last-updated"] = Date.now();
  const { uid, email } = adaptor_getUserData();
  uploadData["last-updated-uid"] = uid;
  uploadData["last-updated-email"] = email;

  console.log("adaptor_updateFirebase", ref);
  try {
    await ref.update(uploadData);
    return true;
  } catch (error) {
    console.error(HELPER_TAG, "adaptor_updateFirebase", error);
    return false;
  }
};

export const adaptor_deleteDocument = async (ref) => {
  try {
    await ref.delete();
    console.log("deleted");
    return true;
  } catch (error) {
    console.error(HELPER_TAG, "adaptor_deleteDocument", error);
    return false;
  }
};

export const adaptor_deleteField = async ({ ref, objectName, path }) => {
  if (!path) {
    await adaptor_updateOrSetPath(ref, {
      [objectName]: firebase.firestore.FieldValue.delete(),
    });
  } else {
    await adaptor_updateOrSetPath(ref, {
      [objectName]: set({}, path, firebase.firestore.FieldValue.delete()),
    });
  }
};
