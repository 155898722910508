import {
  CCol,
  CFormGroup,
  CInputCheckbox,
  CLabel,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CTooltip,
} from "@coreui/react";
import { camelCase, get, upperFirst } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SECTOR_KEYS } from "src/constants/sectorKeys";
import { useTasks } from "src/hooks";
import { selectOrgData } from "src/store/projectSelectors";
import { convertKebabToDisplay } from "src/utils/common-utils";
import { ButtonBasic, CollaboratorSelect } from "..";
import StyledModal from "../BasicModal/BasicModal.style";

const TaskModal = ({ show, toggle, name, label }) => {
  const { t } = useTranslation();
  const sendTaskEmails = !!useSelector(selectOrgData)?.["task-emails"];

  const [sendEmails, setSendEmails] = useState(sendTaskEmails);
  const {
    onTasksChange,
    collaborators,
    onSubmit,
    values: tasks,
  } = useTasks({ sendEmails });

  const defaultValue = get(tasks, name);

  const convertName = (name) => {
    if (!name) return "";
    const segments = name.split(".");
    const sector = t(`${camelCase(segments[0])}.title`);
    let fieldName = t(`tasks.${segments[segments.length - 1]}`);
    let subsector = "";
    if (segments.length === 3) {
      if (segments[0] === SECTOR_KEYS.siteData) {
        subsector = " - " + convertKebabToDisplay(segments[1]);
      } else {
        subsector = " - " + t(`tasks.${segments[1].toLowerCase()}`);
        fieldName = upperFirst(segments[segments.length - 1]);
      }
    }
    return sector + subsector + ": " + fieldName;
  };

  return (
    <div style={{ zIndex: 1040 }}>
      <StyledModal show={show} onClose={toggle} centered>
        <CCol>
          <CModalHeader className="d-flex justify-content-center ">
            <CCol className="text-center">
              <h2>{t("tasks.assignTask", "Assign task")} </h2>
              <p className="text-info mb-0">{label || convertName(name)}</p>
            </CCol>
          </CModalHeader>

          <CModalBody className="text-center">
            <p>{t("tasks.who", "Who do you want to assign this task to?")}</p>
            <CollaboratorSelect
              name={name}
              onTasksChange={onTasksChange}
              value={defaultValue}
              collaborators={collaborators}
              shouldHaveAssignNew
            />
            <CFormGroup
              className="my-2 ml-3 mr-auto d-flex justify-content-start"
              variant="custom-checkbox"
            >
              <CInputCheckbox
                custom
                id={`${name}-checkbox`}
                onChange={(e) => {
                  setSendEmails(e.target.checked);
                }}
                checked={sendEmails}
                style={{ borderRadius: 0 }}
                disabled={process.env.REACT_APP_SEND_EMAILS === "false"}
              />
              <CLabel variant="custom-checkbox" htmlFor={`${name}-checkbox`}>
                <CTooltip
                  placement="bottom"
                  content="Default setting can be modified on Assign tasks page."
                >
                  <small> {t("tasks.sendOneNotification")}</small>
                </CTooltip>
              </CLabel>
            </CFormGroup>
          </CModalBody>

          <CModalFooter
            className="d-flex justify-content-center"
            style={{ border: "none", gap: "1rem" }}
          >
            <ButtonBasic
              disabled={!defaultValue}
              onClick={(e) => {
                onSubmit(e, sendEmails);
                toggle();
              }}
              className="mt-3"
            >
              {t("tasks.assign", "Assign")}
            </ButtonBasic>
            <ButtonBasic
              onClick={toggle}
              className="mt-3"
              color="secondary"
              bgcolor="dark"
            >
              {t("shared.back", "Back")}
            </ButtonBasic>
          </CModalFooter>
        </CCol>
      </StyledModal>
    </div>
  );
};

export default TaskModal;
