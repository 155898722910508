import { convertSpaceToDash } from "../common-utils";

export const removeBracket = (str, number = 1) => {
  if (str.includes("(")) {
    return str.slice(0, str.indexOf("(") - number);
  } else {
    return str;
  }
};

export const convertFormatString = (item) => {
  const format = removeBracket(item).toLowerCase();
  return convertSpaceToDash(format);
};
