import {
  CCreateElement,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const DataCollectionDropdown = ({ show, toggle, item }) => {
  const { t } = useTranslation();
  return (
    <CSidebarNavDropdown
      id="dataCollectionDropdown"
      name={t("sidebar.dataCollection", "Data Collection")}
      className={show ? "c-show" : ""}
      show={show}
      onClick={toggle}
    >
      <CCreateElement
        items={item}
        components={{
          CSidebarNavItem,
        }}
      />
    </CSidebarNavDropdown>
  );
};

export default DataCollectionDropdown;
