import React from "react";
import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";

const DeleteButton = ({ onClick, size, className = "", style }) => {
  return (
    <CButton
      style={style}
      className={className}
      size={size}
      onClick={onClick}
      variant="outline"
      color="danger"
      type="button"
    >
      <CIcon name="cil-trash" />
    </CButton>
  );
};

export default DeleteButton;
