import { CButton } from "@coreui/react";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BasicSelect from "src/components/BasicSelect/BasicSelect";
import { useHttp } from "src/hooks";
import { selectOrgId, selectPeriod } from "src/store/projectSelectors";

const ClearLogs = ({ type = "logs", style = { maxWidth: "350px" } }) => {
  const { t } = useTranslation();
  const orgId = useSelector(selectOrgId);
  const periodId = useSelector(selectPeriod);

  const { sendRequest } = useHttp();

  const [daysAgo, setDaysAgo] = useState();

  const [deleting, setDeleting] = useState(false);

  const clearLogs = async () => {
    if (!daysAgo) return;
    setDeleting(true);
    await sendRequest(`/app/collection/${type}`, {
      method: "DELETE",
      body: JSON.stringify({ org: orgId, daysAgo, periodId }),
      successMsg: t("requests.logsCleared"),
    });
    setDeleting(false);
  };

  return (
    <div
      style={style}
      className="d-flex align-items-center justify-content-between mt-3 p-2 border bg-light rounded"
    >
      <span className="text-black mr-1">{t("log.clearLogs")}</span>
      <BasicSelect
        options={[
          { value: 7, label: t("log.week") },
          { value: 30, label: t("log.month") },
        ]}
        onChange={(e) => {
          setDaysAgo(e.target.value);
        }}
        placeholder={t("log.selectTime")}
        style={{ maxWidth: "120px" }}
      />
      <CButton
        color="danger"
        variant="outline"
        onClick={clearLogs}
        className="d-flex ml-3 align-self-start align-items-center"
        disabled={!daysAgo || deleting}
      >
        <span>{deleting ? "Deleting..." : t("log.clear")}</span>
      </CButton>{" "}
    </div>
  );
};

export default ClearLogs;
