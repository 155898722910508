import { CCard, CCardBody } from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const OurActionsCard = ({ ourActions, yourActions }) => {
  const { t } = useTranslation();

  return (
    <CCard className="px-0 px-sm-3">
      <CCardBody>
        <h2 className="mb-3 text-center">{t("welcomeModal.ourNextActions")}</h2>
        {!!ourActions?.length ? (
          <ul className=" mb-0" style={{ fontSize: "16px" }}>
            {ourActions.map((action, idx) => {
              return (
                <li key={idx}>
                  <p className="mb-0 mt-2">{action}</p>
                </li>
              );
            })}
          </ul>
        ) : !!yourActions?.length ? (
          <h5 className="text-info text-center">{t("welcomeModal.waiting")}</h5>
        ) : (
          <h5 className="text-success text-center">
            {t("welcomeModal.nothingToDo")}
          </h5>
        )}
      </CCardBody>
    </CCard>
  );
};

export default OurActionsCard;
