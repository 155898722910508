import { useSelector } from "react-redux";
import { selectCoreData } from "src/store/projectSelectors";

const usePeriodLength = () => {

  const coreData = useSelector(selectCoreData);

  const reportDateFrom = coreData?.reportDateFrom || "";
  const reportDateTo = coreData?.reportDateTo || "";


  const calculatePeriodLength = (reportDateFrom, reportDateTo) => {
    if (!reportDateFrom || !reportDateTo) return 365;
    return (
      (Date.parse(reportDateTo) - Date.parse(reportDateFrom)) /
        (1000 * 60 * 60 * 24) +
      1
    );
  };

  const periodLength = calculatePeriodLength(reportDateFrom, reportDateTo);




  const annualize = (total) => Math.round((total * 365) / periodLength);

  return { periodLength, annualize, reportDateFrom, reportDateTo };
};

export default usePeriodLength;
