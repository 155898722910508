import styled from "styled-components";
import { basicTableStyles } from "../BasicTable/BasicTableContainer.style";

const StyledDiv = styled.div`
  ${basicTableStyles}

  .details {
    th {
      visibility: hidden;
    }
  }

  .table-sector {
    background-color: gainsboro !important;
    font-weight: bold;
  }

  .upper > thead {
    background-color: var(--clr-table-header)!important;
    border-bottom: 0.1px solid var(--clr-border-light);
  }

  tbody {
    tr {
      background-color: var(--clr-table-light-1);
      border-bottom: 0.1px solid var(--clr-border-light);
    }
    tr:nth-child(odd) {
      border-bottom: none !important;
    }
    tr:nth-child(4n + 1),
    tr:nth-child(4n + 2) {
      background-color: var(--clr-table-light-2);
    }
  }

  label {
    font-size: 12px;
    font-weight: 700;
  }

  .lower {
    font-size: 14px;
    margin-bottom: 0 !important;

    input {
      font-size: 14px;
    }



    td,
    th {
      background-color: transparent !important;
    }

    tr {
      margin-bottom: 0 !important;
      background-color: transparent !important;
      border-bottom: none !important;
    }
  }

  .hide-header {
    th {
      display: none !important;
    }
  }

  //dark mode
  .c-dark-theme & {
    .upper > thead {
      background-color: var(--clr-table-header-dark);
      border-bottom: 0.1px solid var(--clr-border-dark);
    }
    tbody {
      tr {
        background-color: var(--clr-table-dark-1);
        border-bottom: 0.1px solid var(--clr-border-dark);
      }

      tr:nth-child(4n + 1),
      tr:nth-child(4n + 2) {
        background-color: var(--clr-table-dark-2);
      }
    }

    .table-sector {
    background-color: black !important;
    font-weight: bold;
  }
  }
`;

export default StyledDiv;
