import { Cookies, getCookieConsentValue } from "react-cookie-consent";

import {
  browserLocalPersistence,
  browserSessionPersistence,
  setPersistence,
} from "firebase/auth";
import { useAuth } from "reactfire";
import { gtag } from "src/adapters/common-set-up/firebase";
import { useEffect } from "react";

/**
 * Hook to handle cookie consent for Hubspot, Google Analytics and Session Persistence
 *
 * @returns {{hasConsentedToHubspot: boolean, showCookieBanner: function}} Object containing:
 * - `hasConsentedToHubspot` - boolean indicating if the user has consented to Hubspot tracking
 * - `showCookieBanner` - function to show the cookie banner
 */

const useCookieConsent = () => {
  const auth = useAuth();

  //add listener for when the user changes their cookie consent (hubspot banner)
  useEffect(() => {
    const _hsp = (window._hsp = window._hsp || []);
    _hsp.push(["addPrivacyConsentListener", privacyListener]);
  }, []);

  const hasConsentedToHubspot =
    getCookieConsentValue("__hs_opt_out") === "no" ? true : false;

  const privacyListener = function (consent) {
    const prevConsent = consent.previousCategories;
    const currConsent = consent.categories;

    const isSwitchedOn = (category) => {
      const hasConsent = currConsent[category];
      const hasChanged = currConsent[category] !== prevConsent[category];
      return hasConsent && hasChanged;
    };

    const isSwitchedOff = (category) => {
      const hasConsent = currConsent[category];
      const hasChanged = currConsent[category] !== prevConsent[category];
      return !hasConsent && hasChanged;
    };

    //if the user has consented to analytics, set it
    if (isSwitchedOn("analytics")) {
      gtag("consent", "update", {
        analytics_storage: "granted",
      });
    }

    //if the user has revoked analytics, remove it
    if (!currConsent.analytics) {
      gtag("consent", "update", {
        analytics_storage: "denied",
      });
      Object.entries(Cookies.get())
        .filter((entry) => entry[0].startsWith("_ga"))
        .forEach((entry) => Cookies.remove(entry[0]));
    }

    //if the user has consented to local persistence, set it
    if (isSwitchedOn("functionality")) {
      setPersistence(auth, browserLocalPersistence);
    }

    //if the user has revoked local persistence, set it to session
    if (isSwitchedOff("functionality")) {
      setPersistence(auth, browserSessionPersistence);
    }
  };

  const showCookieBanner = () => {
    const hsp = (window._hsp = window._hsp || []);
    hsp.push(["showBanner"]);
  };

  return {
    hasConsentedToHubspot,
    showCookieBanner,
  };
};

export default useCookieConsent;
