import { useParams } from "react-router-dom";
import { useSteps } from "react-step-builder";
import { useFirestoreDocData, useFirestoreDocDataOnce } from "reactfire";
import { DB_PATHS } from "src/constants";
import { useDatabasePaths, useMasterForm, useUpdateDatabase } from "src/hooks";
import { useCollection, useDoc } from "src/hooks/databaseHooks";
import getSurveyFields from "./getSurveyFields";

const EmployeeSurveyController = ({ isDemo }) => {
  const { orgId, employeeID, periodId } = useParams();

  const { getPath } = useDatabasePaths();
  const { getRef } = useUpdateDatabase();

  const periodRef = getRef(getPath(DB_PATHS.period, orgId, periodId));

  const { data: periodData } = useFirestoreDocDataOnce(periodRef);


  const startYear = periodData?.startDate?.split("-")[0];
  const endYear = periodData?.endDate?.split("-")[0];

  const reportingPeriod = periodData
    ? startYear === endYear ?
      startYear :
      `${startYear}-${endYear.slice(-2)}`
    : "";

  const pathArr = getPath(
    [...DB_PATHS.employees, employeeID || "demo"],
    orgId,
    periodId
  );

  const sitesArr = getPath(DB_PATHS.sites, orgId, periodId);

  const { status, data: dataDB } = useDoc(pathArr);

  const { data: projectSettings } = useFirestoreDocData(
    getRef(getPath(DB_PATHS.project, orgId))
  );

  const workDays = projectSettings?.constants?.DAYS_IN_WORK_WEEK?.current || 5;

  const { optionalQuestions = {}, initiatives } = projectSettings || {};

  const masterFormMethods = useMasterForm({
    pathArr,
    getFieldsObject: (getValues) => getSurveyFields(getValues, 7),
    dataDB,
    canAdvanceEmpty: false,
    isDemo,
  });

  const { data: orgData } = useDoc(getPath(DB_PATHS.organisation, orgId));

  const { data: siteData } = useCollection(sitesArr);

  const info = {
    orgName: orgData?.name,
    reportingPeriod,
    employeeID: employeeID,
  };

  const {
    nextClickHandler,
    formMethods: { onSubmit },
  } = masterFormMethods;

  const { prev } = useSteps();

  const onPrev = () => prev();

  return {
    ...masterFormMethods,
    onSubmit,
    nextClickHandler,
    prevClickHandler: onPrev,
    info,
    status,
    siteData,
    workDays,
    optionalQuestions,
    orgId,
    reportingPeriod,
    initiatives
  };
};

export default EmployeeSurveyController;
