import CIcon from "@coreui/icons-react";
import { CAlert, CButton } from "@coreui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import styled from "styled-components";

const UnsavedAlert = () => {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  return (
    <StyledAlert
      className="my-0  w-100 mx-auto d-flex align-items-center justify-content-between"
      color="warning"
      show={show}
    >
      <span>
        <AiOutlineExclamationCircle className="mr-2" />
        {t("shared.unsavedAlert")}
      </span>
      <CButton variant="ghost" type="button" onClick={() => setShow(false)}>
        <CIcon name="cilX" />
      </CButton>
    </StyledAlert>
  );
};

const StyledAlert = styled(CAlert)`
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  z-index: 999999;
  border-radius: 0;

  .c-dark-theme & {
    color: #f6e3c2;
    background-color: #835b00;
    border-color: #ffc035;
  }
`;

export default UnsavedAlert;
