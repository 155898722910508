import { useTranslation } from "react-i18next";
import {
  BasicNumberInput,
  ErrorText,
  QuestionWrapper,
  YesOrNoQuestion,
} from "src/components";
import { convertStrToBool } from "src/utils/common-utils";

const EnergyQuestions = ({ info, values, onChange, errors }) => {
  const { t } = useTranslation();

  return (
    <div className="rounded bg-light px-3 pt-3 pb-4 mb-4">
      <p className="text-info font-bold mb-2">Energy use</p>

      <QuestionWrapper>
        <h3 className="mb-1">
          How much electricity did you use in your lab,
          <br /> in {info.reportingPeriod}? (kWh)
        </h3>
        <p style={{ fontSize: "14px" }} className="text-info mb-2">
          If unknown, please leave blank.
        </p>
        <BasicNumberInput
          allowNegative={false}
          min={0}
          name={"lab-energy"}
          defaultValue={values["lab-energy"]}
          onChange={onChange}
          placeholder={t("shared.pleaseSpecify")}
        />
        <ErrorText value={errors["lab-energy"]} />
      </QuestionWrapper>

      <QuestionWrapper>
        <h3 className="mb-2">Is you electricity on a renewable tariff?</h3>
        <YesOrNoQuestion
          className="  mr-auto"
          onChange={(e) => {
            const value = convertStrToBool(e.target.value);

            onChange({
              target: {
                name: "lab-renewable-tariff",
                value,
              },
            });
          }}
          defaultValue={values["lab-renewable-tariff"]}
          name="lab-renewable-tariff"
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <h3 className="mb-1">
          How much gas did you use in your lab, <br /> in {info.reportingPeriod}
          ? (kWh)
        </h3>
        <p style={{ fontSize: "14px" }} className="text-info mb-2">
          If unknown, please leave blank.
        </p>
        <BasicNumberInput
          allowNegative={false}
          min={0}
          name={"lab-gas"}
          defaultValue={values["lab-gas"]}
          onChange={onChange}
          placeholder={t("shared.pleaseSpecify")}
        />
        <ErrorText value={errors["lab-gas"]} />
      </QuestionWrapper>
    </div>
  );
};

export default EnergyQuestions;
