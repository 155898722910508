import { CModal, CModalBody, CModalFooter, CModalHeader } from "@coreui/react";
import { Trans, useTranslation } from "react-i18next";
import { useWelcomeModals } from "src/hooks";
import styled from "styled-components";
import CTAButton from "../CTAButton/CTAButton";

const FreemiumModal = () => {
  const { closeFreemiumModal, setFreemiumModal } = useWelcomeModals();
  const { t } = useTranslation();

  return (
    <StyledModal
      size="lg"
      centered
      show={setFreemiumModal}
      onClose={closeFreemiumModal}
    >
      <CModalHeader closeButton>
        <h1 className="mb-0 mt-3 text-center w-100 ">
          <Trans
            i18nKey="freemium.modal.join"
            components={{ 1: <span className="text-info w-100"></span> }}
          ></Trans>
        </h1>
      </CModalHeader>
      <CModalBody className="py-4 px-5 ">
        <Trans
          i18nKey="freemium.modal.premiumText"
          components={{
            1: <h5></h5>,
            2: <p></p>,
            3: <p></p>,
          }}
        ></Trans>
      </CModalBody>
      <CModalFooter className="bg-light">
        <CTAButton className="mx-auto" as={"a"} href="mailto:toby@zerobees.com">
          {t("freemium.modal.contact")}
        </CTAButton>
      </CModalFooter>
    </StyledModal>
  );
};

const StyledModal = styled(CModal)`
  header {
    background-color: whitesmoke;
    color: black;
  }

  .c-dark-theme & header {
    background-color: #34343b !important;
  }

  h5,
  p {
    width: 100%;
    text-align: center;
    line-height: 1.5;
    margin-inline: auto;
    max-width: 600px;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 16px;
  }
`;

export default FreemiumModal;
