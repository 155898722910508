import { useSelector } from "react-redux";
import { selectUserRole } from "src/store/userSlice";

const usePermissions = () => {
  const role = useSelector(selectUserRole);

  const isSuperAdmin = role === "superAdmin";
  const isAdmin = role === "admin" || role === "superAdmin";
  const hasExtendedPermissions = ["admin", "superUser", "superAdmin"].includes(
    role
  );

  const checkIsUser = (role) =>
    role === "admin" ||
    role === "user" ||
    role === "superAdmin" ||
    role === "superUser";

  const isUser = checkIsUser(role);

  const isSurveyRespondent = role === "employee" || role === "supplier";

  return {
    isSuperAdmin,
    hasExtendedPermissions,
    isAdmin,
    isSurveyRespondent,
    checkIsUser,
    isUser,
  };
};

export default usePermissions;
