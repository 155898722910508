import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

// routes config
import { Loading } from "src/components";
import OrgCard from "src/components/OrgCard/OrgCard";
import PrivateRoute from "src/components/PrivateRoute/PrivateRoute";
import TermsModal from "src/components/TermsModal/TermsModal";
import { usePermissions } from "src/hooks";
import useRoutes from "src/routes";
import { selectOrgId } from "src/store/projectSelectors";

const TheContent = () => {
  const { isSuperAdmin } = usePermissions();

  const { routes } = useRoutes();

  const RenderRoute = ({ Component, props }) => {
    const userOrgId = useSelector(selectOrgId);
    const { id } = useParams();
    const {
      match: { url },
    } = props;

    // Detect URL params(id) match with signed user's org
    const hasUnmatchedURL = !isSuperAdmin && id && id !== userOrgId;

    // Redirect to the signed user's org path
    if (hasUnmatchedURL) {
      console.log("Unmatched URL path with user org id. Redirecting...");
      const replacedURL = url.replace(id, userOrgId);
      return <Redirect to={replacedURL} />;
    }

    return <Component {...props} />;
  };

  return (
    <main className="c-main position-relative d-flex flex-column justify-content-center">
      <Suspense fallback={<Loading />}>
        <Switch className="h-100">
          {routes.map((route, idx) => {
            let content = (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                // render={(props) => <route.component {...props} />}
                render={(props) => (
                  <RenderRoute Component={route.component} props={props} />
                )}
              />
            );

            if (route.restrictedTo) {
              content = (
                <PrivateRoute
                  restrictedTo={route.restrictedTo}
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  Component={route.component}
                />
              );
            }

            return route.component && content;
          })}
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </Suspense>
      {isSuperAdmin && <OrgCard />}
      <TermsModal />
    </main>
  );
};

export default React.memo(TheContent);
