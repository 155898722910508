import { CAlert } from "@coreui/react";
import styled from "styled-components";

export const StyledReportingPeriod = styled(CAlert)`
  /* max-width: 660px !important; */
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1rem;

  .c-dark-theme & {
    background-color: #24252f !important;
  }
`;
