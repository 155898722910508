import { useTranslation } from "react-i18next";

const useExcelConstants = () => {
  const { t } = useTranslation();

  const HEADER_STYLES = {
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFd8dbe0" },
    },
    border: {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    },
    font: {
      name: "Arial",
      family: 2,
      size: 10,
      bold: true,
    },
  };

  const numberRule = (from, to) => {
    return {
      type: "whole",
      operator: "between",
      allowBlank: true,
      showErrorMessage: true,
      showInputMessage: true,
      formulae: [from, to],
      promptTitle: t("shared.number"),
      prompt: t("validation.numberBetween", {
        min: from,
        max: to,
      }),
      errorStyle: "error",
      errorTitle: t("shared.wrongInput"),
      error: t("validation.numberBetween", {
        min: from,
        max: to,
      }),
    };
  };

  const selectRule = {
    type: "list",
    allowBlank: true,
    showErrorMessage: true,
    errorStyle: "error",
    errorTitle: t("shared.selectFromList"),
    error: t("shared.selectFromDropdown"),
    showInputMessage: true,
    promptTitle: t("shared.select"),
    prompt: t("shared.selectFromDropdown"),
  };

  const unitRule = {
    type: "list",
    allowBlank: true,
    showErrorMessage: true,
    formulae: ['"mi,km"'],
    errorStyle: "error",
    errorTitle: t("shared.selectFromList"),
    error: t("shared.selectFromDropdown"),
    showInputMessage: true,
    promptTitle: t("shared.selectValues"),
    prompt: t("shared.selectDistanceFromDropdown"),
  };

  const dateRule = {
    type: "date",
    operator: "lessThan",
    allowBlank: true,
    formulae: [new Date()],
    showErrorMessage: true,
    errorStyle: "error",
    errorTitle: t("shared.invalidDate"),
    error: t("validation.enterPastDate"),
    showInputMessage: true,
    promptTitle: t("validation.date"),
    prompt: t("validation.enterPastDate"),
  };

  const emailRule = {
    type: "textLength",
    operator: "lessThan",
    allowBlank: true,
    formulae: [100],
    promptTitle: t("shared.email"),
    prompt: t("excel.enterEmail"),
    showInputMessage: true,
  };

  const idRule = {
    type: "textLength",
    operator: "lessThan",
    showErrorMessage: true,
    allowBlank: true,
    formulae: [0],
    promptTitle: t("excel.autoId", "Autogenerated id"),
    prompt: t("excel.doNotEdit", "Do not edit"),
    errorStyle: "error",
    errorTitle: t("excel.autoId", "Autogenerated id"),
    error: t("excel.doNotEdit", "Do not edit"),
    showInputMessage: true,
  };

  const validationFn = (worksheet) => {
    return (key, rule) => {
      const letter = String.fromCharCode(64 + worksheet.getColumn(key)._number);
      worksheet.dataValidations.model[`${letter}2:${letter}9999`] = rule;
    };
  };

  return {
    HEADER_STYLES,
    validationFn,
    emailRule,
    unitRule,
    dateRule,
    selectRule,
    numberRule,
    idRule,
  };
};

export default useExcelConstants;
