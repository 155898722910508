import { CFormGroup, CInputGroup, CLabel } from "@coreui/react";
import { Trans, useTranslation } from "react-i18next";
import {
  AddMoreButton,
  BasicInput,
  BasicTableContainer,
  DeleteButton,
  ErrorText,
  QuestionWrapper,
  YesOrNoQuestion,
} from "src/components";
import { convertStrToBool } from "src/utils/common-utils";
import { isConditionallyRequiredStr } from "src/utils/validationOptions";
import controller from "../SupplierSurveyController9";

import MaterialsTable from "src/components/MaterialsTable";
import { useFormContext } from "react-hook-form";
import { CONSTRUCTION_MATERIALS, OTHER_MATERIALS } from "src/constants";
import EnergyQuestions from "./EnergyQuestions";

const DefaultQuestions = ({ info, onChange, values }) => {
  const {
    producesGoods,
    products,
    isProducerHandler,
    productNameHandler,
    register,
    getValues,
    errors,
    addProductsHandler,
    deleteProductsHandler,
  } = controller({ onChange, values });

  const formMethods = useFormContext();

  const materialsOptions = [
    ...OTHER_MATERIALS.filter((m) => {
      if (CONSTRUCTION_MATERIALS.find((cm) => cm.value === m.value))
        return false;

      return true;
    }),
    ...CONSTRUCTION_MATERIALS.map((m) => ({ ...m, category: "Construction" })),
  ].sort((a, b) => a.label.localeCompare(b.label));

  const categoryOptions = [
    "Construction",
    ...new Set(OTHER_MATERIALS.map((m) => m.category)),
  ];

  const { t } = useTranslation();

  return (
    <>
      <QuestionWrapper className="mb-4">
        <h3 className="mb-4">
          {t(
            "supplierSurvey.q_goods",
            "19. Do you produce any physical goods (e.g. packaging, product, food) for {{name}}?",
            { name: info.orgName }
          )}
        </h3>
        <CInputGroup>
          <YesOrNoQuestion
            name="produces-goods"
            onChange={(e) => {
              const value = convertStrToBool(e.target.value);
              const name = e.target.name;
              isProducerHandler({ target: { value, name } });
            }}
            data={[
              { label: t("shared.yes"), value: true },
              { label: t("shared.no"), value: false },
            ]}
            defaultValue={values["produces-goods"]}
          />
        </CInputGroup>
        <ErrorText value={errors["produces-goods"]} />
      </QuestionWrapper>

      {producesGoods && (
        <>
          <QuestionWrapper className="mb-4">
            <h3>
              {t(
                "supplierSurvey.q_goods2",
                "20. What products do you produce for {{name}}?",
                { name: info.orgName }
              )}
            </h3>
            {products.map((item, index) => {
              const label = t("shared.products", "Product") + " " + (index + 1);
              return (
                <CFormGroup className="mb-3" key={index}>
                  <CLabel>{label}</CLabel>
                  <div className="d-flex">
                    <BasicInput
                      type="text"
                      defaultValue={item.name}
                      onChange={(e) => productNameHandler(e, index)}
                      placeholder="Please specify"
                      className="mr-2"
                    />
                    <DeleteButton
                      onClick={() => deleteProductsHandler(index)}
                    />
                  </div>
                  <ErrorText value={errors.products?.[index]?.name} />
                </CFormGroup>
              );
            })}
            <div className="d-flex justify-content-center">
              <AddMoreButton
                onClick={addProductsHandler}
                label={t("supplierSurvey.addProducts", "Add products")}
              />
            </div>
          </QuestionWrapper>

          <QuestionWrapper>
            {products.map((product, prodIndex) => {
              const label = product.name;

              register(
                `products.${prodIndex}.name`,
                isConditionallyRequiredStr(getValues, "produces-goods")
              );
              return (
                <div key={prodIndex} className="rounded bg-light p-4 mb-4">
                  <div className="mx-auto d-flex flex-column align-items-center">
                    <h4
                      className="mb-4"
                      style={{ maxWidth: "600px", paddingInline: "15px" }}
                    >
                      <Trans i18nKey="supplierSurvey.productTable">
                        For{" "}
                        <b>
                          {{
                            name:
                              label ||
                              t("shared.products", "Product") +
                                " " +
                                `${prodIndex + 1}`,
                          }}
                        </b>
                        , please tell us the components / ingredients, their
                        mass (total) and origin country.
                      </Trans>
                    </h4>
                    <BasicTableContainer>
                      <MaterialsTable
                        values={values}
                        tableName={`products.${prodIndex}.components`}
                        formMethods={formMethods}
                        materialsOptions={materialsOptions}
                        categoryOptions={categoryOptions}
                      />
                    </BasicTableContainer>
                    <EnergyQuestions
                      product={product}
                      idx={prodIndex}
                      onChange={onChange}
                      formMethods={formMethods}
                    />
                  </div>
                  <ErrorText
                    value={errors.products?.[prodIndex]}
                    customMessage={t("validation.enterValues")}
                  />
                </div>
              );
            })}
          </QuestionWrapper>
        </>
      )}
    </>
  );
};

export default DefaultQuestions;
