import React from "react";
import { CCollapse } from "@coreui/react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { useState } from "react";
import { ButtonGhost } from "src/components";

const TextCollapse = ({ title, children, className = "" }) => {
  const [showCollapse, setShowCollapse] = useState(false);

  return (
    <div className={`${className} rounded`}>
      <ButtonGhost
        className="w-100 d-flex justify-content-between align-items-end text-left py-3"
        onClick={() => setShowCollapse((prev) => !prev)}
      >
        <span style={{fontSize:"20px"}} className="m-0 mr-3 ">{title}</span>
        {showCollapse ? (
          <IoMdArrowDropup fontSize={26} />
        ) : (
          <IoMdArrowDropdown fontSize={26} />
        )}
      </ButtonGhost>

      <CCollapse show={showCollapse} className="p-4">
        {children}
      </CCollapse>
    </div>
  );
};

export default TextCollapse;
