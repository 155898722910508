import { CForm, CFormGroup, CInputCheckbox, CLabel } from "@coreui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { analytics } from "src/adapters/common-set-up/firebase";
import {
  BasicInput,
  ButtonBasic,
  // Captcha,
  ErrorText,
  LoadingSpinner,
} from "src/components";
import { useHttp, useHubspot } from "src/hooks";
import { checkIsFormTouched } from "src/utils/common-utils";
import { emailRegex } from "src/utils/common-utils/regex";

const SignupForm = ({ onSignup, isFreeSignup = false }) => {
  const { t } = useTranslation();
  const [signupError, setSignupError] = useState(null);
  const { createOrUpdateContact } = useHubspot();

  // const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { touchedFields, errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const { sendRequest } = useHttp();

  const onSubmit = async (values) => {
    const { firstName, lastName, email, organisation } = values;

    // if (!isCaptchaVerified) return;

    setLoading(true);

    const orgToSave = { ...values };
    if (isFreeSignup) {
      orgToSave.isFree = true;
      orgToSave.acceptedTerms = true;
    } else {
      orgToSave.acceptedTerms = false;
    }

    try {
      await sendRequest(
        "/app/users/admin",
        {
          method: "POST",
          body: JSON.stringify(orgToSave),
          successMsg: t(
            "signup.success",
            "{{org}} account created successfully!",
            { org: values.organisation }
          ),
        },
        true
      );

      if (marketingOptBox) {
        await createOrUpdateContact({
          firstName,
          lastName,
          email,
          company: organisation,
        });
      }

      delete values["password-confirm"];

      analytics.logEvent("sign_up");

      onSignup?.(orgToSave);
    } catch (err) {
      setSignupError(err?.message);
      console.log(err.status);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e) => {
    setValue(e.target.name, e.target.value, { shouldTouch: true });
  };

  register("firstName", {
    setValueAs: (v) => v.trim(),
    required: t("validation.enterName"),
  });
  register("lastName", {
    setValueAs: (v) => v.trim(),
    required: t("validation.enterName"),
  });
  register("email", {
    setValueAs: (v) => v.trim(),
    required: t("validation.enterEmail"),
    pattern: {
      value: emailRegex,
      message: t("validation.enterEmail", "Please enter valid email"),
    },
  });
  register("organisation", {
    setValueAs: (v) => v.trim(),
    required: t("validation.enterOrg", "Please enter organisation name"),
  });
  register("password", {
    setValueAs: (v) => v.trim(),
    minLength: {
      value: 6,
      message: t(
        "validation.passwordLength",
        "Password has to have at least 6 characters"
      ),
    },
    validate: {
      notEmpty: (v) =>
        !!v || t("validation.passwordEmpty", "Password cannot be empty"),
      passwordMatches: (v) =>
        v === getValues("password-confirm") ||
        t("validation.passwordMatch", "Passwords have to match"),
    },
  });
  register("password-confirm", { setValueAs: (v) => v.trim() });

  const [hasAccepted, setHasAccepted] = useState(!isFreeSignup);
  const [marketingOptBox, setMarketingOptBox] = useState(false);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CForm onSubmit={handleSubmit(onSubmit)}>
          {signupError && <h5 className="text-danger">* {signupError}</h5>}
          <CFormGroup>
            <CLabel>{t("shared.organisation", "Organisation")}</CLabel>
            <BasicInput
              type="text"
              name="organisation"
              placeholder={t(
                "signup.enterOrg",
                "Please enter the name of organisation..."
              )}
              onChange={onChange}
            />
            <ErrorText value={errors.organisation} />
          </CFormGroup>
          <CFormGroup>
            <CLabel>{t("shared.email")}</CLabel>
            <BasicInput
              type="email"
              name="email"
              placeholder={t(
                "signup.adminEmail",
                "Please enter admin's email..."
              )}
              onChange={onChange}
            />
            <ErrorText value={errors.email} />
          </CFormGroup>
          <CFormGroup>
            <CLabel>{t("shared.firstName")}</CLabel>
            <BasicInput
              type="text"
              name="firstName"
              placeholder={t(
                "signup.adminFirstName",
                "Please enter admin's first name..."
              )}
              onChange={onChange}
            />
            <ErrorText value={errors.firstName} />
          </CFormGroup>
          <CFormGroup>
            <CLabel>{t("shared.lastName")}</CLabel>
            <BasicInput
              type="text"
              name="lastName"
              placeholder={t(
                "signup.adminLastName",
                "Please enter admin's last name..."
              )}
              onChange={onChange}
            />
            <ErrorText value={errors.lastName} />
          </CFormGroup>

          <CFormGroup>
            <CLabel>{t("shared.password")}</CLabel>
            <BasicInput
              type="password"
              name="password"
              placeholder={t("shared.enterPassword", "Please enter password")}
              onChange={onChange}
            />
            <ErrorText value={errors.password} />
          </CFormGroup>
          <CFormGroup>
            <CLabel>{t("assign.repeatPassword", "Repeat password")}</CLabel>
            <BasicInput
              type="password"
              name="password-confirm"
              placeholder={t(
                "assign.repeatPasswordPlaceholder",
                "Please repeat password"
              )}
              onChange={onChange}
            />
          </CFormGroup>
          {!isFreeSignup && (
            <CFormGroup className="my-3" variant="custom-checkbox">
              <CInputCheckbox
                custom
                id="is-free-checkbox"
                onChange={(e) => {
                  onChange({
                    target: { value: e.target.checked, name: "isFree" },
                  });
                }}
                defaultChecked={false}
                style={{ borderRadius: 0 }}
              />
              <CLabel variant="custom-checkbox" htmlFor="is-free-checkbox">
                {t("superAdmin.isFree")}
              </CLabel>
            </CFormGroup>
          )}
          {isFreeSignup && (
            <>
              <CFormGroup className="mt-4 " variant="custom-checkbox">
                <CInputCheckbox
                  custom
                  id="terms-checkbox"
                  onChange={(e) => setHasAccepted(e.target.checked)}
                  defaultChecked={false}
                  style={{ borderRadius: 0 }}
                />
                <CLabel
                  style={{ fontSize: "16px" }}
                  variant="custom-checkbox"
                  htmlFor="terms-checkbox"
                >
                  I accept the
                  <a
                    className="text-info mx-auto"
                    href="https://www.zerobees.com/legal"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    terms and conditions
                  </a>{" "}
                  <span className="text-danger ">*</span>
                </CLabel>
              </CFormGroup>
              <CFormGroup className="my-2" variant="custom-checkbox">
                <CInputCheckbox
                  custom
                  id="marketing-checkbox"
                  onChange={(e) => setMarketingOptBox(e.target.checked)}
                  defaultChecked={false}
                  style={{ borderRadius: 0 }}
                />
                <CLabel
                  style={{ fontSize: "16px" }}
                  variant="custom-checkbox"
                  htmlFor="marketing-checkbox"
                >
                  I agree to be contacted by ZeroBees with news , reports and
                  information around achieving net zero
                </CLabel>
              </CFormGroup>
            </>
          )}
          {/* {hasAccepted && <Captcha setStateFn={setIsCaptchaVerified} />} */}
          <div className="d-flex justify-content-center w-100">
            <ButtonBasic
              size="lg"
              className="mt-3"
              type="submit"
              bgcolor="green"
              hovercolor="darkgreen"
              disabled={
                !checkIsFormTouched(touchedFields) || !hasAccepted
                // ||
                // !isCaptchaVerified
              }
            >
              {t("signup.title", "Sign up")}
            </ButtonBasic>
          </div>
        </CForm>
      )}
    </>
  );
};

export default SignupForm;
