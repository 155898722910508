import styled from "styled-components";

const StepColumn = styled.div`
  width: ${({ maxwidth }) =>
    maxwidth ? `min(${maxwidth}, 100vw)` : "min(690px, 100vw)"};
  /* width: min(690px, 100vw); */

  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 992px) {
    padding: 0;
  }
`;

export default StepColumn;
