import React from "react";

const Subtitle = ({ content }) => {
  return (
    <h2 style={{ fontSize: "30px" }} className="display text-center my-4">
      {content}
    </h2>
  );
};

export default Subtitle;
