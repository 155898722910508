import { RESULT_KEYS } from "src/constants/resultsSections";

const { sumValuesInArray } = require("src/utils/common-utils");
const { calculateReductionPercentage } = require("./general");

const filterScope = (arr, scope) => arr?.filter((item) => item.scope == scope);

export const calculateSwitchPassengerFuel = (
  results,
  recommendation,
  year,
  log,
  emissionFactors,
  baseYear
) => {
  if (!results) return;
  const {
    specifics: { amount: reduceBy, oldFuel, newFuel },
    shareImplemented,
    yearImplemented,
  } = recommendation;
  const fleetLog = log?.fleetLog;

  const initEmissions = results[RESULT_KEYS.passengerFleet];

  if (!fleetLog) return;

  const passengerEF = emissionFactors?.["passenger-vehicles"];

  const fleetRecords = fleetLog.filter(
    (record) => record.fleet === "Passenger"
  );

  const recordsToSwitch = fleetRecords?.filter(
    (record) => record.fuel === oldFuel
  );

  if (!recordsToSwitch?.length) return;

  if (!passengerEF) return;
  const {
    SCOPE1_CO2E_PER_KM,
    WTT_CO2E_PER_KM,
    SCOPE2_CO2E_PER_KM,
    TD_CO2E_PER_KM,
  } = passengerEF;

  const newEmissionsArray = recordsToSwitch.map((record) => {
    const { size, distance, scope } = record;

    let emissionFactor;
    if (scope == 1) {
      emissionFactor = SCOPE1_CO2E_PER_KM?.car[size]?.[newFuel]|| 0;
    } else if (scope == 2) {
      emissionFactor = SCOPE2_CO2E_PER_KM?.car[size]?.[newFuel] || 0;
    } else if (scope == 3) {
      emissionFactor =
        (TD_CO2E_PER_KM[size]?.[newFuel]|| 0) +
        (WTT_CO2E_PER_KM.car?.[size]?.[newFuel] || 0);
    }

    return {
      scope,
      emissions: distance * emissionFactor,
    };
  });

  const reductionPercentage = calculateReductionPercentage(
    reduceBy,
    shareImplemented,
    year,
    yearImplemented,
    baseYear
  );

  return calculateReductionAllScopes(
    fleetRecords,
    recordsToSwitch,
    newEmissionsArray,
    initEmissions,
    reductionPercentage
  );
};

export const calculateSwitchDeliveryFuel = (
  results,
  recommendation,
  year,
  log,
  emissionFactors,
  baseYear
) => {
  if (!results) return;

  const {
    specifics: { amount: reduceBy, oldFuel, newFuel },
    shareImplemented,
    yearImplemented,
  } = recommendation;

  const initEmissions = results[RESULT_KEYS.deliveryFleet];

  const fleetLog = log?.fleetLog;

  if (!fleetLog) return;

  const fleetRecords = fleetLog.filter((record) => record.fleet === "Delivery");

  const recordsToSwitch = fleetRecords?.filter(
    (record) => record.fuel === oldFuel
  );
  if (!recordsToSwitch?.length) return;

  const deliveryEF = emissionFactors?.["delivery-vehicles"];

  if (!deliveryEF) return;
  const { VEHICLE_CO2E_PER_KM, VEHICLE_WTT_CO2E_PER_KM, TD_CO2E_PER_KM } =
    deliveryEF;

  const newEmissionsArray = recordsToSwitch.map((record) => {
    const { size, type, distance, scope } = record;

    let emissionFactor;
    if (scope == 1) {
      emissionFactor =
        VEHICLE_CO2E_PER_KM[type]?.[size]?.[newFuel] || 0;
    } else if (scope == 2) {
      emissionFactor =
        newFuel === "Electric"
          ? VEHICLE_CO2E_PER_KM.Van[size].Electric
          : 0;
    } else if (scope == 3) {
      emissionFactor =
        (VEHICLE_WTT_CO2E_PER_KM[type]?.[size]?.[newFuel] || 0) +
        (TD_CO2E_PER_KM[type]?.[newFuel]?.[size] || 0);
    }
    return {
      scope,
      emissions: distance * emissionFactor,
    };
  });

  const reductionPercentage = calculateReductionPercentage(
    reduceBy,
    shareImplemented,
    year,
    yearImplemented,
    baseYear
  );

  return calculateReductionAllScopes(
    fleetRecords,
    recordsToSwitch,
    newEmissionsArray,
    initEmissions,
    reductionPercentage
  );
};

const calculateReductionToFleetScopes = (
  scope,
  fleetRecords,
  recordsToSwitch,
  newEmissionsArray,
  initEmissions,
  reductionPercentage
) => {
  const scopeEmissionsInLog = sumValuesInArray(
    filterScope(fleetRecords, scope),
    "emissions"
  );

  const reducedScopeEmissionsinLog = sumValuesInArray(
    filterScope(recordsToSwitch, scope),
    "emissions"
  );
  const newScopeEmissions = sumValuesInArray(
    filterScope(newEmissionsArray, scope),
    "emissions"
  );

  const currentScopeEmissions = initEmissions?.["scope" + scope];
  if (isNaN(currentScopeEmissions)) return 0;

  // console.log("scope", scope);
  // console.log("totalScopeLog", scopeEmissionsInLog);
  // console.log("reducedScopeLog", reducedScopeEmissionsinLog);
  // console.log("newScopeLog", newScopeEmissions);
  // console.log("currentScope", currentScopeEmissions);

  const reduction =
    (reducedScopeEmissionsinLog - newScopeEmissions) *
    reductionPercentage *
    (currentScopeEmissions / scopeEmissionsInLog);

  return reduction || 0;
};

const calculateReductionAllScopes = (...args) => {
  const scope1 = calculateReductionToFleetScopes(1, ...args);
  const scope2 = calculateReductionToFleetScopes(2, ...args);
  const scope3 = calculateReductionToFleetScopes(3, ...args);

  const total = scope1 + scope2 + scope3;

  return { scope1, scope2, scope3, total };
};
