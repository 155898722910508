import { addAlert, cancelAlert } from "./alertSlice";

export const setAlertWithTimeout = (alert) => {
  return (dispatch) => {
    dispatch(addAlert(alert));

    setTimeout(() => {
      dispatch(cancelAlert(alert));
    }, 6000);
  };
};
