import React, { useState } from "react";
import { useEffect } from "react";
import { RangeWrapper, StyledInput } from "./GreenRangeInput.style";

const RangeInput = ({ name, defaultValue, onChange, value }) => {
  // const [rangeValue, setRangeValue] = useState(value || 0);

  return (
    <RangeWrapper className="my-4 pt-3">
      <StyledInput
        id={name + "range-input"}
        name={name}
        defaultValue={defaultValue}
        value={value}
        onChange={(e) => {
          onChange(e);
          // setRangeValue(e.target.value);
        }}
        type="range"
        min="0"
        max="100"
        step="10"
      />
      <div className="range-value">
        <span>{value}%</span>
      </div>
    </RangeWrapper>
  );
};

export default RangeInput;
