import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DB_PATHS } from "src/constants";
import { useCheckPurchases, useCookieConsent, useDatabasePaths, useHubspot, useLatestLog, useOwnerNotifications, usePurchaseCount, useSettings } from "src/hooks";
import { useCollection, useDoc } from "src/hooks/databaseHooks";

import { resetModals } from "src/store/modalSlice";
import { selectOrgId, selectPeriod } from "src/store/projectSelectors";
import {
  setAssessmentSurvey,
  setEmployees,
  setHasPurchases,
  setInvestmentProviders,
  setLog,
  setPensionProviders,
  setProjectInit,
  setPurchases,
  setSites,
  setSuppliers
} from "src/store/projectSlice";
import { selectUserData, selectUserId, setUserData } from "src/store/userSlice";
import { objHasValues } from "src/utils/common-utils";

const TheLayoutController = () => {
  const dispatch = useDispatch();
  const org = useSelector(selectOrgId) || "none";
  const userData = useSelector(selectUserData);
  const userId = useSelector(selectUserId) || "none";
  const periodId = useSelector(selectPeriod)

  const { getPath } = useDatabasePaths();

  const { status: orgStatus, data: orgData } = useDoc(
    getPath(DB_PATHS.organisation)
  );
  const { status: detailsStatus, data: detailsData } = useCollection(
    getPath(DB_PATHS.details)
  );

  const { data: userDbData } = useDoc(getPath(DB_PATHS.user));

  const { data: userAdminData } = useDoc(["admin-users", userId]);

  const { data: superDbData } = useDoc(["super-users", userId]);

  useEffect(() => {
    if (!userAdminData) return;

    dispatch(
      setUserData({
        secondaryOrganisations: userAdminData.secondaryOrganisations,
      })
    );
  }, [userAdminData, superDbData]);

  const { showOwnerNotifications } = useOwnerNotifications();

  useEffect(() => {
    if (!userDbData) return;
    const { completeDashboardIntro, completeSectionIntro, acceptedTerms } =
      userDbData;
    dispatch(
      setUserData({
        completeDashboardIntro: !!completeDashboardIntro,
        completeSectionIntro: !!completeSectionIntro,
        lastName: userDbData["last-name"],
        firstName: userDbData["first-name"],
        acceptedTerms,
      })
    );
  }, [userDbData]);

  useEffect(() => {
    if (!superDbData) return;
    dispatch(
      setUserData({
        lastName: superDbData["last-name"],
        firstName: superDbData["first-name"],
      })
    );
    if (superDbData?.notifications?.length) {
      showOwnerNotifications(superDbData.notifications, userId);
    }
  }, [superDbData]);

  useSettings();
  const { hasConsentedToHubspot } = useCookieConsent();
  const { loadHubspotChat } = useHubspot();

  useEffect(() => {
    if (!userData || !objHasValues(userData)) return;
    if (!hasConsentedToHubspot) return;

    loadHubspotChat(userData);
  }, [userData, hasConsentedToHubspot]);

  const { hasPurchases } = useCheckPurchases()

  useEffect(() => {
    if (hasPurchases) {
      dispatch(setHasPurchases(hasPurchases));
    }
  }, [hasPurchases, dispatch])

  // const { data: purchaseData } = useCollection(
  //   getPath(DB_PATHS.purchases)
  // );

  // useEffect(() => {
  //   if (!purchaseData) return;
  //   dispatch(setPurchases(purchaseData));
  // }, [purchaseData, dispatch]);

  const { data: suppliers } = useCollection(getPath(DB_PATHS.suppliers));

  useEffect(() => {
    if (!suppliers) return;
    dispatch(setSuppliers(suppliers));
  }, [suppliers, dispatch]);

  const { data: employeeData } = useCollection(
    getPath(DB_PATHS.employees)
  );

  useEffect(() => {
    if (!employeeData) return;
    dispatch(setEmployees(employeeData));
  }, [employeeData, dispatch]);

  const { data: sites } = useCollection(getPath(DB_PATHS.sites));

  useEffect(() => {
    if (!sites) return;
    dispatch(setSites(sites));
  }, [sites, dispatch]);

  const { data: pensionProviders } = useCollection(
    getPath(DB_PATHS.pensionProviders)
  );

  useEffect(() => {
    if (!pensionProviders) return;
    dispatch(setPensionProviders(pensionProviders));
  }, [pensionProviders, dispatch]);

  const { data: investmentProviders } = useCollection(
    getPath(DB_PATHS.investmentProviders)
  );

  useEffect(() => {
    if (!investmentProviders) return;
    dispatch(setInvestmentProviders(investmentProviders));
  }, [investmentProviders, dispatch]);

  useEffect(() => {
    if (orgStatus !== "success" || detailsStatus !== "success") {
      return;
    }

    dispatch(
      setProjectInit({
        orgData,
        organisation: org,
        detailsData,
      })
    );
  }, [orgStatus, orgData, detailsStatus, detailsData, dispatch, org]);

  useEffect(() => {
    dispatch(resetModals());
  }, [org]);

  const { log, status: logStatus } = useLatestLog()

  useEffect(() => {
    if (logStatus !== "success") return
    if (!log) return
    async function fetchLog() {
      dispatch(setLog(log))
    }

    fetchLog()

  }, [log, logStatus])

  const { data: esgSurvey } = useCollection(
    ["organisations", org, "periods", periodId || "none", "details", "assessment-survey", "entries"]
  );

  useEffect(() => {
    if (!esgSurvey) return
    dispatch(setAssessmentSurvey(esgSurvey))

    // async function checkSurveyEntries() {

    //   const surveySnapshot =
    //     await adapator_createDatabaseRef(["organisations", org, "periods", periodId, "details", "assessment-survey", "entries"]).get()

    //   const surveyEntries = surveySnapshot.docs?.map(doc => doc.data())

    //   if (!surveyEntries?.length) return

    //   dispatch(setAssessmentSurvey(surveyEntries))
    // }
    // checkSurveyEntries()
  }, [esgSurvey])


  return { status: detailsStatus, org };
};

export default TheLayoutController;
