import React from "react";

const SecondaryQuestion = ({
  title,
  subtitle,
  children,
  fontSize = 18,
  marginBottom = 5,
}) => {
  return (
    <div className={`mb-${marginBottom} w-100`}>
      <p style={{ fontSize }} className="text-start mb-2">
        {title}
      </p>
      {subtitle && (
        <p style={{ fontSize: "14px" }} className="text-info mb-2">
          {subtitle}
        </p>
      )}
      {children}
    </div>
  );
};

export default SecondaryQuestion;
