import CIcon from "@coreui/icons-react";
import { CButton, CDataTable } from "@coreui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingSpinner, StepCard, WarningModal } from "src/components";
import { useHttp } from "src/hooks";
import { useCollection } from "src/hooks/databaseHooks";
import { StyledSuperTable } from "../SuperAdminPage.style";

const UsersCard = ({ allOrgs }) => {
  const { t } = useTranslation();

  const { data: users, status } = useCollection(["admin-users"]);

  const [userToDelete, setUserToDelete] = useState(null);
  const { sendRequest } = useHttp();

  const deleteUser = async (user) => {
    try {
      await sendRequest("/app/users/user", {
        method: "DELETE",
        body: JSON.stringify({
          uid: user.id || user["NO_ID_FIELD"],
        }),
        successMsg: "User deleted",
        errorMsg: "User could not be deleted",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const tableItems =
    users?.map((user) => {
      const email = user.email || "";
      const id = user.id || user["NO_ID_FIELD"];
      const orgName =
        allOrgs.find((org) => org.id === user.organisation)?.name || "";
      const created = user.created
        ? new Date(user.created).toISOString().split("T")[0]
        : "";

      return {
        ...user,
        email,
        created,
        id,
        orgName,
      };
    }) || [];

  return (
    <>
      <StepCard>
        <StyledSuperTable className="w-100">
          {status !== "success" ? (
            <LoadingSpinner />
          ) : (
            <CDataTable
              itemsPerPageSelect={{ label: t("shared.itemsPerPage") }}
              pagination
              sorter
              hover
              columnFilter
              fields={[
                "email",
                { key: "created", label: "Created", sorter: true },

                "id",
                "organisation",
                { key: "orgName", label: "OrgName", sorter: true },
                { key: "delete", label: "Delete", sorter: true, filter: false },
              ]}
              items={tableItems}
              scopedSlots={{
                delete: (item, index) => {
                  return (
                    <td>
                      <CButton
                        onClick={() => {
                          setUserToDelete(item);
                        }}
                        className="delete-button "
                        variant="outline"
                        color="danger"
                      >
                        <CIcon name="cil-trash" />
                      </CButton>
                    </td>
                  );
                },
              }}
            />
          )}
        </StyledSuperTable>
      </StepCard>
      <WarningModal
        type="User"
        name={userToDelete?.email}
        show={!!userToDelete}
        toggle={() => setUserToDelete(null)}
        onConfirm={() => {
          setUserToDelete(null);
          deleteUser(userToDelete);
        }}
      />
    </>
  );
};

export default UsersCard;
