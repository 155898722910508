import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ButtonBasic,
  DemoWarning,
  FormStepWrapper,
  SignOutButton,
} from "src/components";
import { selectOrgId } from "src/store/projectSelectors";

const EmployeeSurvey10 = ({ isDemo }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const orgId = useSelector(selectOrgId);
  return (
    <FormStepWrapper
      aboveCardSlot={<>{isDemo && <DemoWarning className="mb-3" />}</>}
    >
      <div>
        <h1 className="mb-3 text-center">
          {t("employeeSurvey.thankYou", "Thank you for your time")}
        </h1>
        <h2 className="mb-3 text-center">
          {t("employeeSurvey.surveyComplete", "The survey is complete.")}
        </h2>
        <div>
          <p className="text-info text-center mb-2">
            {t("employeeSurvey.surveyCompleteHint1")}
          </p>
          <p className="text-info text-center w-75 mx-auto">
            {t("employeeSurvey.surveyCompleteHint2")}
          </p>
        </div>
      </div>
      {!isDemo ? (
        <SignOutButton />
      ) : (
        <ButtonBasic
          onClick={() => history.push(`/project/${orgId}/employeeData`)}
        >
          {t("employeeSurvey.backToDataCollection")}
        </ButtonBasic>
      )}
    </FormStepWrapper>
  );
};

export default EmployeeSurvey10;
