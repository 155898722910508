export const webDataSteps = [
    {
        key: "welcome",
        target: ".intro-welcome",
        placement: "center",
        disableBeacon: true,
        styles: {
            options: {
                zIndex: 10000,
            },
        },
    },
    {
        key: "domain_input",
        target: ".intro-domain_input",
        placement: "auto",
        disableBeacon: true,
        styles: {
            options: {
                zIndex: 10000,
            },
        },
    },

    {
        key: "crawl_button",
        target: ".intro-crawl_button",
        placement: "auto",
        disableBeacon: true,
        styles: {
            options: {
                zIndex: 10000,
            },
        },
    },
    {
        key: "manual_metrics",
        target: ".intro-manual_metrics",
        placement: "auto",
        disableBeacon: true,
        styles: {
            options: {
                zIndex: 10000,
            },
        },
    },
    {
        key: "vistitors_num",
        target: ".intro-vistitors_num",
        placement: "auto",
        disableBeacon: true,
        styles: {
            options: {
                zIndex: 10000,
            },
        },
    },
    {
        key: "country_split",
        target: ".intro-country_split",
        placement: "auto",
        disableBeacon: true,
        styles: {
            options: {
                zIndex: 10000,
            },
        },
    },
    {
        key: "device_split",
        target: ".intro-device_split",
        placement: "auto",
        disableBeacon: true,
        styles: {
            options: {
                zIndex: 10000,
            },
        },
    },
  ]