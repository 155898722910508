import { useSelector } from "react-redux";
import { selectPeriod } from "src/store/projectSelectors";
import useDatabasePaths from "../useDatabasePaths/useDatabasePaths";

const { useTranslation } = require("react-i18next");
const { DB_PATHS } = require("src/constants");
const { useCollection } = require("../databaseHooks");

const usePeriodValidation = () => {
  const { t } = useTranslation();

  const { getPath } = useDatabasePaths();

  const { data: periods } = useCollection(getPath(DB_PATHS.periods));
  const currentPeriodId = useSelector(selectPeriod);

  const checkDateIsInPeriod = (date, startDate, endDate) => {

    return (
      Date.parse(date) - Date.parse(startDate) >= 0 &&
      Date.parse(endDate) - Date.parse(date) >= 0
    );
  };

  const isRequiredDate = (v) => {
    if (!v) return "Please select date";
    return true;
  };

  const isPastDate = (date) => {
    if (Date.parse(date) - Date.now() > 0) {
      return t("validation.enterPastDate", "Please enter a date in the past");
    }
    return true;
  };

  const isBeforeEndDate = (date, getValues) => {
    const endDate = getValues("endDate") || getValues("report-date-to");

    if (Date.parse(date) - Date.parse(endDate) >= 0) {
      return t(
        "validation.enterEarlierDate",
        "Please enter date earlier than the end date"
      );
    }
    return true;
  };

  const isNotInsideExistingPeriod = (date, isAddingNew) => {
    if (!Array.isArray(periods)) return true;

    const periodsToCompareAgainst = isAddingNew
      ? periods
      : periods.filter((period) => period.id !== currentPeriodId);


    for (let existingPeriod of periodsToCompareAgainst) {
      const { startDate, endDate } = existingPeriod;
      if (checkDateIsInPeriod(date, startDate, endDate)) {
        return "This date overlaps with existing period.";
      }
    }
    return true;
  };

  const existingPeriodIsNotInside = (date, getValues, isAddingNew) => {
    const endDate = date;
    const startDate = getValues("startDate") || getValues("report-date-from");

    if (!Array.isArray(periods)) return true;

    const periodsToCompareAgainst = isAddingNew
      ? periods
      : periods.filter((period) => period.id !== currentPeriodId);

    for (let existingPeriod of periodsToCompareAgainst) {
      const { startDate: periodStart, endDate: periodEnd } = existingPeriod;

      if (
        checkDateIsInPeriod(periodStart, startDate, endDate) ||
        checkDateIsInPeriod(periodEnd, startDate, endDate)
      ) {
        return "This period overlaps with another!";
      }
    }
    return true;
  };

  const getStartDateValidation = (getValues, isAddingNew = false) => {
    return {
      isRequiredDate,
      isPastDate,
      isBeforeEndDate: (date) => isBeforeEndDate(date, getValues),
      isNotInsideExistingPeriod: (date) =>
        isNotInsideExistingPeriod(date, isAddingNew),
    };
  };

  const getEndDateValidation = (getValues, isAddingNew = false) => {
    return {
      isRequiredDate,
      isNotInsideExistingPeriod: (date) =>
        isNotInsideExistingPeriod(date, isAddingNew),
      existingPeriodIsNotInside: (date) =>
        existingPeriodIsNotInside(date, getValues, isAddingNew),
    };
  };

  return { getStartDateValidation, getEndDateValidation };
};

export default usePeriodValidation;
