import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { TheContent, TheSidebar, TheAside, TheHeader } from "./index";
import { ErrorFallback, Loading } from "src/components";
import controller from "./controllers/theLayoutController";
import SuperAdminPage from "src/pages/SuperAdminPage/SuperAdminPage";
import { usePermissions } from "src/hooks";

const TheLayout = () => {
  const darkMode = useSelector((state) => state.coreui.darkMode);
  const { isSuperAdmin } = usePermissions();

  const { org } = controller();
  const classes = classNames(
    "c-app c-default-layout",
    darkMode && "c-dark-theme"
  );

  // if (status === "loading") {
  //   return <Loading />;
  // }

  let content;

  if (isSuperAdmin) {
    if (org && org !== "none") {
      content = <TheContent className="data" />;
    } else {
      content = <SuperAdminPage />;
    }
  } else {
    if (org) {
      content = <TheContent />;
    } else {
      content = <ErrorFallback />;
    }
  }

  return (
    <div className={classes}>
      {org && org !== "none" && <TheSidebar />}
      {!isSuperAdmin && <TheAside />}
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">{content}</div>
      </div>
    </div>
  );
};

export default TheLayout;
