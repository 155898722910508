import CIcon from "@coreui/icons-react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeaderNav,
} from "@coreui/react";
import firebase from "firebase/compat/app";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "reactfire";
import { set, toggleDarkMode } from "src/store/coreuiSlice";
import { setAccountSettingsModal } from "src/store/modalSlice";
import { clearUserData } from "src/store/userSlice";

const HeaderMobileDropdown = () => {
  const dispatch = useDispatch();
  const asideShow = useSelector((state) => state.coreui.asideShow);
  const darkMode = useSelector((state) => state.coreui.darkMode);

  const auth = useAuth();

  const { t } = useTranslation();

  return (
    <div className="mobile">
      <CHeaderNav className="mr-1">
        <CDropdown inNav>
          <CDropdownToggle className="help-center-intro px-0">
            <CIcon size="xl" name="cil-applications-settings" />
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem onClick={() => dispatch(toggleDarkMode(!darkMode))}>
              {darkMode ? t("header.lightMode") : t("header.darkMode")}
            </CDropdownItem>
            <CDropdownItem
              onClick={() => dispatch(setAccountSettingsModal(true))}
            >
              {t("header.accountSettings", "Account settings")}
            </CDropdownItem>

            {/* asidebar has bug to open with inside of CDropdownItem */}
            <span
              className="dropdown-item"
              role="menuitem"
              onClick={() => dispatch(set({ asideShow: !asideShow }))}
            >
              {t("header.helpCenter", "Help center")}
            </span>
            <CDropdownItem
              onClick={() =>
                auth.signOut().then(() => {
                  firebase.firestore().terminate();
                  dispatch(clearUserData());
                })
              }
            >
              {t("shared.signOut", "Sign out")}
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CHeaderNav>
    </div>
  );
};

export default HeaderMobileDropdown;
