import { useTranslation } from "react-i18next";

const useSharedConstants = () => {
  const { t } = useTranslation();

  const fuelTypes = t("arrays.fuelTypes", { returnObjects: true });
  const deliveryTypes = t("arrays.deliveryTypes", { returnObjects: true });
  const hgvSizes = t("arrays.hgvSizes", { returnObjects: true });
  const vanSizes = t("arrays.vanSizes", { returnObjects: true });
  const flightHeaders = t("arrays.flightHeaders", { returnObjects: true });
  const flightClasses = t("arrays.flightClasses", { returnObjects: true });
  const flightTypes = t("arrays.flightTypes", { returnObjects: true });
  const flightCategories = t("arrays.flightCategories", {
    returnObjects: true,
  });
  const resultsTable = t("arrays.resultsTable", { returnObjects: true });
  const summaryCommentaryFields = t("arrays.summaryCommentaryFields", {
    returnObjects: true,
  });
  const employeeHighlightsCommentary = t(
    "arrays.employeeHighlightsCommentary",
    { returnObjects: true }
  );
  const homeEnergy = t("arrays.homeEnergy", { returnObjects: true });
  const greenTariff = t("arrays.green", { returnObjects: true });
  const onlyVehicle = t("arrays.onlyVehicle", { returnObjects: true });
  const emissionImportance = t("arrays.emissionImportance", {
    returnObjects: true,
  });
  const pensionScheme = t("arrays.pensionScheme", { returnObjects: true });
  const concernLevel = t("arrays.concernLevel", { returnObjects: true });
  const initiatives = t("arrays.initiatives", { returnObjects: true });
  const suppliedServices = t("arrays.suppliedServices", {
    returnObjects: true,
  });
  const commutePatterns = t("arrays.commutePatterns", { returnObjects: true });
  const flightPatterns = t("arrays.flightPatterns", { returnObjects: true });
  const usageIT = t("arrays.usageIT", { returnObjects: true });

  const reportingPeriods = t("arrays.reportingPeriods", {
    returnObjects: true,
  });
  const industryList = t("arrays.industryList", { returnObjects: true });
  const recommendations = t("arrays.recommendations", { returnObjects: true });
  const recommendTable = t("arrays.recommendTable", { returnObjects: true });
  const vehicleTypes = t("arrays.vehicleTypes", { returnObjects: true });
  const renewables = t("arrays.renewables", { returnObjects: true });

  const publicTransport = [
    { label: t("travelData.train", "Train"), name: "rail" },
    { label: t("travelData.tram", "Light rail/tram"), name: "tram" },
    { label: t("travelData.metro", "Metro"), name: "metro" },
    { label: t("travelData.bus"), name: "bus" },
    { label: t("travelData.coach"), name: "coach" },
    { label: t("travelData.taxi", "Taxi"), name: "taxi" },
  ];

  const passengerTypes = [
    { label: t("arrays.passengerTypes.0"), value: "Car" },
    { label: t("arrays.passengerTypes.1"), value: "Motorbike" },
  ];

  const passengerSizes = [
    { label: t("arrays.passengerSizes.0"), value: "Small" },
    { label: t("arrays.passengerSizes.1"), value: "Medium" },
    { label: t("arrays.passengerSizes.2"), value: "Large" },
    { label: t("arrays.passengerSizes.3"), value: "Not sure" },
  ];
  const vehicleFuelTypes = [
    { label: t("arrays.vehicleFuelTypes.0"), value: "Diesel" },
    { label: t("arrays.vehicleFuelTypes.1"), value: "Petrol" },
    {
      label: t("arrays.vehicleFuelTypes.2"),
      value: "Hybrid (engine charging)",
    },
    { label: t("arrays.vehicleFuelTypes.3"), value: "Plug-in hybrid" },
    { label: t("arrays.vehicleFuelTypes.4"), value: "Electric only" },
    { label: t("arrays.vehicleFuelTypes.6"), value: "CNG" },
    { label: t("arrays.vehicleFuelTypes.7"), value: "LPG" },
  ];

  const deliveryFuelTypes = [
    "Diesel",
    "Petrol",
    "CNG",
    "LPG",
    "Electric",
    "Unknown",
  ];

  const travelModes = [
    { title: t("arrays.travelModes.0"), name: "car" },
    { title: t("arrays.travelModes.1"), name: "taxi" },
    { title: t("arrays.travelModes.2"), name: "bus" },
    { title: t("arrays.travelModes.3"), name: "rail" },
    { title: t("arrays.travelModes.4"), name: "intl-rail" },
    { title: t("arrays.travelModes.5"), name: "metro" },
  ];

  return {
    deliveryFuelTypes,
    passengerSizes,
    passengerTypes,
    flightCategories,
    flightClasses,
    flightHeaders,
    flightPatterns,
    flightTypes,
    fuelTypes,
    vehicleFuelTypes,
    vehicleTypes,
    commutePatterns,
    industryList,
    initiatives,
    recommendTable,
    recommendations,
    reportingPeriods,
    resultsTable,
    pensionScheme,
    concernLevel,
    usageIT,
    suppliedServices,
    summaryCommentaryFields,
    deliveryTypes,
    hgvSizes,
    homeEnergy,
    vanSizes,
    travelModes,
    emissionImportance,
    employeeHighlightsCommentary,
    greenTariff,
    onlyVehicle,
    renewables,
    publicTransport,
  };
};

export default useSharedConstants;
