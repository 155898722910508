import * as clipboardPolyfill from "clipboard-polyfill";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setAlertWithTimeout } from "src/store/operations";

/**
 * Hook for copying text to clipboard
 * 
 * @returns {{copyTextToClipboard: (function({text: string, label: string}): Promise<void>)}
 */

const useCopyToClipboard = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const copyTextToClipboard = async ({ text, label }) => {
    const clipboard = navigator.clipboard || clipboardPolyfill;
    try {
      await clipboard.writeText(text);
      dispatch(
        setAlertWithTimeout({
          type: "success",
          text: t("shared.copySuccess", "Copied {{label}} to clipboard", {
            label,
          }),
        })
      );
    } catch (err) {
      dispatch(
        setAlertWithTimeout({
          type: "error",
          text: t("shared.copyError", "Error copying {{label}}!", {
            label,
          }),
        })
      );
    }
  };

  return { copyTextToClipboard };
};

export default useCopyToClipboard;
