import { get } from "lodash";
import { RESULT_KEYS } from "src/constants/resultsSections";
import { roundNumber } from "src/utils/common-utils";

export const calculateBasicReduction = (results, recommendation) => {
  const {
    specifics: { amount: reduceBy, location },
    fieldToReduce,
  } = recommendation;

  if (!results) return 0;

  //energy-location adjustment
  let adjustedFields = fieldToReduce;
  if (Array.isArray(fieldToReduce)) {
    adjustedFields = fieldToReduce.filter(
      (item) => item !== RESULT_KEYS.locations.elecLocation
    );
  }

  const calculateReductionForFields = (fieldToReduce, reduceBy, path = "") => {
    const pathValue = path ? path + "." : "";
    const reductionPercentage = reduceBy / 100;

    if (!Array.isArray(fieldToReduce)) {
      const currentVal = get(results, pathValue + fieldToReduce);

      return roundNumber(currentVal * reductionPercentage, 2);
    } else {
      let sumOfReductions = 0;
      fieldToReduce.forEach((field) => {
        const currentVal = get(results, pathValue + field) || 0;
        sumOfReductions += currentVal * reductionPercentage;
      });
      return roundNumber(sumOfReductions, 2);
    }
  };

  if (location) {
    if (!results.locations || !Object.values(results.locations).length)
      return 0;
    if (location === "All locations") {
      let sumForLocations = 0;
      Object.keys(results.locations).forEach((location) => {
        sumForLocations += calculateReductionForFields(
          adjustedFields,
          reduceBy,
          `locations.${location}`
        ) || 0;
      });
      return sumForLocations;
    } else {
      if (!results.locations[location]) return 0;

      return calculateReductionForFields(
        adjustedFields,
        reduceBy,
        `locations.${location}`
      );
    }
  }
  return calculateReductionForFields(adjustedFields, reduceBy);
};

export const calculateReductionPercentage = (
  reduceBy,
  shareImplemented,
  year,
  yearImplemented,
  baseYear
) => {
  if (!year) return parseFloat(reduceBy) / 100;

  return (
    (((Math.min((year - baseYear) / (yearImplemented - baseYear), 1) *
      shareImplemented || 0) /
      100) *
      parseFloat(reduceBy)) /
    100
  );
};
