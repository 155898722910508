import {
  CButton,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CPopover,
  CRow,
} from "@coreui/react";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { AiFillSchedule } from "react-icons/ai";
import { BsPlayBtn } from "react-icons/bs";
import { MdAssignment } from "react-icons/md";
import ReactJoyride from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions, useSectors, useTasks } from "src/hooks";
import {
  selectWelcomeSectionModalState,
  selectWelcomeTutorialState,
  showWelcomeSectionModal,
  showWelcomeTutorial,
} from "src/store/modalSlice";
import styled from "styled-components";
import BasicCollapse from "../BasicCollapse/BasicCollapse";
import TaskModal from "../TaskModal/TaskModal";
import RemindEmailModal from "./components/RemindEmailModal";
import StyledDiv from "./WelcomeTutorial.style";

const WelcomeTutorial = ({
  steps,
  stepIndex,
  callbackHandler,
  intro,
  isResultPage = false,
  isOpen = true,
}) => {
  const watchTutorial = useSelector(selectWelcomeTutorialState);
  const showIntro = useSelector(selectWelcomeSectionModalState);
  const { isAdmin } = usePermissions();

  const dispatch = useDispatch();

  const tutorialToggle = () => {
    dispatch(showWelcomeSectionModal(false));
    dispatch(showWelcomeTutorial(true));
  };

  const onClose = () => {
    showIntro && dispatch(showWelcomeSectionModal(false));

    callbackHandler({ action: "skip", type: "tour:end" });
  };

  const [showTaskModal, setShowTaskModal] = useState(false);
  const taskModalToggle = () => {
    setShowTaskModal((prev) => !prev);
  };

  const [showEmailModal, setShowEmailModal] = useState(false);
  const emailModalToggle = () => {
    setShowEmailModal((prev) => !prev);
  };

  const { collaborators, onSubmit, values: tasks, onTasksChange } = useTasks();
  const name = `${intro?.sectionName}.entire-section`;

  const defaultValue = get(tasks, name);

  const { SECTORS } = useSectors();
  const sector = SECTORS.find((sector) => sector.name === intro?.sectionName);

  const duration = sector?.time;
  const sectionName = sector?.title;

  const taskName = `Complete ${sectionName} section`;
  const { t } = useTranslation();

  return (
    <>
      <StyledDiv style={{ zIndex: "1030" }}>
        <CModal show={showIntro} onClose={onClose} centered size="xl">
          <CModalHeader
            style={{ color: "black" }}
            className="bg-light px-4 pt-4 pb-3"
          >
            <h2 className="m-0 ">
              <Trans
                i18nKey="sectionModal.welcome"
                components={{ 1: <span className="text-info"></span> }}
                values={{ sectionName }}
              ></Trans>
            </h2>
          </CModalHeader>
          <CModalBody className="py-2 px-4">
            <CRow>
              <CCol>
                {intro?.content.map(({ title, text }) => (
                  <BasicCollapse
                    title={title}
                    className="border bg-light my-2"
                    key={title + text}
                  >
                    <CCol>
                      <FirstLetterBig>{text}</FirstLetterBig>
                    </CCol>
                  </BasicCollapse>
                ))}
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter
            style={{ gap: "0.5rem" }}
            className="bg-secondary d-flex flex-column flex-md-row  align-items-center py-3 px-4"
          >
            {isOpen && (
              <>
                <CPopover
                  content={t("sectionModal.schedule", {
                    time: parseInt(duration),
                  })}
                >
                  <CButton
                    color="primary"
                    onClick={emailModalToggle}
                    className="text-white d-flex justify-content-center align-items-center"
                  >
                    <AiFillSchedule className="mr-1" />
                    {t("sectionModal.sendEmail")}
                  </CButton>
                </CPopover>
                {isAdmin && !isResultPage && (
                  <CPopover content={t("sectionModal.assignHint")}>
                    <CButton
                      color="info"
                      onClick={taskModalToggle}
                      className="text-white d-flex justify-content-center align-items-center"
                    >
                      <MdAssignment className="mr-1" />
                      {t("sectionModal.assign")}
                    </CButton>
                  </CPopover>
                )}

                <CPopover content={t("sectionModal.tutorialHint")}>
                  <CButton
                    onClick={tutorialToggle}
                    color="warning"
                    className="text-white d-flex justify-content-center align-items-center bg-golden ml-auto"
                  >
                    <BsPlayBtn className="mr-1" />
                    {t("sectionModal.tutorialHint")}
                  </CButton>
                </CPopover>
              </>
            )}

            <CPopover content={t("sectionModal.start")}>
              <CButton
                color="success"
                onClick={() => {
                  dispatch(showWelcomeSectionModal(false));
                  onClose();
                }}
                className="text-white d-flex justify-content-center align-items-center"
              >
                {t("sectionModal.startButton")}
              </CButton>
            </CPopover>
          </CModalFooter>
        </CModal>
      </StyledDiv>
      {isAdmin && !isResultPage && (
        <TaskModal
          show={showTaskModal}
          {...{
            name,
            toggle: taskModalToggle,
            collaborators,
            onSubmit,
            onTasksChange,
            defaultValue,
          }}
        />
      )}
      <RemindEmailModal
        show={showEmailModal}
        toggle={emailModalToggle}
        task={taskName}
        duration={duration}
      />
      <ReactJoyride
        showSkipButton
        steps={steps}
        stepIndex={stepIndex}
        run={watchTutorial}
        callback={callbackHandler}
        scrollToFirstStep
        hideCloseButton
        locale={{
          close: "Next",
        }}
        styles={{
          buttonNext: { backgroundColor: "var(--info)" },
        }}
      />
    </>
  );
};

export default WelcomeTutorial;

const FirstLetterBig = styled.p`
  font-size: 1rem;
  ::first-letter {
    font-size: 1.3rem;
    font-weight: 700;
  }
`;
