import { CForm } from "@coreui/react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Steps, useSteps } from "react-step-builder";
import {
  Loading,
  Progress,
  ProgressBar
} from "src/components";
import controller from "./controllers/SupplierSurveyController";
import SupplierSurvey1 from "./SupplierSurvey1/SupplierSurvey1";
import SupplierSurvey10 from "./SupplierSurvey10/SupplierSurvey10";
import SupplierSurvey11 from "./SupplierSurvey11/SupplierSurvey11";
import SupplierSurvey2 from "./SupplierSurvey2/SupplierSurvey2";
import SupplierSurvey3 from "./SupplierSurvey3/SupplierSurvey3";
import SupplierSurvey4 from "./SupplierSurvey4/SupplierSurvey4";
import SupplierSurvey5 from "./SupplierSurvey5/SupplierSurvey5";
import SupplierSurvey6 from "./SupplierSurvey6/SupplierSurvey6";
import SupplierSurvey7 from "./SupplierSurvey7/SupplierSurvey7";
import SupplierSurvey8 from "./SupplierSurvey8/SupplierSurvey8";
import SupplierSurvey9 from "./SupplierSurvey9/SupplierSurvey9";

const SupplierSurvey = ({ isDemo }) => {
  const {
    status,
    data,
    onChange,
    onSubmit,
    formMethods,
    nextClickHandler,
    prevClickHandler,
    info,
    onSurveyCompleted,
    reportingPeriod,
  } = controller({ isDemo });

  const values = data || {};
  const stepAttr = { values, onChange, info, isDemo };
  const { current, total } = useSteps();
  const { t } = useTranslation();

  const navigationSteps = [
    "Introduction",
    "Products or services",
    "Employees",
    "Country and commute",
    "Internet usage",
    "Turnover",
    "Emissions",
    "Digital advertising or Dentistry",
    "Phsyical goods or Construction materials",
    "Sustainability policy",
  ]


  return (
    <FormProvider
      {...{
        ...formMethods,
        nextClickHandler,
        prevClickHandler,
        showBackButton: false,
        orgName: info.orgName,
        hideStepComplete: true,
        isSurvey: true,
        reportingPeriod,
      }}
      navigationSteps={isDemo ? navigationSteps : undefined}
    >
      <CForm className="w-100 " onSubmit={nextClickHandler}>
        <Progress className="position-absolute">
          <ProgressBar value={(current / total) * 100} />
        </Progress>
        {status !== "success" ? (
          <Loading />
        ) : (
          <Steps>
            <SupplierSurvey1 {...stepAttr} onNextClick={nextClickHandler} />
            <SupplierSurvey2 {...stepAttr} />
            <SupplierSurvey3 {...stepAttr} />
            <SupplierSurvey4 {...stepAttr} />
            <SupplierSurvey5 {...stepAttr} />
            <SupplierSurvey6 {...stepAttr} />
            <SupplierSurvey7 {...stepAttr} />
            <SupplierSurvey8 {...stepAttr} />
            <SupplierSurvey9 {...stepAttr} />
            <SupplierSurvey10
              {...stepAttr}
              nextClickHandler={nextClickHandler}
            />
            <SupplierSurvey11 {...stepAttr} onCompleted={onSurveyCompleted} />
          </Steps>
        )}
      </CForm>
    </FormProvider>
  );
};

export default SupplierSurvey;
