import { NumericFormat } from "react-number-format";
import Wrapper from "./BasicNumberInput.style";

const BasicNumberInput = ({
  containerClass,
  containerStyle,
  className,
  onBlur,
  decimalScale = 0,
  max,
  min,
  allowNegative = true,
  onChange,
  name,
  ...rest
}) => {
  return (
    <Wrapper
      className={`d-flex ${containerClass} w-100 align-items-center`}
      style={containerStyle}
    >
      <NumericFormat
        className={`form-control pr-1 ${className}`}
        thousandSeparator=","
        decimalScale={decimalScale}
        allowNegative={allowNegative}
        inputMode={decimalScale ? "decimal" : "numeric"}
        isAllowed={(values, sourceInfo) => {
          const { value } = values;
          if (min && value < min) return false;
          if (max && value > max) return false;
          return true;
        }}
        onValueChange={(values, sourceInfo) => {
          const value = isNaN(+values.floatValue) ? null : values.floatValue;
          onChange({ target: { name, value } });
          onBlur?.();
        }}
        {...rest}
      />
    </Wrapper>
  );
};

export default BasicNumberInput;
