import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DB_PATHS } from "src/constants";
import { useDatabasePaths, useToasts, useUpdateDatabase } from "src/hooks";
import { useCollection } from "src/hooks/databaseHooks";
import { resetProject, setOrg as setReduxOrg } from "src/store/projectSlice";
import { selectUserEmail, selectUserId } from "src/store/userSlice";

const SuperAdminController = () => {
  const { t } = useTranslation();

  const { data: allOrgs, status } = useCollection(DB_PATHS.organisations);
  const history = useHistory();
  const userEmail = useSelector(selectUserEmail);
  const userId = useSelector(selectUserId);
  const dispatch = useDispatch();

  const { getPath } = useDatabasePaths();

  const selectOrg = (data) => {
    dispatch(resetProject());
    dispatch(setReduxOrg(data.id));
    history.replace("/");
  };

  const { showToast } = useToasts();

  const { updateDocument } = useUpdateDatabase();

  const updateOrg = async (e, orgId, updateObj, textSuccess, textError) => {
    e.stopPropagation();
    try {
      await updateDocument(getPath(DB_PATHS.organisation, orgId), updateObj);
      showToast({ text: textSuccess, type: "success" });
    } catch (err) {
      console.error(err);
      showToast({
        text: textError,
        type: "error",
      });
    }
  };

  const archiveOrg = async (e, orgId) =>
    await updateOrg(
      e,
      orgId,
      { archived: true },
      t("superAdmin.moveArchive"),
      t("superAdmin.errorArchive")
    );

  const unarchiveOrg = async (e, orgId) =>
    await updateOrg(
      e,
      orgId,
      { archived: false },
      t("superAdmin.moveActive"),
      t("superAdmin.errorActive")
    );

  const assignOwner = async (e, orgId) =>
    await updateOrg(
      e,
      orgId,
      { owner: userEmail, ownerId: userId },
      t("superAdmin.assignSuccess"),
      t("superAdmin.assignError")
    );

  const unassignOwner = async (e, orgId) =>
    await updateOrg(
      e,
      orgId,
      { owner: "", ownerId: "" },
      t("superAdmin.unassignSuccess"),
      t("superAdmin.unassignError")
    );

  const setFreemium = async (e, orgId, isFreemium) =>
    await updateOrg(
      e,
      orgId,
      { freemium: isFreemium },
      t("superAdmin.statusChange"),
      t("superAdmin.errorStatuschange")
    );

  return {
    archiveOrg,
    unarchiveOrg,
    allOrgs,
    selectOrg,
    assignOwner,
    unassignOwner,
    setFreemium,
    status
  };
};

export default SuperAdminController;
