export const supplierDataIntroSteps = [
  {
    key: "welcome",
    target: ".supplierData-welcome",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    spotlightClicks: false,
    placement: "center",
  },
  {
    key: "supplier_survey_explain",
    target: ".supplier-intro-survey",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "review_survey_button",
    target: ".supplier-intro-survey-review",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "email_invitation_tab",
    target: ".supplier-intro-invitation-tab",
    placement: "auto",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
    styles: {
      buttonNext: {
        display: "none",
      },
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "email_template",
    target: ".intro-email-template",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "save_btn",
    target: ".intro-save-btn",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "copy_btn",
    target: ".intro-copy-btn",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "click_supplier_selection_tab",
    target: ".supplier-intro-selection-tab",
    placement: "auto",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
    styles: {
      buttonNext: {
        display: "none",
      },
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "supplier_table_desc",
    target: ".supplier-intro-selection-desc",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "supplier_table",
    target: ".supplier-intro-selection-table",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "save_button",
    target: ".supplier-intro-selection-save",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];
