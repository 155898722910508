import { getWithExpiry, setWithExpiry } from "src/utils/common-utils";
import { useHttp } from "src/hooks";

const useHubspot = () => {
  const { sendRequest } = useHttp();

  const getHubspotToken = async ({ firstName, lastName, email }) => {
    const response = await sendRequest(
      "/app/hubspot/token",
      {
        method: "POST",
        body: JSON.stringify({ firstName, lastName, email }),
      },
      true
    );
    return response?.token;
  };

  const createOrUpdateContact = async ({
    firstName,
    lastName,
    email,
    company,
  }) => {
    const response = await sendRequest(
      "/app/hubspot/createOrUpdateContact",
      {
        method: "POST",
        body: JSON.stringify({ firstName, lastName, email, company }),
      },
      true
    );
    return response;
  };

  const loadHubspotChat = async (userData) => {
    window.hsConversationsSettings = {
      loadImmediately: true,
    };

    const storage = window.localStorage;
    const TTL_FOR_12HRS = 43200;

    const validateToken = async () => {
      const hasToken = getWithExpiry("hs_visitor_identify");

      if (hasToken) {
        const token = JSON.parse(storage.getItem("hs_visitor_identify")).value;

        return token;
      } else {
        const token = await getHubspotToken({
          firstName: userData.firstName,
          lastName: userData.uid,
          email: userData.email,
        })
          .then((res) => {
            return res;
          })
          .catch(() => {
            return null;
          });

        setWithExpiry("hs_visitor_identify", token, TTL_FOR_12HRS);
        return token;
      }
    };

    const hubspotToken = await validateToken();

    if (hubspotToken) {
      window.hsConversationsSettings = {
        identificationToken: hubspotToken,
        identificationEmail: userData.email,
      };
    }
  };

  return { getHubspotToken, createOrUpdateContact, loadHubspotChat };
};

export default useHubspot;
