import CIcon from "@coreui/icons-react";
import {
  CBreadcrumbRouter,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CPopover,
  CSubheader,
  CToggler,
} from "@coreui/react";
import { useState } from "react";
import { MdOutlineLiveHelp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GlobalLogModal from "src/components/GlobalLogModal/GlobalLogModal";
import { selectIsFreemium, selectOrgId } from "src/store/projectSelectors";
import zeroBeesLogo from "../../assets/images/ZeroBees_logo_new.svg";

// routes config
import { useTranslation } from "react-i18next";
import {
  AccountSettingsButton,
  AccountSettingsModal,
  AssignModal,
  ButtonBasic,
  ButtonOutline,
  SignOutButton,
} from "src/components";
import { usePermissions, useResizeObserver } from "src/hooks";
import { set, toggleDarkMode } from "src/store/coreuiSlice";
import { setOrg } from "src/store/projectSlice";
import { selectSecondaryOrgs } from "src/store/userSlice";
import useRoutes from "../../routes";
import HeaderMobileDropdown from "./components/HeaderMobileDropdown";
import SwitchOrgDropdown from "./components/SwitchOrgDropdown";
import HeaderFreemiumText from "./components/HeaderFreemiumText";
import FreemiumModal from "src/components/FreemiumModal/FreemiumModal";
import MessageDropdown from "./components/messageDropdown/MessageDropdown";
import SendNotification from "./components/sendNotification/SendNotification";
import PeriodSwitchModal from "./components/PeriodSwitch";
import { setSwitchPeriodModal } from "src/store/modalSlice";
const TheHeader = () => {
  const dispatch = useDispatch();
  const asideShow = useSelector((state) => state.coreui.asideShow);
  const darkMode = useSelector((state) => state.coreui.darkMode);
  const sidebarShow = useSelector((state) => state.coreui.sidebarShow);

  const [showGlobalLogModal, setShowGlobalLogModal] = useState(false);

  const { isSmallScreen } = useResizeObserver();

  const history = useHistory();
  const orgId = useSelector(selectOrgId);

  const { isSuperAdmin } = usePermissions();

  const { routes } = useRoutes();

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    dispatch(set({ sidebarShow: val }));
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch(set({ sidebarShow: val }));
  };

  const { t } = useTranslation();

  const imgStyle = isSmallScreen ? { width: "80px" } : {};

  const secondary = useSelector(selectSecondaryOrgs);

  const isFree = useSelector(selectIsFreemium);

  return (
    <div>
      <CHeader withSubheader>
        {orgId && orgId !== "none" ? (
          <>
            <CToggler
              inHeader
              className="ml-md-3 d-lg-none"
              onClick={toggleSidebarMobile}
            />
            <CPopover content={t("header.easyAccess")}>
              <CToggler
                inHeader
                className="ml-3 d-md-down-none"
                onClick={toggleSidebar}
              />
            </CPopover>
          </>
        ) : (
          <div className="ml-4" />
        )}
        <CHeaderBrand className="mr-auto d-lg-none" to="/">
          <img
            style={{ ...imgStyle, cursor: "pointer" }}
            src={zeroBeesLogo}
            alt="ZeroBees Logo"
          />
          <HeaderFreemiumText
            fontSize="16px"
            isFreemium={isFree}
            iconSize="1rem"
          />
        </CHeaderBrand>

        <CHeaderNav className="d-md-down-none mr-auto">
          <CHeaderNavItem className="d-flex align-items-center">
            <CHeaderNavLink to="/">
              <img
                src={zeroBeesLogo}
                style={{ cursor: "pointer" }}
                alt="ZeroBees Logo"
              />
            </CHeaderNavLink>
            <HeaderFreemiumText isFreemium={isFree} />
          </CHeaderNavItem>
        </CHeaderNav>

        <CHeaderNav className="px-3 ">
          {/* <LanguageButton /> */}
          {!!secondary?.length && <SwitchOrgDropdown />}
          {!isSuperAdmin ? <MessageDropdown /> : <SendNotification />}
          <CPopover content={t("header.switchPeriod")}>
            <CToggler
              inHeader
              onClick={() => dispatch(setSwitchPeriodModal(true))}
            >
              <CIcon
                className="period-intro"
                name="cil-calendar"
                alt={t("header.switchPeriod")}
              />
            </CToggler>
          </CPopover>
          {!isSmallScreen ? (
            <>
              <CPopover
                content={
                  darkMode ? t("header.lightMode") : t("header.darkMode")
                }
              >
                <CToggler
                  inHeader
                  className="c-d-legacy-none"
                  onClick={() => dispatch(toggleDarkMode(!darkMode))}
                >
                  <CIcon
                    name={darkMode ? "cil-sun" : "cil-moon"}
                    className="darkmode-intro"
                    alt={
                      darkMode ? t("header.lightMode") : t("header.darkMode")
                    }
                  />
                </CToggler>
              </CPopover>
              {!isSuperAdmin && (
                <CToggler
                  inHeader
                  onClick={() => dispatch(set({ asideShow: !asideShow }))}
                >
                  <CPopover content={t("header.helpCenter")}>
                    <MdOutlineLiveHelp
                      className="mr-2 help-center-intro"
                      size="20px"
                    />
                  </CPopover>
                </CToggler>
              )}

              <AccountSettingsButton />
              <SignOutButton size="sm" />
            </>
          ) : (
            <HeaderMobileDropdown />
          )}
        </CHeaderNav>

        <CSubheader className="px-3 justify-content-between align-items-center">
          <CBreadcrumbRouter
            className="border-0 c-subheader-nav m-0 px-0 px-md-3"
            routes={routes}
          />
          {isSuperAdmin && orgId !== "none" && (
            <CHeaderNav>
              <ButtonOutline
                size="sm"
                className="px-3 mr-3"
                onClick={() => setShowGlobalLogModal((prev) => !prev)}
              >
                {t("header.globalLogs", "Global logs")}
              </ButtonOutline>
              <CPopover
                content={t("header.selectOther", "Select other organisations")}
                placement="bottom"
              >
                <ButtonBasic
                  className=" px-3"
                  size="sm"
                  onClick={() => {
                    history.push("/");
                    dispatch(setOrg("none"));
                  }}
                >
                  {t("header.switch", "Switch project")}
                </ButtonBasic>
              </CPopover>
            </CHeaderNav>
          )}
        </CSubheader>
      </CHeader>
      <PeriodSwitchModal />
      <AccountSettingsModal />
      <AssignModal />
      <FreemiumModal />
      {isSuperAdmin && showGlobalLogModal && (
        <GlobalLogModal
          show={showGlobalLogModal}
          toggle={() => setShowGlobalLogModal((prev) => !prev)}
        />
      )}
    </div>
  );
};

export default TheHeader;
