import { useSelector } from "react-redux";
import { selectOrgId, selectPeriod } from "src/store/projectSelectors";
import { selectUserId } from "src/store/userSlice";

const useDatabasePaths = () => {
  const orgId = useSelector(selectOrgId) || "none";
  const periodId = useSelector(selectPeriod) || "none";
  const userId = useSelector(selectUserId) || "none";

  const getPath = (pathConstant, orgProp, periodProp, uidProp) => {
    return pathConstant
      .join(",")
      .replace("ORG_ID", orgProp || orgId)
      .replace("PERIOD_ID", periodProp || periodId)
      .replace("USER_ID", uidProp || userId)
      .split(",");
  };

  return { getPath };
};

export default useDatabasePaths;
