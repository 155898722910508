export const DEFAULT_HEADER_STYLES = {
  fill: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFd8dbe0" },
  },
  border: {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  },
  alignment: {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  },
  font: {
    name: "Arial",
    family: 2,
    size: 10,
    bold: true,
  },
};

/***************CONVERTING DATA INTO EXCEL**************/

export const convertIntoExcelDate = (dateStr) => {
  const date = new Date(dateStr);
  const daysAfter1970 = Math.floor(date.getTime() / (24 * 60 * 60 * 1000));
  const daysAfter1900 = daysAfter1970 + 25569;
  return daysAfter1900;
};

export const convertDataIntoExcel = (arrayOfObjects, columns) => {
  const arrayOfArrays = arrayOfObjects.map((obj) => {
    const row = [];
    columns.forEach((column) => {
      const { key } = column;
      if (!key.includes(".")) {
        if (key.includes("date")) {
          row.push(convertIntoExcelDate(obj[key]) || "");
        } else {
          row.push(obj[key] || "");
        }
      } else {
        const [parent, field] = key.split(".");

        row.push(obj[parent]?.[field] || "");
      }
    });
    return row;
  });

  return arrayOfArrays;
};

export const convertArrayOfObjIntoArrays = (arrayOfObjects, columns) => {
  if (!arrayOfObjects) return [];
  const arrayOfArrays = arrayOfObjects.map((employeeObj) => {
    const row = [];
    columns.forEach((column) => {
      const { key } = column;

      row.push(employeeObj[key] || "");
    });

    return row;
  });
  return arrayOfArrays;
};

export const addHeaders = (worksheet, columns, headersRow = 1) => {
  worksheet.getRow(headersRow).values = columns.map((item) => item.header);
};

export const applyStylesToHeaders = (
  worksheet,
  headersRow = 1,
  headerStyles = DEFAULT_HEADER_STYLES
) =>
  worksheet.getRow(headersRow).eachCell((cell) => {
    Object.entries(headerStyles).forEach((entry) => {
      const [key, values] = entry;
      cell[key] = values;
    });
  });

export const addPropToColumns = (worksheet, columns, prop) => {
  columns.forEach((column, index) => {
    worksheet.getColumn(index + 1)[prop] = column[prop];
  });
};

export const addRows = (worksheet, data, columns) => {
  convertDataIntoExcel(data, columns).forEach((row) => {
    worksheet.addRow(row);
  });
};

export const createSheet = ({
  workbook,
  sheetName,
  columns,
  headersRow = 1,
  data,
  options = null,
  title,
}) => {
  let worksheet = workbook.addWorksheet(sheetName);

  let offset = 0;
  if (title) {
    worksheet.getCell("A1").value = title;
    worksheet.getCell("A1").font = titleFont;
    offset += 2;
  }

  options?.(worksheet);

  addHeaders(worksheet, columns, headersRow + offset);
  applyStylesToHeaders(worksheet, headersRow + offset);
  addPropToColumns(worksheet, columns, "width");
  addRows(worksheet, data, columns);
};

const titleFont = {
  name: "Arial",
  family: 2,
  size: 11,
  bold: true,
};
