import { CButton, CForm, CFormGroup, CLabel } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { ErrorText } from "src/components";
import DatePicker from "src/components/DatePicker/DatePicker";

import kebabcaseKeys from "kebabcase-keys";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { DB_PATHS } from "src/constants";
import {
  useDatabasePaths,
  useUpdateDatabase,
  useToasts,
  usePeriodValidation,
} from "src/hooks";
import {
  selectCoreData,
  selectCurrentEsgSurvey,
  selectSites,
} from "src/store/projectSelectors";
import { removeUndefinedFromObjectValues } from "src/utils/common-utils";

const AddNewPeriod = ({ periods }) => {
  const { t } = useTranslation();

  const { getPath } = useDatabasePaths();

  const { fullName, industrySector, email, website } =
    useSelector(selectCoreData) || {};

  const coreDataToKeep = kebabcaseKeys({
    fullName,
    industrySector,
    email,
    website,
  });

  const sitesToKeep = useSelector(selectSites).map((site) => {
    const newSite = {};

    const fieldsToKeep = [
      "name",
      "country",
      "id",
      "heating",
      "insulation",
      "lighting",
      "site-age",
      "site-type",
      "size",
      "size-unit",
    ];
    fieldsToKeep.forEach((field) => {
      if (site[field]) {
        newSite[field] = site[field];
      }
    });
    return newSite;
  });

  const currentEsgSurveyVersion = useSelector(selectCurrentEsgSurvey);

  const { addDocumentToCollection, setOrUpdateDocument, updateDocument } =
    useUpdateDatabase();


  const addPeriod = async (startDate, endDate) => {
    const previousPeriods = periods.filter((p) => p.endDate < endDate);

    const newPeriodId = await addDocumentToCollection(DB_PATHS.periods, {
      startDate,
      endDate,
    });

    await setOrUpdateDocument(
      getPath(DB_PATHS.coreData, null, newPeriodId),
      removeUndefinedFromObjectValues({
        ...coreDataToKeep,
        "report-date-from": startDate,
        "report-date-to": endDate,
      })
    );

    for (let site of sitesToKeep) {
      await setOrUpdateDocument(
        getPath([...DB_PATHS.sites, site.id], null, newPeriodId),
        removeUndefinedFromObjectValues(site)
      );
    }

    if (currentEsgSurveyVersion) {
      await setOrUpdateDocument(
        getPath(DB_PATHS.assessmentSurvey, null, newPeriodId),
        {
          currentVersion: currentEsgSurveyVersion,
        }
      );
    }

    const promises = [];
    for (let period of previousPeriods) {
      promises.push(
        setOrUpdateDocument([...DB_PATHS.periods, period.id], {
          locked: true,
        })
      );
    }

    await Promise.all(promises);
  };

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    reset,
    watch,
  } = useForm();

  const onChange = (e) => {
    setValue(e.target.name, e.target.value, { shouldTouch: true });
  };

  const { tryAndNotify } = useToasts();

  const onSubmit = async (values) => {
    const { startDate, endDate } = values;

    await tryAndNotify(
      async () => await addPeriod(startDate, endDate),
      "Added period!",
      "Error adding period!"
    );

    reset();
  };

  const { getStartDateValidation, getEndDateValidation } =
    usePeriodValidation();

  register("startDate", {
    validate: getStartDateValidation(getValues, true),
  });

  register("endDate", {
    validate: getEndDateValidation(getValues, true),
  });

  return (
    <>
      <h5>Add new period </h5>
      <CForm onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`d-flex  align-self-start justify-content-between px-2 pt-2 align-items-center border bg-light rounded`}
        >
          <CFormGroup className=" col">
            <CLabel>{t("shared.from", "From")}</CLabel>

            <DatePicker
              maxDate={new Date()}
              name="startDate"
              onChange={onChange}
              className="w-100 border-bottom"
              isClearable
              value={watch("startDate")}
            />
            <ErrorText value={errors["startDate"]} />
          </CFormGroup>
          <CFormGroup className="col ">
            <CLabel>{t("shared.to", "To")}</CLabel>

            <DatePicker
              name="endDate"
              onChange={onChange}
              className="w-100 border-bottom"
              value={watch("endDate")}
              isClearable
            />
            <ErrorText value={errors["endDate"]} />
          </CFormGroup>
          <CButton
            type="submit"
            className="col-3 px-3"
            // disabled={!name}
            color="success"
            // onClick={() => onAdd(name)}
          >
            {t("shared.add", "Add")}
          </CButton>
        </div>
      </CForm>
    </>
  );
};

export default AddNewPeriod;
