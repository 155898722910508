import { CTooltip } from "@coreui/react";
import { BsInfoCircle } from "react-icons/bs";

const QuestionHeader = ({ question, tooltip = "", hint, className }) => {
  return (
    <div className="mb-3 w-100">
      <h2 className={`${className} mb-0`}>
        {question}&nbsp;
        {tooltip && (
          <CTooltip content={tooltip} placement="top">
            <BsInfoCircle className=" mb-2 text-info" size={15} />
          </CTooltip>
        )}
      </h2>
      {hint && typeof hint === "string" ? (
        <p className="mb-0 mt-1 text-info">{hint}</p>
      ) : (
        hint
      )}
    </div>
  );
};

export default QuestionHeader;
