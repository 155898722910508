import CIcon from "@coreui/icons-react";
import {
  CButton,
  CPopover,
  CProgress,
  CProgressBar,
  CTooltip,
} from "@coreui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectSector } from "src/store/projectSelectors";
import styled from "styled-components";
import { DeleteButton, StepCard, TaskModal } from "..";
import CinemaCheckbox from "./components/CinemaCheckbox";

const ItemToFill = ({
  name,
  onFill,
  onDelete,
  progressValue,
  type = "provider",
  item,
  taskName,
  isSite = false,
  ...rest
}) => {
  const hasCompleted = progressValue >= 100;
  const [showTaskModal, setShowTaskModal] = useState(false);
  const toggle = () => {
    setShowTaskModal((prev) => !prev);
  };

  const { t } = useTranslation();

  const sector = useSelector(selectSector);
  const sectorIsCinema = sector === "Cinema";
  return (
    <>
      <StyledCard {...rest} onClick={onFill} className="position-relative">
        <CTooltip content={t("tasks.assignToCollaborator")}>
          <CButton
            style={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}
            className="ml-auto "
            variant="outline"
            size="sm"
            color="info"
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
          >
            <CIcon name="cilPlus" />
          </CButton>
        </CTooltip>
        <div className="w-75 position-relative my-3 ">
          <div className="d-block d-sm-flex w-100 justify-content-between align-items-baseline pb-2">
            <div className="d-flex align-items-center">
              <h4>{name} </h4>
              {hasCompleted ? (
                <CPopover content={t("shared.completed")}>
                  <CIcon
                    style={{
                      color: "green",
                      width: "1.5rem",
                      height: "1.5rem",
                    }}
                    name="cil-check-circle"
                    className="ml-2 mb-2"
                  />
                </CPopover>
              ) : (
                <CPopover content={t("shared.incomplete")}>
                  <CIcon
                    style={{
                      color: "orange",
                      width: "1.5rem",
                      height: "1.5rem",
                    }}
                    name="cil-x-circle"
                    className="ml-2 mb-2"
                  />
                </CPopover>
              )}
            </div>
            <div className="d-flex">
              <CTooltip content={t("shared.fillIn", "Fill in")}>
                <CButton
                  variant="outline"
                  color="success"
                  className="mr-2 site-intro-fill-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    onFill();
                  }}
                >
                  <CIcon name="cil-pencil" />
                </CButton>
              </CTooltip>
              {!!onDelete && (
                <CTooltip
                  content={
                    t("shared.delete") + " " + type ||
                    t("shared.provider", "provider")
                  }
                >
                  <div className="site-intro-delete-btn">
                    <DeleteButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                      }}
                    />
                  </div>
                </CTooltip>
              )}
            </div>
          </div>
          <CProgress
            style={{ height: "0.75rem" }}
            className="intro-progress-bar"
          >
            <CProgressBar color="primary" value={progressValue} />
          </CProgress>
          {/* {isSite && sectorIsCinema && <CinemaCheckbox site={item} />} */}
        </div>
      </StyledCard>
      <TaskModal
        name={taskName}
        label={`Assign item: ${item.name || item["provider-name"]}`}
        show={showTaskModal}
        toggle={toggle}
      />
    </>
  );
};

const StyledCard = styled(StepCard)`
  transition: all 0.3s linear;
  .progress {
    transition: all 0.3s linear;
  }

  &:hover {
    background-color: #e9e7e7;
    .progress {
      background-color: white !important;
    }
  }

  .c-dark-theme &:hover {
    background-color: #363636;
    .progress {
      background-color: #8d8d8d !important;
    }
  }
`;

export default ItemToFill;
