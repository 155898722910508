import { BaseContainer, StepCard } from "src/components";
import NavButtons from "./components/NavButtons";
import PeriodAlert from "./components/PeriodAlert";
import TasksAlert from "./components/TasksAlert";
import TopButtons from "./components/TopButtons";
import UnsavedAlert from "./components/UnsavedAlert";
import NavModal from "./components/NavModal";
import { useState } from "react";
import MenuButton from "./components/MenuButton";

const StepWrapper = ({
  stepName,
  taskName,
  siteName,
  orgName,
  showReportingPeriod = true,
  maxWidth = "690px",
  showNav = true,
  showTop = true,
  aboveCardSlot = null,
  nextClickHandler,
  prevClickHandler,
  nextIsDisabled,
  prevIsDisabled,
  tooltipsVisible = true,
  hasUnsavedChanges = false,
  showBackButton = true,
  onBackButtonClick,
  backButtonLabel,
  children,
  isSurvey,
  reportingPeriod,
  navigationSteps,
  showMenuButton = false,
  ...rest
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <BaseContainer>
        {showTop && (
          <TopButtons
            {...{
              showBackButton,
              onBackButtonClick,
              backButtonLabel,
            }}
            hasNav={!!navigationSteps}
            onNavClick={() => setShow(true)}
          />
        )}
        {showMenuButton && (
          <MenuButton
            className="grow-0 ml-auto"
            onClick={() => setShow(true)}
          />
        )}
        <div
          className="w-100 mx-auto h-100 mt-4 d-flex flex-column justify-content-center position-relative"
          style={{ maxWidth }}
        >
          {aboveCardSlot}
          {showReportingPeriod && (
            <PeriodAlert
              taskName={taskName}
              orgName={orgName}
              siteName={siteName}
              reportingPeriod={reportingPeriod}
              isSurvey={isSurvey}
            />
          )}
          <TasksAlert taskName={taskName} />

          <StepCard
            style={{
              marginBottom: showNav ? "0" : "4rem",
            }}
          >
            {children}
          </StepCard>
          {showNav && (
            <NavButtons
              {...{
                nextClickHandler,
                prevClickHandler,
                nextIsDisabled,
                prevIsDisabled,
                tooltipsVisible,
              }}
            />
          )}
          {!!hasUnsavedChanges && (
            <UnsavedAlert maxWidth={maxWidth} isSurvey={isSurvey} />
          )}
        </div>
      </BaseContainer>
      <NavModal {...{ show, setShow, navigationSteps }} />
    </>
  );
};

export default StepWrapper;
