import CIcon from "@coreui/icons-react";
import { CTooltip } from "@coreui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { analytics } from "src/adapters/common-set-up/firebase";
import { ButtonRound, BasicInput } from "src/components";
import * as XLSX from "xlsx/xlsx.mjs";

const UploadExcelButton = ({
  headers,
  customSubmit,
  fontStyle,
  externalRef,
  onClick,
}) => {
  const [uploadData, setUploadData] = useState(null);
  const innerRef = useRef();
  const fileInputRef = externalRef || innerRef;
  const dispatch = useDispatch();

  const templateParser = async (e) => {
    let input = e.target;
    let reader = new FileReader();
    reader.onload = function () {
      let data = reader.result;
      let workBook = XLSX.read(data, { type: "binary" });
      const sheetName = workBook.SheetNames[0];
      let rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName], {
        header: headers,
      });

      rows = JSON.stringify(
        rows.map((row) => {
          return { ...row, rowNum: row.__rowNum__ + 1 };
        })
      );
      const result = JSON.parse(rows).splice(1);

      setUploadData(result);
    };
    reader.readAsBinaryString(input.files[0]);
    e.target.value = null;
  };

  const onSubmit = useCallback(
    async (uploadData) => {
      try {
        if (!customSubmit) {
          return;
        }
        await customSubmit(uploadData);
        analytics.logEvent("upload_excel_template");

        return true;
      } catch (error) {
        console.log(error);

        return false;
      }
    },
    [uploadData, dispatch]
  );

  useEffect(() => {
    if (uploadData) {
      onSubmit(uploadData);
    }
  }, [uploadData]);

  const { t } = useTranslation();

  return (
    <CTooltip
      content={t(
        "shared.downloadExcelHint",
        "We only accept '.xlsx' file format, please download template"
      )}
    >
      <ButtonRound
        className="d-flex align-items-center"
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            fileInputRef.current.click();
          }
        }}
      >
        <BasicInput
          innerRef={fileInputRef}
          type="file"
          accept=".xlsx"
          style={{ display: "none" }}
          onChange={templateParser}
        />
        <CIcon name="cil-plus" />
        <span className="ml-2" style={fontStyle}>
          {t("shared.uploadExcel", "Upload Excel template")}
        </span>
      </ButtonRound>
    </CTooltip>
  );
};

export default UploadExcelButton;
