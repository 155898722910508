import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ButtonBasic, FormStepWrapper, SignOutButton } from "src/components";
import { selectOrgId } from "src/store/projectSelectors";
import AboveCard from "../components/AboveCard";

const SupplierSurvey11 = ({ onCompleted, values, isDemo, info }) => {
  useEffect(() => {
    if (isDemo) return;
    onCompleted(values["data-id"]);
  }, []);
  const { t } = useTranslation();
  const history = useHistory();
  const orgId = useSelector(selectOrgId);

  return (
    <FormStepWrapper aboveCardSlot={<AboveCard {...{ info, isDemo }} />}>
      <h1 className="mb-2 text-center">
        {t("employeeSurvey.thankYou", "Thank you for your time")}
      </h1>
      <h2 className="mb-4 text-center">
        {t("employeeSurvey.surveyComplete", "The survey is complete.")}
      </h2>
      <div>
        <p className="text-info text-center mb-1">
          {t("employeeSurvey.surveyCompleteHint1")}
        </p>
        <p className="text-info text-center w-75 mb-4 mx-auto">
          {t("employeeSurvey.surveyCompleteHint2")}
        </p>
      </div>
      {!isDemo ? (
        <SignOutButton />
      ) : (
        <ButtonBasic
          onClick={() => history.push(`/project/${orgId}/supplierData`)}
        >
          {t("employeeSurvey.backToDataCollection")}
        </ButtonBasic>
      )}{" "}
    </FormStepWrapper>
  );
};

export default SupplierSurvey11;
