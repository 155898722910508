import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DB_PATHS } from "src/constants";
import { useDatabasePaths, useToasts, useUpdateDatabase } from "src/hooks";
import { useCollection } from "src/hooks/databaseHooks";
import { generateUUID } from "src/utils/common-utils/helpers";


const SendNotificationController = () => {
  const { data, status } = useCollection(DB_PATHS.organisations);
  const { showToast } = useToasts();
  const { setOrUpdateDocument } = useUpdateDatabase();

  const [loading, setLoading] = useState(false);
  const [orgOptions, setOrgOptions] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);

  const ALL_SELECT_OPTION = {
    label: "All Organisations",
    value: "All",
  };

  useEffect(() => {
    if (status !== "success") return;
    if (!data?.length) return;

    const dataOptions = data.map(({ name, NO_ID_FIELD: uid }) => {
      return {
        label: name,
        value: uid,
      };
    });

    setOrgOptions([ALL_SELECT_OPTION, ...dataOptions]);
  }, [data, status]);

  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const onChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value, { shouldTouch: true });
  };

  const orgSelectChange = (data) => {
    const hasAllOrg = data.some(({ value }) => value === "All");

    if (hasAllOrg) {
      setSelectedOrg([ALL_SELECT_OPTION]);
    } else {
      setSelectedOrg(data);
    }
  };

  const closeHandler = () => {
    setSelectedOrg([]);
    reset();
  };

  const onSubmit = async (data) => {
    const { title, body } = data;

    if (!selectedOrg?.length) {
      setError("organisation", {
        type: "custom",
        message: "Please Select organisation ",
      });
      return;
    }

    setLoading(true);

    try {
      await sendNotification(title, body);

      showToast({
        type: "success",
        text: "Notification has sent Successfully!",
      });
    } catch (error) {
      showToast({
        type: "fail",
        text: "Failed to send notification, Try again ",
      });
    } finally {
      setSelectedOrg([]);
      setLoading(false);
    }
  };

  const sendNotification = async (title, body) => {
    if (!title && !body) return;

    const hasAllOrg = selectedOrg.some(({ value }) => value === "All");

    const updateData = {
      title,
      body,
      notifyID: generateUUID(),
    };

    if (hasAllOrg) {
      const orgsArray = orgOptions.filter(({ value }) => value !== "All");

      await writeToDB(orgsArray, updateData);
    } else {
      await writeToDB(selectedOrg, updateData);
    }
  };

  const { getPath } = useDatabasePaths();
  const { data: adminUsers } = useCollection(getPath(DB_PATHS.adminUsers));

  const writeToDB = async (orgsData, updateData) => {
    if (!orgsData?.length) return;

    orgsData.forEach(async ({ value: orgID }) => {
      adminUsers?.forEach(async (user) => {
        if (user.exists) {
          const { notification } = user.data();

          if (notification?.length) {
            await setOrUpdateDocument([...DB_PATHS.adminUsers, user.id], {
              notification: [updateData, ...notification],
            });
          } else {
            await setOrUpdateDocument([...DB_PATHS.adminUsers, user.id], {
              notification: [updateData],
            });
          }
        }
      });
    });
  };

  register("title", {
    required: true,
  });
  register("body", {
    required: true,
  });

  return {
    loading,
    onSubmit,
    handleSubmit,
    getValues,
    onChange,
    errors,
    setError,
    orgOptions,
    selectedOrg,
    setSelectedOrg,
    orgSelectChange,
    closeHandler,
  };
};

export default SendNotificationController;
