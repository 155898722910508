import { DB_PATHS } from "src/constants";
import { useUpdateDatabase } from "..";

const useWriteTasks = () => {
  const { setOrUpdateDocument, deleteField } = useUpdateDatabase();

  const writeTasks = async (tasks) =>
    await setOrUpdateDocument(DB_PATHS.tasks, { tasks });

  const writeCompletedTasks = async (completedTasks) =>
    await setOrUpdateDocument(DB_PATHS.tasks, { completedTasks });

  const deleteTask = async (path) =>
    await deleteField(DB_PATHS.tasks, "tasks", path);

  return { writeTasks, deleteTask, writeCompletedTasks };
};

export default useWriteTasks;
