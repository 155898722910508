import CIcon from "@coreui/icons-react";
import { CButton } from "@coreui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { WarningModal } from "src/components";
import { useUpdateDatabase } from "src/hooks";
import { useDoc } from "src/hooks/databaseHooks";
import { setAlertWithTimeout } from "src/store/operations";

const ResetConstants = ({
  constantsPath,
  defaultPath,
  buttonLabel,
  modalMsg,
  successMsg,
  arrayName,
  color = "danger",
  icon = "cil-reload",
  className = "mx-auto mt-4",
  customConfirmHandler,
  disabled = false,
  onConfirm,
  modalConfirmLabel = "Reset",
}) => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  const { data: defaultDoc } = useDoc(defaultPath);

  const defaultValues = !!arrayName ? defaultDoc?.[arrayName] : defaultDoc;

  const dispatch = useDispatch();

  const { updateDocument } = useUpdateDatabase();

  const overrideObject = async (path, objectName, newValue) => {
    await updateDocument(path, {
      [objectName]: newValue,
    });
  };

  const resetConstantsToDefault = async () => {
    try {
      if (!defaultValues) {
        throw new Error(t("adminConstants.unableConstants"));
      }

      if (arrayName) {
        await overrideObject(constantsPath, arrayName, defaultValues);
      } else {
        for (let key of Object.keys(defaultValues)) {
          await overrideObject(constantsPath, key, defaultValues[key]);
        }
      }

      dispatch(
        setAlertWithTimeout({
          type: "success",
          text: successMsg,
        })
      );
    } catch (err) {
      console.error(err);

      dispatch(
        setAlertWithTimeout({
          type: "error",
          text: err.message,
        })
      );
    }
  };

  const confirmHandler = async () => {
    if (customConfirmHandler) {
      await customConfirmHandler();
      return;
    } else {
      await resetConstantsToDefault();
    }

    onConfirm?.();
  };

  return (
    <>
      <CButton
        color={color}
        variant="outline"
        onClick={() => setShowModal((prev) => !prev)}
        disabled={disabled}
        className={
          "d-flex p-2  justify-content-center h-100 " + className
        }
      >
        <span className="mr-2 text-center" >{buttonLabel}</span>
        <CIcon name={icon} />
      </CButton>{" "}
      <WarningModal
        customMsg={modalMsg}
        confirmMsg={modalConfirmLabel}
        show={showModal}
        toggle={() => setShowModal((prev) => !prev)}
        onConfirm={confirmHandler}
      />
    </>
  );
};

export default React.memo(ResetConstants);
