import coreuiReducer from "./coreuiSlice";
import projectReducer from "./projectSlice";
import userReducer from "./userSlice";
import alertReducer from "./alertSlice";
import modalReducer from "./modalSlice";
import navReducer from "./navSlice";
import globalReducer from "./globalSlice";

const { configureStore } = require("@reduxjs/toolkit");

const store = configureStore({
  reducer: {
    coreui: coreuiReducer,
    user: userReducer,
    project: projectReducer,
    alert: alertReducer,
    modal: modalReducer,
    nav: navReducer,
    global: globalReducer,
  },
});

export default store;
