import React, { useState } from "react";
import { CButton, CInput } from "@coreui/react";
import { useSelector } from "react-redux";
import {
  adapator_createDatabaseRef,
  adaptor_updateOrSetPath,
} from "src/adapters";
import { selectOrgId } from "src/store/projectSelectors";
import { selectUserRole } from "src/store/userSlice";

const PeriodNameInput = ({ item }) => {
  const orgID = useSelector(selectOrgId);
  const role = useSelector(selectUserRole);

  const [isEditMode, setIsEditMode] = useState(false);
  const [error, setError] = useState();

  const writeToField = async (e) => {
    let { value } = e.target;
    if (!item["NO_ID_FIELD"]) return;
    if (e.key !== "Enter") return;

    if (!value) {
      value = "";
    }
    setError();

    const ref = adapator_createDatabaseRef([
      "organisations",
      orgID,
      "periods",
      item["NO_ID_FIELD"],
    ]);

    try {
      await adaptor_updateOrSetPath(ref, { "period-name": value });
      setIsEditMode((prev) => false);
    } catch (error) {
      setError("Failed to set name. try again");
    }
  };
  return (
    <div onClick={(e) => e.stopPropagation()}>
      {isEditMode ? (
        <>
          <CInput
            autoFocus
            placeholder="Press enter to save"
            onBlur={() => setIsEditMode(false)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                writeToField(e);
              }
            }}
            maxLength={20}
            invalid={!!error}
          />
          {error && <small className="text-danger">{error}</small>}
        </>
      ) : (
        <div
          className="d-flex align-items-center justify-content-between border rounded pl-1"
          style={{ cursor: "not-allowed" }}
        >
          <strong className="mb-0 mr-2">{item["period-name"]}</strong>
          {role !== "user" && (
            <CButton
              size="sm"
              color="info"
              onClick={() => setIsEditMode((prev) => true)}
            >
              Edit
            </CButton>
          )}
        </div>
      )}
    </div>
  );
};

export default PeriodNameInput;
