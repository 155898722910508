import { useEffect } from "react";
import { useMemo } from "react";

/**
 * <p> A custom hook for handling arrays of values  </p>
 *
 *
 * @param {String} name- The name of the array in values
 * @param {Aray} initValues - The data from DB to populate the form
 * @param {Object} formMethods - methods from useForm hook
 * @param {Object} emptyItem - An object to be added to array onAdd,
 
 *
 * @return {Object} <ul> tableItems (Array) - the data returned </ul>
 *                  <ul> changeHandler (Function) </ul>
 *                  <ul> addHandler (Function) </ul>
 *                  <ul> deleteHandler (Function) </ul
 *                  <ul> addToValues (Function) - function which adds values to the array </ul>
 *
 *
 */

const useTable = ({
  name: tableName = "",
  initValues,
  emptyItem = {},
  formMethods: { setValue, watch },
}) => {
  const values = watch(tableName);

  const dataDB = useMemo(() => initValues || [], [initValues]);

  useEffect(() => {
    if (!dataDB) return;
    setValue(tableName, dataDB);
  }, [dataDB, setValue, tableName]);

  const deleteHandler = (index) => {
    const newValues = values.filter((v, i) => i !== index);
    setValue(tableName, newValues, { shouldTouch: true });
  };

  const changeHandler = (index, fieldName, fieldValue, shouldTouch = true) => {
    setValue(`${tableName}.${index}.${fieldName}`, fieldValue, {
      shouldTouch,
    });
  };

  const addHandler = (itemToAdd = emptyItem, shouldTouch = false) => {
    if (values) {
      setValue(tableName, [...values, itemToAdd], {
        shouldTouch,
      });
    } else {
      setValue(tableName, [itemToAdd], {
        shouldTouch,
      });
    }
  };

  const addToValues = (items, shouldTouch = true) => {
    if (values) {
      setValue(tableName, [...values, ...items], { shouldTouch });
    } else {
      setValue(tableName, items, { shouldTouch });
    }
  };

  const resetValues = (items) => {
    setValue(tableName, items, { shouldTouch: true });
  };

  return {
    tableItems: values,
    changeHandler,
    addHandler,
    deleteHandler,
    addToValues,
    resetValues,
  };
};

export default useTable;
