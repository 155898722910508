import { createSlice } from "@reduxjs/toolkit";

const initialState = { constants: [] };

const globalSlice = createSlice({
  name: "global",
  initialState: initialState,
  reducers: {
    setGlobalConstants(state, action) {
      state.constants = action.payload;
    },
  },
});

export const { setGlobalConstants } = globalSlice.actions;

export default globalSlice.reducer;
