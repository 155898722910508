import { CButton } from "@coreui/react";
import styled from "styled-components";

const StyledButton = styled(CButton)`

background-color: white;

&:hover:not([disabled]){
  background-color: gainsboro;
}
`;

export default StyledButton
