import {
  isConditionallyRequiredBool,
  isRequired,
  isRequiredBetween,
  isRequiredBool,
  isRequiredNum,
} from "src/utils/validationOptions";

import { t } from "i18next";

const getSurveyFields = (getValues, workDays) => {
  const fields = {
    1: [
      {
        name: "start-date",
        options: {
          validate: {
            required: (v) => {
              if (!v) return t("validation.enterDate", "Please enter date");
              return true;
            },
            isPastDate: (date) => {
              if (Date.parse(date) - Date.now() > 0) {
                return t(
                  "validation.enterPastDate",
                  "Please enter a date in the past"
                );
              }
              return true;
            },
          },
        },
      },
      {
        name: "end-date",
        options: {
          validate: {
            isPastDate: (date) => {
              if (Date.parse(date) - Date.now() > 0) {
                return t(
                  "validation.enterPastDate",
                  "Please enter a date in the past"
                );
              }
              return true;
            },
          },
        },
      },
      {
        name: "had-leave",
        options: {
          validate: {
            required: (v) => {
              if (v === undefined) {
                return t("validation.enterAnswer", "Please enter answer");
              }
              return true;
            },
          },
        },
      },
      {
        name: "weeks-leave",
        options: {
          valueAsNumber: true,
          validate: {
            required: (v) => {
              if (getValues("had-leave") && !v) {
                return t("validation.enterAnswer", "Please enter answer");
              }
              return true;
            },
            isBetween: (v) => {
              return v < 1 || v > 52
                ? t(
                    "validation.weeksInYear",
                    "Please enter value between 1 and 52"
                  )
                : true;
            },
          },
        },
      },
    ],
    2: [
      {
        name: "days-per-week",
        options: isRequiredBetween(1, 7),
      },
      {
        name: "days-from-home",
        options: {
          ...isRequiredNum,
          validate: {
            max: (v) =>
              +v >= 0 ||
              t(
                "validation.numberBetween",
                `Please enter number between {{min}} and {{max}}`,
                { min: 0, max: 7 }
              ),
            min: (v) =>
              +v <= 7 ||
              t(
                "validation.numberBetween",
                `Please enter number between {{min}} and {{max}}`,
                { min: 0, max: 7 }
              ),
            isCorrect: (v) =>
              +v <= getValues("days-per-week") ||
              t(
                "validation.daysWeek",
                "The number cannot exceed total days worked per week."
              ),
          },
        },
      },
      { name: "home-energy", options: isRequired },
    ],
    3: [
      { name: "green-tariff", options: isRequired },
      { name: "printer-paper", options: isRequiredBetween(0, 1e3) },
      { name: "notepads", options: isRequiredBetween(0, 1e3) },
    ],
    4: [
      { name: "commute.destination", options: isRequired },
      {
        name: "uses-vehicles",
        options: isConditionallyRequiredBool(getValues, "is-commuting"),
      },
    ],
    5: [
      { name: "commute.distance", options: isRequiredBetween(1, 1e3) },
      { name: "has-second-vehicle", options: isRequiredBool },
      { name: "first-vehicle.type", options: isRequired },
      { name: "first-vehicle.size", options: isRequired },
      {
        name: "first-vehicle.fuel",
        options: {
          validate: {
            required: (v) => {
              if (
                getValues("first-vehicle.type") !==
                  t("shared.motorbike", "Motorbike") &&
                v === undefined
              ) {
                return t("validation.enterAnswer", "Please enter answer");
              }
              return true;
            },
          },
        },
      },
    ],
    6: [
      { name: "first-vehicle-share", options: isRequiredBetween(0, 100) },
      { name: "second-vehicle.type", options: isRequired },
      { name: "second-vehicle.size", options: isRequired },
      {
        name: "second-vehicle.fuel",
        options: {
          validate: {
            required: (v) => {
              if (
                getValues("second-vehicle.type") !==
                  t("shared.motorbike", "Motorbike") &&
                v === undefined
              ) {
                return t("validation.enterAnswer", "Please enter answer");
              }
              return true;
            },
          },
        },
      },
    ],
    7: [
      { name: "public-transport" },
      {
        name: "nights-away-year",
        options: isRequiredBetween(0, 365),
      },
    ],
    8: [
      // { name: "offset-travel", options: isRequiredBool },
      // { name: "offset-total", options: isRequiredBool },
    ],
    9: [
      // { name: "impact-importance", options: isRequired },
      // { name: "impact-aware", options: isRequired },
      // { name: "climate-views", options: isRequired },
    ],
    10: [
      // {
      // name: "carbon-initiatives",
      // options: isNotEmptyArray,
      // },
    ],
  };

  return fields;
};

export default getSurveyFields;
