import React from "react";
import { useState, useEffect } from "react";
import { CFormGroup, CInputCheckbox, CLabel } from "@coreui/react";
import { VerticalCheckboxes, BasicInput } from "src/components";
import { useTranslation } from "react-i18next";

const CheckboxesWithOther = ({
  answers,
  values,
  setValue,
  name,
  twoColumns = false,
}) => {
  const convertedAnswers = answers.map((item) => {
    const { value, label } = item;
    if (value !== undefined && label !== undefined) {
      return item;
    } else {
      return { label: item, value: item };
    }
  });

  const [localState, setLocalState] = useState(values[name]);

  const findOther = () => {
    return localState?.filter(
      (item) => !convertedAnswers.map((item) => item.value).includes(item)
    )[0];
  };

  const [showCustom, setShowCustom] = useState(!!findOther());
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (!isTouched) return;
    setValue(name, localState, { shouldTouch: true });
  }, [localState, setValue, isTouched, name]);

  const handleCheckboxChange = (e) => {
    setIsTouched(true);
    const item = e.target.value;
    setLocalState((prev) => {
      if (!prev) return [item];
      if (prev.includes(item)) {
        return prev.filter((el) => el !== item);
      } else {
        return [...prev, item];
      }
    });
  };


  const handleOtherChange = (newValue) => {
    setIsTouched(true);
    const oldValue = findOther();
    if (!newValue && !oldValue) return;
    setLocalState((prev) => {
      if (!prev) return [newValue];
      let newArr = prev;
      if (oldValue) {
        newArr = newArr.filter((item) => item !== oldValue);
      }
      if (newValue) {
        return [...newArr, newValue];
      }
      return newArr;
    });
  };

  const removeOther = () => {
    setIsTouched(true);
    const other = findOther();
    if (!other) return;
    setLocalState((prev) => prev.filter((item) => item !== other));
  };

  const preventPressEnter = (e) => {
    e.key === "Enter" && e.preventDefault();
  };

  const { t } = useTranslation();

  return (
    <>
      <VerticalCheckboxes
        name={name}
        onChange={handleCheckboxChange}
        data={convertedAnswers}
        defaultValues={localState}
        twoColumns={twoColumns}
      />
      <CFormGroup variant="custom-checkbox" className="my-3">
        <CInputCheckbox
          custom
          id="other"
          onChange={(e) => {
            removeOther();
            setShowCustom((prev) => !prev);
          }}
          defaultChecked={showCustom}
        />
        <CLabel variant="custom-checkbox" htmlFor="other">
          {t("shared.otherOption", "Other (please specify)")}
        </CLabel>
      </CFormGroup>
      {showCustom && (
        <BasicInput
          type="text"
          name={name}
          defaultValue={findOther()}
          onBlur={(e) => handleOtherChange(e.target.value)}
          placeholder={t("shared.pleaseSpecify", "Please specify")}
          onKeyPress={preventPressEnter}
        />
      )}
    </>
  );
};

export default CheckboxesWithOther;
