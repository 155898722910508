import { useTranslation } from "react-i18next";
import { countryListArray } from "src/constants/countryList";
import { StyledReactSelect } from "..";

const CountrySelect = ({
  value,
  defaultValue,
  customDefault,
  onChange,
  customOptions,
  name = "country",
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <StyledReactSelect
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      isSearchable
      isClearable
      backspaceRemovesValue
      classNamePrefix="react-select"
      placeholder={t("shared.selectCountry", "Select country")}
      options={(customOptions || countryListArray).map((item) => {
        return { label: item, value: item, target: { name, value: item } };
      })}
      defaultValue={{ label: defaultValue, value: defaultValue }}
      value={
        value
          ? {
              label: value,
              value: value,
            }
          : customDefault
      }
      name={name}
      onChange={onChange}
      className="w-100"
      {...rest}
    />
  );
};

export default CountrySelect;
