import styled from "styled-components";
import { CCard } from "@coreui/react";

const StyledStepCard = styled(CCard)`
  overflow-x: auto;
  width: 100%;
  padding: ${({ padding }) => (padding ? padding : "30px")};
  /* padding-top: 40px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  box-shadow: 0px 10px 20px 0px #1817430d !important;

  .assign-plus-icon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 30px;
    height: 30px;
    border: 0.1px solid var(--primary);
    border-radius: 100%;
    margin-left: -0.75rem;
    cursor: pointer;
    padding: 0.25rem;
  }

  .c-dark-theme & {
    .assign-plus-icon {
      border: 0.1px solid #ffffff;
      color: #ffffff;
    }

    .assigned-me-icon {
      color: var(--warning);
    }
  }

  @media screen and (max-width: 576px) {
    /* padding: 20px; */

    .assign-plus-icon {
      right: 0.3rem;
      top: 0.3rem;
    }
  }
`;

export default StyledStepCard;
