import AsyncSelect from "react-select/async";
import styled from "styled-components";

const StyledSelect = styled(AsyncSelect)`
  border-bottom: 0.1px solid hsla(0, 0%, 20%, 0.5);
  font-weight: 500;
  color: var(--primary);

  .react-select__control {
    background-color: transparent !important;
    border-radius: 0;
    border: none;
    /* border-bottom:  0.1px solid hsla(0, 0%, 20%, 0.1); */
    border-color: black;
    box-shadow: none;
  }

  .react-select__menu {
    border-radius: 0;
    background-color: #f5f5f5;
  }

  .react-select__multi-value {
    background-color: #181743;
    color: white;
  }

  .react-select__multi-value__label {
    color: white;
  }

  .react-select__option:hover {
    background-color: #e8e8e8;
  }

  .react-select__option--is-focused {
    background-color: #e8e8e8;
  }

  .react-select__option--is-selected {
    background-color: #808080;
  }

  .react-select__option--is-selected:hover {
    background-color: #909090;
  }

  .react-select__indicator {
    color: var(--color-secondary);
  }
`;

export default StyledSelect;
