
export const adDataIntroSteps = [
  {
    key: "welcome",
    target: ".adData-intro-welcome",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    spotlightClicks: false,
    placement: "center",
  },
  {
    key: "yes_or_no",
    target: ".ad-intro-question-yes-no",
    placement: "auto",
    disableBeacon: true,
    paragraphs: 2,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "format_categories",
    target: ".ad-intro-format-categories",
    placement: "auto",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
  {
    key: "format_picker",
    target: ".ad-intro-format-picker",
    placement: "auto",
    disableBeacon: true,
    paragraphs: 2,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
  },
];
