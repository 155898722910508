import { CCard, CCardBody, CProgress } from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { InfoTooltip } from "src/components";
import FreemiumText from "src/components/FreemiumText/FreemiumText";
import { SUPPLIER_STATUS } from "src/constants/supplierStatus";
import { setWelcomeModal } from "src/store/modalSlice";
import { selectIsFreemium, selectSuppliers } from "src/store/projectSelectors";

const SuppliersCard = () => {
  const suppliers = useSelector(selectSuppliers);
  const isFreemium = useSelector(selectIsFreemium);

  const allContactableSuppliersNum =
    suppliers?.filter((supplier) => !!supplier["can-contact"]).length || 0;

  const contactedSuppliersNum =
    suppliers?.filter((supplier) => !!supplier.status).length || 0;

  const completedSuppliersNum =
    suppliers?.filter(
      (supplier) => supplier.status === SUPPLIER_STATUS.complete
    ).length || 0;

  const contactedSuppliersRate = allContactableSuppliersNum
    ? ((contactedSuppliersNum / allContactableSuppliersNum) * 100).toFixed()
    : 100;

  const completedSuppliersRate = allContactableSuppliersNum
    ? ((completedSuppliersNum / allContactableSuppliersNum) * 100).toFixed()
    : 100;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  

  return (
    <CCard className="px-3 ">
      <CCardBody>
        <div className="d-flex flex-column flex-xl-row justify-content-between mb-xl-3">
          <h2 className="mb-2">{t("welcomeModal.supplierSurvey")}</h2>
          <span style={{ fontSize: "16px" }} className="text-muted mb-2">
            {t("welcomeModal.suppliers")} <b>{allContactableSuppliersNum}</b>
            <InfoTooltip
              content={t("welcomeModal.suppliersTooltip")}
              size={14}
            />
          </span>
        </div>
        {!isFreemium ? (
          <>
            <h5 className="mb-0 ">{t("welcomeModal.contactedSuppliers")}</h5>
            <p style={{ fontSize: "16px" }} className="text-muted mb-0">
              <b style={{ fontSize: "24px" }} className="text-info mr-2">
                {contactedSuppliersNum}
              </b>
              {t("welcomeModal.ofTotal", { rate: contactedSuppliersRate })}
            </p>
            <CProgress
              className="progress-xs"
              precision={1}
              color="info"
              value={contactedSuppliersRate}
            />
            <h5 className="mt-3 mb-0">{t("welcomeModal.surveysCompl")}</h5>
            <p style={{ fontSize: "16px" }} className="text-muted mb-0">
              <b style={{ fontSize: "24px" }} className="text-info mr-2">
                {completedSuppliersNum}
              </b>
              {t("welcomeModal.ofTotal", { rate: completedSuppliersRate })}
            </p>
            <CProgress
              className="progress-xs"
              precision={1}
              color="info"
              value={completedSuppliersRate}
            />
          </>
        ) : (
          <FreemiumText onClick={() => dispatch(setWelcomeModal({ show: false }))} text={t("freemium.sectionNotAvailable")} />
        )}
      </CCardBody>
    </CCard>
  );
};

export default SuppliersCard;
