import CIcon from '@coreui/icons-react'
import ButtonGhost from 'src/components/ButtonGhost/ButtonGhost'

const MenuButton = ({ onClick, className }) => {

    return (
        <ButtonGhost className={className} onClick={onClick}>
            <CIcon size="lg" name="cil-menu" />
        </ButtonGhost>

    )
}

export default MenuButton