import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import { ConstantsTable } from "src/components";
import ReadOnlyConstantsTable from "src/pages/globalPages/globalFactors/ReadOnlyConstantsTable";

const EmissionFactorsTabs = ({
  tabs,
  pathDB,
  section,
  readonly = false,
  canAddFactors = false,
}) => {
  if (!tabs || !tabs.length) return null;

  return (
    <CTabs activeTab={tabs[0].name}>
      <CNav
        className=" d-flex justify-content-center w-100 border-bottom"
        variant="pills"
      >
        {tabs.length > 1 &&
          tabs.map((tab, index) => {
            return (
              <CNavItem key={`${section}-${index}-nav`}>
                <CNavLink data-tab={tab.name}>{tab.label}</CNavLink>
              </CNavItem>
            );
          })}
      </CNav>
      <CTabContent className="w-100">
        {tabs.map((tab, index) => {
          return (
            <CTabPane data-tab={tab.name} key={`${section}-${index}-pane`}>
              <h2 className="w-100 text-center mt-4 mb-3">{tab.label}</h2>
              {tab.aboveTableSlot ? (
                <p className="text-center w-100">{tab.aboveTableSlot}</p>
              ) : (
                <></>
              )}
              {!readonly ? (
                <ConstantsTable
                  existingConstants={tab.data}
                  pathDB={pathDB}
                  arrayName={tab.dbName}
                  addedColumns={tab.addedColumns}
                  canAddFactors={!!tab.canAddFactors}
                />
              ) : (
                <ReadOnlyConstantsTable
                  existingConstants={tab.data}
                  addedColumns={tab.addedColumns}
                  canAddFactors={canAddFactors}
                />
              )}
            </CTabPane>
          );
        })}
      </CTabContent>
    </CTabs>
  );
};

export default EmissionFactorsTabs;
