import { CFormGroup, CInputGroup, CLabel } from "@coreui/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BasicNumberInput,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
  VerticalRadioButtons,
} from "src/components";
import { convertStrToBool } from "src/utils/common-utils";
import AboveCard from "../components/AboveCard";
import Checkbox from "src/components/Checkbox/Checkbox";
import LabQuestions from "./components/LabQuestions";

const SupplierSurvey8 = ({ values, onChange, info, isDemo }) => {
  const {
    formState: { errors },
    control,
    register,
    getValues
  } = useFormContext();

  const [buysAds, setBuysAds] = useState(values["buys-ads"]);

  const { t } = useTranslation();

  const [showLabQuestions, setShowLabQuestions] = useState(false);

  const isLab = values?.services?.includes("Dentist lab");

  return (
    <FormStepWrapper aboveCardSlot={<AboveCard {...{ info, isDemo }} />}>
      {isDemo && (
        <div className="mb-3 align-self-start ">
          <div className="d-flex flex-direction-row items-center  ">
            <div className="mr-2">
              <span className="text-danger">DEMO:</span> Show dentist lab
              questions
            </div>
            <Checkbox
              value={showLabQuestions}
              onChange={(e) => {
                setShowLabQuestions(e.target.checked);
              }}
            />
          </div>
          <p className="text-info mb-0" style={{ fontSize: "14px" }}>
            In the survey those questions will appear for suppliers who selected
            "Dentist lab" in question 2.
          </p>
        </div>
      )}
      <QuestionWrapper>
        {!isLab && !showLabQuestions ? (
          <>
            <h3 className="mb-4">
              {t(
                "supplierSurvey.q_ads",
                "Do you produce or purchase any digital advertising assets for {{name}}?",
                { name: info.orgName }
              )}
            </h3>
            <CInputGroup>
              <VerticalRadioButtons
                name="buys-ads"
                onChange={(e) => {
                  const val = convertStrToBool(e.target.value);
                  setBuysAds(val);
                  onChange({
                    target: {
                      name: "buys-ads",
                      value: val,
                    },
                  });
                }}
                data={[
                  { label: t("shared.yes"), value: true },
                  { label: t("shared.no"), value: false },
                ]}
                defaultValue={values["buys-ads"]}
              />
            </CInputGroup>
            <ErrorText value={errors["buys-ads"]} />
          </>
        ) : (
          <LabQuestions
            {...{ info, values, errors, onChange, control, register, getValues }}
          />
        )}
      </QuestionWrapper>
      {buysAds && (
        <>
          <QuestionWrapper>
            <h3>
              {t(
                "supplierSurvey.q_digitalVolume",
                `18. What volume (in total MB) of static digital content did you
              deliver in {{period}} for {{name}} to each of
              the following devices?`,
                { name: info.orgName, period: info.reportingPeriod }
              )}
            </h3>
            {["mobile", "tablet", "laptop", "desktop"].map((type) => {
              return (
                <CFormGroup className="mb-4">
                  <CLabel>{t(`shared.${type}`)}</CLabel>
                  <BasicNumberInput
                    min={0}
                    allowNegative={false}
                    name={"ads." + type}
                    defaultValue={values.ads?.[type]}
                    onChange={onChange}
                    placeholder={t("shared.pleaseSpecify")}
                  />
                  <ErrorText value={errors.ads?.[type]} />
                </CFormGroup>
              );
            })}
            <ErrorText value={errors["ads"]} />
          </QuestionWrapper>
        </>
      )}
    </FormStepWrapper>
  );
};

export default SupplierSurvey8;
