import styled from "styled-components";

import { CModal } from "@coreui/react";

const StyledModal = styled(CModal)`
  .card {
    flex-grow: 1;
  }

  [class^="col-"] {
    display: flex;
  }

  .btn-results {
    border: white !important;
    color: white !important;
  }

  .results-box {
    /* background-color: gainsboro; */
  }

  .footer-logo {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0; */
  }

  .c-dark-theme & {
    .results-box {
      /* background-color: var(--dark); */
    }
  }
`;
export default StyledModal;
