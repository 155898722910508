import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BasicInput,
  CheckboxesWithOther,
  ErrorText,
  FormStepWrapper,
  QuestionWrapper,
} from "src/components";
import { useSurveyConstants } from "src/hooks";
import AboveCard from "../components/AboveCard";

const SupplierSurvey2 = ({ values, onChange, info, isDemo }) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();
  const { suppliedServices } = useSurveyConstants();

  return (
    <FormStepWrapper aboveCardSlot={<AboveCard {...{ info, isDemo }} />}>
      <QuestionWrapper>
        <h3>
          {t(
            "supplierSurvey.q_name",
            "1. What is the name of your organisation?"
          )}
        </h3>
        <BasicInput
          type="text"
          name="name"
          defaultValue={values["name"]}
          onChange={onChange}
          placeholder={t("shared.pleaseSpecify", "Please specify")}
        />
        <ErrorText value={errors["name"]} />
      </QuestionWrapper>

      <QuestionWrapper>
        <h3 className="mb-2">
          {t(
            "supplierSurvey.q_services",
            "2. Please select the services or products you supply to {{name}}:",
            { name: info.orgName }
          )}
        </h3>
        <CheckboxesWithOther
          name="services"
          values={values}
          setValue={setValue}
          answers={suppliedServices}
          twoColumns
        />
        <ErrorText value={errors["services"]} />
      </QuestionWrapper>
    </FormStepWrapper>
  );
};

export default SupplierSurvey2;
