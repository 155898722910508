export const NOTIFICATION_KEYS = {
  purchases: "purchases",
};

export const USER_NOTIFICATION_KEYS = {
  recommendations: "recommendations",
  newUser: "user",
  newTask: "task",
  sectionOpened: "sectionOpened",
  sectionClosed: "sectionClosed",
  suppliers: "suppliers",
};
