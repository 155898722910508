export const RESULT_SECTIONS_KEYS = {
  energyLocation: "energy-location",
  energyMarket: "energy-market",
  paper: "paper",
  water: "water",
  waste: "waste",
  customerTravel: "customer-travel",
  dentistry: "dentistry",
  construction: "construction",
  labs: "labs",
  website: "website",
  data: "data",
  homeEnergy: "home-energy",
  homePaper: "home-paper",
  ac: "ac",
  fleet: "fleet",
  commute: "commute",
  travel: "travel",
  hotels: "hotels",
  pensions: "pensions",
  investments: "investments",
  materials: "materials",
  distribution: "distribution",

};

export const RESULT_KEYS = {
  locations: {
    elecLocation: "energy-location",
    elecMarket: "energy-market",
    gas: "gas",
    elecTransmission: "energy-transmission",
    gasTransmission: "gas-transmission",
    paperRec: "paper-recycling",
    paperNotRec: "paper-not-recycling",
    waterUsed: "water-used",
    waterProduced: "water-produced",
    waste: "waste",
    ac: "ac",
    fuels: "fuels-direct",
    fuelsWtt: "fuels-wtt",
    customerTravel: "customer-travel",
    dentistry: "dentistry",
    construction: "construction",
  },
  labs: "labs",
  website: "website",
  homeElec: "home-energy",
  homeGas: "home-gas",
  homePaper: "home-paper",
  passengerFleet: "passenger-fleet",
  deliveryFleet: "delivery-fleet",
  commutePublic: "commute-public",
  commutePrivate: "commute-private",
  commuteHotels: "commute-hotels",
  commuteVolunteers: "commute-volunteers",
  air: "air",
  car: "car",
  rail: "rail",
  intlRail: "intl-rail",
  bus: "bus",
  taxi: "taxi",
  metro: "metro",
  hotels: "hotels",
  travel: "travel",
  investments: "investment",
  pensions: "pension",
  accounts: "accounts",
  materials: "materials",
  distribution: "distribution",
};
