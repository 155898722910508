import { CModalHeader, CPopover } from "@coreui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsInfoCircle } from "react-icons/bs";
import { ButtonBasic, InfoTooltip } from "..";
import { StyledModal, StyledTable } from "./VehiclesSize.styles";

const PassengerVehiclesSize = () => {
  const [showSize, setShowSize] = useState(false);

  const onClickHandler = (e) => {
    setShowSize((prev) => !prev);
  };

  const { t } = useTranslation();

  const passengerVehiclesSize = t("vehicleSizesReference", {
    returnObjects: true,
  });

  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={onClickHandler}>
        <InfoTooltip
          content={t(
            "fleetData.button",
            "Click to see the vehicles size reference"
          )}
        />
      </div>
      <StyledModal show={showSize} onClose={onClickHandler} size="xl">
        <CModalHeader>
          {t("fleetData.reference", "Vehicles size reference")}
        </CModalHeader>
        <div className="table-responsive">
          <StyledTable className="table table-bordered">
            <thead>
              <tr>
                <th>{t("shared.type")}</th>
                <th>{t("shared.fuel")}</th>
                <th>{t("fleetData.marketSegment", "Market segment")}</th>
              </tr>
            </thead>
            <tbody>
              {passengerVehiclesSize.map(({ name, fuel, segments }) => (
                <tr key={name}>
                  <td>{name}</td>
                  <td>
                    <p>{fuel.common}</p>
                    <p>{fuel.diesel}</p>
                    <p>{fuel.others}</p>
                  </td>
                  <td>
                    {segments.map((type) => (
                      <CPopover
                        key={type.name}
                        header={type.info}
                        content={type.models}
                      >
                        <div className="d-flex align-items-center">
                          <span className="text-info">{type.name}</span>
                          <BsInfoCircle
                            className="ml-1 mb-3 text-info"
                            size={13}
                          />
                        </div>
                      </CPopover>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </div>
        <ButtonBasic
          style={{ borderRadius: "inherit" }}
          onClick={onClickHandler}
        >
          {t("shared.ok", "OK")}
        </ButtonBasic>
      </StyledModal>
    </>
  );
};

export default PassengerVehiclesSize;
