import React from "react";
import { useTranslation } from "react-i18next";
import { StyledCreatable } from "..";
import ErrorText from "../ErrorText/ErrorText";

const CreatableSelect = ({
  name,
  options,
  onChange,
  defaultValue,
  index,
  hasError = null,
  value,
  isMulti = true,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledCreatable
        invalid={hasError?.[name]}
        name={name}
        isMulti={isMulti}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          valueContainer: (provided, state) => ({
            ...provided,
            maxWidth: "80%",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }),
        }}
        isSearchable
        isClearable
        backspaceRemovesValue
        value={
          isMulti
            ? value.map((item) => {
                return {
                  label: item,
                  value: item,
                };
              })
            : { label: value, value: value }
        }
        placeholder={t("validation.selectCategory")}
        classNamePrefix="react-select"
        options={options}
        onChange={(e) => {
          onChange({
            target: {
              name,
              value: isMulti ? e?.map((category) => category.value) : e?.value,
            },
          });
        }}
        {...rest}
      />
      <ErrorText value={hasError?.[name]} />
    </>
  );
};

export default CreatableSelect;
