import styled from "styled-components";
import { CSelect } from "@coreui/react";
import placeholderStyle from "../Placeholder/Placeholder.style";

const StyledSelect = styled(CSelect)`
  cursor: pointer;
  font-weight: 500;
  color: var(--primary);

  &:required:invalid {
    ${placeholderStyle}
  }

  option {
    color: black !important;
  }

  &,
  &:focus {
    outline: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: var(--border);
    width: 100%;
    font-size: 14px;
  }

  &:disabled {
    border: none;
    background-color: #cccccc;

    &:hover {
      cursor: unset;
    }
  }

  .c-dark-theme & {
    option {
      color: white !important;
    }
  }
`;

export default StyledSelect;
