import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BasicNumberInput,
  BasicTableContainer,
  ConstructionMaterialsTable,
  QuestionWrapper,
} from "src/components";

const ConstructionQuestions = ({ info, values, onChange }) => {
  const { orgName } = info || {};

  const { getValues, setValue, reset, control, trigger } = useFormContext();

  const formMethods = { getValues, setValue, reset, control, trigger };
  const { t } = useTranslation();

  return (
    <>
      <QuestionWrapper className="mb-4">
        <h3 className="mb-4">
          13a. What construction materials were used in providing services to{" "}
          {orgName}?
        </h3>
        <BasicTableContainer>
          <ConstructionMaterialsTable {...{ formMethods, values }} />
        </BasicTableContainer>
      </QuestionWrapper>
      <QuestionWrapper className="mb-4">
        <h3 className="mb-4">
          13b. How much construction waste was generated in providing services
          to {orgName}? (kg)
        </h3>
        <BasicNumberInput
          min={0}
          allowNegative={false}
          name={"construction-waste"}
          defaultValue={values["construction-waste"]}
          onChange={onChange}
          placeholder={t("shared.pleaseSpecify")}
        />
      </QuestionWrapper>
    </>
  );
};

export default ConstructionQuestions;
