import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHttp } from "src/hooks";
import { selectOrgId, selectPeriod } from "src/store/projectSelectors";
import { useFactorSets } from "../useFactorSets.js";
import { ENDPOINTS } from "src/constants/endpoints.js";



const useAutoCategorisation = () => {
  const orgId = useSelector(selectOrgId);
  const periodId = useSelector(selectPeriod);

  const { sendRequest } = useHttp();

  const { t } = useTranslation();

  const [isLaunching, setIsLaunching] = useState(false);


  const { primaryCountry, calcVersion = 1 } = useFactorSets()


  const runninQueue = useSelector(
    (state) => state.project?.details?.purchaseData?.runningQueue
  );


  const launchCategorisation = async () => {
    setIsLaunching(true);
    await sendRequest(ENDPOINTS.createClassificationQueue, {
      body: JSON.stringify({ orgId, periodId, primaryCountry, calcVersion }),
      successMsg: t("requests.classificationStarted"),
    });
    setIsLaunching(false);
  };

  const launchRecategorisation = async () => {
    setIsLaunching(true);
    await sendRequest(ENDPOINTS.createClassificationQueue, {
      body: JSON.stringify({ orgId, periodId, reclassifyAll: true, primaryCountry, calcVersion }),
      successMsg: t("requests.classificationStarted"),
    });
    setIsLaunching(false);
  };

  const stopCategorisation = async () => {
    await sendRequest(ENDPOINTS.purgeClassificationQueue, {
      body: JSON.stringify({ orgId, periodId }),
      successMsg: t("requests.stopClassification"),
    });
  };

  const queueIsRunning = !!runninQueue

  return {
    launchCategorisation,
    queueIsRunning,
    stopCategorisation,
    launchRecategorisation,
    isLaunching,
  };
};

export default useAutoCategorisation;
